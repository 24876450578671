import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import check from "../assets/icons/check.svg";
// import horizontalDots from "../assets/icons/horizontalDots.svg";
import p1 from "../assets/images/p1.jpg";
// import sneaker1 from "../assets/images/sneaker1.jpg";
// import { AiOutlinePlus, AiOutlineEdit, AiOutlineMessage } from "react-icons/ai";
// import { FaRegHandshake } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useSelector, useDispatch } from "react-redux";
import { fetchBuyerOrders } from "../features/auth/authSlice";
//---------trnslate
import { useTranslation } from "react-i18next";

const BuyerOrders = () => {
  //---------trnslate
  const { t } = useTranslation();

  const [ads, setAds] = useState(1);

  const dispatch = useDispatch();

  const { user, orderedProducts } = useSelector((state) => {
    return state.auth;
  });

  const [sellerIds, setsellerIds] = useState([]);
  const [markedDeleivered, setMarkedDeleivered] = useState([]);
  const [isIncludeId, setIsIncludeId] = useState(false);

  useEffect(() => {
    if (orderedProducts) {
      for (let i = 0; i < orderedProducts.length; i++) {
        if (orderedProducts[i].orderId) {
          setsellerIds(orderedProducts[i].orderId.sellerId);
          setMarkedDeleivered(orderedProducts[i].orderId.sellersmarkedcomplete);
        }
      }
    }

    for (var i = 0; i < sellerIds.length; i++) {
      if (markedDeleivered.includes(sellerIds[i])) {
        setIsIncludeId(true);
      } else {
        setIsIncludeId(false);
      }
    }
  }, [orderedProducts, markedDeleivered, sellerIds]);

  let userId = {
    userId: user?.user?._id,
  };

  useEffect(() => {
    let body = {
      userId: user?.user?._id,
    };

    dispatch(fetchBuyerOrders(body));
  }, [dispatch, user]);

  //---------------Update Buyer Order status start================================
  //  const [walletBalanace, setWalletBalance] = useState([]);

  const UpdateOrderStatus = async (e, orderId) => {
    // console.log("orderId", orderId);

    e.preventDefault();
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `/user/update/updateOrderStatus/${orderId}`,
      // body,
      config
    );
    if (response.data.status === true) {
      toast.success(response?.data.msg);
      dispatch(fetchBuyerOrders(userId));
    }
    if (response.data.status === false) {
      toast.error(response?.data.msg);
    }
  };

  //---------------Update Buyer Order status end================================

  //=============Review system===============================
  //==============Review An Ad Modal and Api Start====================
  const [reviewAdModal, setReviewAdModal] = useState(false);
  const [textReview, setTextReview] = useState("");
  const [productid, setProductid] = useState("");
  const [buyerId, setBuyerId] = useState("");
  const [orderid, setOrderid] = useState("");
  const [rating, setRating] = useState(0);
  // console.log("rating: ", rating);
  // const ratings = allReview.map((review) => review.rating);
  // const sumRating = ratings.reduce((acc, cur) => acc + cur, 0);
  // const averageRating = sum / ratings.length;

  function tog_review_ad(orderId, productId, buyerId) {
    setBuyerId(buyerId);
    setOrderid(orderId);
    setProductid(productId);
    setTextReview("");
    setRating(0);
    setReviewAdModal(!reviewAdModal);
  }

  const reviewAd = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    try {
      if (textReview === "") {
        toast.error("Text is required");
      } else {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/product/reviewAd",
          {
            orderId: orderid,
            buyerId: user?.user._id, // Who review this Ad
            sellerId: buyerId, // to Whom review this Ad
            productId: productid,
            rating: rating,
            comment: textReview,
          },
          config
        );

        if (res.data.success === true) {
          toast.success("Review Added Successfully !");
          setTextReview("");
          setRating(0);
          setReviewAdModal(!reviewAdModal);
        }
        if (res.data.success === false) {
          toast.error(res.data.msg);
          setTextReview("");
          setRating(0);
        }
      }
    } catch (err) {
      console.log(err.response.date.error);
    }
  };
  //==============Review An Ad Modal and Api END====================

  return (
    <div>
      <div className="ads-top">
        <div className="ads-top-left">
          <h5>{t("All Orders")}</h5>
          <p>
            {t(
              "Order Activity is reported in the time zone of your ad account."
            )}
          </p>
        </div>
        <Link
          to={"/createItem"}
          className="font-poppins green-btn hover-shadow"
        >
          {t("Create Ad")}
        </Link>
      </div>

      <div className="ads-tabs">
        <button className={ads === 1 ? "active" : ""} onClick={() => setAds(1)}>
          {t("Active Orders")}
        </button>
        <button className={ads === 2 ? "active" : ""} onClick={() => setAds(2)}>
          {t("Complete Orders")}
        </button>
      </div>

      {ads === 1 && (
        <div>
          {orderedProducts &&
            orderedProducts.map((order) => (
              <div className="row">
                {!order?.orderId.sellersmarkedcomplete?.includes(
                  user.user._id
                ) && (
                  <>
                    {order?.orderId?.orderItems?.map(
                      (item, idx) =>
                        item?.user._id === user.user._id && (
                          <div className="col-lg-4 col-md-6 mx-auto " key={idx}>
                            <div className="products-item shadow">
                              <Link
                                to={`/details/${item._id}`}
                                className="product-img-container w-100"
                              >
                                {item?.type === "Urgent" && (
                                  <div className="product-ribbon urgent">
                                    {item?.type}
                                  </div>
                                )}
                                {item?.type === "Standard" && (
                                  <div className="product-ribbon standard">
                                    {item?.type}
                                  </div>
                                )}
                                {item?.type === "Featured" && (
                                  <div className="product-ribbon featured">
                                    {item?.type}
                                  </div>
                                )}
                                {item?.cartQuantity ? (
                                  <div className="product-quantity text-center">
                                    {item?.cartQuantity} {t("Quantity")}
                                  </div>
                                ) : (
                                  <div className="product-quantity text-center">
                                    {1} {t("Quantity")}
                                  </div>
                                )}
                                {item?.images ? (
                                  <img
                                    className="product-img"
                                    src={
                                      process.env.REACT_APP_IMAGES_URL +
                                      "/" +
                                      item?.images[0]
                                    }
                                    onError={(e) =>
                                      (e.target.src = "/product.jpg")
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="product-img"
                                    src={p1}
                                    alt=""
                                  />
                                )}
                              </Link>
                              <div className="product-text">
                                <div className="product-text">
                                  <div className="product-text-top">
                                    <h6>
                                      <Link to={`/details/${item._id}`}>
                                        {item?.name}
                                      </Link>
                                    </h6>
                                    <div>
                                      <AiFillEye className="me-2" />
                                      {item?.views?.length}
                                    </div>
                                  </div>
                                  <div className="product-text-bottom">
                                    <div className="desc">
                                      {item?.description}
                                    </div>
                                    <h6>
                                      ${" "}
                                      {item?.cartQuantity
                                        ? item?.price
                                        : order?.amount}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-1">
                                  {/* <button
                                className="add-feedback-btn green-btn hover-shadow"
                                data-bs-toggle="modal"
                                data-bs-target="#feedbackModal"
                              >
                                Add Feedback
                              </button> */}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center my-2 border-bottom border-dark pb-2">
                              <Link
                                className="view-invoice-btn hover-shadow"
                                onClick={(e) =>
                                  UpdateOrderStatus(e, order?.orderId._id)
                                }
                              >
                                {t("Mark As Delivered")}
                              </Link>
                            </div>
                          </div>
                        )
                    )}
                  </>
                )}

                {/* {!isIncludeId && ( */}

                {/* )} */}
              </div>
            ))}

          {!orderedProducts.length > 0 && (
            <div className="d-flex justify-content-center my-2 border-bottom border-dark pb-2">
              {t("There is no Order Placed yet")}
            </div>
          )}
        </div>
      )}
      {ads === 2 && (
        <div>
          {orderedProducts &&
            orderedProducts.map((order) => (
              <div className="row border-bottom pb-4 border-dark">
                {order?.orderId.sellersmarkedcomplete?.includes(
                  user.user._id
                ) && (
                  <>
                    {order.orderId.orderItems.map(
                      (item, idx) =>
                        item?.user._id === user.user._id && (
                          <div
                            className="col-lg-4 col-md-6 mx-auto  "
                            key={idx}
                          >
                            <div className="products-item shadow">
                              <Link
                                to={`/details/${item._id}`}
                                className="product-img-container w-100"
                              >
                                {item?.type === "Urgent" && (
                                  <div className="product-ribbon urgent">
                                    {item?.type}
                                  </div>
                                )}
                                {item?.type === "Standard" && (
                                  <div className="product-ribbon standard">
                                    {item?.type}
                                  </div>
                                )}
                                {item?.type === "Featured" && (
                                  <div className="product-ribbon featured">
                                    {item?.type}
                                  </div>
                                )}
                                {item?.cartQuantity ? (
                                  <div className="product-quantity text-center">
                                    {item?.cartQuantity} {t("Quantity")}
                                  </div>
                                ) : (
                                  <div className="product-quantity text-center">
                                    {1} {t("Quantity")}
                                  </div>
                                )}
                                {item?.images ? (
                                  <img
                                    className="product-img"
                                    src={
                                      process.env.REACT_APP_IMAGES_URL +
                                      "/" +
                                      item?.images[0]
                                    }
                                    onError={(e) =>
                                      (e.target.src = "/product.jpg")
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="product-img"
                                    src={p1}
                                    alt=""
                                  />
                                )}
                              </Link>
                              <div className="product-text">
                                <div className="product-text">
                                  <div className="product-text-top">
                                    <h6>
                                      <Link to={`/details/${item._id}`}>
                                        {item?.name}
                                      </Link>
                                    </h6>
                                    <div>
                                      <AiFillEye className="me-2" />
                                      {item?.views?.length}
                                    </div>
                                  </div>
                                  <div className="product-text-bottom">
                                    <div className="desc">
                                      {item?.description}
                                    </div>
                                    <h6>
                                      ${" "}
                                      {item?.cartQuantity
                                        ? item?.price
                                        : order?.amount}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-1">
                                  <button
                                    className="add-feedback-btn green-btn hover-shadow"
                                    onClick={() =>
                                      tog_review_ad(
                                        order?._id,
                                        item?._id,
                                        order?.orderId?.user
                                      )
                                    }
                                  >
                                    {t("Add Feedback/Review")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </>
                )}

                {/* {!isIncludeId && (
                  <div className="d-flex justify-content-center my-2 border-bottom border-dark pb-2">
                    {t("No Complete Order Found")}
                  </div>
                )} */}
              </div>
            ))}
          {!orderedProducts.length > 0 && (
            <div className="d-flex justify-content-center my-2 border-bottom border-dark pb-2">
              {t("No Complete Order Found")}
            </div>
          )}
        </div>
      )}
      {/* <p className="ad-more">
        {t("Show More Details in")}
        <Link to={"#"}> {t("Orders Manager")}</Link>
      </p> */}

      {/* REVIEW MODAL START*/}
      <Modal
        className="fade"
        show={reviewAdModal}
        onHide={() => {
          tog_review_ad();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            {t("Add Feedback")}
          </h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setReviewAdModal(false);
            }}
          />
        </div>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="form-field">
                <label>{t("Rating")}</label>
                <div className="rating-box">
                  <div className="rating-container">
                    <ReactStars
                      count={5}
                      // value={review.rating}
                      onChange={setRating}
                      size={20}
                      isHalf={true}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ffd700"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <textarea
            className="message-modal-input"
            placeholder={t("Send Review")}
            value={textReview}
            onChange={(e) => setTextReview(e.target.value)}
          ></textarea>
          <button
            className="message-modal-btn hover-shadow green-btn"
            onClick={reviewAd}
          >
            {t("Send")}
          </button>
        </ModalBody>
      </Modal>

      {/* Review MODAL END*/}
    </div>
  );
};

export default BuyerOrders;
