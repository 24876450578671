import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHomeCategories } from "../features/ad/adSlice";
import { Link } from "react-router-dom";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import Footer from "../components/Footer";
//====================React translate================================
import { useTranslation } from "react-i18next";

const CategoriesPage = () => {
  const { t } = useTranslation();

  //===============Get All Categories useSelector start=================
  const dispatch = useDispatch();
  const { homeCategories } = useSelector((state) => state.ads);

  //===============Get All Categories useSelector end=================
  //==============Use Effect================================
  useEffect(() => {
    dispatch(getHomeCategories());
  }, [dispatch]);

  //==============Use Effect end================================

  return (
    <>
      <Header />

      <Categories />
      <div className="products cat-cards my-5">
        <div className="container">
          <h5 className="cat-heading">{t("All Categories")} </h5>
          <div className="row">
            {/* {categories ? (
              categories.map((category, i) => (
                <div className="col-lg-4 col-md-6" key={i}>
                  <div className="cat-card">
                    <Link
                      to={`/products/Category=${category._id}`}
                      className="cat-img"
                    >
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          category?.images
                        }
                        alt=""
                      />
                    </Link>
                    <div className="cat-text">
                      <Link
                        to={`/products/Category=${category._id}`}
                        className="cat-name"
                      >
                        {category?.name}
                      </Link>
                      
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="card shadow-sm p-2 cate-card">
                <h3 className="mx-auto fs-6 my-2">
                  {t("Not Added Any Category Yet !")}
                </h3>
              </div>
            )} */}

            {homeCategories ? (
              homeCategories.map((cate, i) => (
                <div className="col-lg-4 col-md-6" key={i}>
                  <div className="cat-card">
                    <Link
                      to={`/products?Category=${cate.category._id}`}
                      className="cat-img"
                    >
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          cate.category?.images[0]
                        }
                        alt=""
                        onError={(e) => (e.target.src = "/img03.png")}
                      />
                    </Link>
                    <div className="cat-text ms-3 my-auto">
                      <Link
                        to={`/products?Category=${cate?.category._id}`}
                        className="cat-name"
                      >
                        {cate?.category?.name}
                      </Link>
                      <p>{cate?.count} items</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="card shadow-sm p-2 cate-card">
                <h3 className="mx-auto fs-6 my-2">
                  {t("Not Added Any Category Yet !")}
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoriesPage;
