import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ticketService from "./ticketService";

const initialState = {
  tickets: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All Tickets
export const addTicket = createAsyncThunk(
  "ticket/addTicket",
  async (body, thunkAPI) => {
    try {
      const res = await ticketService.addTicket(body);
      return res;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All Tickets
export const getAllTickets = createAsyncThunk(
  "ticket/all",
  async (body, thunkAPI) => {
    try {
      const res = await ticketService.getAllTickets(body);
      return res;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Post a Question on Ticket
export const addQuestion = createAsyncThunk(
  "ticket/addQuestion",
  async (body, thunkAPI) => {
    try {
      const response = await ticketService.addQuestion(body);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTickets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTickets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tickets = action.payload;
      });
  },
});

export const { reset } = ticketSlice.actions;
export default ticketSlice.reducer;
