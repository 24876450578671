import React, { useState, useEffect } from "react";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import Moment from "moment";

//==============Bootstrap import ============
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { toast } from "react-toastify";
//==============Bootstrap import end ============
import p1 from "../assets/images/p1.jpg";
import user1 from "../assets/images/user.png";
import paypal from "../assets/images/paypal.png";
import visa from "../assets/images/visa.png";
import payoneer from "../assets/images/payoneer.png";
import star from "../assets/icons/star.svg";
import tick from "../assets/icons/tick.svg";
import clock from "../assets/icons/clock.svg";
import chat from "../assets/icons/chat.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import { AiFillDelete, AiFillEye, AiOutlineSave } from "react-icons/ai";
import { FaCopy, FaShare } from "react-icons/fa";
import { GoReport } from "react-icons/go";
import { MdOutlineFavoriteBorder } from "react-icons/md";
// import { VscFeedback } from "react-icons/vsc";

import FsLightbox from "fslightbox-react";

//==============redux actions============
import {
  getAdById,
  postQuestion,
  postAnswer,
  getSellerAds,
  reset,
  getMainCategoryAds,
  adViewed,
  postOfferAd,
  acceptAdOffer,
  cancelAcceptedOffer,
  addToFavourite,
  addToSavedList,
} from "../features/ad/adSlice";
import { addToCart } from "../features/cart/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ReactStars from "react-rating-stars-component";
//==============redux actions end============
//====================React translate================================
import { useTranslation } from "react-i18next";

//---------Loader skeleton-------------------
import { Code } from "react-content-loader";
const MyCodeLoader = () => <Code />;

//---------Loader skeleton end-------------------

const Details = () => {
  const { t } = useTranslation();

  //-----------------------LOADING ON DATA LOAD START---------------
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // simulate data loading
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  //-----------------------LOADING ON DATA LOAD END---------------

  //==============lightboxController start================================

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const openLightboxOnSlide = (number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };
  //==============lightboxController end================================

  //==============redux Hooks start==================================
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //==============redux Hooks start==================================

  //==============AD selector start==================================

  const {
    Ad,
    Offers,
    acceptedOffers,
    sellerAd,
    categoryAd,
    allReview,
    isSuccess,
  } = useSelector((state) => state.ads);
  //==============AD selector end==================================

  let accpetedUserOffer;
  if (acceptedOffers) {
    for (let i = 0; i < acceptedOffers.length; i++) {
      accpetedUserOffer = acceptedOffers[i]?.user;
    }
  }

  //==============User selector start==================================

  const { user, profile } = useSelector((state) => {
    return state.auth;
  });

  let userId = profile?.user?._id;
  let adUserId = Ad?.user?._id;

  //==============User selector end==================================

  //==============redux end==================================

  //==============Get single Ad start==================================

  useEffect(() => {
    dispatch(getAdById(id));
  }, [dispatch, id]);

  //==============Get single Ad end==================================

  //============== Images array of Slider LightBox Start===================
  const images = Ad?.images;

  const imgUrls = [];
  images &&
    images.length !== 0 &&
    images.map((item) => {
      imgUrls.push(process.env.REACT_APP_IMAGES_URL + "/" + item);
    });

  //============== Image array of Slider LightBox end===================

  //================Hide show description start======================
  const [showDesciption, setShowDesciption] = useState(false);
  //================Hide show description start======================

  //==============Add Question Modal and Api Start===================
  const [showQ, setShowQ] = useState(false);
  function tog_add_question() {
    setQuestion("");
    setShowQ(!showQ);
  }

  //==============Question Add state====================
  const [question, setQuestion] = useState("");

  const handlePostQuestion = (e) => {
    e.preventDefault();
    const body = {
      productId: id,
      question: question,
      userId: userId,
    };
    dispatch(postQuestion(body));
  };

  useEffect(() => {
    if (isSuccess === true) {
      setShowQ(false);
      setQuestion("");
      dispatch(getAdById(id));
      dispatch(reset());
    }
  }, [dispatch, isSuccess]);

  //==============Add Question Modal and Api end  ===================

  //==============Add Answer Modal and Api Start====================
  const [showA, setShowA] = useState(false);
  const [answer, setAnswer] = useState("");

  const [quesId, setQuestionId] = useState();

  function tog_add_answer(id) {
    setQuestionId(id);
    setAnswer("");
    setShowA(!showA);
  }

  const handlePostAnswer = (e) => {
    e.preventDefault();
    const body = {
      productId: id,
      answer: answer,
      questionId: quesId,
      userId: Ad?.user?._id,
    };
    dispatch(postAnswer(body));
  };

  useEffect(() => {
    if (isSuccess === true) {
      setShowA(false);
      setAnswer("");
      dispatch(getAdById(id));
      dispatch(reset());
    }
  }, [dispatch, isSuccess]);

  //==============Add Answer Modal and Api end  ===================

  //==============Get seller Item/Products Api Start====================

  useEffect(() => {
    const body = {
      user: Ad?.user?._id,
    };
    dispatch(getSellerAds(body));
  }, [dispatch, Ad?.user?._id]);
  //==============Get seller Item/Products Api end====================

  //==============Get seller Item/Products Api Start====================

  useEffect(() => {
    const body = {
      category: Ad?.category,
    };
    dispatch(getMainCategoryAds(body));
  }, [dispatch, Ad?.category]);
  //==============Get seller Item/Products Api end====================
  //==============View Ad Item/Products Api Start====================

  useEffect(() => {
    const body = {
      adID: Ad?._id,
      user: userId,
    };
    if (userId) {
      dispatch(adViewed(body));
    }
  }, [dispatch, Ad?._id, userId]);
  //==============View Ad Item/Products Api end====================
  //==============Add Ad To Fav/wishlist Api Start===================
  const addToWishlist = () => {
    dispatch(addToFavourite({ adID: Ad?._id, userID: userId }));
  };
  //==============Add Ad To Fav/wishlist Api end====================

  //==============Add an Ad To Saved List Api Start===================
  const addToSaved = () => {
    dispatch(addToSavedList({ adID: Ad?._id, userID: userId }));
  };
  //==============Add an Ad To Saved List Api end====================

  //==============Share Ad Modal and Api Start====================
  const [shareAdModal, setShareAdModal] = useState(false);

  function tog_share_ad() {
    setShareAdModal(!shareAdModal);
  }

  const shareAd = async () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success("URL Copied Successfully");
    setShareAdModal(false);
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/product/shareAd",
        {
          adId: Ad?._id,
          userID: userId,
        },
        config
      );
    } catch (err) {
      console.log(err.response.date.error);
    }
  };

  //==============Share Ad Modal and Api End====================

  //==============Report An Ad Modal and Api Start====================
  const [textReport, setTextReport] = useState("");
  const [reportAdModal, setReportAdModal] = useState(false);

  function tog_report_ad() {
    setTextReport("");
    setReportAdModal(!reportAdModal);
  }

  const reportAd = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    try {
      if (textReport === "") {
        toast.error("Text is required");
      } else {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/product/reportAd",
          {
            adId: Ad?._id,
            user: userId,
            text: textReport,
          },
          config
        );

        if (res.data === "success") {
          toast.success("Reported Successfully !");
          setReportAdModal(false);
          tog_report_ad();
        }
      }
    } catch (err) {
      console.log(err.response.date.error);
    }
  };

  //==============Share Ad Modal and Api End====================

  //==============Post offer Modal and Api Start====================
  const [showOffer, setShowOffer] = useState(false);
  const [offerPrice, setOfferPrice] = useState("");

  function tog_add_offer() {
    setOfferPrice("");
    setShowOffer(!showOffer);
  }

  const handlePostOffer = (e) => {
    e.preventDefault();
    if (offerPrice < Ad?.price) {
      toast.error(`Price must be greater than ${Ad?.price}`);
    } else {
      const body = {
        adID: Ad?._id,
        userID: userId,
        offerPrice: offerPrice,
      };
      dispatch(postOfferAd(body));
    }
  };

  useEffect(() => {
    if (isSuccess === true) {
      setShowOffer(false);
      setOfferPrice("");
      dispatch(reset());
    }
  }, [dispatch, isSuccess]);

  //==============Post offer Modal and Api end  ===================

  //==============Accept Offer Api Start====================
  // const [showOffer, setShowOffer] = useState(false);
  // useEffect(() => {
  //   var found = false;
  //   if (Offers.length !== 0) found = Offers.find((item) => item.accepted === 1);
  //   if (found) setAccepted(true);
  //   else setAccepted(false);
  // }, [Offers]);

  const handleAcceptOffer = (e, offerId) => {
    e.preventDefault();
    const body = {
      adID: Ad?._id,
      user: userId,
      offerID: offerId,
      acceptedOffersItems: Ad,
    };
    dispatch(acceptAdOffer(body));
  };

  useEffect(() => {
    if (isSuccess === true) {
      setShowOffer(false);
      dispatch(reset());
    }
  }, [dispatch, isSuccess, Offers]);

  //==============Accept Offer Api end  ===================

  //==============Cancel Offer Api Start====================

  const handleCancelOffer = (e, offerId) => {
    e.preventDefault();
    const body = {
      adID: Ad?._id,
      user: userId,
      offerID: offerId,
    };
    dispatch(cancelAcceptedOffer(body));
  };

  useEffect(() => {
    if (isSuccess === true) {
      setShowOffer(false);
      dispatch(reset());
    }
  }, [dispatch, isSuccess, Offers]);

  //==============Cancel Offer Api end  ===================

  //==============Message To seller Start====================
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  function tog_add_message() {
    setMessageContent("");
    setShowMessage(!showMessage);
  }

  const sendMessage = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/userProConv/new",
        {
          adID: Ad?._id,
          senderId: userId,
          receiverId: adUserId,
          text: messageContent,
        },
        config
      );
      if (res.data.msg === "success") {
        toast.success("Message send successfully !");
        setShowMessage(false);
      }
    } catch (err) {
      console.log(err.response.date.error);
    }
  };

  //==============Message To seller  end  ===================

  //==============Handle Add to Cart Start  ===================

  const handleAddToCart = (cartItem) => {
    dispatch(addToCart(cartItem));
  };
  //==============Handle Add to Cart end  ===================
  //--------------------Get Reviews on Ad Start ---------------------------

  //---------calculate rating average start-------------------

  const ratings = allReview.map((review) => review.rating);
  const sum = ratings.reduce((acc, cur) => acc + cur, 0);
  const averageRating = sum / ratings.length;
  //---------calculate rating average end-------------------

  //==============count rating stars====================

  const [showReview, setShowReview] = useState(false);

  function tog_Reviewfeedbacks() {
    setShowReview(!showReview);
  }
  //--------------------Get Reviews on Ad End ---------------------------

  //=============Users reviews end =================

  //==================DELETE REVIEW BY ADMIN START===================

  const handleDeleteReview = async (e, reviewId) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/product/delte/deleteReview",
        { reviewId: reviewId },
        config
      );
      if (res.data.success === true) {
        toast.success(res.data.msg);
        setShowReview(false);
        dispatch(getAdById(id));
      }
    } catch (err) {
      console.log(err.response.date.error);
    }
  };

  //==================DELETE REVIEW BY ADMIN END=================

  //=================Get seller Review Start=======================
  //==============Get Seller Ad end==================================

  //==============Review get Api Start====================
  const [sellerReview, setSellerReview] = useState([]);

  //---------calculate rating average start-------------------

  const sellerRatings = sellerReview.map((review) => review.rating);
  const sumSellerReviews = sellerRatings.reduce((acc, cur) => acc + cur, 0);
  const averageSellerRating = sumSellerReviews / sellerRatings.length;
  //---------calculate rating average end-------------------
  useEffect(() => {
    const getallReview = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + `/product/getSellerReview/${adUserId}`
        );
        setSellerReview(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getallReview();
  }, [adUserId]);

  //==============Review get Api END====================

  //=================Get seller Review end=======================

  //=============Get refund policy modal start =================
  const [showRefund, setShowRefund] = useState(false);
  const [refundOne, setRefund] = useState([]);

  async function tog_Refund() {
    let Id = "642406183580f9874140bc90";
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/refundPolicy/getOne/${Id}`
      );
      // console.log("res", res);
      if (res.data) setRefund(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
    setShowRefund(!showRefund);
  }
  //--------------------Get Reports on Ad End ------------------

  //==============User Redirection To Login  ===================
  const handleToLogin = () => {
    navigate("/login", { replace: true });
  };
  //==============User Redirection To Login  ===================

  return (
    <div className="details-page">
      <Header />
      <Categories className="border-0" />
      {loading && <MyCodeLoader />}

      {!loading && (
        <>
          <div className="container mt-5 mb-4">
            <FsLightbox
              toggler={lightboxController.toggler}
              sources={imgUrls}
              slide={lightboxController.slide}
            />

            <div className="row">
              <div className="col-lg-7 mb-3">
                <div className="preview-img">
                  <img
                    src={
                      process.env.REACT_APP_IMAGES_URL + "/" + Ad?.images?.[0]
                    }
                    onError={(e) => (e.target.src = "/product.jpg")}
                    alt=""
                    onClick={() => openLightboxOnSlide(1)}
                  />
                  <span>{Ad?.status}</span>
                </div>

                <div className="p-imgs">
                  {images?.map((item, i) => (
                    <div key={i}>
                      <img
                        src={process.env.REACT_APP_IMAGES_URL + "/" + item}
                        alt=""
                        onError={(e) => (e.target.src = "/product.jpg")}
                        onClick={() => openLightboxOnSlide(1)}
                        className="mx-2"
                      />
                    </div>
                  ))}
                </div>

                <div className="author">
                  <Link to={`/profile/${adUserId}`} className="author-left">
                    <img
                      src={
                        process.env.REACT_APP_IMAGES_URL + "/" + Ad?.user?.image
                      }
                      onError={(e) => (e.target.src = "/user.png")}
                      className="author-user"
                      alt=""
                    />
                    <div className="author-left-text">
                      <h5>{Ad?.user?.name}</h5>
                      <p>
                        <img src={star} alt="" />
                        {averageSellerRating
                          ? parseInt(averageSellerRating)
                          : 0}{" "}
                        ({sellerRatings.length}) {t("Reviews")}
                      </p>
                    </div>
                  </Link>
                  <div className="author-right">
                    <p>
                      <img src={tick} alt="" />
                      {Ad?.user?.address} , {Ad?.user?.state}
                    </p>
                    <p className="mb-0">
                      <img src={clock} alt="" />
                      {t("Member since")}{" "}
                      {Moment(Ad?.user?.createdAt).format("YYYY")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                {/* <div className="p-price"> */}
                <div className="border-bottom border-grey">
                  <h4>{Ad?.name} </h4>

                  {/* <p onClick={() => tog_Reviewfeedbacks()}>
                    <u className="fw-bold text-success">
                      {t("Average Reviews")} :{" "}
                      {averageRating ? parseInt(averageRating) : 0}
                      <br />
                      {t("Total")} : ({allReview.length})
                    </u>
                  </p> */}
                  {/* </div> */}
                  <p onClick={() => tog_Reviewfeedbacks()}>
                    <span className="text-nowrap d-flex">
                      <ReactStars
                        count={5}
                        value={averageRating}
                        // onChange={setRating}
                        size={20}
                        edit={false}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                      />{" "}
                      ({allReview.length} {t("Reviews")})
                    </span>
                  </p>
                </div>
                <div className="p-desc">
                  <div className="p-price border-bottom-0">
                    <h5>$ {Ad?.price}</h5>

                    <div className="d-flex">
                      {!userId && userId !== adUserId ? (
                        <></>
                      ) : (
                        userId !== adUserId && (
                          <>
                            <p>
                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={addToSaved}
                              >
                                <AiOutlineSave />
                              </button>
                            </p>
                            <p>
                              <button
                                type="button"
                                className="btn btn-secondary mx-1"
                                onClick={addToWishlist}
                              >
                                <MdOutlineFavoriteBorder />
                              </button>
                            </p>
                            <p>
                              <button
                                type="button"
                                className="btn btn-light mx-1"
                                onClick={() => tog_report_ad()}
                              >
                                <GoReport />
                              </button>
                            </p>
                          </>
                        )
                      )}

                      <p>
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => tog_share_ad(Ad?._id)}
                        >
                          <FaShare />
                        </button>
                      </p>
                    </div>
                  </div>
                  <div
                    className={`description ${
                      showDesciption ? "expanded" : ""
                    }`}
                  >
                    {Ad?.description}
                  </div>
                  <Link
                    className="app-bg border-0 border-bottom border-primary text-primary"
                    onClick={() => setShowDesciption(!showDesciption)}
                  >
                    {showDesciption ? `${t("Show Less")}` : `${t("Show More")}`}
                  </Link>
                </div>
                <div className="p-specs">
                  <div className="p-spec">
                    <strong>{t("Brand")}</strong>
                    <span>{Ad?.brand}</span>
                  </div>
                  <div className="p-spec">
                    <strong>{t("Category")}</strong>
                    <span>{Ad?.category?.name}</span>
                  </div>
                  <div className="p-spec">
                    <strong>{t("Size")}</strong>
                    <span>{Ad?.size}</span>
                  </div>
                  <div className="p-spec">
                    <strong>{t("Color")}</strong>
                    <span>{Ad?.color}</span>
                  </div>
                  <div className="p-spec">
                    <strong>{t("Condition")}</strong>
                    <span>{Ad?.condition}</span>
                  </div>
                  <div className="p-spec">
                    <strong>{t("Views")}</strong>
                    <span>{Ad?.views?.length}</span>
                  </div>
                  <div className="p-spec">
                    <strong>{t("Ad Type")}</strong>
                    <span>{Ad?.type}</span>
                  </div>
                  <div className="p-spec">
                    <strong>{t("Location")}</strong>
                    <span>
                      {Ad?.address} , {Ad?.city} , {Ad?.state}
                    </span>
                  </div>
                </div>
                <div className="p-terms">
                  {t(
                    "Our Buyer Protection is added for a fee to every purchase made with the"
                  )}
                  <Link to={"#"}>“{t("Buy now")}”</Link>
                  {t("button")} .{t("Buyer Protection includes our")}{" "}
                  <Link onClick={() => tog_Refund()}>{t("Refund Policy")}</Link>
                  .
                </div>
                <div className="p-shipping">
                  <h5>{t("SHIPPING")}</h5>
                  <h5>$ {Ad?.shipping_cost ? Ad?.shipping_cost : 0}</h5>
                </div>
                <div className="p-payment">
                  <h5>{t("PAYMENT OPTION")}</h5>
                  <div className="p-options">
                    <img src={paypal} alt="" />
                    <img src={visa} alt="" />
                    <img src={payoneer} alt="" />
                  </div>
                </div>
                {userId === adUserId ? (
                  <></>
                ) : (
                  // <button
                  //   className="p-buy hover-shadow green-btn"
                  //   onClick={() => handleAddToCart(Ad)}
                  // >
                  //   ADD TO CART
                  // </button>
                  <>
                    {/* {Offers?.length !== 0 &&
                      Offers.map(
                        (offer, i) =>
                          offer?.user._id === userId &&
                          offer.acceptance === 2 && ( */}
                    <button
                      className="p-buy hover-shadow green-btn"
                      onClick={() => handleAddToCart(Ad)}
                    >
                      {t("ADD TO CART")}
                    </button>
                    {/* )
                      )} */}
                  </>
                )}
                <div className="row p-btns">
                  <div className="col-md-6">
                    {userId === adUserId ? (
                      <button
                        className="p-btn hover-shadow"
                        onClick={() => tog_add_offer()}
                      >
                        {t("Accept an offer")}
                      </button>
                    ) : accpetedUserOffer &&
                      accpetedUserOffer === user?.user?._id ? (
                      <></>
                    ) : (
                      <button
                        className="p-btn hover-shadow"
                        onClick={() => tog_add_offer()}
                      >
                        {t("Make an offer")}
                      </button>
                    )}
                  </div>
                  <div className="col-md-6">
                    {userId === adUserId ? (
                      <button className="p-btn hover-shadow">
                        <Link
                          to="/profileSettings/messages"
                          className="text-dark"
                        >
                          {t("Go to Messages")}
                        </Link>
                      </button>
                    ) : (
                      <button
                        className="p-btn hover-shadow"
                        onClick={() => tog_add_message()}
                      >
                        <img src={chat} alt="" />
                        {t("Message seller")}
                      </button>
                    )}
                  </div>
                  {/* <div className="col-md-4">
                    <button
                      type="button"
                      className="p-btn hover-shadow"
                      onClick={() => tog_feedbacks()}
                    >
                      <VscFeedback className="mr-2" />
                      {t("Users Feedback")}
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="qa-container container">
            <div className="qa-heading">
              <h5>{t("Questions And Answers")}</h5>
              <h6 className="qa-right mb-0">
                {userId === adUserId ? (
                  <></>
                ) : (
                  <button
                    className="form-save"
                    onClick={() => tog_add_question()}
                  >
                    {t("Ask Question")}
                  </button>
                )}
              </h6>
            </div>
            {Ad?.questions?.map((Q, i) => (
              <div className="qa-item" key={i}>
                <div className="qa-item-left">
                  <div className="qa-user">
                    {Q?.user?.image ? (
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          Q?.user?.image
                        }
                        onError={(e) => (e.target.src = "/user.png")}
                        alt=""
                      />
                    ) : (
                      <img src={user1} alt="" />
                    )}
                    <div className="qa-user-text">
                      <h5 className="mb-0">{Q?.user?.name}</h5>
                      <h6>{Q?.question}?</h6>
                    </div>
                  </div>
                  {Q?.answer && (
                    <div className="qa-user ms-4">
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          Ad?.user?.image
                        }
                        onError={(e) => (e.target.src = "/user.png")}
                        className="author-user"
                        alt=""
                      />
                      <div className="qa-user-text">
                        <h5 className="mb-0">{Ad?.user?.name}</h5>
                        <h6>{Q?.answer}</h6>{" "}
                      </div>
                    </div>
                  )}

                  {/* <img
                      src={
                        process.env.REACT_APP_IMAGES_URL + "/" + Ad?.user?.image
                      }
                      onError={(e) => (e.target.src = "/user.png")}
                      className="author-user"
                      alt=""
                    /> */}
                </div>
                <div className="qa-item-right text-end">
                  <p>{Moment(Q?.createdAt).format("MMM D, YYYY")}</p>
                  {userId === adUserId ? (
                    <button
                      className="form-save green-btn hover-shadow mt-2"
                      onClick={() => tog_add_answer(Q?._id)}
                    >
                      {t("Reply")}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="products container">
            <div className="products-heading">
              <h5>{t("Items of Seller")}</h5>
              <Link to={`/profile/${adUserId}`}>{t("See All")}</Link>
            </div>

            <div className="row">
              {sellerAd.length > 0 &&
                sellerAd?.map((ad, i) => (
                  <div className="col-lg-3 col-md-6" key={i}>
                    <div className="products-item shadow">
                      <Link
                        to={`/details/${ad._id}`}
                        className="product-img-container w-100"
                      >
                        {ad?.type === "Urgent" && (
                          <div className="product-ribbon urgent">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.type === "Standard" && (
                          <div className="product-ribbon standard">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.type === "Featured" && (
                          <div className="product-ribbon featured">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.images ? (
                          <img
                            className="product-img"
                            src={
                              process.env.REACT_APP_IMAGES_URL +
                              "/" +
                              ad?.images[0]
                            }
                            onError={(e) => (e.target.src = "/product.jpg")}
                            alt=""
                          />
                        ) : (
                          <img className="product-img" src={p1} alt="" />
                        )}
                      </Link>
                      <div className="product-text">
                        <div className="product-text-top">
                          <h6>
                            <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                          </h6>
                          <div>
                            <AiFillEye className="me-2" />
                            {ad?.views?.length}
                          </div>
                        </div>
                        <div className="product-text-bottom">
                          <div className="desc">{ad?.description}</div>
                          <h6>$ {ad?.price}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="products container">
            <div className="products-heading">
              <h5>{t("Similar Items (Based On Category)")}</h5>
              <Link to={"#"}>{t("See All")}</Link>
            </div>

            <div className="row">
              {categoryAd.length > 0 &&
                categoryAd?.map((ad, i) => (
                  <div className="col-lg-3 col-md-6" key={i}>
                    <div className="products-item shadow">
                      <Link
                        to={`/details/${ad._id}`}
                        className="product-img-container w-100"
                      >
                        {ad?.type === "Urgent" && (
                          <div className="product-ribbon urgent">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.type === "Standard" && (
                          <div className="product-ribbon standard">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.type === "Featured" && (
                          <div className="product-ribbon featured">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.images ? (
                          <img
                            className="product-img"
                            src={
                              process.env.REACT_APP_IMAGES_URL +
                              "/" +
                              ad?.images[0]
                            }
                            onError={(e) => (e.target.src = "/product.jpg")}
                            alt=""
                          />
                        ) : (
                          <img className="product-img" src={p1} alt="" />
                        )}
                      </Link>
                      <div className="product-text">
                        <div className="product-text-top">
                          <h6>
                            <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                          </h6>
                          <div>
                            <AiFillEye className="me-2" />
                            {ad?.views?.length}
                          </div>
                        </div>
                        <div className="product-text-bottom">
                          <div className="desc">{ad?.description}</div>
                          <h6>$ {ad?.price}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}

      {/* MODALS */}

      {/* REPORT MODAL START*/}
      {userId ? (
        <Modal
          className="fade"
          show={reportAdModal}
          onHide={() => {
            tog_report_ad();
          }}
        >
          <div className="modal-header">
            <h1 className="modal-title fs-5">{t("Report")}</h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setReportAdModal(false);
              }}
            />
          </div>
          <ModalBody>
            <div className="message-modal-top">
              <div className="offer-modal-item">
                <img
                  src={process.env.REACT_APP_IMAGES_URL + "/" + Ad?.images?.[0]}
                  onError={(e) => (e.target.src = "/product.jpg")}
                  alt=""
                />
                <div className="offer-modal-item-text">
                  <h5>{Ad?.name}</h5>
                  <p>
                    {t("Color")}: {Ad?.color},{t("Size")}:{Ad?.size}
                  </p>
                </div>
              </div>
            </div>
            <textarea
              className="message-modal-input"
              placeholder={t("Send Report")}
              value={textReport}
              onChange={(e) => setTextReport(e.target.value)}
            ></textarea>
            <button
              className="message-modal-btn hover-shadow green-btn"
              onClick={reportAd}
            >
              {t("Send")}
            </button>
          </ModalBody>
        </Modal>
      ) : (
        <Modal
          className="fade"
          show={reportAdModal}
          onHide={() => {
            tog_report_ad();
          }}
        >
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setReportAdModal(false);
              }}
            />
          </div>

          <ModalBody>
            <div className="">
              <h4 className="text-center">{t("Please Login To Add Report")}</h4>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <button
              type="button"
              className="form-save green-btn hover-shadow"
              onClick={handleToLogin}
            >
              Go To Login
            </button>
          </ModalFooter> */}
        </Modal>
      )}
      {/* REPORT MODAL END*/}

      {/*GET ALL REVIEW MODAL START*/}
      <Modal
        className="fade"
        show={showReview}
        onHide={() => {
          tog_Reviewfeedbacks();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Reviews On Product")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShowReview(false);
            }}
          />
        </div>
        <div className="message-modal-top mx-auto">
          <div className="offer-modal-item">
            <img
              src={process.env.REACT_APP_IMAGES_URL + "/" + Ad?.images?.[0]}
              onError={(e) => (e.target.src = "/product.jpg")}
              alt=""
            />
            <div className="offer-modal-item-text">
              <h5>{Ad?.name}</h5>
              <p>
                {t("Color")} : {Ad?.color},{t("Size")} :{Ad?.size}
              </p>
            </div>
          </div>
        </div>
        <div className="modal-dialog-scrollable">
          <ModalBody style={{ maxHeight: "350px ", overflowY: "scroll" }}>
            {allReview.length > 0 ? (
              allReview.map((review, idx) => (
                <div className="reviews-modal-item" key={idx}>
                  <div className="reviews-modal-item-top">
                    <div className="reviews-modal-item-left">
                      <img
                        className="reviews-modal-user"
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          review?.buyerId?.image
                        }
                        onError={(e) => (e.target.src = "/user.png")}
                        alt=""
                      />
                      <div className="reviews-modal-item-left-text">
                        <h6>{review.buyerId?.name}</h6>
                      </div>
                    </div>
                    <div className="reviews-modal-item-bottom">
                      <div className="rating-container">
                        <ReactStars
                          count={5}
                          value={review?.rating}
                          // onChange={setRating}
                          size={20}
                          edit={false}
                          isHalf={true}
                          emptyIcon={<i className="far fa-star"></i>}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          activeColor="#ffd700"
                        />
                      </div>
                    </div>
                    <div className="reviews-modal-item-right mt-3 mx-2">
                      {Moment(review?.createdAt).format("DD-MM-YYYY h:mm:ss a")}
                    </div>
                  </div>
                  <p className="reviews-modal-item-bottom">{review?.comment}</p>
                  {user?.user.role === "Admin" && (
                    <div
                      className="reviews-modal-item-right mt-3 mx-2"
                      onClick={(e) => handleDeleteReview(e, review?._id)}
                    >
                      <AiFillDelete className="text-danger fs-5 mb-2" />
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="card shadow bg-light border-0 mx-auto p-3 w-75 mb-4">
                <h5 className="text-center my-auto">
                  {t("No Reviews Found!")}
                </h5>
              </div>
            )}
          </ModalBody>
          <button className="message-modal-btn hover-shadow green-btn">
            {allReview.length}
            {t("Reviews")}
          </button>
        </div>
      </Modal>
      {/*GET ALL REVIEW MODAL END*/}

      {/* MESSAGE MODAL START*/}
      {userId ? (
        <Modal
          className="fade"
          show={showMessage}
          onHide={() => {
            tog_add_message();
          }}
        >
          <div className="modal-header">
            <h1 className="modal-title fs-5">{t("Messages")}</h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowMessage(false);
              }}
            />
          </div>
          <ModalBody>
            <div className="message-modal-top">
              <img
                src={process.env.REACT_APP_IMAGES_URL + "/" + Ad?.user?.image}
                className="author-user"
                onError={(e) => (e.target.src = "/user.png")}
                alt=""
              />

              <div className="message-modal-top-text">
                <h6 className="mb-0">
                  {Ad?.user?.name ? Ad?.user?.name : "John Doe"}
                </h6>
                <div>
                  <img src={star} alt="" />
                  <span>
                    {averageSellerRating ? parseInt(averageSellerRating) : 0} (
                    {sellerRatings.length} {t("reviews")} )
                  </span>
                </div>
              </div>
            </div>
            <textarea
              className="message-modal-input"
              placeholder={t("Send Message")}
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
            ></textarea>
            <button
              className="message-modal-btn hover-shadow green-btn"
              onClick={sendMessage}
            >
              {t("Send")}
            </button>
          </ModalBody>
        </Modal>
      ) : (
        <Modal
          className="fade"
          show={showMessage}
          onHide={() => {
            tog_add_message();
          }}
        >
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowMessage(false);
              }}
            />
          </div>

          <ModalBody>
            <div className="">
              <h4 className="text-center">
                {t("Please Login To Message Seller")}
              </h4>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="form-save green-btn hover-shadow"
              onClick={handleToLogin}
            >
              {t("Go To Login")}
            </button>
          </ModalFooter>
        </Modal>
      )}
      {/* MESSAGE MODAL END*/}

      {/* OFFER MODAL */}
      {userId ? (
        <Modal
          className="fade"
          show={showOffer}
          onHide={() => {
            tog_add_offer();
          }}
        >
          <div className="modal-header">
            {userId !== adUserId && (
              <h1 className="modal-title fs-5">{t("Make an Offer")}</h1>
            )}
            {userId === adUserId && (
              <h1 className="modal-title fs-5">{t("Accept an Offer")}</h1>
            )}
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowOffer(false);
              }}
            />
          </div>
          {userId !== adUserId && (
            <ModalBody>
              <div className="offer-modal-toggle">
                <button className={userId !== adUserId ? "active w-100" : ""}>
                  {t("Buyer")}
                </button>
              </div>
              <div className="offer-modal-item">
                <img
                  src={process.env.REACT_APP_IMAGES_URL + "/" + Ad?.images?.[0]}
                  onError={(e) => (e.target.src = "/product.jpg")}
                  alt=""
                />
                <div className="offer-modal-item-text">
                  <h5>{Ad?.name}</h5>
                  <p>
                    {t("Color")}: {Ad?.color},{t("Size")}:{Ad?.size}
                  </p>
                </div>
              </div>
              <div className="offer-modal-price">
                <p>{t("Price")}</p>
                <p>$ {Ad?.price}</p>
              </div>
              <div className="offer-modal-text">
                {t("Your Offer")}
                <div className="form-input">
                  <input
                    type="number"
                    value={offerPrice}
                    onChange={(e) => setOfferPrice(e.target.value)}
                  />
                </div>
              </div>
              <button
                className="offer-modal-btn hover-shadow green-btn"
                onClick={handlePostOffer}
              >
                {t("Send")}
              </button>
              <h4 className="border mt-4">{t("All Offers")} </h4>
              {Offers?.length !== 0 &&
                Offers.map((offer, i) => (
                  <div className="offer-modal-user" key={i}>
                    <img
                      src={
                        process.env.REACT_APP_IMAGES_URL +
                        "/" +
                        offer?.user?.image
                      }
                      onError={(e) => (e.target.src = "/user.png")}
                      alt=""
                    />
                    <div className="offer-modal-user-text">
                      <h5 className="mb-0">{offer?.user.name}</h5>
                      <div className="offer-modal-item-price">
                        {t("Price Offered")}: $ {offer?.price}
                      </div>
                    </div>
                  </div>
                ))}
              {Offers?.length === 0 && (
                <div className="offer-modal-user-text">
                  <h5 className="mb-0 text-center my-3">
                    {t("No Offer Found !")}
                  </h5>
                </div>
              )}
            </ModalBody>
          )}
          {userId === adUserId && (
            <ModalBody>
              <div className="offer-modal-toggle">
                <button className={userId === adUserId ? "active w-100" : ""}>
                  {t("Buyer's Offers")}
                </button>
              </div>
              {Offers?.length !== 0 &&
                Offers.map((offer, i) => (
                  <div className="offer-modal-user" key={i}>
                    <img
                      src={
                        process.env.REACT_APP_IMAGES_URL +
                        "/" +
                        offer?.user?.image
                      }
                      onError={(e) => (e.target.src = "/user.png")}
                      alt=""
                    />
                    <div className="offer-modal-user-text">
                      <h5 className="mb-0">{offer?.user.name}</h5>
                      <div className="offer-modal-item-price">
                        {t("Price Offered")}: $ {offer?.price}
                      </div>
                    </div>
                    <div className="offer-modal-item-btn">
                      {offer.acceptance === 1 && (
                        <button
                          className="green-btn hover-shadow"
                          onClick={(e) => handleAcceptOffer(e, offer?._id)}
                        >
                          {t("Accept")}
                        </button>
                      )}
                      {offer?.acceptance === 2 && (
                        <button
                          className="red-btn hover-shadow"
                          onClick={(e) => handleCancelOffer(e, offer?._id)}
                        >
                          {t("Cancel")}
                        </button>
                      )}
                      {offer?.acceptance === 0 && (
                        <>
                          <button
                            className="green-btn hover-shadow"
                            onClick={(e) => handleAcceptOffer(e, offer?._id)}
                          >
                            {t("Accept")}
                          </button>
                          <button
                            className="red-btn hover-shadow"
                            onClick={(e) => handleCancelOffer(e, offer?._id)}
                          >
                            {t("Cancel")}
                          </button>
                        </>
                      )}

                      {/* ) : (
                        <button className="green-btn hover-shadow" disabled>
                          Can't Accept
                        </button>
                        // <Button style={buttonStyle} disabled>
                        //   {" "}
                        //   Can't Accept{" "}
                        // </Button> */}
                    </div>
                  </div>
                ))}
              {Offers?.length === 0 && (
                <div className="offer-modal-user-text">
                  <h5 className="mb-0 text-center my-3">
                    {t("No Offer Found !")}
                  </h5>
                </div>
              )}
            </ModalBody>
          )}
        </Modal>
      ) : (
        <Modal
          className="fade"
          show={showOffer}
          onHide={() => {
            tog_add_offer();
          }}
        >
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowOffer(false);
              }}
            />
          </div>

          <ModalBody>
            <div className="">
              <h4 className="text-center">{t("Please Login To Add Offer")}</h4>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="form-save green-btn hover-shadow"
              onClick={handleToLogin}
            >
              {t("Go To Login")}
            </button>
          </ModalFooter>
        </Modal>
      )}

      {/*QUESTION ASK MODAL */}
      {userId ? (
        <Modal
          className="fade"
          show={showQ}
          onHide={() => {
            tog_add_question();
          }}
        >
          <div className="modal-header">
            <h1 className="modal-title fs-5">{t("Ask Question")}</h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowQ(false);
              }}
            />
          </div>

          <ModalBody>
            <div className="form-field">
              <label>{t("Question")}</label>
              <div className="form-input">
                <input
                  type="text"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="form-save green-btn hover-shadow"
              onClick={handlePostQuestion}
            >
              {t("Submit")}
            </button>
          </ModalFooter>
        </Modal>
      ) : (
        <Modal
          className="fade"
          show={showQ}
          onHide={() => {
            tog_add_question();
          }}
        >
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowQ(false);
              }}
            />
          </div>

          <ModalBody>
            <div className="">
              <h4 className="text-center">
                {t("Please Login To Add Question")}
              </h4>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="form-save green-btn hover-shadow"
              onClick={handleToLogin}
            >
              {t("Go To Login")}
            </button>
          </ModalFooter>
        </Modal>
      )}

      {/*QUESTION ASK MODAL END */}

      {/*SHARE AD MODAL Start*/}
      {userId ? (
        <Modal
          className="fade"
          show={shareAdModal}
          onHide={() => {
            tog_share_ad();
          }}
        >
          <div className="modal-header">
            <h1 className="modal-title fs-5">{t("Share Ad")}</h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShareAdModal(false);
              }}
            />
          </div>

          <ModalBody>
            <div className="form-field">
              <div className="form-input">
                <input
                  type="text"
                  placeholder={window.location.href}
                  defaultValue={window.location.href}
                  disabled={true}
                />
                <span className="cursor-pointer mx-2">
                  <FaCopy onClick={shareAd} className="fs-5 " />
                </span>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {/* <button
              type="button"
              className="form-save green-btn hover-shadow"
              onClick={handlePostQuestion}
            >
              Submit
            </button> */}
          </ModalFooter>
        </Modal>
      ) : (
        <Modal
          className="fade"
          show={shareAdModal}
          onHide={() => {
            tog_share_ad();
          }}
        >
          <div className="modal-header">
            <h1 className="modal-title fs-5">{t("Share Ad")}</h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShareAdModal(false);
              }}
            />
          </div>

          <ModalBody>
            <div className="">
              <h4 className="text-center">{t("Please Login To Share Ad")}</h4>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="form-save green-btn hover-shadow"
              onClick={handleToLogin}
            >
              {t("Go To Login")}
            </button>
          </ModalFooter>
        </Modal>
      )}

      {/*SHARE AD MODAL END */}

      {/* REPLY MODAL */}
      <Modal
        className="fade"
        show={showA}
        onHide={() => {
          tog_add_answer();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Reply")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShowA(false);
            }}
          />
        </div>

        <ModalBody>
          <div className="form-field">
            <label>{t("Answer")}</label>
            <div className="form-input">
              <input
                type="text"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="form-save green-btn hover-shadow"
            onClick={handlePostAnswer}
          >
            {t("Submit")}
          </button>
        </ModalFooter>
      </Modal>

      {/*GET REFUND MODAL START*/}

      <Modal
        className="fade"
        show={showRefund}
        onHide={() => {
          tog_Refund();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{refundOne?.title}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShowRefund(false);
            }}
          />
        </div>

        <div className="modal-dialog-scrollable">
          <ModalBody style={{ maxHeight: "350px ", overflowY: "scroll" }}>
            <div className="col-md-12 mx-auto">
              <div dangerouslySetInnerHTML={{ __html: refundOne?.content }} />
            </div>
          </ModalBody>
        </div>
      </Modal>

      {/*GET REFUND MODAL END*/}

      <div className="my-5"></div>

      <Footer />
    </div>
  );
};

export default Details;
