import { useEffect, useState } from "react";
import axios from "axios";
import Moment from "moment";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";

// import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { BsFillChatFill } from "react-icons/bs";
//===================Redux===================
import { useSelector } from "react-redux";
//---------trnslate
import { useTranslation } from "react-i18next";

const UserTickets = () => {
  const { t } = useTranslation();

  //========auth user information
  const { user } = useSelector((state) => state.auth);
  // const { tickets } = useSelector((state) => state.ticket);
  // console.log("tickets", tickets);

  //=====Get All Tickets START=====
  const [tickets, setAllTickets] = useState([]);
  useEffect(() => {
    getAllTickets();
  }, []);

  // GET All Tickets
  const getAllTickets = async () => {
    let body = {
      user: user?.user?._id,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/ticket/getAllTickets",
        body,
        config
      );
      if (res.data) setAllTickets(res?.data);
    } catch (err) {
      console.log(err.response.data.error);
    }
  };

  //=====Get All Tickets END=====
  //=====Add Ticket START=====
  //---------------------Form Data with change Handler start------------------------
  const [formData, setFormData] = useState({
    title: "",
    relatedTo: "",
    description: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  //---------------------Form Data with change Handler end------------------------

  //-------------------Handle Add Faq start-----------------------
  const [add_ticket, setadd_ticket] = useState(false);
  function tog_add_ticket() {
    setFormData({
      title: "",
      relatedTo: "",
      description: "",
    });
    setadd_ticket(!add_ticket);
  }

  const handleAddTicket = async (e) => {
    e.preventDefault();

    try {
      const url = process.env.REACT_APP_API_URL + "/ticket/addTicket";
      const response = await axios({
        method: "post",
        url: url,
        data: {
          user: user?.user?._id,
          title: formData.title,
          related: formData.relatedTo,
          description: formData.description,
        },
        headers: {
          "x-auth-token": localStorage.token,
        },
      });
      // console.log("success", response);
      if (response.data.success === true) {
        toast.success(response.data?.message);
        setadd_ticket(!add_ticket);
        getAllTickets();
        setFormData({
          title: "",
          relatedTo: "",
          description: "",
        });
      } else {
        if (response.data.success === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //=====Add Ticket END=====

  return (
    <div>
      <div className="profile-infos d-block">
        <div className="d-flex justify-content-between mb-4">
          <div>
            <h5 className="withdraw-title mt-2">{t("Tickets")} </h5>
          </div>
          <div className="infos-btns">
            <button
              className="me-2 green-btn hover-shadow"
              onClick={() => tog_add_ticket()}
            >
              {t("Add Ticket")}
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table shop-table table-bordered">
            <thead>
              <tr>
                <th className="text-center">Sr. no</th>
                <th className="text-center">{t("Title")}</th>
                <th className="text-center">{t("Added By")}</th>
                <th className="text-center">{t("Related")}</th>
                <th className="text-center">{t("Created At")} </th>
                <th className="text-center">{t("Status")}</th>
                <th className="text-center">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {tickets.length > 0 ? (
                tickets.map((ticket, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{ticket.title}</td>
                    <td className="text-center">{ticket.user?.name}</td>
                    <td className="text-center">{ticket.related}</td>
                    <td className="text-center text-nowrap">
                      {" "}
                      {Moment(ticket?.createdAt).format("DD-MM-YYYY h:mm a")}
                    </td>
                    {ticket.status === "Pending" && (
                      <td className="text-center text-warning">
                        {ticket.status}
                      </td>
                    )}
                    {ticket.status === "Cancelled" && (
                      <td className="text-center text-danger">
                        {ticket.status}
                      </td>
                    )}
                    {ticket.status === "Resolved" && (
                      <td className="text-center text-success">
                        {ticket.status}
                      </td>
                    )}

                    <td className="text-center">
                      <div className="d-flex justify-content-center">
                        <Link
                          to={`/profileSettings/details/${ticket._id}`}
                          className="btn px-5 btn-light-primary"
                        >
                          <BsFillChatFill className="text-secondary" />
                        </Link>

                        {/* <button className="btn ">
                          <AiOutlineEdit className="text-primary" />
                        </button>
                        <button className="btn ">
                          <AiOutlineDelete className="text-danger" />
                        </button> */}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="d-flex justify-content-center ">
                  <h6 className="text-center">{t("No Ticket Found")}</h6>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Add Faq Modal start */}

      <Modal
        isOpen={add_ticket}
        toggle={() => {
          tog_add_ticket();
        }}
        size="md"
        centered
      >
        <div className="bg-light p-3 d-flex justify-content-between">
          <div>
            <h2 className="pt-2">{t("Add Ticket")}</h2>
          </div>
          <div className="">
            <Button
              type="button"
              onClick={() => {
                setadd_ticket(false);
              }}
              className="btn-close "
              aria-label="Close"
            ></Button>
          </div>
        </div>

        <ModalBody>
          <form className="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div className="row">
              <div className="d-flex flex-column col-lg-12 mb-8 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                  <span className="required">{t("Title")}</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid mb-3"
                  placeholder={t("Enter your Title")}
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>
            <div className="d-flex flex-column col-lg-12 mb-8 fv-row fv-plugins-icon-container">
              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                <span className="required">{t("Related To / Category")}</span>
              </label>
              <input
                type="text"
                className="form-control form-control-solid mb-3"
                placeholder={t("Enter your Category")}
                name="relatedTo"
                value={formData.relatedTo}
                onChange={handleChange}
              />
              <div className="fv-plugins-message-container invalid-feedback" />
            </div>

            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label className="fs-6 fw-semibold mb-2">
                {t("Description")}
              </label>
              <textarea
                className="form-control form-control-solid mb-3"
                rows={4}
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder={t("Type your description")}
                defaultValue={""}
              />
              <div className="fv-plugins-message-container invalid-feedback" />
            </div>
            <div className="text-center">
              <div className="infos-btns mx-auto">
                <button
                  className=" my-2 green-btn hover-shadow"
                  onClick={handleAddTicket}
                >
                  {t("Submit")}
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* Add Faq Modal end */}
    </div>
  );
};

export default UserTickets;
