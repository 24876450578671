import axios from "axios";

// GET Colors
const getAllColors = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/color/getAllColors"
  );
  return response?.data;
};

const colorServcie = {
  getAllColors,
};

export default colorServcie;
