import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import userImg from "../assets/images/user1.jpg";
import Moment from "moment";
import Pagination from "../pages/Pagination";

//====================React translate================================
import { useTranslation } from "react-i18next";

const Transactions = () => {
  const { t } = useTranslation();

  useEffect(() => {
    getTransactionHistory();
  }, []);

  const [transactions, setTransactions] = useState([]);

  const getTransactionHistory = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/user/get/fetchTransactionHistory",
      config
    );
    // console.log("response", response);
    if (response) {
      setTransactions(response?.data?.transactoins);
    }
  };

  // //==============Pagination  START=============================

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(8);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = transactions?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(transactions?.length / recordsPerPage);
  // //==============Pagination  END=============================
  return (
    <div>
      {" "}
      <div className="profile-infos d-block">
        <h5 className="withdraw-title">{t("Transactions Logs")}</h5>
        <div className="table-responsive">
          <table className="table shop-table table-bordered">
            <thead>
              <tr>
                <th className="text-center">Sr. no</th>
                <th className="text-center">{t("Seller/Buyer")} </th>
                <th className="text-center">{t("Amount")}</th>
                <th className="text-center">{t("Description")}</th>
                <th className="text-center">{t("Created At")}</th>
                <th className="text-center">{t("Credit/Debit")}</th>
                {/* <th className="text-center">Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {transactions &&
                currentRecords.map((trns, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td className="text-center">
                      {trns?.user?.image ? (
                        <>
                          <Link to={`/profile/${trns?.user?._id}`}>
                            <img
                              src={
                                process.env.REACT_APP_IMAGES_URL +
                                "/" +
                                trns?.user?.image
                              }
                              style={{
                                width: "25px",
                                height: "25px",
                                objectFit: "cover",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              alt=""
                            />
                            <span className="text-dark">
                              {" "}
                              {trns?.user?.name}
                            </span>
                          </Link>
                        </>
                      ) : (
                        <img
                          style={{
                            width: "25px",
                            height: "25px",
                            objectFit: "cover",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                          src={userImg}
                          alt=""
                        />
                      )}
                    </td>
                    <td className="text-center">$ {trns?.amount.toFixed(2)}</td>
                    <td className="text-center">{trns?.description}</td>
                    <td className="text-center">
                      {Moment(trns?.createdAt).format("LLL")}
                    </td>
                    <td className="text-center text-success">{trns?.type}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Transactions;
