import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "react-bootstrap";
import Moment from "moment";

import logo from "../assets/images/logo.png";
import search from "../assets/icons/search.svg";
import user2 from "../assets/images/user2.jpg";
import {
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiFillBell,
} from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";

import { toast } from "react-toastify";
import { getUserProfile, reset, logout } from "../features/auth/authSlice";
import {
  searchByKeyword,
  // searchByCategory,
} from "../features/search/searchSlice";
import { getAllCategoriesMain } from "../features/category/categorySlice";

import { getTotals } from "../features/cart/cartSlice";
import axios from "axios";

//===================Redux===================
import { useSelector, useDispatch } from "react-redux";

//---------trnslate
import { useTranslation } from "react-i18next";

const LoggedHeader = () => {
  // const countryModal = useRef();
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   countryModal.current.click();
  // }, []);
  //==============Redux hooks================================
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, profile } = useSelector((state) => state?.auth);
  const userId = localStorage.getItem("userId");
  const { cartTotalQuantity } = useSelector((state) => state.cart);
  const { categories } = useSelector((state) => state.category);

  const cart = useSelector((state) => state.cart);

  //==============Get Total Quatity plus tota start==================================

  React.useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);
  //==============Get single Ad start==================================

  //==============  State start =============================
  const [active, setActive] = useState(false);

  //==============  State end ===============================

  //==============Use Effect================================
  useEffect(() => {
    if (userId) {
      dispatch(getUserProfile(userId));
    }
  }, [dispatch, userId]);

  //==============Use Effect================================

  //============Get All Categories start==================
  useEffect(() => {
    dispatch(getAllCategoriesMain());
  }, [dispatch]);

  // handle Main change
  const [mainCategory, setMainCategoryData] = useState("");
  const handleMainCategoryChange = (e) => {
    setMainCategoryData(e.target.value);
  };
  //============Get All Categories end====================

  //==============Logout Handler Submit=====================
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    dispatch(reset());
    toast.success("Logout successfully !");
  };

  //==============Login Handler Submit End===================

  //==============  State search start =============================

  const [searchItem, setSearchItem] = useState("");
  const [options, setOptions] = useState([]);
  const loading = options.length === 0;

  // const [searchedData, setSearchedData] = useState([]);

  // const onChangeDatas = async (searchItem) => {
  //   setSearchItem(searchItem);
  //   if (searchItem) {
  //     const url =
  //       process.env.REACT_APP_API_URL + `/product/search/product/${searchItem}`;
  //     const response = await axios.get(url);
  //     setSearchedData(response?.data);
  //   }
  // };

  //==============  State search end =============================

  const onChangeData = (e) => {
    if (e.target.value.length > 1) {
      setTimeout(async () => {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/product/search/getSuggetions",
          {
            string: e.target.value,
          }
        );
        if (res.data) {
          const { ads, users } = res.data;
          const formatData = [];
          if (ads.length !== 0) {
            ads.map((item) =>
              formatData.push({
                id: item._id,
                title: item.name,
                img: item.images[0],
                brand: item.brand,
                type: "Ad",
              })
            );
          }
          // if (users.length !== 0) {
          //   console.log("----", users);

          //   users.map((item) =>
          //     formatData.push({
          //       id: item._id,
          //       title: item.name,
          //       img: item.image,
          //       type: "user",
          //     })
          //   );
          // }
          if (formatData.length !== 0) {
            setOptions(formatData);
          }
        }
      }, 500);
    }
    setSearchItem(e.target.value);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      if (searchItem) {
        navigate(`/products?Keyword=${searchItem}`);
        dispatch(searchByKeyword({ keyword: searchItem }));
      }
    }
  };

  const handleClickedSearch = (e) => {
    e.preventDefault();
    if (searchItem) {
      navigate(`/products?Keyword=${searchItem}`);
      dispatch(searchByKeyword({ keyword: searchItem }));
    }
    if (mainCategory) {
      navigate(`/products?Category=${mainCategory}`);
    }
  };

  //==============Location Filter Ads start ===========================
  const [location, setLocation] = useState();
  const handleLocationSearch = (e) => {
    e.preventDefault();
    if (location) {
      navigate(`/products?Location=${location}`);
      setShowLocation(!showLocation);
    }
  };

  //-----------location modal-----------
  const [showLocation, setShowLocation] = useState(false);
  function tog_location() {
    setLocation("");
    setShowLocation(!showLocation);
  }

  //==============Location Filter Ads end =============================

  //==============Language change =================
  const languages = [
    { value: "", text: "Select" },
    { value: "en", text: "English" },
    { value: "it", text: "Italian" },
    { value: "fr", text: "France" },
    { value: "de", text: "Germen" },
  ];

  const [lang, setLang] = useState("");
  const handleChange = (e) => {
    setLang(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  //============Get Notification for Admin And User==================
  const [notificatios, setNotifications] = useState([]);
  const [userNotificatios, setUserNotifications] = useState([]);

  const getNotifications = async () => {
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/logs/getAdminNotis",
        config
      );
      if (res.data) setNotifications(res?.data);
    } catch (err) {
      console.log(err.response.data.error);
    }
  };

  const getUserNotifications = async () => {
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/logs/getUserLogs",
        config
      );
      if (res.data) setUserNotifications(res?.data);
    } catch (err) {
      console.log(err.response.data.error);
    }
  };

  useEffect(() => {
    getUserNotifications();
  }, []);

  //-----------------On Click User Notifcation Seen--------------------
  const handleSeenNotification = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/logs/userSeen/${id}`,
        config
      );
      if (res.data.status === true) {
        getUserNotifications();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="container-fluid header-container">
        <div className="container header">
          <div className="header-left">
            <Link to={"/"}>
              <img src={logo} width="220" alt="" />
            </Link>
          </div>
          <div className="header-center">
            <div className="header-center-search">
              <div className="dropdown  bg-success rounded">
                <select
                  className="form-cancel-2 language-dropdown-2"
                  value={mainCategory}
                  onChange={(e) => handleMainCategoryChange(e)}
                >
                  <option value="All...">{t("All...")}</option>
                  {categories
                    ? categories.map((category, i) => (
                        <option value={category?._id} key={i}>
                          {category?.name}
                        </option>
                      ))
                    : null}
                </select>

                {/* <button
                  className="header-search-submit "
                  onClick={handleClickedSearch}
                >
                  <img src={search} alt="" />
                </button> */}
              </div>

              <input
                type="text"
                placeholder={t("Press Enter to continue...")}
                value={searchItem}
                onChange={onChangeData}
                onKeyPress={onKeyPress}
                loading={loading}
              />
            </div>
            <button
              className="header-search-submit"
              onClick={handleClickedSearch}
            >
              <img src={search} alt="" />
            </button>
          </div>
          {searchItem && (
            <div className="card position-fixed header-search-card pt-2">
              <div style={{ height: "200px" }}>
                <div className="custom-notifications ml-0">
                  {options ? (
                    options?.map((data, idx) => (
                      <li className="unread border-bottom pt-1" key={idx}>
                        <Link to={`/details/${data?.id}`} className=" d-flex">
                          <img
                            src={
                              process.env.REACT_APP_IMAGES_URL + "/" + data?.img
                            }
                            alt=""
                            className="pro-img rounded-circle mx-2 my-1"
                          />
                          <div className="">
                            <h6 className="text-overflow text-muted mb-0">
                              {data?.title}
                            </h6>
                            <p className="text-muted">{data?.brand}</p>
                          </div>
                        </Link>
                      </li>
                    ))
                  ) : (
                    <>
                      <h2>No Item Found</h2>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {user ? (
            <div className="header-right">
              <Link className="header-navlink" onClick={() => tog_location()}>
                <BiCurrentLocation />
              </Link>
              {user?.user.role !== "Admin" && (
                <>
                  {/* <Link className="header-navlink " to={"/wishlist"}>
                    <AiOutlineHeart />
                  </Link> */}
                  <Link
                    className="header-navlink cart-navlink cart-animate"
                    to={"/checkout"}
                  >
                    <AiOutlineShoppingCart />
                    <div>{cartTotalQuantity ? cartTotalQuantity : 0}</div>
                  </Link>
                </>
              )}

              <select
                className="form-cancel language-dropdown"
                value={lang}
                onChange={handleChange}
              >
                {languages.map((item) => {
                  return (
                    <option key={item.value} value={t(`${item.value}`)}>
                      {t(`${item.text}`)}
                    </option>
                  );
                })}
              </select>

              <div className="dropdown profile-dropdown">
                <button
                  className="dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {profile?.user?.image ? (
                    <img
                      src={
                        process.env.REACT_APP_IMAGES_URL +
                        "/" +
                        profile?.user?.image
                      }
                      onError={(e) => (e.target.src = "/user.png")}
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        process.env.REACT_APP_IMAGES_URL +
                        "/" +
                        profile?.user?.image
                      }
                      alt=""
                      onError={(e) => (e.target.src = "/user.png")}
                    />
                  )}
                  {profile?.user?.name ? profile?.user?.name : "John Smith"}
                </button>
                <ul className="dropdown-menu">
                  {user?.user.role === "Admin" ? (
                    <li>
                      <Link to={"/dashboard"}>{t("Dasboard")}</Link>
                    </li>
                  ) : (
                    <>
                      <li className="border-bottom ">
                        <Link to={"/profileSettings/profile"}>
                          {t("Dasboard")}
                        </Link>
                      </li>
                      <li className="border-bottom ">
                        <Link to={"/createItem"}>{t("Create Ad")}</Link>
                      </li>
                      <li className="border-bottom ">
                        <Link to={"/profileSettings/messages"}>
                          {t("Messages")}
                        </Link>
                      </li>
                      <li className="border-bottom ">
                        <Link to={"/profileSettings/wallet"}>
                          {t("My Wallet")}
                        </Link>
                      </li>
                      <li className="border-bottom ">
                        <Link to={"/profileSettings/savedAds"}>
                          {t("Saved Ads")}
                        </Link>
                      </li>
                      <li className="border-bottom ">
                        <Link to={"/wishlist"}>{t("My WishList")}</Link>
                      </li>
                      <li className="border-bottom ">
                        <Link to={"/profileSettings/orders"}>
                          {t("My Orders")}
                        </Link>
                      </li>
                      <li className="border-bottom ">
                        <Link to={"/profileSettings/tickets"}>
                          {t("Support")}
                        </Link>
                      </li>
                    </>
                  )}

                  <li>
                    <Link onClick={handleLogout}>{t("Logout")}</Link>
                  </li>
                </ul>
              </div>
              {user?.user.role === "Admin" ? (
                <div className="dropdown-noti ">
                  <button
                    id="dLabel"
                    className="dropdown-toggle border-0  header-navlink"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={getNotifications}
                  >
                    <AiFillBell />
                  </button>

                  <ul
                    className="dropdown-menu notifications mt-2"
                    role="menu"
                    aria-labelledby="dLabel"
                  >
                    <li className="divider"></li>
                    <div className="notifications-wrapper">
                      {notificatios &&
                        notificatios?.map((log, idx) => (
                          <>
                            {log?.type === "Product" && (
                              <Link
                                className="content"
                                to={`/details/${log?.targetId}`}
                                key={idx}
                              >
                                <div className="notification-item">
                                  <h4 className="item-title">
                                    {log?.user?.name} {log?.activity}
                                  </h4>
                                  <p className="item-info">
                                    {Moment(log?.createdAt).format(
                                      "DD-MM-YY h:mm a"
                                    )}
                                  </p>
                                </div>
                              </Link>
                            )}
                            {log?.type === "Ticket" && (
                              <Link
                                to={`/dashboard/details/${log?.targetId}`}
                                key={idx}
                                className="content"
                              >
                                <div className="notification-item">
                                  <h4 className="item-title">
                                    {log?.user?.name} {log?.activity}
                                  </h4>
                                  <p className="item-info">
                                    {Moment(log?.createdAt).format(
                                      "DD-MM-YY h:mm a"
                                    )}
                                  </p>
                                </div>
                              </Link>
                            )}
                            {log?.type === "Category" && (
                              <Link
                                className="content"
                                to={"/dashboard/addcategory"}
                                key={idx}
                              >
                                <div className="notification-item">
                                  <h4 className="item-title">
                                    {log?.user?.name} {log?.activity}
                                  </h4>
                                  <p className="item-info">
                                    {Moment(log?.createdAt).format(
                                      "DD-MM-YY h:mm a"
                                    )}
                                  </p>
                                </div>
                              </Link>
                            )}
                            {log?.type === "Sport" && (
                              <Link
                                className="content"
                                to={"/dashboard/addsport"}
                                key={idx}
                              >
                                <div className="notification-item">
                                  <h4 className="item-title">
                                    {log?.user?.name} {log?.activity}
                                  </h4>
                                  <p className="item-info">
                                    {Moment(log?.createdAt).format(
                                      "DD-MM-YY h:mm a"
                                    )}
                                  </p>
                                </div>
                              </Link>
                            )}
                            {log?.type === "User" && (
                              <Link
                                className="content"
                                to={`/profile/${log?.targetId}`}
                                key={idx}
                              >
                                <div className="notification-item">
                                  <h4 className="item-title">
                                    {log?.user?.name} {log?.activity}
                                  </h4>
                                  <p className="item-info">
                                    {Moment(log?.createdAt).format(
                                      "DD-MM-YY h:mm a"
                                    )}
                                  </p>
                                </div>
                              </Link>
                            )}
                            {log?.type === "Faq" && (
                              <Link className="content" to={"/faq"} key={idx}>
                                <div className="notification-item">
                                  <h4 className="item-title">
                                    {log?.user?.name} {log?.activity}
                                  </h4>
                                  <p className="item-info">
                                    {Moment(log?.createdAt).format(
                                      "DD-MM-YY h:mm a"
                                    )}
                                  </p>
                                </div>
                              </Link>
                            )}
                          </>
                        ))}
                      {/* <a className="content" href="#">
                        <div class="notification-item">
                          <h4 class="item-title">
                            Evaluation Deadline 1 · day ago
                          </h4>
                          <p class="item-info">
                            Marketing 101, Video Assignment
                          </p>
                        </div>
                      </a> */}
                    </div>
                  </ul>
                </div>
              ) : (
                <div className="dropdown-noti ">
                  <Link
                    id="dLabel"
                    className="dropdown-toggle header-navlink cart-navlink "
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <AiFillBell />
                    <div className="badge badge-danger">
                      {" "}
                      {userNotificatios?.numUnseenMessages}
                    </div>
                  </Link>

                  {/* <button

                  // onClick={getUserNotifications}
                  >
                    <span className="badge badge-danger noti-badge"></span>
                  </button> */}

                  <ul
                    className="dropdown-menu notifications mt-2"
                    role="menu"
                    aria-labelledby="dLabel"
                  >
                    <li className="divider"></li>
                    <div className="notifications-wrapper">
                      {userNotificatios &&
                      userNotificatios.userLogs &&
                      userNotificatios.userLogs.length !== 0 ? (
                        userNotificatios?.userLogs?.map((log, idx) => (
                          <>
                            {log?.type === "Product" && (
                              <div
                                onClick={(e) =>
                                  handleSeenNotification(e, log?._id)
                                }
                              >
                                <Link
                                  className="content"
                                  to={`/details/${log?.targetId}`}
                                  key={idx}
                                >
                                  <div
                                    className={`notification-item ${
                                      log.seen === false
                                        ? "bg-success text-white"
                                        : "bg-light"
                                    }`}
                                  >
                                    <h4
                                      className={`${
                                        log.seen === false
                                          ? "bg-success item-title-white"
                                          : "bg-light item-title"
                                      }`}
                                    >
                                      {log?.from?.name} {log?.activity}
                                    </h4>
                                    <p
                                      className={`${
                                        log.seen === false
                                          ? "bg-success item-info-white"
                                          : "bg-light item-info"
                                      }`}
                                    >
                                      {Moment(log?.createdAt).format(
                                        "DD-MM-YY h:mm a"
                                      )}
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            )}
                            {log?.type === "Ticket" && (
                              <div
                                onClick={(e) =>
                                  handleSeenNotification(e, log?._id)
                                }
                              >
                                <Link
                                  to={`/dashboard/details/${log?.targetId}`}
                                  key={idx}
                                  className="content"
                                >
                                  <div
                                    className={`notification-item ${
                                      log.seen === false
                                        ? "bg-success text-white"
                                        : "bg-light"
                                    }`}
                                  >
                                    <h4
                                      className={`${
                                        log.seen === false
                                          ? "bg-success item-title-white"
                                          : "bg-light item-title"
                                      }`}
                                    >
                                      {log?.from?.name} {log?.activity}
                                    </h4>
                                    <p
                                      className={`${
                                        log.seen === false
                                          ? "bg-success item-info-white"
                                          : "bg-light item-info"
                                      }`}
                                    >
                                      {Moment(log?.createdAt).format(
                                        "DD-MM-YY h:mm a"
                                      )}
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            )}
                            {log?.type === "Order" && (
                              <div
                                onClick={(e) =>
                                  handleSeenNotification(e, log?._id)
                                }
                              >
                                <Link
                                  to={`/profileSettings/orders`}
                                  key={idx}
                                  className="content"
                                >
                                  <div
                                    className={`notification-item ${
                                      log.seen === false
                                        ? "bg-success text-white"
                                        : "bg-light"
                                    }`}
                                  >
                                    <h4
                                      className={`${
                                        log.seen === false
                                          ? "bg-success item-title-white"
                                          : "bg-light item-title"
                                      }`}
                                    >
                                      {log?.from?.name} {log?.activity}
                                    </h4>
                                    <p
                                      className={`${
                                        log.seen === false
                                          ? "bg-success item-info-white"
                                          : "bg-light item-info"
                                      }`}
                                    >
                                      {Moment(log?.createdAt).format(
                                        "DD-MM-YY h:mm a"
                                      )}
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            )}
                            {log?.type === "Order Created" && (
                              <div
                                onClick={(e) =>
                                  handleSeenNotification(e, log?._id)
                                }
                              >
                                <Link
                                  to={`/profileSettings/buyerorders`}
                                  key={idx}
                                  className="content"
                                >
                                  <div
                                    className={`notification-item ${
                                      log.seen === false
                                        ? "bg-success text-white"
                                        : "bg-light"
                                    }`}
                                  >
                                    <h4
                                      className={`${
                                        log.seen === false
                                          ? "bg-success item-title-white"
                                          : "bg-light item-title"
                                      }`}
                                    >
                                      {log?.from?.name} {log?.activity}
                                    </h4>
                                    <p
                                      className={`${
                                        log.seen === false
                                          ? "bg-success item-info-white"
                                          : "bg-light item-info"
                                      }`}
                                    >
                                      {Moment(log?.createdAt).format(
                                        "DD-MM-YY h:mm a"
                                      )}
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            )}
                          </>
                        ))
                      ) : (
                        <h6 className="text-center my-auto">
                          No Notification Found
                        </h6>
                      )}
                    </div>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="header-right">
              <Link className="header-navlink" onClick={() => tog_location()}>
                <BiCurrentLocation />
              </Link>
              <Link to={"/login"} className="login-navlink text-light">
                {t("Log in")}
              </Link>
              <select
                className="form-cancel language-dropdown"
                value={lang}
                onChange={handleChange}
              >
                {languages.map((item) => {
                  return (
                    <option key={item.value} value={t(`${item.value}`)}>
                      {t(`${item.text}`)}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          <div
            className={`menu-toggle ${active ? "active" : ""}`}
            onClick={() => setActive(!active)}
          >
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </div>
      </div>
      <div
        className={`container-fluid mobile-header ${active ? "active" : ""}`}
      >
        <div className="header-center">
          <div className="header-center-search">
            <div className="dropdown  bg-success rounded">
              <select
                className="form-cancel-2 language-dropdown-2 w-100"
                value={mainCategory}
                onChange={(e) => handleMainCategoryChange(e)}
              >
                <option value="All..."> {t("All...")} </option>
                {categories
                  ? categories.map((category, i) => (
                      <option value={category?._id} key={i}>
                        {category?.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>

            <input
              type="text"
              placeholder="Search in the market"
              value={searchItem}
              onChange={onChangeData}
              onKeyPress={onKeyPress}
              loading={loading}
            />
          </div>
          <button
            className="header-search-submit"
            onClick={handleClickedSearch}
          >
            <img src={search} alt="" />
          </button>
        </div>
        {user ? (
          <div className="header-right">
            <Link className="header-navlink" onClick={() => tog_location()}>
              <BiCurrentLocation />
            </Link>
            {user?.user.role !== "Admin" && (
              <>
                {/* <Link className="header-navlink " to={"/wishlist"}>
                  <AiOutlineHeart />
                </Link> */}
                <Link
                  className="header-navlink cart-navlink cart-animate"
                  to={"/checkout"}
                >
                  <AiOutlineShoppingCart />
                  <div>{cartTotalQuantity ? cartTotalQuantity : 0}</div>
                </Link>
              </>
            )}

            <select
              className="form-cancel language-dropdown"
              value={lang}
              onChange={handleChange}
            >
              {languages.map((item) => {
                return (
                  <option key={item.value} value={t(`${item.value}`)}>
                    {t(`${item.text}`)}
                  </option>
                );
              })}
            </select>
            <div className="dropdown profile-dropdown">
              <button
                className="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {profile?.user?.image ? (
                  <img
                    src={
                      process.env.REACT_APP_IMAGES_URL +
                      "/" +
                      profile?.user.image
                    }
                    onError={(e) => (e.target.src = "/user.png")}
                    alt=""
                  />
                ) : (
                  <img src={user2} alt="" />
                )}
                John Doe
              </button>
              <ul className="dropdown-menu">
                {user?.user.role === "Admin" ? (
                  <li>
                    <Link to={"/dashboard"}>{t("Dasboard")} </Link>
                  </li>
                ) : (
                  <>
                    <li className="border-bottom ">
                      <Link to={"/profileSettings/profile"}>
                        {t("Dasboard")}
                      </Link>
                    </li>
                    <li className="border-bottom ">
                      <Link to={"/createItem"}>{t("Create Ad")}</Link>
                    </li>
                    <li className="border-bottom ">
                      <Link to={"/profileSettings/messages"}>
                        {t("Messages")}
                      </Link>
                    </li>
                    <li className="border-bottom ">
                      <Link to={"/profileSettings/wallet"}>
                        {t("My Wallet")}
                      </Link>
                    </li>
                    <li className="border-bottom ">
                      <Link to={"/profileSettings/savedAds"}>
                        {t("Saved Ads")}
                      </Link>
                    </li>
                    <li className="border-bottom ">
                      <Link to={"/wishlist"}>{t("My WishList")}</Link>
                    </li>
                    <li className="border-bottom ">
                      <Link to={"/profileSettings/orders"}>
                        {t("My Orders")}
                      </Link>
                    </li>
                    <li className="border-bottom ">
                      <Link to={"/profileSettings/tickets"}>
                        {t("Support")}
                      </Link>
                    </li>
                  </>
                )}

                <li>
                  <Link to={"/login"} onClick={handleLogout}>
                    {t("Logout")}
                  </Link>
                </li>
              </ul>
            </div>
            {user?.user.role === "Admin" ? (
              <div className="dropdown-noti ">
                {/* <button
                  id="dLabel"
                  className="dropdown-toggle border-0  header-navlink"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={getNotifications}
                >
                  <AiFillBell />
                </button> */}
                <Link
                  id="dLabel"
                  className="dropdown-toggle header-navlink cart-navlink "
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <AiFillBell />
                  <div className="badge badge-danger">
                    {" "}
                    {userNotificatios?.numUnseenMessages}
                  </div>
                </Link>

                <ul
                  className="dropdown-menu notifications mt-2"
                  role="menu"
                  aria-labelledby="dLabel"
                >
                  <li className="divider"></li>
                  <div className="notifications-wrapper">
                    {notificatios &&
                      notificatios?.map((log, idx) => (
                        <>
                          {log?.type === "Product" && (
                            <Link
                              className="content"
                              to={`/details/${log?.targetId}`}
                              key={idx}
                            >
                              <div className="notification-item">
                                <h4 className="item-title">
                                  {log?.user?.name} {log?.activity}
                                </h4>
                                <p className="item-info">
                                  {Moment(log?.createdAt).format(
                                    "DD-MM-YY h:mm a"
                                  )}
                                </p>
                              </div>
                            </Link>
                          )}
                          {log?.type === "Ticket" && (
                            <Link
                              to={`/dashboard/details/${log?.targetId}`}
                              key={idx}
                              className="content"
                            >
                              <div className="notification-item">
                                <h4 className="item-title">
                                  {log?.user?.name} {log?.activity}
                                </h4>
                                <p className="item-info">
                                  {Moment(log?.createdAt).format(
                                    "DD-MM-YY h:mm a"
                                  )}
                                </p>
                              </div>
                            </Link>
                          )}
                          {log?.type === "Category" && (
                            <Link
                              className="content"
                              to={"/dashboard/addcategory"}
                              key={idx}
                            >
                              <div className="notification-item">
                                <h4 className="item-title">
                                  {log?.user?.name} {log?.activity}
                                </h4>
                                <p className="item-info">
                                  {Moment(log?.createdAt).format(
                                    "DD-MM-YY h:mm a"
                                  )}
                                </p>
                              </div>
                            </Link>
                          )}
                          {log?.type === "Sport" && (
                            <Link
                              className="content"
                              to={"/dashboard/addsport"}
                              key={idx}
                            >
                              <div className="notification-item">
                                <h4 className="item-title">
                                  {log?.user?.name} {log?.activity}
                                </h4>
                                <p className="item-info">
                                  {Moment(log?.createdAt).format(
                                    "DD-MM-YY h:mm a"
                                  )}
                                </p>
                              </div>
                            </Link>
                          )}
                          {log?.type === "User" && (
                            <Link
                              className="content"
                              to={`/profile/${log?.targetId}`}
                              key={idx}
                            >
                              <div className="notification-item">
                                <h4 className="item-title">
                                  {log?.user?.name} {log?.activity}
                                </h4>
                                <p className="item-info">
                                  {Moment(log?.createdAt).format(
                                    "DD-MM-YY h:mm a"
                                  )}
                                </p>
                              </div>
                            </Link>
                          )}
                          {log?.type === "Faq" && (
                            <Link className="content" to={"/faq"} key={idx}>
                              <div className="notification-item">
                                <h4 className="item-title">
                                  {log?.user?.name} {log?.activity}
                                </h4>
                                <p className="item-info">
                                  {Moment(log?.createdAt).format(
                                    "DD-MM-YY h:mm a"
                                  )}
                                </p>
                              </div>
                            </Link>
                          )}
                        </>
                      ))}
                  </div>
                </ul>
              </div>
            ) : (
              <div className="dropdown-noti ">
                <div>
                  <button
                    id="dLabel"
                    className="dropdown-toggle border-0  header-navlink"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    // onClick={getUserNotifications}
                  >
                    <AiFillBell />
                  </button>
                  <span className="badge badge-danger noti-badge">
                    {userNotificatios?.numUnseenMessages}
                  </span>
                </div>
                <ul
                  className="dropdown-menu notifications mt-2"
                  role="menu"
                  aria-labelledby="dLabel"
                >
                  <li className="divider"></li>
                  <div className="notifications-wrapper">
                    {userNotificatios &&
                    userNotificatios.userLogs &&
                    userNotificatios.userLogs.length !== 0 ? (
                      userNotificatios?.userLogs?.map((log, idx) => (
                        <>
                          {log?.type === "Product" && (
                            <div
                              onClick={(e) =>
                                handleSeenNotification(e, log?._id)
                              }
                            >
                              <Link
                                className="content"
                                to={`/details/${log?.targetId}`}
                                key={idx}
                              >
                                <div
                                  className={`notification-item ${
                                    log.seen === false
                                      ? "bg-success text-white"
                                      : "bg-light"
                                  }`}
                                >
                                  <h4
                                    className={`${
                                      log.seen === false
                                        ? "bg-success item-title-white"
                                        : "bg-light item-title"
                                    }`}
                                  >
                                    {log?.from?.name} {log?.activity}
                                  </h4>
                                  <p
                                    className={`${
                                      log.seen === false
                                        ? "bg-success item-info-white"
                                        : "bg-light item-info"
                                    }`}
                                  >
                                    {Moment(log?.createdAt).format(
                                      "DD-MM-YY h:mm a"
                                    )}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          )}
                          {log?.type === "Ticket" && (
                            <div
                              onClick={(e) =>
                                handleSeenNotification(e, log?._id)
                              }
                            >
                              <Link
                                to={`/dashboard/details/${log?.targetId}`}
                                key={idx}
                                className="content"
                              >
                                <div
                                  className={`notification-item ${
                                    log.seen === false
                                      ? "bg-success text-white"
                                      : "bg-light"
                                  }`}
                                >
                                  <h4
                                    className={`${
                                      log.seen === false
                                        ? "bg-success item-title-white"
                                        : "bg-light item-title"
                                    }`}
                                  >
                                    {log?.from?.name} {log?.activity}
                                  </h4>
                                  <p
                                    className={`${
                                      log.seen === false
                                        ? "bg-success item-info-white"
                                        : "bg-light item-info"
                                    }`}
                                  >
                                    {Moment(log?.createdAt).format(
                                      "DD-MM-YY h:mm a"
                                    )}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          )}
                          {log?.type === "Order" && (
                            <div
                              onClick={(e) =>
                                handleSeenNotification(e, log?._id)
                              }
                            >
                              <Link
                                to={`/profileSettings/orders`}
                                key={idx}
                                className="content"
                              >
                                <div
                                  className={`notification-item ${
                                    log.seen === false
                                      ? "bg-success text-white"
                                      : "bg-light"
                                  }`}
                                >
                                  <h4
                                    className={`${
                                      log.seen === false
                                        ? "bg-success item-title-white"
                                        : "bg-light item-title"
                                    }`}
                                  >
                                    {log?.from?.name} {log?.activity}
                                  </h4>
                                  <p
                                    className={`${
                                      log.seen === false
                                        ? "bg-success item-info-white"
                                        : "bg-light item-info"
                                    }`}
                                  >
                                    {Moment(log?.createdAt).format(
                                      "DD-MM-YY h:mm a"
                                    )}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          )}
                          {log?.type === "Order Created" && (
                            <div
                              onClick={(e) =>
                                handleSeenNotification(e, log?._id)
                              }
                            >
                              <Link
                                to={`/profileSettings/buyerorders`}
                                key={idx}
                                className="content"
                              >
                                <div
                                  className={`notification-item ${
                                    log.seen === false
                                      ? "bg-success text-white"
                                      : "bg-light"
                                  }`}
                                >
                                  <h4
                                    className={`${
                                      log.seen === false
                                        ? "bg-success item-title-white"
                                        : "bg-light item-title"
                                    }`}
                                  >
                                    {log?.from?.name} {log?.activity}
                                  </h4>
                                  <p
                                    className={`${
                                      log.seen === false
                                        ? "bg-success item-info-white"
                                        : "bg-light item-info"
                                    }`}
                                  >
                                    {Moment(log?.createdAt).format(
                                      "DD-MM-YY h:mm a"
                                    )}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          )}
                        </>
                      ))
                    ) : (
                      <h6 className="text-center my-auto">
                        No Notification Found
                      </h6>
                    )}
                  </div>
                </ul>
              </div>
            )}
          </div>
        ) : (
          <div className="header-right">
            <>
              <Link
                to={"/login"}
                className="login-navlink text-light hover-shadow"
              >
                {t("Log in")}
              </Link>
              <select
                className="form-cancel language-dropdown"
                value={lang}
                onChange={handleChange}
              >
                {languages.map((item) => {
                  return (
                    <option key={item.value} value={t(`${item.value}`)}>
                      {t(`${item.text}`)}
                    </option>
                  );
                })}
              </select>
            </>
          </div>
        )}
      </div>

      {/* Modal */}

      <Modal
        className="fade"
        show={showLocation}
        onHide={() => {
          tog_location();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Where are you from?")} </h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShowLocation(false);
            }}
          />
        </div>
        <ModalBody>
          <div className="dropdown rounded">
            <select
              className="form-cancel-2 language-dropdown-2 w-100"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              <option selected>{t("Select Ad Location")}</option>

              <option value="Zürich (ZH)">Zürich (ZH)</option>
              <option value="Berne (BE)">Berne (BE)</option>

              <option value="Luzern (LU)">Luzern (LU)</option>

              <option value="Uri (UR)">Uri (UR)</option>
              <option value="Schwyz (SZ)">Schwyz (SZ)</option>
              <option value="Unterwalden (Obwalden (OW) / Nidwalden (NW))">
                Unterwalden (Obwalden (OW) / Nidwalden (NW))
              </option>
              <option value="Glarus (GL)">Glarus (GL)</option>
              <option value="Zug (ZG)">Zug (ZG)</option>
              <option value="Freiburg / Fribourg (FR)">
                Freiburg / Fribourg (FR)
              </option>
              <option value="Solothurn (SO)">Solothurn (SO)</option>
            </select>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="form-cancel"
              onClick={() => {
                setShowLocation(false);
              }}
            >
              {t("Close")}
            </button>
            <button
              type="button"
              className="form-save"
              onClick={handleLocationSearch}
            >
              {t("Submit")}
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Modal */}
    </div>
  );
};

export default LoggedHeader;
