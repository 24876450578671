import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import p1 from "../assets/images/p1.jpg";
import { toast } from "react-toastify";
import { AiFillEye, AiFillDelete } from "react-icons/ai";
import axios from "axios";
//---------trnslate
import { useTranslation } from "react-i18next";

const SavedAds = () => {
  const { t } = useTranslation();

  //===================Get Saved Ads API start =================
  let userID = localStorage.getItem("userId");
  const [savedAds, setSavedAds] = useState([]);
  useEffect(() => {
    getSavedAds();
  }, []);

  const getSavedAds = async () => {
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/product/getSavedAds",
        { userID: userID },
        config
      );
      if (res.data) setSavedAds(res?.data?.savedAds);
    } catch (err) {
      console.log(err.response.data.error);
    }
  };

  //===================Get Saved Ads API end =================

  //===================Remove Ad From Saved List API start =================

  const handleRemoveFromFav = async (remId) => {
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/product/removeFromSaved",
        { adID: remId, userID: userID },
        config
      );
      if (res.data) {
        setSavedAds(res?.data);
        toast.success("Removed From Saved List !");

        getSavedAds();
      }
    } catch (err) {
      console.log(err.response.data.error);
    }
  };
  //===================Remove Ad From Saved List API end =================

  return (
    <div>
      <div className="ads-top">
        <div className="ads-top-left">
          <h5> {t("Saved Ads")} </h5>
        </div>
      </div>

      <div className="container">
        <div className="row">
          {savedAds.length > 0 ? (
            savedAds?.map((ad, i) => (
              <div className="col-lg-4 col-md-6" key={i}>
                <div className="products-item shadow">
                  <Link
                    to={`/details/${ad._id}`}
                    className="product-img-container w-100"
                  >
                    {ad?.type === "Urgent" && (
                      <div className="product-ribbon urgent">{ad?.type}</div>
                    )}
                    {ad?.type === "Standard" && (
                      <div className="product-ribbon standard">{ad?.type}</div>
                    )}
                    {ad?.type === "Featured" && (
                      <div className="product-ribbon featured">{ad?.type}</div>
                    )}
                    {ad?.images ? (
                      <img
                        className="product-img"
                        src={
                          process.env.REACT_APP_IMAGES_URL + "/" + ad?.images[0]
                        }
                        alt=""
                        onError={(e) => (e.target.src = "/product.jpg")}
                      />
                    ) : (
                      <img className="product-img" src={p1} alt="" />
                    )}
                  </Link>
                  <div className="product-text">
                    <div className="product-text-top">
                      <h6>
                        <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                      </h6>
                      <div>
                        <AiFillDelete
                          className="me-2 text-danger cursor-pointer"
                          onClick={() => handleRemoveFromFav(ad?._id)}
                        />
                      </div>
                      <div>
                        {/* <i onClick={() => setP1Like(!p1Like)}>
                            {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                          </i> */}
                        <AiFillEye className="me-2" />
                        {ad?.views?.length}
                      </div>
                    </div>
                    <div className="product-text-bottom">
                      <div className="desc">{ad?.description}</div>
                      <h6>$ {ad?.price}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <h5 className="text-center mt-5">{t("No Saved Ad Found !")} </h5>
              <div className="d-flex justify-content-center">
                <button className="banner-link text-light hover-shadow border-0 text-center text-white">
                  <Link className="text-white" to="/">
                    {t("Go to Home")}
                  </Link>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavedAds;
