import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";
import Moment from "moment";
import user2 from "../assets/images/user.png";

import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";

//====================React translate================================
import { useTranslation } from "react-i18next";

const PaymentRequests = () => {
  const { t } = useTranslation();

  useEffect(() => {
    getAllPaymentRequests();
  }, []);

  //---------------Get All Payment Requests start

  const [paymentRequests, setPaymentRequests] = useState([]);

  const getAllPaymentRequests = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/user/get/fetchWithDrawAdmins",
      config
    );
    if (response) {
      setPaymentRequests(response?.data?.withdraws);
    }
  };

  //---------------Hanlde Payment Request Approve start

  const handleApprovedWithdraw = async (e, withdrawId) => {
    e.preventDefault();
    let body = {
      id: withdrawId,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/user/approveWithDrawStatus",
      body,
      config
    );
    // console.log("response", response);
    if (response.data.success === true) {
      toast.success(response?.data.msg);
      getAllPaymentRequests();
    }
    if (response.data.success === false) {
      toast.error(response?.data.msg);
    }
  };

  //---------------Hanlde Payment Request Cancelled start

  const handleCancelledWithdraw = async (e, withdrawId) => {
    e.preventDefault();
    let body = {
      id: withdrawId,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/user/cancelledWithDrawRequest",
      body,
      config
    );
    if (response.data.success === true) {
      toast.success(response?.data.msg);
      getAllPaymentRequests();
    }
    if (response.data.success === false) {
      toast.error(response?.data.msg);
    }
  };

  return (
    <div>
      <div className="profile-infos d-block">
        <h5 className="withdraw-title">{t("Payment Requests")} </h5>
        <div className="table-responsive">
          <table className="table shop-table table-bordered table-responsive">
            <thead>
              <tr>
                <th className="text-center">Sr. no</th>
                <th className="text-center  text-nowrap">{t("User")}</th>
                <th className="text-center">{t("Price")}</th>
                <th className="text-center">{t("Note")}</th>
                <th className="text-center">{t("Created At")}</th>
                <th className="text-center">{t("Status")}</th>
                <th className="text-center">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {paymentRequests &&
                paymentRequests.map((payment, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td className="text-center  text-nowrap">
                      {payment?.user?.image ? (
                        <>
                          <Link to={`/profile/${payment?.user?._id}`}>
                            <img
                              src={
                                process.env.REACT_APP_IMAGES_URL +
                                "/" +
                                payment?.user?.image
                              }
                              onError={(e) => (e.target.src = "/user.png")}
                              style={{
                                width: "25px",
                                height: "25px",
                                objectFit: "cover",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              alt=""
                            />
                            <span className="text-dark">
                              {" "}
                              {payment?.user?.name}
                            </span>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to={`/profile/${payment?.user?._id}`}>
                            <img
                              src={user2}
                              style={{
                                width: "25px",
                                height: "25px",
                                objectFit: "cover",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              alt=""
                            />
                            <span className="text-dark">
                              {" "}
                              {payment?.user?.name}
                            </span>
                          </Link>
                        </>
                      )}
                    </td>
                    <td className="text-center">$ {payment?.amount}</td>
                    <td className="text-center">{payment?.description}</td>
                    <td className="text-center">
                      {Moment(payment?.createdAt).format("LLL")}
                    </td>
                    {payment?.status === "Pending" && (
                      <td className="text-center text-warning">
                        {payment?.status}
                      </td>
                    )}
                    {payment?.status === "Approved" && (
                      <td className="text-center text-success">
                        {payment?.status}
                      </td>
                    )}
                    {payment?.status === "Cancelled" && (
                      <td className="text-center text-danger">
                        {payment?.status}
                      </td>
                    )}
                    <td className="text-center">
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn "
                          onClick={(e) =>
                            handleApprovedWithdraw(e, payment?._id)
                          }
                          disabled={
                            payment?.status === "Approved" ? true : false
                          }
                        >
                          <FcApproval className="text-primary" />
                        </button>
                        <button
                          className="btn mx-1"
                          onClick={(e) =>
                            handleCancelledWithdraw(e, payment?._id)
                          }
                          disabled={
                            payment?.status === "Approved" ? true : false
                          }
                        >
                          <MdCancel className="text-danger" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentRequests;
