import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Modal, ModalBody } from "reactstrap";
import Header from "../components/LoggedHeader";
import Footer from "../components/Footer";
import Categories from "../components/Categories";

import { toast } from "react-toastify";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

//============redux import============
import { useSelector } from "react-redux";

const FAQs = () => {
  const { user } = useSelector((state) => {
    return state.auth;
  });

  const [allFaqs, setAllFaqs] = useState([]);
  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + "/faq/get");
      if (res.data) setAllFaqs(res?.data);
    } catch (err) {
      console.log(err.response.data.error);
    }
  };

  //---------------------Form Data with change Handler start------------------------
  const [formData, setFormData] = useState({
    id: "",
    question: "",
    answer: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  //---------------------Form Data with change Handler end------------------------

  //-------------------Handle Add Faq start-----------------------
  const [add_faq, setadd_faq] = useState(false);
  function tog_add_faq() {
    setFormData({
      question: "",
      answer: "",
    });
    setadd_faq(!add_faq);
  }

  const handleAddFaq = async (e) => {
    e.preventDefault();

    try {
      const url = process.env.REACT_APP_API_URL + "/faq/add";
      const response = await axios({
        method: "post",
        url: url,
        data: {
          question: formData.question,
          answer: formData.answer,
          role: user?.user?.role,
        },
        headers: {
          "x-auth-token": localStorage.token,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data?.msg);
        setadd_faq(!add_faq);
        getFaqs();
        setFormData({
          question: "",
          answer: "",
        });
      } else {
        if (response.data.success === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //-------------------Handle Add Faq end-----------------------

  //=================Edit Faq Modal start==============
  const [editId, setEditId] = useState();
  const [editFaq, seteditFaq] = useState(false);

  function tog_edit(t) {
    setEditId(t);
    let selectedFaq = [];
    allFaqs.map((item) =>
      item._id === t
        ? selectedFaq.push({
            question: item.question,
            answer: item.answer,
          })
        : null
    );
    setFormData(selectedFaq[0]);
    seteditFaq(!editFaq);
  }

  const handleEditFaq = async (e) => {
    e.preventDefault();

    try {
      const url = process.env.REACT_APP_API_URL + "/faq/update";
      const response = await axios({
        method: "post",
        url: url,
        data: {
          id: editId,
          question: formData.question,
          answer: formData.answer,
          role: user?.user?.role,
        },
        headers: {
          "x-auth-token": localStorage.token,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.msg);
        seteditFaq(!editFaq);
        getFaqs();
      } else {
        if (response.data.success === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //=================Edit Faq Modal end==============

  //=================Delete Faq  start==============
  const handleDeleteFaq = async (id) => {
    try {
      const url = process.env.REACT_APP_API_URL + "/faq/delete";
      const response = await axios({
        method: "post",
        url: url,
        data: {
          id: id,
          role: user?.user?.role,
        },
        headers: {
          "x-auth-token": localStorage.token,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.msg);
        getFaqs();
      } else {
        if (response.data.status === false) {
          toast.error(response.data.msg);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //=================Delete Faq  end================

  return (
    <div>
      <Header />
      <Categories />
      <section className="profile-infos d-block">
        <div className="container">
          <div className="col-12 col-md-10 mx-auto">
            <div className="container  rounded">
              <div className="row my-3 rounded">
                <div className="col-md-6 mx-auto text-center ">
                  <h2>Frequently Asked Questions</h2>
                </div>
                {user?.user?.role === "Admin" && user ? (
                  <div className="infos-btns">
                    <button
                      className=" green-btn hover-shadow me-5"
                      onClick={() => tog_add_faq()}
                    >
                      Add FAQs
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <div className="accordion" id="accordionRental">
                    {allFaqs &&
                      allFaqs.map((faq, idx) => (
                        <div className="accordion-item mb-3" key={idx}>
                          <h5
                            className="accordion-header"
                            id={`headingOne${faq._id}`}
                          >
                            <button
                              className="accordion-button border-bottom font-weight-bold"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${faq._id}`}
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              {faq?.question}
                              <i className="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"></i>
                              <i className="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"></i>
                            </button>
                          </h5>
                          <div
                            id={`collapseOne${faq._id}`}
                            className="accordion-collapse collapse "
                            aria-labelledby={`headingOne${faq._id}`}
                            data-bs-parent="#accordionRental"
                          >
                            <div className="accordion-body text-sm opacity-8">
                              {faq?.answer}
                            </div>
                            {user?.user?.role === "Admin" && user ? (
                              <div className="d-flex mb-2">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-success ms-4 "
                                  onClick={() => tog_edit(faq._id)}
                                >
                                  <AiFillEdit />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger mx-2"
                                  onClick={() => handleDeleteFaq(faq._id)}
                                >
                                  <AiFillDelete />
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      {/* Add Faq Modal start */}

      <Modal
        isOpen={add_faq}
        toggle={() => {
          tog_add_faq();
        }}
        size="md"
        centered
      >
        <div className="bg-light p-3 d-flex justify-content-between">
          <div>
            <h2 className="pt-2">Add Faq</h2>
          </div>
          <div className="">
            <Button
              type="button"
              onClick={() => {
                setadd_faq(false);
              }}
              className="btn-close "
              aria-label="Close"
            ></Button>
          </div>
        </div>

        <ModalBody>
          <form className="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div className="row">
              <div className="d-flex flex-column col-lg-12 mb-8 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                  <span className="required">Question</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="Enter your Faq question"
                  name="question"
                  value={formData.question}
                  onChange={handleChange}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>

            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label className="fs-6 fw-semibold mb-2">Answer</label>
              <textarea
                className="form-control form-control-solid"
                rows={4}
                name="answer"
                value={formData.answer}
                onChange={handleChange}
                placeholder="Type your Faq answer"
                defaultValue={""}
              />
              <div className="fv-plugins-message-container invalid-feedback" />
            </div>
            <div className="text-center">
              <div className="infos-btns mx-auto">
                <button
                  className=" my-2 green-btn hover-shadow"
                  onClick={handleAddFaq}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* Add Faq Modal end */}

      {/* Edit Faq Modal start */}

      <Modal
        isOpen={editFaq}
        toggle={() => {
          tog_edit(formData.id);
        }}
        size="lg"
        // centered
        // backdrop="static"
      >
        <div className="bg-light p-3 d-flex justify-content-between">
          <div>
            <h2 className="pt-2">Edit Faq</h2>
          </div>
          <div className="">
            <Button
              type="button"
              onClick={() => {
                seteditFaq(false);
              }}
              className="btn-close "
              aria-label="Close"
            ></Button>
          </div>
        </div>

        <ModalBody>
          <form className="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div className="row">
              <div className="d-flex flex-column col-lg-12 mb-8 fv-row fv-plugins-icon-container">
                <input
                  type="hidden"
                  className="form-control form-control-solid"
                  name="id"
                  value={formData.id}
                />
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                  <span className="required">Question</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="Enter your Faq question"
                  name="question"
                  value={formData.question}
                  onChange={handleChange}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>

            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label className="fs-6 fw-semibold mb-2">Answer</label>
              <textarea
                className="form-control form-control-solid"
                rows={4}
                name="answer"
                value={formData.answer}
                onChange={handleChange}
                placeholder="Type your Faq answer"
                defaultValue={""}
              />
              <div className="fv-plugins-message-container invalid-feedback" />
            </div>
            <div className="text-center">
              <div className="infos-btns mx-auto">
                <button
                  className=" my-2 green-btn hover-shadow"
                  onClick={handleEditFaq}
                >
                  Update
                </button>
              </div>
            </div>

            <div />
          </form>
        </ModalBody>
      </Modal>

      {/* Edit Faq Modal end */}
    </div>
  );
};

export default FAQs;
