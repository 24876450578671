import React, { useState, useEffect } from "react";
import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Header from "../components/LoggedHeader";
import left from "../assets/icons/arrowLeft.svg";
import arrowRight from "../assets/icons/arrowRight.svg";
// import usa from "../assets/icons/usa.svg";
// import trash from "../assets/icons/trash.svg";
import visa from "../assets/images/visa.svg";
import masterCard from "../assets/images/masterCard.svg";
import maestro from "../assets/images/maestro.svg";
// import sneaker from "../assets/images/sneaker.jpg";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

//============import  redux start============
import {
  addToCart,
  decreaseCart,
  getTotals,
  clearCart,
} from "../features/cart/cartSlice";

import { getUserProfile } from "../features/auth/authSlice";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
//============import redux end============
//---------trnslate
import { useTranslation } from "react-i18next";

const Checkout = () => {
  const { t } = useTranslation();

  let navigate = useNavigate();
  const [method, setMethod] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");

  // console.log("Check", paymentMethod);

  useEffect(() => {
    if (method === 1) {
      setPaymentMethod("Visa");
    }
    if (method === 2) {
      setPaymentMethod("Master Card");
    }
    if (method === 3) {
      setPaymentMethod("Maestro");
    }
  }, [method]);

  // const [item1, setItem1] = useState(2);
  // const [item2, setItem2] = useState(1);
  // const [item3, setItem3] = useState(0);

  //==============Redux hooks start================================
  const dispatch = useDispatch();
  const { cartTotalQuantity, cartTotalAmount } = useSelector(
    (state) => state.cart
  );

  const cart = useSelector((state) => state.cart);
  //============== Get User Profile Data start=============================

  const { profile, user } = useSelector((state) => state.auth);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userId) {
      dispatch(getUserProfile(userId));
    }
  }, [dispatch, userId]);

  //=================User Profile State Start===========================
  const [name, setName] = useState(profile?.user?.name);
  const [contact, setContact] = useState(profile?.user?.contact);
  const [email, setEmail] = useState(profile?.user?.email);
  const [address, setAddress] = useState(profile?.user?.address);
  const [city, setCity] = useState(profile?.user?.city);
  const [state, setState] = useState(profile?.user?.state);

  const [notes, setNotes] = useState("");
  const [discountCode, setDiscountCode] = useState("");

  const shippingInfo = {
    address: address ? address : profile?.user?.address,
    city: city ? city : profile?.user?.city,
    state: state ? state : profile?.user?.state,
  };

  const contactInfo = {
    fullName: name ? name : profile?.user?.name,
    phone: contact ? contact : profile?.user?.contact,
    email: email ? email : profile?.user?.email,
  };

  // let Ids = [{}];
  // console.log("asasasas", Ids);
  // for (var i = 0; i < cart.cartItems; i++) {
  //   Ids = cart[i]._id;
  // }
  // this.setState({ products, total });

  //=================User Profile State End===========================

  //============== Get User Profile Data end=============================

  //==============Redux hooks end==================================

  //==============  Handle Increase cart Items Start================================

  const handleAddToCart = (cartItem) => {
    dispatch(addToCart(cartItem));
  };
  //==============  Handle Increase cart Items end================================

  //==============  Handle Decrease cart Items Start================================

  const handleDecreaseCart = (cartItem) => {
    dispatch(decreaseCart(cartItem));
  };
  //==============  Handle Decrease cart Items end================================

  //==============  Handle Clear cart Items Start================================
  const handleClearCart = () => {
    dispatch(clearCart());
  };
  //==============  Handle Clear cart Items end================================

  //==============Get Total Quatity plus total start==================================

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);
  //==============Get Total Quatity plus total end==================================

  //================Handle Create order start ===========================

  const handleCreateOrder = async () => {
    const body = {
      shippingInfo: shippingInfo,
      contactInfo: contactInfo,
      notes: notes,
      orderItems: cart.cartItems,
      paymentInfo: paymentMethod,
      totalPrice: cartTotalAmount,
      discountCode: discountCode,
      cartTotalQuantity: cartTotalQuantity,
      user: user?.user?._id,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/user/createOrder",
      body,
      config
    );
    if (response?.data?.success === true) {
      toast.success(response.data?.message);
      dispatch(clearCart());
      navigate("/success");
    }
    if (response?.data?.errors) {
      for (let i = 0; i < response.data?.errors.length; i++) {
        toast.error(response.data?.errors[i].msg);
      }
      // response?.data?.errors.map((err) => {});
    }
  };

  //================Handle Create order end =============================

  return (
    <div>
      <Header />
      <Categories />

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-8 mt-3">
            <div className="checkout-heading">
              <h3>
                <img src={left} alt="" />
                {t("Check out")}
              </h3>
            </div>
            <h3 className="billing-heading">{t("Billing Details")}</h3>
            <div className="billing-card">
              <h6 className="billing-title">1. {t("Contact Information")}</h6>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-field">
                    <label>{t("FULL NAME")}*</label>
                    <div className="form-input">
                      <input
                        type="text"
                        placeholder="Lemon"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-field">
                    <label>{t("PHONE")}*</label>
                    <div className="form-input">
                      {/* <div className="form-country">
                        <img src={usa} alt="" />
                        +1
                      </div> */}
                      <input
                        type="text"
                        placeholder="32456897868"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-field">
                    <label>{t("EMAIL")}*</label>
                    <div className="form-input">
                      <input
                        type="text"
                        placeholder="Lemon@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="billing-title mt-4">
                2. {t("Delivery Information")}
              </h6>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-field">
                    <label>{t("CITY")}*</label>
                    <select
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      <option value="Zürich (ZH)">Zürich (ZH)</option>
                      <option value="Berne (BE)">Berne (BE)</option>

                      <option value="Luzern (LU)">Luzern (LU)</option>

                      <option value="Uri (UR)">Uri (UR)</option>
                      <option value="Schwyz (SZ)">Schwyz (SZ)</option>
                      <option value="Unterwalden (Obwalden (OW) / Nidwalden (NW))">
                        Unterwalden (Obwalden (OW) / Nidwalden (NW))
                      </option>
                      <option value="Glarus (GL)">Glarus (GL)</option>
                      <option value="Zug (ZG)">Zug (ZG)</option>
                      <option value="Freiburg / Fribourg (FR)">
                        Freiburg / Fribourg (FR)
                      </option>
                      <option value="Solothurn (SO)">Solothurn (SO)</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-field">
                    <label>{t("ADDRESS")}*</label>
                    <div className="form-input">
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-field">
                    <label>{t("STATE")}*</label>
                    <div className="form-input">
                      <input
                        type="text"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-field">
                    <label></label>
                    <div className="form-input form-textarea">
                      <textarea
                        placeholder={t("Notes")}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="billing-title mt-4">3.{t("Payment method")}</h6>
              <div className="row">
                <div className="col-lg-4 mt-2">
                  <div
                    className={`payment-method ${method === 1 ? "active" : ""}`}
                    onClick={() => setMethod(1)}
                  >
                    <img src={visa} alt="" />
                  </div>
                </div>
                <div className="col-lg-4 mt-2">
                  <div
                    className={`payment-method ${method === 2 ? "active" : ""}`}
                    onClick={() => setMethod(2)}
                  >
                    <img src={masterCard} alt="" />
                  </div>
                </div>
                <div className="col-lg-4 mt-2">
                  <div
                    className={`payment-method ${method === 3 ? "active" : ""}`}
                    onClick={() => setMethod(3)}
                  >
                    <img src={maestro} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mt-3">
            <div className="cart-items">
              <h5 className="cart-items-heading">{t("Order Summary")}</h5>
              <p className="cart-items-number">
                {cartTotalQuantity} {t("Items")}{" "}
              </p>
              {cart?.cartItems.map((cartItem, i) => (
                <div className="cart-item" key={i}>
                  <img
                    src={
                      process.env.REACT_APP_IMAGES_URL +
                      "/" +
                      cartItem?.images[0]
                    }
                    className="cart-item-img"
                    alt=""
                  />
                  <div className="cart-item-text">
                    <h5>{cartItem?.name}</h5>
                    <p>
                      {t("Color")} : {cartItem?.color},{t("Size")} :{" "}
                      {cartItem?.size}
                    </p>
                    <h6>$ {cartItem.price * cartItem.cartQuantity}</h6>
                  </div>
                  {/* <div className="cart-item-options">
                   
                    <div className="cart-item-num">
                      <span
                        className="me-2"
                        onClick={() => handleDecreaseCart(cartItem)}
                      >
                        -
                      </span>
                      {cartItem?.cartQuantity}
                      <span
                        className="ms-2"
                        onClick={() => handleAddToCart(cartItem)}
                      >
                        +
                      </span>
                    </div>
                  </div> */}
                </div>
              ))}
              {cart?.cartItems?.length > 0 && (
                <button
                  className="btn btn-block btn-danger w-100"
                  onClick={() => handleClearCart()}
                >
                  {t("Clear Cart")}
                </button>
              )}

              <div className="cart-specs">
                <div className="cart-spec">
                  <span>{t("SUBTOTAL")}</span>$ {cartTotalAmount}
                </div>
                {/* <div className="cart-spec">
                  <span>DISCOUNT (Coupon)</span>-$60
                </div> */}
                <div className="cart-spec">
                  <span>{t("SHIPPING")}</span>
                  {t("Free")}
                </div>
              </div>

              {/* <div className="cart-discount">
                <span className="discount-heading">{t("Discount Coupon")}</span>
                <div className="discount-field">
                  <input
                    type="text"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                  />
                  <button className="green-btn hover-shadow">
                    {t("Apply")}
                  </button>
                </div>
              </div> */}

              <Link
                className="checkout-submit green-btn hover-shadow text-light"
                onClick={handleCreateOrder}
              >
                {t("Checkout")}
                <img src={arrowRight} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Checkout;
