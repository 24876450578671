import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { VscVmActive } from "react-icons/vsc";
import { FaBan, FaSortAmountUpAlt } from "react-icons/fa";
import { MdAccountBalanceWallet } from "react-icons/md";
import Moment from "moment";
import { toast } from "react-toastify";
import {
  getAllUsers,
  blockUser,
  unblockUser,
} from "../features/auth/authSlice";

//============redux import============
import { useDispatch, useSelector } from "react-redux";
import user2 from "../assets/images/user.png";
//====================React translate================================
import { useTranslation } from "react-i18next";

const Users = () => {
  const { t } = useTranslation();

  //==================redux hooks start=================
  const dispatch = useDispatch();
  const { AllUsers } = useSelector((state) => {
    return state.auth;
  });

  //==================redux hooks end=================

  //==================Use Effect start (Get All Users)=================
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  //==================Use Effect end (Get All Users)=================

  //==================Use Effect start (Active User)=================

  const handleActive = (e, id) => {
    e.preventDefault();
    dispatch(unblockUser(id));
  };

  const handleDeActive = (e, id) => {
    e.preventDefault();
    dispatch(blockUser(id));
  };
  //==================Use Effect end (Active User)=================

  //=================Add Amount to User wallet Directly by Admin====================
  //================Wallet withdraw Modal and API================
  const [walletModal, setWalletModal] = useState(false);
  const [userWalletId, setUserWalletId] = useState();
  function tog_WalletModal(IdUser) {
    setUserWalletId(IdUser);
    setFormData({
      amount: 0,
      description: "",
    });

    setWalletModal(!walletModal);
  }

  const [formData, setFormData] = useState({
    amount: 0,
    description: "",
  });

  const { amount, description } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddAmount = async (e) => {
    e.preventDefault();
    let body = {
      userId: userWalletId,
      addamount: amount,
      description: description,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/user/addAmountToUserWallet",
      body,
      config
    );
    if (response.data.success === true) {
      toast.success(response?.data.msg);
      setWalletModal(!walletModal);
    }
    if (response.data.success === false) {
      toast.error(response?.data.msg);
    }
  };
  //================Wallet withdraw Modal and end================

  //=================Remove Amount from user wallet Directly by Admin====================
  //================Wallet withdraw Modal and API================
  const [removeBalance, setRemoveBalance] = useState(false);
  const [userRemoveBalance, setUserRemoveBalance] = useState();
  function tog_DeductModal(IdUser) {
    setUserRemoveBalance(IdUser);
    setWalletData({
      removeAmount: 0,
      descriptionRemove: "",
    });

    setRemoveBalance(!removeBalance);
  }

  const [WalletData, setWalletData] = useState({
    removeAmount: 0,
    descriptionRemove: "",
  });

  const { removeAmount, descriptionRemove } = WalletData;

  const handleChangeAmount = (e) => {
    setWalletData({ ...WalletData, [e.target.name]: e.target.value });
  };

  const handleRemoveAmount = async (e) => {
    e.preventDefault();
    let body = {
      userId: userRemoveBalance,
      removeAmount: removeAmount,
      description: descriptionRemove,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/user/deductWalletAmount",
      body,
      config
    );
    if (response.data.success === true) {
      toast.success(response?.data.msg);
      setRemoveBalance(!removeBalance);
    }
    if (response.data.success === false) {
      toast.error(response?.data.msg);
    }
  };
  //=================Remove Amount from user wallet Directly by Admin====================

  return (
    <div>
      <div className="profile-infos d-block">
        <h5 className="withdraw-title">{t("Users")} </h5>
        <div className="table-responsive">
          <table className="table shop-table table-bordered">
            <thead>
              <tr>
                <th className="text-center">{t("Image")}</th>
                <th className="text-center">{t("Name")}</th>
                <th className="text-center">{t("Email")}</th>
                <th className="text-center">{t("Phone No.")}</th>
                <th className="text-center">{t("State")}</th>
                <th className="text-center">{t("Status")}</th>
                <th className="text-center">{t("Joined At")}</th>
                <th className="text-center">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {AllUsers?.map((data, i) => (
                <tr key={i}>
                  <td className="text-center">
                    {data?.image ? (
                      <Link to={`/profile/${data?._id}`}>
                        <img
                          src={
                            process.env.REACT_APP_IMAGES_URL + "/" + data?.image
                          }
                          style={{
                            width: "45px",
                            height: "45px",
                            objectFit: "cover",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                          alt=""
                          onError={(e) => (e.target.src = "/user.png")}
                        />
                      </Link>
                    ) : (
                      <Link to={`/profile/${data?._id}`}>
                        <img
                          style={{
                            width: "45px",
                            height: "45px",
                            objectFit: "cover",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                          alt=""
                          src={user2}
                        />
                      </Link>
                      // <img
                      //   style={{
                      //     width: "45px",
                      //     height: "45px",
                      //     objectFit: "cover",
                      //     borderRadius: "50%",
                      //     cursor: "pointer",
                      //   }}
                      //   onError={(e) => (e.target.src = "/user.png")}
                      //   src={userImg}
                      //   alt=""
                      // />
                    )}
                  </td>
                  <td className="text-center">{data?.name}</td>
                  <td className="text-center">{data?.email}</td>
                  <td className="text-center">{data?.contact}</td>
                  <td className="text-center">{data?.state}</td>

                  {data?.status === true ? (
                    <td className="text-center text-success">{t("Active")}</td>
                  ) : (
                    <td className="text-center text-danger">{t("Block")}</td>
                  )}
                  <td className="text-center">
                    {Moment(data?.createdAt).format("DD-MM-YYYY")}
                  </td>

                  <td className="text-center">
                    <div className="d-flex justify-content-center">
                      {data?.status === true ? (
                        <button className="btn ">
                          <FaBan
                            className="text-danger"
                            title="Block"
                            onClick={(e) => handleDeActive(e, data._id)}
                          />
                        </button>
                      ) : (
                        <button className="btn">
                          <VscVmActive
                            className="text-primary"
                            title="Active"
                            onClick={(e) => handleActive(e, data._id)}
                          />
                        </button>
                      )}
                      <button className="btn">
                        <FaSortAmountUpAlt
                          className="text-secondary"
                          title="Add Amount"
                          onClick={() => tog_WalletModal(data._id)}
                        />
                      </button>
                      <button className="btn">
                        <MdAccountBalanceWallet
                          className="text-secondary"
                          title="Remove Amount"
                          onClick={() => tog_DeductModal(data._id)}
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        className="fade"
        show={walletModal}
        onHide={() => {
          tog_WalletModal();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Withdraw Amount")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setWalletModal(false);
            }}
          />
        </div>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="form-field mb-2">
                <label>{t("Amount")}</label>
                <div className="form-input">
                  <input
                    type="number"
                    placeholder={t("Amount")}
                    name="amount"
                    value={amount}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <label>{t("Description")}</label>
              <div className="form-input form-textarea">
                <textarea
                  className="form-control form-control-solid"
                  rows={3}
                  name="description"
                  value={description}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button
              className="form-save green-btn hover-shadow"
              onClick={(e) => handleAddAmount(e)}
            >
              {t("Submit")}
            </button>
          </div>
        </ModalFooter>
      </Modal>

      {/* --------Deduct amount modal-------- */}
      <Modal
        className="fade"
        show={removeBalance}
        onHide={() => {
          tog_DeductModal();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Deduct Amount")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setRemoveBalance(false);
            }}
          />
        </div>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="form-field mb-2">
                <label>{t("Amount")}</label>
                <div className="form-input">
                  <input
                    type="number"
                    placeholder={t("Amount")}
                    name="removeAmount"
                    value={removeAmount}
                    onChange={handleChangeAmount}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <label>{t("Description")}</label>
              <div className="form-input form-textarea">
                <textarea
                  className="form-control form-control-solid"
                  rows={3}
                  name="descriptionRemove"
                  value={descriptionRemove}
                  onChange={handleChangeAmount}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button
              className="form-save green-btn hover-shadow"
              onClick={(e) => handleRemoveAmount(e)}
            >
              {t("Submit")}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Users;
