import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import p1 from "../assets/images/p5.jpg";
import { AiFillEye } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import Header from "./LoggedHeader";
import Categories from "./Categories";
import Footer from "./Footer";
import star from "../assets/icons/star.svg";
import axios from "axios";
import Moment from "moment";
import ReactStars from "react-rating-stars-component";
import searchBlue from "../assets/icons/searchBlue.svg";

//Redux starts
import { useSelector, useDispatch } from "react-redux";
import { getAdById, getSellerAds } from "../features/ad/adSlice";
//Redux end
//====================React translate================================
import { useTranslation } from "react-i18next";

const SellerProfile = () => {
  const { t } = useTranslation();

  //==============redux Hooks start==================================
  const [tab, setTab] = useState(1);
  let { id } = useParams();

  const dispatch = useDispatch();
  //==============redux Hooks start==================================

  //==============AD selector start==================================

  const { sellerAd } = useSelector((state) => state.ads);

  //==============AD selector end==================================

  //==============redux end==================================

  //==============Get Seller Ad start==================================

  useEffect(() => {
    dispatch(getAdById(id));
  }, [dispatch, id]);

  //==============Get Seller Ad end==================================
  useEffect(() => {
    getallReview();
    getSellerStates();
  }, []);
  //==============Review get Api Start====================
  const [allReview, setAllReview] = useState([]);

  const getallReview = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/product/getSellerReview/${id}`
      );
      setAllReview(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  //==============Review get Api END====================

  //==============Get Seller States Api Start====================
  const [sellerStates, setSellerStates] = useState([]);
  // console.log("sellerStates", sellerStates);

  const getSellerStates = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/user/getStates/${id}`
      );
      setSellerStates(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  //==============Get Seller States Api END====================

  //==============Get seller Item/Products Api Start====================

  useEffect(() => {
    const body = {
      user: id,
    };
    dispatch(getSellerAds(body));
  }, [dispatch, id]);
  //==============Get seller Item/Products Api end====================
  //==============Review get Api Start====================
  const sellerRatings = allReview?.map((review) => review.rating);
  const sumSellerReviews = sellerRatings.reduce((acc, cur) => acc + cur, 0);
  const averageSellerRating = sumSellerReviews / sellerRatings.length;

  //=================Get seller Review end=======================
  //==================Search filter start ==============================
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = sellerAd.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(sellerAd);
    }
  };

  //==================Search filter end ==============================

  return (
    <div>
      <Header />
      <Categories />

      <div className="container my-5">
        {sellerAd && (
          <div className="row">
            <div className="col-lg-4 mb-2">
              <div className="profile-img">
                <img
                  src={
                    process.env.REACT_APP_IMAGES_URL +
                    "/" +
                    sellerAd[0]?.user?.image
                  }
                  alt=""
                  onError={(e) => (e.target.src = "/user.png")}
                />
                <h5 className="font-poppins">{sellerAd[0]?.user?.name}</h5>
                <p className="mb-0">
                  {sellerStates?.soldCount > 0 ? t("Top Seller") : ""}
                </p>
                <h6>
                  <IoLocationOutline className="me-1" />
                  {sellerAd[0]?.user?.address}, {sellerAd[0]?.user?.city},{" "}
                  {sellerAd[0]?.user?.state}
                </h6>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="profile-infos mb-2">
                <div className="infos-left">
                  <h5>{t("Performance")} </h5>
                  <p>
                    {sellerStates?.soldCount} {t("Item sold out")}{" "}
                  </p>
                  <p>
                    {sellerStates?.activeCount} {t("Item available")}
                  </p>
                  <h6 className="d-flex align-items-center mb-0">
                    <img src={star} alt="" className="me-1 mb-1" />
                    {averageSellerRating ? parseInt(averageSellerRating) : 0} (
                    {allReview?.length} Reviews) {t("")}
                  </h6>
                </div>
                <div className="infos-pourcentage">
                  <div className="pourcentage-item">
                    <h5>Sales Amount</h5>
                    <p className="font-poppins">
                      $
                      {sellerStates?.trans
                        ? sellerStates?.trans[0]?.allamount.toFixed(2)
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
              {sellerAd[0]?.user?.description && (
                <div className="profile-infos mb-2">
                  <div className="infos-left">
                    <h5>{t("Description")}</h5>
                    <p>{sellerAd[0]?.user?.description}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="ads-tabs d-flex justify-content-between">
          <div>
            <button
              className={tab === 1 ? "active" : ""}
              onClick={() => setTab(1)}
            >
              {t("Products")}
            </button>
            <button
              className={tab === 2 ? "active" : ""}
              onClick={() => setTab(2)}
            >
              {t("Reviews")}
            </button>
          </div>
          <div>
            <div className="messages-input my-auto">
              <img src={searchBlue} alt="" />
              <input
                type="text"
                placeholder={t("Search")}
                onChange={(e) => searchItems(e.target.value)}
              />
            </div>
          </div>
        </div>

        {tab === 1 && (
          <div className="orders-container">
            <div className="orders-container-top profile-products">
              <h5 className="m-0">{t("Products")}</h5>
              {/* <Link to={"#"}>See All</Link> */}
            </div>
            <div className="row">
              {searchInput.length > 1
                ? filteredResults.map((ad, i) => {
                    return (
                      <div className="col-lg-3 col-md-6" key={i}>
                        <div className="products-item shadow">
                          <Link
                            to={`/details/${ad._id}`}
                            className="product-img-container w-100"
                          >
                            {ad?.type === "Urgent" && (
                              <div className="product-ribbon urgent">
                                {ad?.type}
                              </div>
                            )}
                            {ad?.type === "Standard" && (
                              <div className="product-ribbon standard">
                                {ad?.type}
                              </div>
                            )}
                            {ad?.type === "Featured" && (
                              <div className="product-ribbon featured">
                                {ad?.type}
                              </div>
                            )}
                            {ad?.images ? (
                              <img
                                className="product-img"
                                src={
                                  process.env.REACT_APP_IMAGES_URL +
                                  "/" +
                                  ad?.images[0]
                                }
                                onError={(e) => (e.target.src = "/product.jpg")}
                                alt=""
                              />
                            ) : (
                              <img className="product-img" src={p1} alt="" />
                            )}
                          </Link>
                          <div className="product-text">
                            <div className="product-text-top">
                              <h6>
                                <Link to={`/details/${ad._id}`}>
                                  {ad?.name}
                                </Link>
                              </h6>
                              <div>
                                {/* <i onClick={() => setP1Like(!p1Like)}>
                          {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                        </i> */}
                                <AiFillEye className="me-2" />
                                {ad?.views?.length}
                              </div>
                            </div>
                            <div className="product-text-bottom">
                              <div className="desc">{ad?.description}</div>
                              <h6>$ {ad?.price}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : sellerAd?.map((ad, i) => (
                    <div className="col-lg-3 col-md-6" key={i}>
                      <div className="products-item shadow">
                        <Link
                          to={`/details/${ad._id}`}
                          className="product-img-container w-100"
                        >
                          {ad?.type === "Urgent" && (
                            <div className="product-ribbon urgent">
                              {ad?.type}
                            </div>
                          )}
                          {ad?.type === "Standard" && (
                            <div className="product-ribbon standard">
                              {ad?.type}
                            </div>
                          )}
                          {ad?.type === "Featured" && (
                            <div className="product-ribbon featured">
                              {ad?.type}
                            </div>
                          )}
                          {ad?.images ? (
                            <img
                              className="product-img"
                              src={
                                process.env.REACT_APP_IMAGES_URL +
                                "/" +
                                ad?.images[0]
                              }
                              onError={(e) => (e.target.src = "/product.jpg")}
                              alt=""
                            />
                          ) : (
                            <img className="product-img" src={p1} alt="" />
                          )}
                        </Link>
                        <div className="product-text">
                          <div className="product-text-top">
                            <h6>
                              <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                            </h6>
                            <div>
                              {/* <i onClick={() => setP1Like(!p1Like)}>
                        {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                      </i> */}
                              <AiFillEye className="me-2" />
                              {ad?.views?.length}
                            </div>
                          </div>
                          <div className="product-text-bottom">
                            <div className="desc">{ad?.description}</div>
                            <h6>$ {ad?.price}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        )}

        {tab === 2 && (
          <div className="orders-container">
            <div className="orders-container-top profile-products">
              <h5 className="m-0 mb-3">{t("Reviews")}</h5>
              {/* <Link to={"#"}>See All</Link> */}
            </div>

            <div className="row px-2">
              {allReview.length > 0 ? (
                allReview.map((review, idx) => (
                  <>
                    {review.status === true && (
                      <div
                        className="col-lg-4 col-md-6 shadow rounded"
                        key={idx}
                      >
                        <div className="review-card my-review">
                          {/* <div className="hide-check">
                    Hide
                    <input
                      type="checkbox"
                      className="form-check-input shadow-none"
                    />
                  </div> */}

                          <div className="review-card-top">
                            <img
                              className="reviews-modal-user"
                              src={
                                process.env.REACT_APP_IMAGES_URL +
                                "/" +
                                review?.productId?.images[0]
                              }
                              onError={(e) => (e.target.src = "/product.jpg")}
                              alt=""
                            />
                            <div className="review-card-top-text">
                              <p>{review?.productId?.name}</p>
                              <span>
                                {t("Color")} :{" "}
                                <span className="text-primary">
                                  {review?.productId?.color}
                                </span>{" "}
                                , {t("size")} :{" "}
                                <span className="text-info">
                                  {" "}
                                  {review?.productId?.size}
                                </span>
                              </span>
                            </div>
                          </div>
                          <p className="review-card-text text-trancate">
                            “{review?.comment}”
                          </p>
                          <div className="review-card-bottom">
                            <p className="review-card-date">
                              {Moment(review.createdAt).format(
                                "h:mm a - MMM D, YYYY"
                              )}
                            </p>
                            <p className="review-card-stars">
                              <ReactStars
                                count={5}
                                value={review.rating}
                                size={20}
                                isHalf={true}
                                edit={false}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={
                                  <i className="fa fa-star-half-alt"></i>
                                }
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))
              ) : (
                <div className="card shadow bg-light border-0 mx-auto p-3 w-75 mb-4">
                  <h5 className="text-center my-auto">
                    {t("No Review Found !")}{" "}
                  </h5>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default SellerProfile;
