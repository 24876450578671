import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import adService from "./adService";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  ads: [],
  featuredAds: [],
  Ad: [],
  watchList: [],
  savedList: [],
  questions: [],
  Offers: [],
  acceptedOffers: [],
  images: [],
  points: 0,
  sellerAd: [],
  mostSoldAd: [],
  categoryAd: [],
  // personalAds: [],
  // soldAds: [],
  totalAdView: [],
  allReview: [],
  adminReview: [],
  adminProducts: [],
  authCategories: [],
  homeCategories: [],
  message: "",
};

// Add Post
export const postAd = createAsyncThunk("ad/postAd", async (data, thunkAPI) => {
  try {
    const response = await adService.postAnAd(data);
    return response;
  } catch (error) {
    // const message =
    //   error.response && error.response.data && error.response.data.message;
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Get All Products/Ads
export const getAllAds = createAsyncThunk("ad/getAllAds", async (thunkAPI) => {
  try {
    return await adService.getAllAdsDB();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// GET Product/ad by id
export const getAdById = createAsyncThunk(
  "ad/getAdById",
  async (id, thunkAPI) => {
    try {
      const response = await adService.getAdById(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Post a Question on Ad
export const postQuestion = createAsyncThunk(
  "ads/postQuestion",
  async (body, thunkAPI) => {
    try {
      const response = await adService.postQuestion(body);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Post a Answer against Question on Ad
export const postAnswer = createAsyncThunk(
  "ads/postAnswer",
  async (body, thunkAPI) => {
    try {
      const response = await adService.postAnswer(body);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Products/Ads of Seller
export const getSellerAds = createAsyncThunk(
  "ads/getSellerAds",
  async (body, thunkAPI) => {
    try {
      const response = await adService.getSellerAds(body);
      return response?.sellerAd;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Products/Ads of Main Category
export const getMainCategoryAds = createAsyncThunk(
  "ads/getMainCategoryAds",
  async (body, thunkAPI) => {
    try {
      const response = await adService.getMainCateAds(body);
      return response?.categoryAd;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get Personal Products/Ads
// export const getPersonalAds = createAsyncThunk(
//   "ads/getPersonalAds",
//   async (body, thunkAPI) => {
//     try {
//       const response = await adService.getPersonalAds(body);
//       return response?.personalAd;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data.error);
//     }
//   }
// );

// Get Personal Sold Products/Ads
// export const getSoldAds = createAsyncThunk(
//   "ads/getSoldAds",
//   async (body, thunkAPI) => {
//     try {
//       const response = await adService.getSoldAds(body);
//       console.log("Sold Ads Response Slice", response);

//       return response?.soldAds;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data.error);
//     }
//   }
// );

//  getMostSoldAds
export const getMostSoldAds = createAsyncThunk(
  "ads/getMostSoldAds",
  async (thunkAPI) => {
    try {
      const response = await adService.getMostSoldAds();
      return response?.mostSoldAds;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Increase View by 1 When Ad is Viewed or Clicked
export const adViewed = createAsyncThunk(
  "ads/adViewed",
  async (id, thunkAPI) => {
    try {
      const response = await adService.adViewed(id);
      return response?.TotalViews;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Post an Offer against Ad
export const postOfferAd = createAsyncThunk(
  "ads/postOfferAd",
  async (body, thunkAPI) => {
    try {
      const response = await adService.postOfferAd(body);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Accept an offer on Ad/Product
export const acceptAdOffer = createAsyncThunk(
  "ads/acceptAdOffer",
  async (body, thunkAPI) => {
    try {
      const response = await adService.acceptAdOffer(body);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Cancel Accepted Offer on Ad/Product
export const cancelAcceptedOffer = createAsyncThunk(
  "ads/cancelAcceptedOffer",
  async (body, thunkAPI) => {
    try {
      const response = await adService.cancelAcceptedOffer(body);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Delete Personal AD
// export const deleteAd = createAsyncThunk(
//   "ads/deleteAd",
//   async (adId, thunkAPI) => {
//     try {
//       const response = await adService.deleteAd(adId);
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data.error);
//     }
//   }
// );

// MARk Ad as inactive
// export const markAsInactive = createAsyncThunk(
//   "ads/markAsInactive",
//   async (id, thunkAPI) => {
//     try {
//       const response = await adService.markAsInactive(id);
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data.error);
//     }
//   }
// );

// MARk Ad as Active
// export const markAsActive = createAsyncThunk(
//   "ads/markAsActive",
//   async (id, thunkAPI) => {
//     try {
//       const response = await adService.markAsActive(id);
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data.error);
//     }
//   }
// );

// MARk Ad as Sold
export const markAsSold = createAsyncThunk(
  "ads/markSold",
  async (body, thunkAPI) => {
    try {
      const response = await adService.markAsSold(body);
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Add an Ad to Favourite
export const addToFavourite = createAsyncThunk(
  "ads/addToFavourite",
  async (id, thunkAPI) => {
    try {
      const response = await adService.addToFavourite(id);
      return response.watchList;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// // Get Featured Ads
export const getFeaturedAd = createAsyncThunk(
  "ads/getFeaturedAd",
  async (thunkAPI) => {
    try {
      const response = await adService.getFeaturedAd();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Add an Ad to Saved List
export const addToSavedList = createAsyncThunk(
  "ads/addToSavedList",
  async (id, thunkAPI) => {
    try {
      const response = await adService.addToSavedList(id);
      return response.savedAds;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Products/Ads for ADMIN
export const getAdminProducts = createAsyncThunk(
  "ad/getAdminProducts",
  async (body, thunkAPI) => {
    try {
      return await adService.getAdminProducts(body);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// MARk Ad as Ban
export const markAsBanned = createAsyncThunk(
  "ads/markAsBanned",
  async (body, thunkAPI) => {
    try {
      const response = await adService.markAsBan(body);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// MARk Ad as Unbanned
export const markAsUnBanned = createAsyncThunk(
  "ads/markAsUnBanned",
  async (body, thunkAPI) => {
    try {
      const response = await adService.markAsUnBan(body);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//Mark Ad  Deleted
export const markAsDelete = createAsyncThunk(
  "ads/markAsDelete",
  async (body, thunkAPI) => {
    try {
      const response = await adService.markAsDelete(body);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//========Get All Reviews for admin ============================
export const getAllReviews = createAsyncThunk(
  "ads/getAllReviews",
  async (thunkAPI) => {
    try {
      return await adService.getAllReviews();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Block A Review
export const blockReview = createAsyncThunk(
  "ads/blockReviews",
  async (id, thunkAPI) => {
    try {
      await adService.blockReview(id);
      const response = await adService.getAllReviews();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Active A Review
export const activeReview = createAsyncThunk(
  "ads/activeReviews",
  async (id, thunkAPI) => {
    try {
      await adService.activeReview(id);
      const response = await adService.getAllReviews();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Active A Review
export const deleteReview = createAsyncThunk(
  "ads/deleteReview",
  async (id, thunkAPI) => {
    try {
      await adService.deleteReview(id);
      const response = await adService.getAllReviews();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Categories
export const getAuthCategories = createAsyncThunk(
  "ads/getAuthCategories",
  async (thunkAPI) => {
    try {
      return await adService.getAuthCategories();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All getMostItemsHome
export const getHomeCategories = createAsyncThunk(
  "ads/getHomeCategories",
  async (thunkAPI) => {
    try {
      return await adService.getHomeCategories();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const adSlice = createSlice({
  name: "ads",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAd.pending, (state, action) => {
        state.isLoading = true;
        state.message = action.payload;
      })
      .addCase(postAd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.ads = action.payload;
      })
      .addCase(postAd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllAds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ads = action.payload;
      })
      .addCase(getFeaturedAd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFeaturedAd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.featuredAds = action.payload;
      })
      .addCase(getAdById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.Ad = action.payload.product[0];
        state.images = action.payload.product[0].images;
        state.Offers = action.payload.product[0].offers;
        state.acceptedOffers = action.payload.product[0]?.acceptedOffers;
        state.questions = action.payload.product[0].questions;
        state.points = action.payload.product[0].points;
        state.allReview = action.payload.review;
      })
      .addCase(postQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postQuestion.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          toast.success(action.payload.message);
        }
        if (action.payload.success === false) {
          toast.error(action.payload.message);
        }
        state.isSuccess = action.payload.success;
      })
      .addCase(postQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.isError = action.payload.success;
      })
      .addCase(postAnswer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postAnswer.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          toast.success(action.payload.message);
        }
        if (action.payload.success === false) {
          toast.error(action.payload.message);
        }
        state.isSuccess = action.payload.success;
      })
      .addCase(postAnswer.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.isError = action.payload.success;
      })
      .addCase(getSellerAds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSellerAds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sellerAd = action.payload;
      })
      .addCase(getSellerAds.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(getMainCategoryAds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMainCategoryAds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categoryAd = action.payload;
      })
      .addCase(getMainCategoryAds.rejected, (state) => {
        state.isLoading = true;
      })
      // .addCase(getPersonalAds.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(getPersonalAds.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.personalAds = action.payload;
      // })
      // .addCase(getPersonalAds.rejected, (state) => {
      //   state.isLoading = true;
      // })

      // .addCase(getSoldAds.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(getSoldAds.fulfilled, (state, action) => {
      //   console.log("Sold Ads Response action.payload", action.payload);
      //   const newArray = state.personalAds.filter((item) => {
      //     return item._id !== action.payload;
      //   });
      //   state.personalAds = newArray;

      //   state.isLoading = false;
      //   state.soldAds = action.payload;
      // })
      // .addCase(getSoldAds.rejected, (state) => {
      //   state.isLoading = true;
      // })
      .addCase(getMostSoldAds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMostSoldAds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.mostSoldAd = action.payload;
      })
      .addCase(getMostSoldAds.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(adViewed.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalAdView = action.payload;
      })
      .addCase(postOfferAd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postOfferAd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success("Offer successfully Added !");
      })
      .addCase(postOfferAd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(acceptAdOffer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(acceptAdOffer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success("Offer Accepted And Order placed Successfully !");
      })
      .addCase(acceptAdOffer.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        toast.error("Error while Accepting Offer !");
      })
      .addCase(cancelAcceptedOffer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelAcceptedOffer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success("Offer Cancelled !");
      })
      // .addCase(deleteAd.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   console.log("action.payload deleted", action.payload);
      //   const newArr = state.ads.filter((item) => {
      //     return item._id !== action.payload;
      //   });
      //   state.ads = newArr;
      //   const newAd = state.ads?.filter((item) => {
      //     return item._id !== action.payload;
      //   });
      //   state.Ad = newAd;
      //   const newArray = state.personalAds.filter((item) => {
      //     return item._id !== action.payload;
      //   });
      //   state.personalAds = action.payload.personalAd;
      //   toast.success("Ad Deleted Successfully!");
      // })
      // .addCase(deleteAd.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.message = action.payload;
      //   state.isError = true;
      //   toast.success(action.payload);
      // })
      // .addCase(markAsInactive.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(markAsInactive.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   state.personalAds = action.payload;

      //   toast.success("Ad InActive Successfully");
      // })
      // .addCase(markAsInactive.rejected, (state) => {
      //   state.isLoading = false;
      //   state.isError = true;
      // })
      // .addCase(markAsActive.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(markAsActive.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   state.personalAds = action.payload;
      //   toast.success("Ad Activated Successfully");
      // })
      // .addCase(markAsActive.rejected, (state) => {
      //   state.isLoading = false;
      //   state.isError = true;
      // })
      // .addCase(markAsSold.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(markAsSold.fulfilled, (state, action) => {
      //   console.log("action from state mark as sold");
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   // const newArr = state.personalAds.filter((item) => {
      //   //   // console.log(
      //   //   //   "item._id !== action.payload._id",
      //   //   //   item._id !== action.payload._id
      //   //   // );
      //   //   return item._id !== action.payload._id;
      //   // });
      //   // state.personalAds = newArr;
      //   // state.soldAds = action.payload;
      //   toast.success(action.payload.message);
      //   // state.soldAds = action.payload;
      //   // var data = {};
      //   // data = state.ads;
      //   // data.adStatus = "sold";
      //   // state.ads = data;
      // })
      // .addCase(markAsSold.rejected, (state) => {
      //   state.isLoading = false;
      //   state.isError = true;
      // })
      .addCase(addToFavourite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToFavourite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.watchList = action.payload;
        toast.success("Added To WishList !");
      })
      .addCase(addToFavourite.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(addToSavedList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToSavedList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.savedList = action.payload;
        toast.success("Added To Saved List !");
      })
      .addCase(addToSavedList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getAdminProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminProducts = action.payload;
      })
      .addCase(getAdminProducts.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(markAsBanned.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(markAsBanned.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminProducts = action.payload;
        toast.success("Ad Banned Successfully");
      })
      .addCase(markAsBanned.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(markAsUnBanned.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(markAsUnBanned.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminProducts = action.payload;
        toast.success("Ad UnBanned Successfully");
      })
      .addCase(markAsUnBanned.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(markAsDelete.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // const newArr = state.ads.filter((item) => {
        //   return item._id !== action.payload;
        // });
        // state.ads = newArr;
        // const newAd = state.ads?.filter((item) => {
        //   return item._id !== action.payload;
        // });
        // state.Ad = newAd;
        // const newArray = state.personalAds.filter((item) => {
        //   return item._id !== action.payload;
        // });
        // state.personalAds = newArray;
        // const adminArray = state.adminProducts.filter((item) => {
        //   return item._id !== action.payload;
        // });
        state.adminProducts = action.payload;
        toast.success("Ad Deleted Successfully!");
      })
      .addCase(markAsDelete.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.isError = true;
        toast.success(action.payload);
      })
      .addCase(getAllReviews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllReviews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminReview = action.payload;
      })
      .addCase(getAllReviews.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(blockReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(blockReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminReview = action.payload;

        toast.success("Review Blocked Successfull");
      })
      .addCase(blockReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(activeReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(activeReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminReview = action.payload;

        toast.success("Review Active Successfull");
      })
      .addCase(activeReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminReview = action.payload;

        toast.success("Review Deleted Successfull");
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAuthCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuthCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.authCategories = action.payload;
      })
      .addCase(getHomeCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHomeCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.homeCategories = action.payload;
      });

    // remove the addToFavourite
    // .addCase(removeAdFromFavourite.pending, (state) => {
    //   state.isLoading = true;
    // })
    // .addCase(removeAdFromFavourite.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.isSuccess = true;
    //   state.watchList = action.payload;
    //   toast.success("Removed From WishList !");
    // })
    // .addCase(removeAdFromFavourite.rejected, (state) => {
    //   state.isLoading = false;
    //   state.isError = true;
    // });
  },
});

export const { reset } = adSlice.actions;
export default adSlice.reducer;
