import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// const Navigate = () => {
//   let navigation = useNavigate();
//   return navigation;
// };
// const API_URL = "/api/users/";
const config = {
  headers: {
    "x-auth-token": localStorage.token,
  },
};

// Register user
const register = async (userData) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/user/signup",
    userData
  );
  // console.log("response signup", response);
  if (response.data.success === true) {
    toast.success(response.data.message);
    // localStorage.setItem("user", JSON.stringify(response.data));
  }
  if (response.data.errors) {
    for (let i = 0; i < response.data.errors.length; i++) {
      toast.error(response.data.errors[i].msg);
    }
  }

  return response.data;
};

// Login user
const login = async (userData) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/user/login",
    userData
  );

  if (response.data.success === true) {
    toast.success("Login successfully !");
    localStorage.setItem("token", response?.data.token);
    localStorage.setItem("userId", response?.data.user?._id);
    return response.data;
  }
  if (
    response.data.success === false &&
    response.data.message === "Your Account is not Verified ! "
  ) {
    window.location.href = "/verify";
  }
  if (response.data.success === false) {
    toast.error(response.data.message);

    // localStorage.setItem("user", JSON.stringify(response.data));
  }
};

// GET public Profile
const getProfile = async (id) => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `/user/${id}`
  );
  return response?.data;
};

// Get All Users
const getAllUsers = async (id) => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/user/",
    config
  );
  return response?.data;
};

// Block a user
const blockUser = async (id) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/user/blockUser",
    { id },
    config
  );
  return response;
};

// Unblock a user
const unblockUser = async (id) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/user/unblockUser",
    { id },
    config
  );

  return response;
};

// Fetch User wallet Amount and active Orders
const fetchBuyerOrders = async (id) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/user/fetchBuyerOrders",
    id,
    config
  );

  return response.data;
};

// Get balance of wallet
const getWalletBalanace = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/user/get/fetchWallet",
    config
  );
  return response;
};

// Logout user
const logout = () => {
  localStorage.clear();
};

const authService = {
  register,
  login,
  getProfile,
  getAllUsers,
  blockUser,
  unblockUser,
  fetchBuyerOrders,
  getWalletBalanace,
  logout,
};

export default authService;
