import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { useSelector, useDispatch } from "react-redux";
import {
  addSport,
  addSubSport,
  getAllMainSports,
} from "../features/sport/sportSlice";
//----------react-translate------------
import { useTranslation } from "react-i18next";
// const { t } = useTranslation();

const AddSport = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { sports } = useSelector((state) => state.sport);

  //==============UseEffect start========================
  //==============Use Effect================================
  useEffect(() => {
    dispatch(getAllMainSports());
  }, [dispatch]);
  //==============Use Effect================================
  //==============UseEffect end========================

  //=============Form Add Category start================
  const [name, setName] = useState("");

  //================= Handle file And Preview start==========================
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  //================= Handle file And Preview end==========================
  const handleAddSport = () => {
    var sportData = new FormData();
    sportData.append("name", name);
    sportData.append("file", selectedFile);
    dispatch(addSport(sportData));
    setName("");
    setSelectedFile(undefined);
  };
  //=============Form Add Category end================

  const [mainSport, setMainSportData] = useState("");
  const [SubSport, setSubSportData] = useState([]);

  let Options = SubSport?.map((sub) => {
    return {
      label: sub.label,
      value: sub.value,
    };
  });

  let sendSub = Options?.map((opt) => opt.value);

  // handle Main change
  const handleMainSportChange = (e) => {
    setMainSportData(e.target.value);
  };
  // handle Sub change

  const handleSubSport = (e) => {
    setSubSportData(e);
  };

  //===============Add Sub category start========================

  const handleAddSubSport = (e) => {
    e.preventDefault();
    let body = {
      subSport: sendSub,
      sport: mainSport,
    };
    dispatch(addSubSport(body));
    setMainSportData("");
    setSubSportData([]);
  };

  //===============Add Sub category end========================

  return (
    <div>
      <div className="profile-infos d-block">
        <h5 className="withdraw-title">
          {" "}
          {t("Add Sport")} & {t("Sub Sport")}
        </h5>
        <div className="row">
          <div className="col-lg-4">
            <div className="form-field">
              <label>{t("Sport")}</label>
              <div className="form-input">
                <input
                  type="text"
                  placeholder={t("Please enter sport name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-field">
              <label>{t("Add Image")}</label>
              <div className="form-input">
                <input
                  type="file"
                  className="form-control"
                  onChange={onSelectFile}
                  accept="image/png, image/gif, image/jpeg"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2 my-auto">
            {selectedFile && (
              <img className="rounded-circle cat-img " src={preview} alt="" />
            )}
          </div>
          <div className="col-lg-3  my-auto">
            <label></label>

            <div className="infos-btns">
              <button
                className="mt-3 green-btn hover-shadow"
                onClick={handleAddSport}
              >
                {t("Add Sport")}
              </button>
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="form-field">
              <label>{t("Select Main Sport")}</label>
              <select
                value={mainSport}
                onChange={(e) => handleMainSportChange(e)}
              >
                <option selected>{t("")}Select Sport</option>
                {sports
                  ? sports.map((sport, i) => (
                      <option value={sport?._id} key={i}>
                        {sport?.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="form-field">
              <label>{t("Sub Sport")}</label>
              <CreatableSelect
                isClearable={true}
                value={SubSport}
                isMulti={true}
                onChange={(e) => handleSubSport(e)}
              />
            </div>
          </div>

          <div className="col-lg-3  my-auto">
            <label></label>

            <div className="infos-btns">
              <button
                className="mt-3 green-btn hover-shadow"
                onClick={handleAddSubSport}
              >
                {t("Add Sub Sport")}
              </button>
            </div>
          </div> */}
        </div>
        <h4 className="my-4 text-success border-bottom border-success pb-2 w-25">
          {t("All Sports")}
        </h4>

        <div className="row">
          {sports
            ? sports.map((sport, i) => (
                <div className="col-4 col-lg-2 mb-2" key={i}>
                  <div className="card shadow-sm p-2 cate-card">
                    <img
                      className="rounded-circle cat-img mx-auto"
                      src={
                        process.env.REACT_APP_IMAGES_URL + "/" + sport?.images
                      }
                      onError={(e) => (e.target.src = "/cate.jpg")}
                      alt=""
                    />
                    <h3 className="mx-auto fs-6 my-2">{sport?.name}</h3>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default AddSport;
