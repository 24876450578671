import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from "moment";
//---------trnslate
import { useTranslation } from "react-i18next";

const Points = () => {
  const { t } = useTranslation();

  let userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  //==============Get User Logs and Points Api Start====================
  const [userLogs, setUserLogs] = useState([]);

  //==============Get Total Points start =================
  var totalPoints = 0;
  userLogs?.forEach((service) => {
    totalPoints = totalPoints + service?.points;
  });
  //==============Get Total Points start =================

  useEffect(() => {
    const getCredits = async () => {
      try {
        const config = {
          headers: {
            "x-auth-token": localStorage.token,
          },
        };
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/user/getUserLogs",
          { userId: userId },
          config
        );
        if (res.data) setUserLogs(res?.data?.credits);
      } catch (err) {
        console.log(err.response.data.error);
      }
    };
    getCredits();
  }, [userId, token]);

  //==============Get User Logs and Points Api end====================
  return (
    <div>
      <div className="points-heading">
        <h3>{t("My Points")} </h3>
        <h5 className="mb-2">
          {t("Total Points")}: {totalPoints}
        </h5>
      </div>
      <div
        style={{
          height: "450px ",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {userLogs?.map((item, i) => (
          <div className="point-card shadow" key={i}>
            <h6>
              {t("You have earned")}{" "}
              <span className="text-success font-weight-bold border-bottom border-success">
                {item?.points}
              </span>{" "}
              {t("points from")} {item?.activity}
            </h6>
            <span>{Moment(item?.date).format("lll")}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Points;
