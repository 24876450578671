import React, { useState, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Range, getTrackBackground } from "react-range";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
// import search from "../assets/icons/search.svg";

// import searchBlue from "../assets/icons/searchBlue.svg";
// import down from "../assets/icons/down.svg";
// import remove from "../assets/icons/remove.svg";
// import heartRed from "../assets/icons/heartRed.svg";
// import heart from "../assets/icons/heart.svg";
// import left from "../assets/icons/left.svg";
// import right from "../assets/icons/right.svg";
import p1 from "../assets/images/p1.jpg";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import { AiFillEye } from "react-icons/ai";
import Spinner from "../components/Spinner";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";

//============Import Pagination Page==============
// import Pagination from "./Pagination";

//==============redux actions start=============================
// import { getAllAds } from "../features/ad/adSlice";
import { getAllCategoriesMain } from "../features/category/categorySlice";
import {
  // searchByCategory,
  // priceFilter,
  homeSeached,
} from "../features/search/searchSlice";
import { getAllBrands } from "../features/brands/brandSlice";
import { getAllColors } from "../features/color/colorSlice";

//==============redux actions end=============================

//==============redux Hooks start=============================
import { useSelector, useDispatch } from "react-redux";

//==============redux Hooks end=============================
//====================React translate================================
import { useTranslation } from "react-i18next";

const Items = () => {
  const { t } = useTranslation();

  //==============redux start=============================
  const dispatch = useDispatch();
  const { id } = useParams();

  const { data, loading } = useSelector((state) => {
    return state.search;
  });

  const { categories } = useSelector((state) => state.category);
  const { allColors } = useSelector((state) => state.color);
  const { allBrands } = useSelector((state) => state.brand);

  //==============redux  end===============================
  //==============Use Effect================================
  useEffect(() => {
    dispatch(getAllCategoriesMain());
    // dispatch(getAllAds());
    dispatch(getAllBrands());
    dispatch(getAllColors());
  }, [dispatch]);

  useEffect(() => {
    // dispatch(homeSeached(id));
    const authResult = new URLSearchParams(window.location.search);
    dispatch(homeSeached(authResult.toString()));
  }, [dispatch]);

  //==============Use Effect end================================

  //==============Filters Start=================================

  //=================Handler category start=================
  // handle Main change
  const [mainCategory, setMainCategoryData] = useState("");
  const handleMainCategoryChange = (e) => {
    setMainCategoryData(e.target.value);
    const authResult = new URLSearchParams(window.location.search);
    if (e.target.value === "All") {
      authResult.delete("Category");
      dispatch(homeSeached(e.target.value));
      window.history.replaceState(
        null,
        "New Page Title",
        "?" + authResult.toString()
      );
    } else {
      if (authResult.get("Category") != null) {
        authResult.set("Category", e.target.value);
      } else {
        authResult.set("Category", e.target.value);
      }

      dispatch(homeSeached(authResult.toString()));
      window.history.replaceState(
        null,
        "New Page Title",
        "?" + authResult.toString()
      );
    }
  };

  //=================Handler category end=================

  //=================Handler condition start=================
  const [condtionAd, setConditionAd] = useState("");

  const handleConditionChange = (e) => {
    setConditionAd(e.target.value);
    const authResult = new URLSearchParams(window.location.search);

    if (e.target.value === "All") {
      authResult.delete("Condition");
      dispatch(homeSeached(e.target.value));
      window.history.replaceState(
        null,
        "New Page Title",
        "?" + authResult.toString()
      );
    } else {
      if (authResult.get("Condition") != null) {
        authResult.set("Condition", e.target.value);
      } else {
        authResult.set("Condition", e.target.value);
      }

      dispatch(homeSeached(authResult.toString()));
      window.history.replaceState(
        null,
        "New Page Title",
        "?" + authResult.toString()
      );
    }
  };

  //=================Handler condition  end=================

  //=================Handler Color start=================
  const [color, setColorAd] = useState("");

  const handleColorChange = (e) => {
    setColorAd(e.target.value);
    const authResult = new URLSearchParams(window.location.search);

    if (e.target.value === "All") {
      authResult.delete("Color");
      dispatch(homeSeached(e.target.value));
      window.history.replaceState(
        null,
        "New Page Title",
        "?" + authResult.toString()
      );
    } else {
      if (authResult.get("Color") != null) {
        authResult.set("Color", e.target.value);
      } else {
        authResult.set("Color", e.target.value);
      }
      dispatch(homeSeached(authResult.toString()));
      window.history.replaceState(
        null,
        "New Page Title",
        "?" + authResult.toString()
      );
    }
  };

  //=================Handler condition  end=================

  //=================Handler Brand start=================
  const [brand, setBrand] = useState("");

  const handleBrandChange = (e) => {
    setBrand(e.target.value);
    const authResult = new URLSearchParams(window.location.search);
    if (e.target.value === "All") {
      authResult.delete("Brand");
      dispatch(homeSeached(e.target.value));
      window.history.replaceState(
        null,
        "New Page Title",
        "?" + authResult.toString()
      );
    } else {
      if (authResult.get("Brand") != null) {
        authResult.set("Brand", e.target.value);
      } else {
        authResult.set("Brand", e.target.value);
      }
      dispatch(homeSeached(authResult.toString()));
      window.history.replaceState(
        null,
        "New Page Title",
        "?" + authResult.toString()
      );
    }
  };

  //=================Handler condition  end=================

  //============ Form Data end ===============
  //================= OnChange Handler =====================
  const MIN = 1;
  const MAX = 500000;
  const [values, setValues] = useState([MIN, MAX]);
  const setMinValue = (e) => {
    e.preventDefault();
    let items = [...values];
    let item = { ...values[0] };
    item = e.target.value;
    items[0] = item;
    setValues(items);
  };

  const setMaxValue = (e) => {
    e.preventDefault();

    let items = [...values];
    let item = { ...values[1] };
    item = e.target.value;
    items[1] = item;
    setValues(items);
  };

  const applyPiceFilter = () => {
    const authResult = new URLSearchParams(window.location.search);
    if (authResult.get("Price") != null) {
      authResult.set("Price", values[0] + "-" + values[1]);
    } else {
      authResult.set("Price", values[0] + "-" + values[1]);
    }
    dispatch(homeSeached(authResult.toString()));
    window.history.replaceState(
      null,
      "New Page Title",
      "?" + authResult.toString()
    );

    // dispatch(priceFilter({ minValue: values[0], maxValue: values[1] }));
  };

  // handle Option types change
  const [optionTypes, setOptionTypes] = useState("");
  const handleOptionTypes = (e) => {
    setOptionTypes(e.target.value);
    const authResult = new URLSearchParams(window.location.search);
    authResult.delete("Popular");
    authResult.delete("Featured");
    authResult.delete("Latest");
    authResult.delete("PriceLowToHigh");
    dispatch(homeSeached(e.target.value));
    if (e.target.value === "Popular") {
      if (authResult.get("Popular") != null) {
        authResult.set("Popular", e.target.value);
      } else {
        authResult.set("Popular", e.target.value);
      }
    } else if (e.target.value === "Featured") {
      if (authResult.get("Featured") != null) {
        authResult.set("Featured", e.target.value);
      } else {
        authResult.set("Featured", e.target.value);
      }
    } else if (e.target.value === "Latest") {
      if (authResult.get("Latest") != null) {
        authResult.set("Latest", e.target.value);
      } else {
        authResult.set("Latest", e.target.value);
      }
    } else if (e.target.value === "PriceLowToHigh") {
      if (authResult.get("PriceLowToHigh") != null) {
        authResult.set("PriceLowToHigh", e.target.value);
      } else {
        authResult.set("PriceLowToHigh", e.target.value);
      }
    }

    dispatch(homeSeached(authResult.toString()));
    window.history.replaceState(
      null,
      "New Page Title",
      "?" + authResult.toString()
    );
  };

  // const authResult = new URLSearchParams(window.location.search);
  // if (authResult.get("Popular") != null) {
  //   authResult.set("Popular", optionTypes);
  // } else {
  //   authResult.set("Popular", optionTypes);
  // }
  // dispatch(homeSeached("?" + authResult.toString()));
  // window.history.replaceState(
  //   null,
  //   "New Page Title",
  //   "?" + authResult.toString()
  // );

  //=================Handler category end=================

  //================Location filter start=================
  const [location, setLocation] = useState();
  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    const authResult = new URLSearchParams(window.location.search);
    if (e.target.value === "All") {
      authResult.delete("Location");
      dispatch(homeSeached(e.target.value));
      window.history.replaceState(
        null,
        "New Page Title",
        "?" + authResult.toString()
      );
    } else {
      if (authResult.get("Location") != null) {
        authResult.set("Location", e.target.value);
      } else {
        authResult.set("Location", e.target.value);
      }
      dispatch(homeSeached(authResult.toString()));
      window.history.replaceState(
        null,
        "New Page Title",
        "?" + authResult.toString()
      );
    }
  };

  //================Location filter end=================

  //==============Filters end=================================

  // //==============Pagination  START=============================
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(8);

  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = data?.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(data?.length / recordsPerPage);

  // //==============Pagination  END===============================

  return (
    <div>
      <Header />
      <Categories />

      <div className="container">
        <div className="items-filters">
          {/* <div className="dropdown items-filter">
            <div
              className="dropdown-togglej items-filter-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={searchBlue} className="d-search-img" alt="" />
              <input
                type="text"
                placeholder="Categories"
                // value={mainCategory}
                // onChange={(e) => handleMainCategoryChange(e)}
              />
              <img src={down} className="d-down-img" alt="" />
            </div>
            <ul
              className="dropdown-menu"
              value={mainCategory}
              onChange={(e) => handleMainCategoryChange(e)}
            >
              {categories
                ? categories.map((category, i) => <li>{category?.name}</li>)
                : null}
            </ul>
          </div> */}
          <div className="dropdown items-filter d-flex filter-width">
            <select
              className="form-control"
              value={mainCategory}
              onChange={(e) => handleMainCategoryChange(e)}
            >
              <option value="All">{t("All Categories")} </option>
              {categories
                ? categories.map((category, i) => (
                    // <option value={`Category=${category?._id}`} key={i}>
                    <option value={category?._id} key={i}>
                      {category?.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="dropdown items-filter d-flex filter-width">
            <select
              className="form-control"
              value={location}
              onChange={(e) => handleLocationChange(e)}
            >
              <option value="All">{t("All Location")} </option>

              <option value="Zürich (ZH)">Zürich (ZH)</option>
              <option value="Berne (BE)">Berne (BE)</option>

              <option value="Luzern (LU)">Luzern (LU)</option>

              <option value="Uri (UR)">Uri (UR)</option>
              <option value="Schwyz (SZ)">Schwyz (SZ)</option>
              <option value="Unterwalden (Obwalden (OW) / Nidwalden (NW))">
                Unterwalden (Obwalden (OW) / Nidwalden (NW))
              </option>
              <option value="Glarus (GL)">Glarus (GL)</option>
              <option value="Zug (ZG)">Zug (ZG)</option>
              <option value="Freiburg / Fribourg (FR)">
                Freiburg / Fribourg (FR)
              </option>
              <option value="Solothurn (SO)">Solothurn (SO)</option>
            </select>
          </div>
          <div className="dropdown items-filter d-flex filter-width">
            <select
              className="form-control"
              value={condtionAd}
              onChange={(e) => handleConditionChange(e)}
            >
              {["All", "New", "Good", "Fair", "Repaired"].map((cond, i) => (
                // <option value={`Condition=${cond}`} key={i}>
                <option value={cond} key={i}>
                  {cond}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown items-filter d-flex filter-width">
            <select
              className="form-control"
              value={color}
              onChange={(e) => handleColorChange(e)}
            >
              <option value="All">{t("All Colors")}</option>
              {allColors?.map((color, i) => (
                // <option value={`Color=${color?.name}`} key={i}>
                <option value={color?.name} key={i}>
                  {color?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown items-filter d-flex filter-width">
            <select
              className="form-control"
              value={brand}
              onChange={(e) => handleBrandChange(e)}
            >
              <option value="All">{t("All Brands")}</option>
              {allBrands?.map((brand, i) => (
                // <option value={`Brand=${color?.name}`} key={i}>
                <option value={brand?.name} key={i}>
                  {brand?.name}
                </option>
              ))}
            </select>
          </div>

          {/* <div className="dropdown items-filter">
            <div
              className="dropdown-togglej items-filter-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={searchBlue} className="d-search-img" alt="" />
              <input type="text" placeholder="Location" />
              <img src={down} className="d-down-img" alt="" />
            </div>
            <ul className="dropdown-menu">
              <li>Women</li>
              <li>Men</li>
              <li>Kids</li>
            </ul>
          </div>
          <div className="dropdown items-filter">
            <div
              className="dropdown-togglej items-filter-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={searchBlue} className="d-search-img" alt="" />
              <input type="text" placeholder="Brand" />
              <img src={down} className="d-down-img" alt="" />
            </div>
            <ul className="dropdown-menu">
              <li>Women</li>
              <li>Men</li>
              <li>Kids</li>
            </ul>
          </div>
          <div className="dropdown items-filter">
            <div
              className="dropdown-togglej items-filter-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={searchBlue} className="d-search-img" alt="" />
              <input type="text" placeholder="Color" />
              <img src={down} className="d-down-img" alt="" />
            </div>
            <ul className="dropdown-menu">
              <li>Women</li>
              <li>Men</li>
              <li>Kids</li>
            </ul>
          </div> */}

          <div className="dropdown items-filter d-flex  filter-width">
            <Dropdown>
              <Dropdown.Toggle
                className="top-btn-2 btn btn-light border"
                id="item-condition-dropdown-basic"
              >
                {t("Price")}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ height: "400px", maxWidth: "100vw", width: "350px" }}
              >
                <h5 style={{ margin: "15px", fontSize: "18px" }}>
                  <strong>{t("How much ?")}</strong>
                </h5>
                <Dropdown.ItemText>
                  <p>{t("Price Range")}</p>
                  <Range
                    values={values}
                    min={MIN}
                    max={MAX}
                    onChange={(values) => {
                      setValues(values);
                    }}
                    renderTrack={({ props, children }) => (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: "36px",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: "5px",
                            width: "100%",
                            borderRadius: "4px",
                            background: getTrackBackground({
                              values,
                              colors: ["#ccc", "#548BF4", "#ccc"],
                              min: MIN,
                              max: MAX,
                            }),
                            alignSelf: "center",
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "30px",
                          width: "30px",
                          borderRadius: "20px",
                          backgroundColor: "#FFF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0px 2px 6px #AAA",
                        }}
                      >
                        <div
                          style={{
                            height: "16px",
                            width: "5px",
                            backgroundColor: isDragged ? "#548BF4" : "#CCC",
                          }}
                        />
                      </div>
                    )}
                  />
                  <p style={{ opacity: "40%", marginTop: "10px" }}>
                    1
                    <span
                      style={{
                        color: "black",
                        opacity: "80%",
                        float: "right",
                      }}
                    >
                      999999
                    </span>
                  </p>
                </Dropdown.ItemText>

                <Dropdown.ItemText>
                  <span style={{ marginLeft: "10px" }}>{t("From")}</span>{" "}
                  <span style={{ marginLeft: "130px" }}>{t("To")}</span>
                </Dropdown.ItemText>
                <Dropdown.ItemText style={{ margin: "10px", fontSize: "18px" }}>
                  <span>
                    <input
                      type="text"
                      value={values[0]}
                      style={{
                        opacity: "80%",
                        border: "1px solid gray",
                        borderRadius: "5px",
                        height: "50px",
                        width: "135px",
                        paddingLeft: "10px",
                      }}
                      maxLength={10}
                      onChange={setMinValue}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="From"
                    />
                  </span>
                  <span>
                    <input
                      type="text"
                      style={{
                        opacity: "80%",
                        borderRadius: "5px",
                        border: "1px solid gray",
                        marginLeft: "20px",
                        height: "50px",
                        width: "135px",
                        paddingLeft: "10px",
                      }}
                      maxLength={10}
                      onChange={setMaxValue}
                      value={values[1]}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="To"
                    />
                  </span>
                </Dropdown.ItemText>
                <Dropdown.ItemText></Dropdown.ItemText>
                <Dropdown.Toggle
                  className="btn"
                  style={{
                    marginTop: "30px",
                    marginLeft: "165px",
                    opacity: "50%",
                    backgroundColor: "white",
                    color: "black",
                    border: "0px solid white",
                  }}
                  id="cancel-condition-dropdown-basic"
                >
                  {t("Cancel")}
                </Dropdown.Toggle>

                <Button
                  style={{
                    marginTop: "30px",
                    backgroundColor: "#13C1AC",
                    color: "white",
                    borderRadius: "30px",
                    border: "0px solid #13C1AC",
                  }}
                  onClick={applyPiceFilter}
                >
                  {t("Apply")}
                </Button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* <div className="dropdown items-filter">
            <div
              className="dropdown-togglej items-filter-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={searchBlue} className="d-search-img" alt="" />
              <input type="text" placeholder="Price" />
              <img src={down} className="d-down-img" alt="" />
            </div>
            <ul className="dropdown-menu">
              <li>Women</li>
              <li>Men</li>
              <li>Kids</li>
            </ul>
          </div> */}
        </div>
        {/* {mainCategory === "All" && condtionAd === "All" && color === "All" && brand === "All" && location === "All" ? (
         <div className="selected-filter">All</div>
       ) : (

       )} */}

        {mainCategory === "All" ||
        condtionAd === "All" ||
        color === "All" ||
        brand === "All" ||
        location === "All" ? (
          <div className="selected-filter">All</div>
        ) : (
          <div className="selected-filters">
            {optionTypes ? (
              <div className="selected-filter">{optionTypes}</div>
            ) : null}
            {mainCategory && (
              <div className="selected-filter">
                {categories
                  ? categories.map((category, i) => (
                      <div key={i}>
                        {mainCategory === category?._id ? category?.name : null}
                      </div>
                    ))
                  : null}
                {/* <img src={remove} alt="" /> */}
              </div>
            )}

            {condtionAd && (
              <div className="selected-filter">
                {condtionAd}
                {/* <img src={remove} alt="" /> */}
              </div>
            )}

            {color && (
              <div className="selected-filter">
                {color}
                {/* <img src={remove} alt="" /> */}
              </div>
            )}
            {brand && (
              <div className="selected-filter">
                {brand}
                {/* <img src={remove} alt="" /> */}
              </div>
            )}
            {location && (
              <div className="selected-filter">
                {location}
                {/* <img src={remove} alt="" /> */}
              </div>
            )}

            {/* {MAX > 1 || MIN < 1999999 ? (
              <div className="selected-filter">
                {values[0] + "-" + values[1]}
              </div>
            ) : null} */}
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between">
          <h2 className="items-heading my-4 font-poppins d-flex align-items-end">
            {t("Items")}
            <span className="fs-6 mb-1 ms-2">
              {data?.length ? data?.length : 0} {t(" result show ")}
            </span>
          </h2>
          <div className="dropdown items-filter d-flex">
            <select
              className="form-cancel-2 dropdown-togglej items-filter-btn"
              value={optionTypes}
              onChange={(e) => handleOptionTypes(e)}
            >
              <option selected>----Select----</option>

              <option value="Popular">{t("Popular")}</option>
              <option value="Featured">{t("Featured")}</option>
              <option value="Latest">{t("Latest")}</option>
              <option value="PriceLowToHigh">
                {t("Price Lowest to Highest")}
              </option>
            </select>
          </div>
        </div>

        <div className="row">
          {loading && (
            <div style={{ marginTop: "100px" }}>
              <Spinner />
            </div>
          )}
          {!loading && data?.length === 0 && (
            <div style={{ marginTop: "50px" }}>
              <h5 className="text-center">{t("No Ads Found !")} </h5>
            </div>
          )}
          {data.length > 0 ? (
            data?.map((ad, i) => (
              <div className="col-lg-3 col-md-6" key={i}>
                <div className="item-card shadow">
                  <Link
                    to={`/details/${ad._id}`}
                    className="item-card-img w-100"
                  >
                    {ad?.type === "Urgent" && (
                      <div className="product-ribbon urgent">{ad?.type}</div>
                    )}
                    {ad?.type === "Standard" && (
                      <div className="product-ribbon standard">{ad?.type}</div>
                    )}
                    {ad?.type === "Featured" && (
                      <div className="product-ribbon featured">{ad?.type}</div>
                    )}
                    {ad?.images ? (
                      <img
                        className="product-img"
                        src={
                          process.env.REACT_APP_IMAGES_URL + "/" + ad?.images[0]
                        }
                        alt=""
                        onError={(e) => (e.target.src = "/product.jpg")}
                      />
                    ) : (
                      <img className="product-img" src={p1} alt="" />
                    )}
                    {/* <div className="item-card-like">
                      <img src={heartRed} alt="" />
                    </div> */}
                  </Link>
                  <div className="item-card-text">
                    <div className="product-text-top">
                      <h6>
                        <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                      </h6>
                      <div>
                        {/* <i onClick={() => setP1Like(!p1Like)}>
                            {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                          </i> */}
                        <AiFillEye className="me-2" />
                        {ad?.views?.length}
                      </div>
                    </div>
                    <div className="product-text-bottom">
                      <div className="desc">{ad?.description}</div>
                      <h5>$ {ad?.price}</h5>
                    </div>
                    <div className="item-card-brand">
                      <span>{t("Brand")}:</span> {ad?.brand}
                    </div>
                    <div className="item-card-tags">
                      {ad?.option?.map((opt, i) => (
                        <div key={i} className="d-flex">
                          {opt.split(",").map((op) => (
                            <div className="item-card-tag both">{op}</div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex justify-content-center">
              <h2 className="text-center mt-4 mb-5 bg-light rounded shadow p-3 w-25">
                {data?.message}
              </h2>
            </div>
          )}
        </div>
        {/* <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        /> */}
      </div>

      <Footer />
    </div>
  );
};

export default Items;
