import React, { useState, useEffect } from "react";
import axios from "axios";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import mail from "../assets/icons/mail.png";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import { toast } from "react-toastify";

//===================Redux===================
// import { useSelector, useDispatch } from "react-redux";

//=================API METHODS================
//---------trnslate
import { useTranslation } from "react-i18next";

const ResetPass = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  //============States===============
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleShowNew = () => setShowNew(!showNew);
  const handleShowConfirm = () => setShowConfirm(!showConfirm);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //==============Login Handler Submit=============
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let body = {
        email: email,
        newPassword: password,
        confirmPassword: confirmPassword,
      };
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };

      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/user/resetPassword",
        body,
        config
      );
      if (res.data.success === true) {
        toast.success(res.data.message);
        navigate("/login");
      }

      if (res.data.success === false) {
        toast.error(res.data.message);
      }
    } catch (err) {
      console.log(err.response.data.error);
    }
  };

  return (
    <div>
      <Header />
      <Categories />

      <div className="register">
        <div className="container mb-5">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="register-form shadow">
                <h3 className="register-form-heading font-sora">
                  {t("Reset Password ")}
                </h3>

                <div className="row">
                  <div className="col-12 register-col">
                    <label>{t("Email")}</label>
                    <div className="register-field">
                      <img src={mail} alt="" />
                      <input
                        type="email"
                        placeholder="e.g  jhn22@gmail.com"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 register-col">
                    <div className="form-field">
                      <label>{t("New Password")}</label>
                      <div className="form-input">
                        <input
                          type={showNew ? "text" : "password"}
                          autoComplete="off"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {/* <input type="password" placeholder="sbdfbnd65sfdvb s" /> */}
                        <button
                          type="button"
                          className="btn btn-sm"
                          onClick={handleShowNew}
                        >
                          {showNew ? <AiFillEyeInvisible /> : <AiFillEye />}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 register-col">
                    <div className="form-field">
                      <label>{t("Confirm Password")}</label>
                      <div className="form-input">
                        <input
                          type={showConfirm ? "text" : "password"}
                          autoComplete="off"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {/* <input type="password" placeholder="sbdfbnd65sfdvb s" /> */}
                        <button
                          type="button"
                          className="btn btn-sm"
                          onClick={handleShowConfirm}
                        >
                          {showConfirm ? <AiFillEyeInvisible /> : <AiFillEye />}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="register-submit text-light green-btn hover-shadow border-0"
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ResetPass;
