import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { VscVmActive } from "react-icons/vsc";
import { FaBan } from "react-icons/fa";
import Moment from "moment";
import {
  getAllReviews,
  blockReview,
  activeReview,
  deleteReview,
} from "../features/ad/adSlice";
import ReactStars from "react-rating-stars-component";

//============redux import============
import { useDispatch, useSelector } from "react-redux";
//====================React translate================================
import { useTranslation } from "react-i18next";
import { AiFillDelete } from "react-icons/ai";

const Reviews = () => {
  const { t } = useTranslation();

  //==================redux hooks start=================
  const dispatch = useDispatch();
  const { adminReview } = useSelector((state) => {
    return state.ads;
  });
  //==================redux hooks end=================

  //==================Use Effect start (Get All Reviews)=================
  useEffect(() => {
    dispatch(getAllReviews());
  }, [dispatch]);

  //==================Use Effect end (Get All Reviews)=================

  //==================Use Effect start (Active User)=================

  const handleActive = (e, id) => {
    e.preventDefault();
    dispatch(activeReview(id));
  };

  const handleDeActive = (e, id) => {
    e.preventDefault();
    dispatch(blockReview(id));
  };
  //==================Use Effect end (Active User)=================

  //================Handle Delete Review =================
  const handleDeleteReview = (e, id) => {
    let body = {
      reviewId: id,
    };
    e.preventDefault();
    dispatch(deleteReview(body));
  };

  return (
    <div>
      <div className="profile-infos d-block">
        <h5 className="withdraw-title">{t("Reviews")} </h5>
        <div className="table-responsive">
          <table className="table shop-table table-bordered">
            <thead>
              <tr>
                <th className="text-center">{t("Sr No.")}</th>
                <th className="text-center text-nowrap">{t("Reviewer")}</th>
                <th className="text-center text-nowrap">{t("Reviewing")}</th>
                <th className="text-center">{t("Rating")}</th>
                <th className="text-center">{t("Comment")}</th>
                <th className="text-center">{t("Status")}</th>
                <th className="text-center">{t("Created At")}</th>
                <th className="text-center">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {adminReview?.map((data, i) => (
                <tr key={i}>
                  <td className="text-center">{i + 1}</td>

                  <td className="text-center text-nowrap">
                    <Link to={`/profile/${data?.buyerId._id}`}>
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          data?.buyerId.image
                        }
                        style={{
                          width: "45px",
                          height: "45px",
                          objectFit: "cover",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        alt=""
                        onError={(e) => (e.target.src = "/user.png")}
                      />
                      <span> {data?.buyerId.name}</span>
                    </Link>
                  </td>
                  <td className="text-center text-nowrap">
                    <Link to={`/profile/${data?.sellerId._id}`}>
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          data?.sellerId.image
                        }
                        style={{
                          width: "45px",
                          height: "45px",
                          objectFit: "cover",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        alt=""
                        onError={(e) => (e.target.src = "/user.png")}
                      />
                      <span> {data?.sellerId.name}</span>
                    </Link>
                  </td>
                  <td className="text-center">
                    {
                      <ReactStars
                        count={5}
                        value={data?.rating}
                        size={20}
                        isHalf={true}
                        edit={false}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                      />
                    }
                  </td>
                  <td className="text-center">{data?.comment}</td>
                  {data?.status === true ? (
                    <td className="text-center text-success">{t("Active")}</td>
                  ) : (
                    <td className="text-center text-danger">{t("Block")}</td>
                  )}
                  <td className="text-center">
                    {Moment(data?.createdAt).format("DD-MM-YYYY")}
                  </td>
                  <td className="text-center">
                    <div className="d-flex justify-content-center">
                      {data?.status === true ? (
                        <button className="btn ">
                          <FaBan
                            className="text-danger"
                            title="Block"
                            onClick={(e) => handleDeActive(e, data._id)}
                          />
                        </button>
                      ) : (
                        <button className="btn">
                          <VscVmActive
                            className="text-primary"
                            title="Active"
                            onClick={(e) => handleActive(e, data._id)}
                          />
                        </button>
                      )}
                      <button className="btn ">
                        <AiFillDelete
                          className="text-danger"
                          onClick={(e) => handleDeleteReview(e, data._id)}
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
