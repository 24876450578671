import axios from "axios";
import { toast } from "react-toastify";

const config = {
  headers: {
    "x-auth-token": localStorage.token,
  },
};
// Post an Ad
const postAnAd = async (formData) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/add",
    formData,
    {
      headers: {
        "x-auth-token": localStorage.token,
      },
    }
  );
  if (response.data.success === true) {
    toast.success(response?.data.message);
    return response.data;
  }
  if (response.data.errors) {
    for (let i = 0; i < response.data.errors.length; i++) {
      toast.error(response.data.errors[i].msg);
    }
  }
  // if (response.data.success === false) {
  //   toast.error(response?.data.message);
  // }
};

// GET All Ads
const getAllAdsDB = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/product/getAllProducts"
  );
  return response?.data;
};

// GET Featured Ads
const getFeaturedAd = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/product/get/featuredAd"
  );
  return response?.data;
};

// Get Ad by Id
const getAdById = async (id) => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `/product/getById/${id}`
  );
  return response?.data;
};

// Post a Question on Ad
const postQuestion = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/addQuestion",
    body,
    config
  );
  return response?.data;
};

// Post a Question on Ad
const postAnswer = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/answerQuestion",
    body,
    config
  );
  return response?.data;
};

// Get Seller Products/Ads
const getSellerAds = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/get/sellerAds",
    body
  );
  return response?.data;
};

// Get Main Category Products/Ads
const getMainCateAds = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/get/categoryAd",
    body
  );
  return response?.data;
};

// Get Personal Products/Ads

// const getPersonalAds = async (body) => {
//   const response = await axios.post(
//     process.env.REACT_APP_API_URL + "/product/get/personalAds",
//     body,
//     config
//   );

//   return response?.data;
// };

// Get Personal Sold Products/Ads

// const getSoldAds = async (body) => {
//   const response = await axios.post(
//     process.env.REACT_APP_API_URL + "/product/get/getSoldAds",
//     body,
//     config
//   );
//   console.log("Sold Ads Response Service", response);

//   return response?.data;
// };

// Get Most Sold Products/Ads On Home Page

const getMostSoldAds = async (body) => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/product/get/getMostSoldAds"
  );
  return response?.data;
};

// Increase View when ad viewed
const adViewed = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/get/viewedAd",
    body
  );
  return response?.data;
};

// Add/Post Offer on Products/Ads
const postOfferAd = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/postOffer",
    body,
    config
  );
  return response?.data?.msg;
};

// Accept an Offer on Products/Ads
const acceptAdOffer = async (body) => {
  const response = await axios.put(
    process.env.REACT_APP_API_URL + "/product/acceptOffer",
    body,
    config
  );
  return response?.data?.msg;
};

// Cancel accepted Offer on Products/Ads
const cancelAcceptedOffer = async (body) => {
  const response = await axios.put(
    process.env.REACT_APP_API_URL + "/product/cancelOffer",
    body,
    config
  );
  return response?.data?.msg;
};

// Delete an Personal Ad
// const deleteAd = async (adId) => {
//   const response = await axios.post(
//     process.env.REACT_APP_API_URL + "/product/delete",
//     adId,
//     config
//   );
//   return response?.data;
// };

// Mark as Inactive
// const markAsInactive = async (adId) => {
//   const response = await axios.post(
//     process.env.REACT_APP_API_URL + "/product/markAsInactive",
//     { adId },
//     config
//   );
//   return response?.data;
// };

// Mark as Inactive
// const markAsActive = async (adId) => {
//   const response = await axios.post(
//     process.env.REACT_APP_API_URL + "/product/markAsActive",
//     { adId },
//     config
//   );
//   return response?.data;
// };

// Mark as sold
// const markAsSold = async (body) => {
//   const response = await axios.post(
//     process.env.REACT_APP_API_URL + "/product/markAsSold",
//     body,
//     config
//   );
//   console.log("response from servoce mark sold", response);

//   return response;
// };

// addToFavourite An AD
const addToFavourite = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/addToWishList",
    body,
    config
  );
  return response.data;
};

// saved An AD
const addToSavedList = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/addToSaved",
    body,
    config
  );
  return response.data;
};

//=====ADMIN PRODUCTS START

const getAdminProducts = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/getAdminProducts",
    body,
    config
  );
  return response.data;
};

// Mark as markAsBan Ad for Admin
const markAsBan = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/markAsBanned",
    body,
    config
  );

  return response?.data;
};

// Mark as markAsUnBan Ad for Admin
const markAsUnBan = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/markAsUnBanned",
    body,
    config
  );

  return response?.data;
};

// Delete an Ad for Admin
const markAsDelete = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/markAsDeleted",
    body,
    config
  );
  return response?.data?.allProducts;
};
//=====ADMIN PRODUCTS END

// // removeAdFromFavourite An AD
// const removeAdFromFavourite = async (body) => {
//   const response = await axios.post(
//     process.env.REACT_APP_API_URL + "/product/removeAdFromWatchList",
//     body,
//     config
//   );
//   return response.data;
// };

//-=========Get All Review for Admin=-=============
// GET All Ads
const getAllReviews = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/product/getAllReviews",
    config
  );
  // console.log("response", response);
  return response?.data;
};

// Block a Review by Admin
const blockReview = async (id) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/blockReview",
    { id },
    config
  );
  return response;
};

// Active a Review by Admin
const activeReview = async (id) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/activeReview",
    { id },
    config
  );
  return response;
};

// delete Review by Admin
const deleteReview = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/delte/deleteReview",
    body,
    config
  );
  return response;
};

// GET getAuthCategories
const getAuthCategories = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/product/getSportCategoriesItems"
  );
  return response?.data;
};

// GET getMostItemsHome
const getHomeCategories = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/product/getMostItemsHome"
  );
  return response?.data;
};

const adService = {
  postAnAd,
  getAllAdsDB,
  getAdById,
  postQuestion,
  postAnswer,
  getSellerAds,
  getMainCateAds,
  adViewed,
  postOfferAd,
  acceptAdOffer,
  cancelAcceptedOffer,
  // deleteAd,
  // markAsInactive,
  // markAsActive,
  // markAsSold,
  addToFavourite,
  // removeAdFromFavourite,
  getMostSoldAds,
  getFeaturedAd,
  addToSavedList,
  getAdminProducts,
  markAsBan,
  markAsUnBan,
  markAsDelete,
  getAllReviews,
  blockReview,
  activeReview,
  deleteReview,
  getAuthCategories,
  getHomeCategories,
};

export default adService;
