import axios from "axios";

// GET Brands
const getAllBrands = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/brands/getAllBrands"
  );
  return response?.data;
};

const brandServcie = {
  getAllBrands,
};

export default brandServcie;
