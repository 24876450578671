import { useEffect, Suspense } from "react";
import "./App.css";
import "./responsive.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { motion, useScroll, useSpring } from "framer-motion";

//=================React other libraray imports here
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//=================React other libraray imports here end

//=================Other pages
import Home from "./pages/Home";
import Details from "./pages/Details";
import Reviews from "./pages/Reviews";
import Register from "./pages/Register";
import Items from "./pages/Items";
import ProfileSettings from "./pages/ProfileSettings";
import Checkout from "./pages/Checkout";
import CreateItem from "./pages/CreateItem";
import Login from "./pages/Login";
import SellerProfile from "./components/SellerProfile";
import Wishlist from "./pages/Wishlist";
import Success from "./pages/Success";
import Invoice from "./pages/Invoice";
//=================Other pages end

//=================PrivateRoute========
import PrivateRoute from "./routes/PrivateRoute";

//=================Admin pages start
import MainPage from "./admin/MainPage";

//=================Admin pages end
//===================Redux===================
import { useSelector } from "react-redux";
import axios from "axios";
import AllProducts from "./pages/AllProducts";
import EditItem from "./pages/EditItem";
import CategoriesPage from "./pages/Categories";
import TermsAndConditions from "./pages/TermsAndConditions";
import CategoryFilter from "./pages/CategoryFilter";

//==============Socket Connection start=================
import { io } from "socket.io-client";
import FAQs from "./pages/Faq";
import RefundPolicy from "./pages/RefundPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import About from "./pages/About";
import Collection from "./pages/Collection";

//=======
import Verify from "./pages/Verify";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPass";

//---------Language react------------------
import "./i18n";

//==============Socket Connection start=================
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

var connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity",
  timeout: 10000,
};

export var socket;
socket = io.connect(SOCKET_URL, connectionOptions);

function App() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  const { user } = useSelector((state) => state.auth);
  //============== USE EFFECTS START=============================

  useEffect(() => {
    if (!user?.token) {
      <Navigate to="/" />;
    }
  }, [user]);

  //============== USE EFFECTS END=============================
  //============== USE EFFECTS FOR CURRENT USER SESSION  START========

  useEffect(() => {
    if (!localStorage.sessionStart) {
      localStorage.setItem("sessionStart", JSON.stringify(new Date()));
    }
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const handleTabClose = async (e) => {
    e.preventDefault();
    await axios.post(process.env.REACT_APP_API_URL + "/user/sessionTimes", {
      start: JSON.parse(localStorage.sessionStart),
    });
  };

  //============== USE EFFECTS FOR CURRENT USER SESSION  START========

  return (
    <div className="App">
      <Suspense fallback={null}>
        <motion.div className="progress-bar" style={{ scaleX }} />

        <ToastContainer autoClose={2500} />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            {/* <Route path="/resetPassword/:id" element={<ResetPassword />} /> */}
            <Route path="/resetPassword" element={<ResetPassword />} />

            <Route path="/register" element={<Register />} />
            <Route path="/details/:id" element={<Details />} />
            <Route path="/products" element={<Items />} />
            <Route path="/products/:id" element={<Items />} />
            <Route path="/allProducts/:id" element={<AllProducts />} />
            <Route path="/category/:id" element={<CategoryFilter />} />
            <Route path="/allCategories" element={<CategoriesPage />} />
            <Route path="/termAndCondition" element={<TermsAndConditions />} />
            <Route path="/refundpolicy" element={<RefundPolicy />} />
            <Route path="/cookiepolicy" element={<CookiePolicy />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/aboutus" element={<About />} />
            <Route path="/collection" element={<Collection />} />

            <Route path="/faq" element={<FAQs />} />

            <Route path="/profile/:id" element={<SellerProfile />} />
            <Route path="/editItem/:id" element={<EditItem />} />

            <Route
              element={
                <PrivateRoute
                  auth={user?.token && user?.user?.role !== "Admin"}
                />
              }
            >
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/createItem" element={<CreateItem />} />
              <Route path="/success" element={<Success />} />
              <Route path="/invoice/:id" element={<Invoice />} />
              <Route path="/profileSettings/*" element={<ProfileSettings />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  auth={user?.token && user?.user?.role === "Admin"}
                />
              }
            >
              <Route path="/dashboard/*" element={<MainPage />} />
              <Route path="/invoice/:id" element={<Invoice />} />
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/checkout" element={<Checkout />} />
            </Route>

            {/* <Route path="/dashboard/profile" element={<AdminDashboard />} /> */}
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
