import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Header from "../components/LoggedHeader";
import upload from "../assets/icons/upload.svg";

// import Spinner from "../components/Spinner";
import CreatableSelect from "react-select/creatable";
//===================Redux===================
import {
  getAllCategoriesMain,
  getAllCateAndSub,
} from "../features/category/categorySlice";
import { getAllBrands } from "../features/brands/brandSlice";

import { getAllMainSports } from "../features/sport/sportSlice";
import { useSelector, useDispatch } from "react-redux";
import { postAd, reset } from "../features/ad/adSlice";
import {
  AiOutlineDelete,
  AiOutlinePlus,
  AiOutlineStepBackward,
} from "react-icons/ai";
import { getAllColors } from "../features/color/colorSlice";
//---------trnslate
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CreateItem = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { categories, allCategories } = useSelector((state) => state.category);
  // console.log("allCategories", allCategories);
  const { sports } = useSelector((state) => state.sport);
  const { allBrands } = useSelector((state) => state.brand);
  const { allColors } = useSelector((state) => state.color);
  const [step, setStep] = useState(1);

  function scrollToTopPrev() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setStep(1);
  }

  function scrollToTopNext() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setStep(2);
  }

  //==============Constants starts================================
  // const paymentOptions = ["Paypal", "Visa", "Payoneer"];

  //==============Constants end================================
  //=================Utilities start================
  const userId = localStorage.getItem("userId");
  const { isSuccess, message } = useSelector((state) => {
    return state.ads;
  });
  //=================Utilities end  ============

  //==============Use Effect================================
  useEffect(() => {
    dispatch(getAllCategoriesMain());
    dispatch(getAllCateAndSub());
    dispatch(getAllMainSports());
    dispatch(getAllBrands());
    dispatch(getAllColors());
  }, [dispatch]);
  //==============Use Effect================================

  //============ Form Data Start==============
  const [data, setData] = useState({
    title: "",
    price: 0,
    width: 0,
    height: 0,
    length: 0,
    // stock: 0,
    description: "",
    size: "",
    address: "",
    state: "",
    location: "",
    shipping_cost: 0,
    delivery_notes: "",
    payment_option: "",
    image: null,
  });

  const {
    title,
    price,
    width,
    height,
    length,
    // stock,
    description,
    size,
    address,
    state,
    shipping_cost,
    delivery_notes,
    payment_option,
    image,
  } = data;

  const [city, setCity] = useState();
  const [adType, setAdType] = useState();
  const [location, setLocation] = useState();
  const [AdCondition, setAdCondition] = useState();

  //=================Ad type =================
  const checkList = ["Exchange", "Sell"];

  // Add/Remove checked item from list
  const [checkedVal, setChecked] = useState([]);
  const handleCheck = (event) => {
    var updatedList = [...checkedVal];
    if (event.target.checked) {
      updatedList = [...checkedVal, event.target.value];
    } else {
      updatedList.splice(checkedVal.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  // Generate string of checked items
  const checkedItems = checkedVal.length
    ? checkedVal.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  // Return classes based on whether item is checked
  var isChecked = (item) =>
    checkedVal.includes(item) ? "checked-item" : "not-checked-item";

  // const [adOption, setAdOption] = useState([]);
  // const handleChange = (e) => {
  //   const { value, checked } = e.target;
  //   // Case 1 : The user checks the box
  //   if (checked) {
  //     setAdOption([...adOption, value]);
  //   }
  //   // Case 2  : The user unchecks the box
  //   else {
  //     setAdOption(adOption.filter((e) => e !== value));
  //   }
  // };

  //=================Handler category start=================
  // handle Main change
  const [mainCategory, setMainCategoryData] = useState("");

  const handleMainCategoryChange = (e) => {
    setMainCategoryData(e.target.value);
  };

  const [subCategory, setSubCategoryData] = useState("");

  const handleSubCategoryChange = (e) => {
    setSubCategoryData(e.target.value);
  };
  //=================Handler category end=================

  //=================Handler Sport category start=================
  const [sportCategory, setSportCategoryData] = useState("");

  const handleSportCategoryChange = (e) => {
    setSportCategoryData(e.target.value);
  };
  //=================Handler Sport category end=================

  //=================Handler Brand start=================
  const [brand, setBrand] = useState("");

  const handleBrandChange = (e) => {
    setBrand(e);
  };
  //=================Handler Brand end=================

  //=================Handler Color start=================
  const [color, setColor] = useState("");

  const handleColorChange = (e) => {
    setColor(e);
  };
  //=================Handler Color end=================

  //============ Form Data end ===============
  //================= OnChange Handler =====================

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  //================= OnChange Handler end=====================

  //================= useEffect =====================
  useEffect(() => {
    if (isSuccess) {
      setData({
        title: "",
        price: 0,
        width: 0,
        height: 0,
        length: 0,
        stock: 0,
        color: "",
        description: "",
        size: "",
        address: "",
        state: "",
        delivery_notes: "",
        payment_option: "",
        shipping_cost: 0,
        image: null,
      });
      setBrand("");
      setColor("");
      setCity("");
      setAdType("");
      setAdCondition("");
      setLocation("");
      navigate("/profileSettings/profile", { replace: true });
    }
    dispatch(reset());
    if (message) {
      toast.error(message);
    }
  }, [message, isSuccess, dispatch, navigate]);
  //================= useEffect =====================
  //============ POST AN PRODUCT/AD Start ================

  const handleAddAD = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user", userId);
    formData.append("name", title);
    formData.append("price", price);
    formData.append("category", mainCategory);
    formData.append("subCategory", subCategory);
    formData.append("sportCategory", sportCategory);
    formData.append("option", checkedItems);
    formData.append("width", width);
    formData.append("length", length);
    formData.append("height", height);
    formData.append("description", description);
    // formData.append("stock", stock);
    formData.append("brand", brand?.value);
    formData.append("color", color?.value);
    formData.append("condition", AdCondition);
    formData.append("size", size);
    formData.append("type", adType);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("location", location);
    formData.append("shipping_cost", shipping_cost);
    formData.append("delivery_notes", delivery_notes);
    formData.append("payment_option", payment_option);

    if (image !== null) {
      for (let i = 0; i < image.length; i++) {
        formData.append("file", image[i]);
      }
    }
    dispatch(postAd(formData));
  };

  //============ POST AN PRODUCT/AD end ================

  // ======== Handle Images and Previews Start =========
  const [images, setImages] = useState([]);

  const handleMultipleImages = (evnt) => {
    const selectedFIles = [];
    const targetFiles = evnt.target.files;
    setData({ ...data, image: targetFiles });
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };

  const uploadMore = (evnt) => {
    const selectedFIles = [];
    const targetFiles = evnt.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    const more = images.concat(selectedFIles);
    setImages(more);

    var newFiles = [...image];
    for (var i = 0; i < targetFiles.length; i++) {
      newFiles.push(targetFiles[i]);
    }
    setData({ ...data, image: newFiles });
  };

  const removeImg = (i) => {
    var x = [];
    var targetFiles = [];
    for (var j = 0; j < images.length; j++) {
      if (j !== i) {
        x.push(images[j]);
        targetFiles.push(image[j]);
      }
    }
    setData({ ...data, image: targetFiles });
    setImages(x);
  };

  //============Handler for Images End=============

  // ======== Handle Previews Back Button Start=========
  const handleBack = () => {
    navigate("/profileSettings/profile", { replace: true });
  };
  // ======== Handle Previews Back Button End=========

  return (
    <div>
      <Header />
      <Categories />

      <div className="container ">
        <div className="my-3">
          <button
            className="form-save green-btn hover-shadow btn-sm "
            onClick={handleBack}
          >
            <AiOutlineStepBackward />
          </button>
        </div>
        <h4 className="create-item-heading">{t("Create New Item")}</h4>
        <div className="row">
          <div className="col-lg-8 mt-2">
            {step === 1 && (
              <div className="create-item-form">
                {images.length === 0 ? (
                  <div className="row mb-2 ">
                    <div className="col-12">
                      <div className="drop-file">
                        <img src={upload} alt="" />
                        <h5 className="drop-heading">
                          {t("Drag and Drop or")} <span>{t("Browse")}</span>
                        </h5>
                        <h6>
                          {t("File Type Supported:JPG,PNG, Max size: 100 MB")}
                        </h6>
                        <input
                          type="file"
                          multiple
                          onChange={handleMultipleImages}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    {images.map((url, i) => {
                      return (
                        <div
                          className="col-2"
                          style={{ padding: "5px" }}
                          key={i}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src={url}
                              style={{
                                width: "90px",
                                height: "90px",
                                borderRadius: "7px",
                                objectFit: "cover",
                              }}
                              alt=""
                            />

                            <AiOutlineDelete
                              className="text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => removeImg(i)}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <div
                      style={{
                        border: "1px solid grey",
                        width: "90px",
                        height: "90px",
                        fontSize: "52px",
                        textAlign: "center",
                        borderRadius: "7px",
                        padding: "5px",
                        marginLeft: "6px",
                        marginTop: "6px",
                      }}
                    >
                      <label htmlFor="formId">
                        <input
                          name="image"
                          onChange={uploadMore}
                          type="file"
                          id="formId"
                          hidden
                          accept="image/png, image/jpeg"
                          multiple
                        />
                        <AiOutlinePlus />
                      </label>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Name of Product")}*</label>
                      <div className="form-input">
                        <input
                          type="text"
                          name="title"
                          value={title}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Price of Product")}*</label>
                      <div className="form-input">
                        <input
                          type="number"
                          name="price"
                          value={price}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Product Category")}*</label>
                      <select
                        value={mainCategory}
                        onChange={(e) => handleMainCategoryChange(e)}
                      >
                        <option selected>{t("Select Category")}</option>
                        {categories
                          ? categories.map((category) => (
                              <option value={category?._id} key={category?._id}>
                                {category?.name}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Product Sub Category")}</label>
                      <select
                        value={subCategory}
                        onChange={(e) => handleSubCategoryChange(e)}
                      >
                        <option>{t("Select Sub Category")}</option>
                        {allCategories
                          ? allCategories.map(
                              (cate) =>
                                mainCategory === cate?.category._id && (
                                  <option value={cate?._id} key={cate?._id}>
                                    {cate?.name}
                                  </option>
                                )
                            )
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Sport Category")}*</label>
                      <select
                        value={sportCategory}
                        onChange={(e) => handleSportCategoryChange(e)}
                      >
                        <option selected>{t("Select Sport Category")}</option>
                        {sports
                          ? sports.map((sport) => (
                              <option value={sport?._id} key={sport?._id}>
                                {sport?.name}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("In Stock")}*</label>
                      <div className="form-input">
                        <input
                          type="number"
                          placeholder={t("In Stock")}
                          name="stock"
                          value={stock}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Shipping Cost")}*</label>

                      <div className="form-input">
                        <input
                          type="number"
                          placeholder={t("Shipping Cost")}
                          name="shipping_cost"
                          value={shipping_cost}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Ad Type")}*</label>

                      <div className="form-group d-flex align-items-center">
                        <div className=" d-flex">
                          {checkList.map((item, index) => (
                            <>
                              <input
                                value={item}
                                type="checkbox"
                                className="form-check-input"
                                onChange={handleCheck}
                              />
                              <label className={isChecked(item)} htmlFor="sell">
                                {item}
                              </label>
                            </>
                          ))}
                          {/* <input
                            className="form-check-input"
                            type="checkbox"
                            value="Exchange"
                            id="exchange"
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exchange"
                          >
                            {t("Exchange")}
                          </label> */}
                        </div>
                        {/* <div className="form-check d-flex">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Sell"
                            id="sell"
                            onChange={handleChange}
                          />
                          <label className="form-check-label" htmlFor="sell">
                            {t("Sell")}
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Dimensions")}</label>
                      <div className="row">
                        <div className="col-4">
                          <label>{t("Width")}</label>

                          <div className="form-input">
                            <input
                              type="number"
                              placeholder={t("Width")}
                              name="width"
                              value={width}
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <label>{t("Length")}</label>

                          <div className="form-input">
                            <input
                              type="number"
                              placeholder={t("Length")}
                              name="length"
                              value={length}
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <label>{t("Height")}</label>

                          <div className="form-input">
                            <input
                              type="number"
                              placeholder={t("Height")}
                              name="height"
                              value={height}
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-field">
                      <label>{t("Description")}*</label>
                      <div className="form-input form-textarea">
                        <textarea
                          placeholder={t("Description")}
                          value={description}
                          name="description"
                          onChange={handleOnChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3">
                    {/* <button className="form-cancel hover-shadow">Cancel</button> */}
                    <button
                      className="form-save green-btn hover-shadow"
                      onClick={scrollToTopNext}
                    >
                      {t("Next")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="create-item-form">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Brand")}*</label>
                      <CreatableSelect
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        onChange={handleBrandChange}
                        value={brand}
                        options={allBrands?.map((singleBrand) => ({
                          value: singleBrand.name,
                          label: singleBrand.name,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Color")}*</label>
                      <CreatableSelect
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        onChange={handleColorChange}
                        value={color}
                        options={allColors?.map((singleColor) => ({
                          value: singleColor.name,
                          label: singleColor.name,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Condition")}*</label>
                      <select
                        value={AdCondition}
                        onChange={(e) => setAdCondition(e.target.value)}
                      >
                        <option selected>{t("Select Condition")}</option>

                        <option value="New">{t("New")}</option>
                        <option value="Good">{t("Good")}</option>
                        <option value="Fair">{t("Fair")}</option>
                        <option value="Repaired">{t("Repaired")}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Size")}</label>
                      <div className="form-input">
                        <input
                          type="text"
                          placeholder="6 / S"
                          name="size"
                          value={size}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-field">
                      <label>Delivery Notes</label>
                      <div className="form-input">
                        <input
                          type="text"
                          placeholder="Notes"
                          name="notes"
                          value={notes}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-6">
                    <div className="form-field">
                      <label>Payment Options</label>
                      <div className="form-multiple-select">
                        <button onClick={() => setSelect(!select)}>
                          <span>
                            {option1 && "Paypal, "}
                            {option2 && "Visa, "}
                            {option3 && "Payoneer"}
                          </span>
                          <img src={down} alt="" />
                        </button>
                        <ul className={select ? "" : "d-none"}>
                          <li>
                            <input
                              type="checkbox"
                              className="form-check-input shadow-none"
                              id="p1"
                              onClick={() => setOption1(!option1)}
                            />{" "}
                            Paypal
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              className="form-check-input shadow-none"
                              id="p2"
                              onClick={() => setOption2(!option2)}
                            />{" "}
                            Visa
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              className="form-check-input shadow-none"
                              id="p3"
                              onClick={() => setOption3(!option3)}
                            />{" "}
                            Payoneer
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Ad Type")}</label>
                      <select
                        value={adType}
                        onChange={(e) => setAdType(e.target.value)}
                      >
                        <option selected>{t("Select Ad Type")}</option>

                        <option value="Standard">{t("Standard")}</option>
                        <option value="Featured">{t("Featured")}</option>

                        <option value="Urgent">{t("Urgent")}</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Payment Options")}</label>
                      <select
                        name="payment_option"
                        value={payment_option}
                        onChange={handleOnChange}
                      >
                        <option selected>{t("Select Payment Methods")}</option>
                        <option value="Offline">{t("Offline")}</option>
                        <option value="VISA">{t("VISA")}</option>
                        <option value="Paypal">{t("Paypal")}</option>
                        <option value="Payoneer">{t("Payoneer")}</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("City")}*</label>
                      <select
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      >
                        <option selected>{t("Select Ad Location")}</option>

                        <option value="Zürich (ZH)">Zürich (ZH)</option>
                        <option value="Berne (BE)">Berne (BE)</option>

                        <option value="Luzern (LU)">Luzern (LU)</option>

                        <option value="Uri (UR)">Uri (UR)</option>
                        <option value="Schwyz (SZ)">Schwyz (SZ)</option>
                        <option value="Unterwalden (Obwalden (OW) / Nidwalden (NW))">
                          Unterwalden (Obwalden (OW) / Nidwalden (NW))
                        </option>
                        <option value="Glarus (GL)">Glarus (GL)</option>
                        <option value="Zug (ZG)">Zug (ZG)</option>
                        <option value="Freiburg / Fribourg (FR)">
                          Freiburg / Fribourg (FR)
                        </option>
                        <option value="Solothurn (SO)">Solothurn (SO)</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-field">
                      <label>{t("Address")}*</label>
                      <div className="form-input">
                        <input
                          type="text"
                          placeholder="Ganclik m/s, Ziya Bunyadzade."
                          name="address"
                          value={address}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("Location")}*</label>
                      <div className="form-input">
                        <input
                          type="text"
                          placeholder="Ganclik m/s."
                          name="city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      {/* <select
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      >
                        <option selected>Select City</option>

                        <option>London</option>
                        <option>Manchister</option>
                      </select> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-field">
                      <label>{t("State")}*</label>
                      <div className="form-input">
                        <input
                          type="text"
                          placeholder="Azerbaijan"
                          name="state"
                          value={state}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-field form-field-profile">
                      <label>{t("Delivery Notes")}</label>
                      <div className="form-input form-textarea">
                        <textarea
                          placeholder={t("Enter Delivery Notes...")}
                          value={delivery_notes}
                          name="delivery_notes"
                          onChange={handleOnChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-end mt-3">
                    <button
                      className="form-cancel hover-shadow"
                      onClick={scrollToTopPrev}
                    >
                      {t("Previous")}
                    </button>
                    <button
                      className="form-save green-btn hover-shadow"
                      onClick={handleAddAD}
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-4 mt-2">
            <div className="terms-card">
              <h5>Heading</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Neque in in faucibus
                facilisis fermentum et ultrices malesuada. Sed lacus sed
                scelerisque in enim sed nisi leo. Elementum sed egestas interdum
                consectetur pulvinar eget.
              </p>
              <h5>Heading</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Neque in in faucibus
                facilisis fermentum et ultrices malesuada. Sed lacus sed
                scelerisque in enim sed nisi leo. Elementum sed egestas interdum
                consectetur pulvinar eget. lacus sed scelerisque in enim sed
                nisi leo. Elementum sed egestas interdum consectetur pulvinar
                eget. lacus sed scelerisque in enim sed nisi leo. Elementum sed
                egestas interdum consectetur pulvinar eget.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CreateItem;
