import React, { useState, useEffect } from "react";
import axios from "axios";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import mail from "../assets/icons/mail.png";
import passwordIcon from "../assets/icons/password.png";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

import { toast } from "react-toastify";

//===================Redux===================
// import { useSelector, useDispatch } from "react-redux";

//=================API METHODS================
//---------trnslate
import { useTranslation } from "react-i18next";

const Verify = () => {
  const { t } = useTranslation();

  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { user } = useSelector((state) => state.auth);

  //============== USE EFFECTS START=============================

  useEffect(() => {}, []);
  //============== USE EFFECTS END=============================
  const [email, setEmail] = useState();
  const [verificationCode, setVerificationCode] = useState();

  //==============Login Handler Submit=============
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const userData = {
        email: email,
        verificationCode: verificationCode,
      };
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/user/verify",
        userData,
        config
      );
      if (res.data.success === true) {
        toast.success(res.data.message);
        navigate("/login");
      }

      if (res.data.success === false) {
        toast.error(res.data.message);
      }
    } catch (err) {
      console.log(err.response.data.error);
    }

    // const userData = {
    //   email,
    //   password,
    // };

    // dispatch(login(userData));
  };

  // const onKeyPress = async (e) => {
  //   console.log("event", e);
  //   e.preventDefault();

  //   if (e.key === "Enter") {
  //     alert("true");
  //     const userData = {
  //       email,
  //       password,
  //     };
  //     dispatch(login(userData));
  //   }
  // };
  //==============Login Handler Submit end=============

  // if (isLoading) {
  //   return <Spinner />;
  // }

  return (
    <div>
      <Header />
      <Categories />

      <div className="register">
        <div className="container mb-5">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="register-form shadow">
                <h3 className="register-form-heading font-sora">
                  {t("Verify your Account")}
                </h3>

                <div className="row">
                  <div className="col-12 register-col">
                    <label>{t("Email")}</label>
                    <div className="register-field">
                      <img src={mail} alt="" />
                      <input
                        type="text"
                        placeholder="e.g  jhn22@gmail.com"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 register-col">
                    <label>{t("Verification Code")}</label>
                    <div className="register-field">
                      <img src={passwordIcon} alt="" />
                      <input
                        type="text"
                        placeholder={t("Enter Verification Code")}
                        name="verificationCode"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      // onKeyPress={onKeyPress}
                      className="register-submit text-light green-btn hover-shadow"
                    >
                      {t("Verify")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Verify;
