import React from "react";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <Header />

      <Categories />
      <div className="container">
        <div className="row">
          <br />
          <br />
        </div>
        <div className="row">
          <div className="col-11 col-md-10 col-lg-10 mx-auto">
            <h2 style={{ color: "black" }}>About Us</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-11 col-md-10 col-lg-10 mx-auto">
            <span>
              Skimber is a market place application and has adopted a new
              sustainable approach to microfinance: a blend of traditional
              personal banking with a highly sophisticated IT support system.
              Qredits currently has around 100 employees and works with more
              than 600 volunteer mentors/coaches. Qredits' loan officers are
              assigned to specific geographical areas and visit and screen loan
              applicants at home or at their place of business. The loan
              assessment process is personal and tailored to the customer's
              needs, a necessary aspect for a target group that cannot be
              assessed through standardized methods and criteria. Thanks to
              Qredits’ risk score model many customers can now opt for a
              distant-screening through video conference and quick disbursement
              so they can get started immediately.
            </span>
          </div>
        </div>

        <div className="row">
          {" "}
          <br /> <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
