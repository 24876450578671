import axios from "axios";
import { toast } from "react-toastify";
const config = {
  headers: {
    "x-auth-token": localStorage.token,
  },
};

// Add Category
const addCategory = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/category/add",
    body,
    config
  );

  if (response.data.success === true) {
    toast.success(response?.data.message);
    return response.data;
  }
  if (response.data.success === false) {
    toast.error(response.data.message);
  }
};

// Add Category
const addSubCategory = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/subCategory/add",
    body,
    config
  );
  if (response.data.success === true) {
    toast.success(response?.data.message);
    return response.data;
  }
  if (response.data.success === false) {
    toast.error(response.data.message);
  }
};

// GET AllCategories
const getAllCategories = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/category/get"
  );
  return response?.data;
};

const deleteCategory = async (id) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + `/category/delete`,
    id,
    config
  );
  if (response.data.success === true) {
    toast.success(response?.data.message);
    return response.data;
  }
  if (response.data.success === false) {
    toast.error(response.data.message);
  }
};

// GET All AllCategories And  Sub Category
const getAllCateAndSub = async (id) => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `/subCategory/get`
  );
  return response?.data;
};

const categoryServcie = {
  addCategory,
  addSubCategory,
  getAllCategories,
  deleteCategory,
  getAllCateAndSub,
};

export default categoryServcie;
