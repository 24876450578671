import React from "react";
import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Header from "../components/LoggedHeader";
import { BsCheckCircle } from "react-icons/bs";
import { Link } from "react-router-dom";

const Success = () => {
  return (
    <div>
      <Header />
      <Categories />

      <div className="container my-5 success-message">
        <h1>
          <BsCheckCircle />
        </h1>
        <h3>Your Order has been placed successfully!</h3>
        <Link
          to={"/profileSettings/orders"}
          className="view-orders-btn text-light green-btn hover-shadow"
        >
          View My Orders
        </Link>
      </div>

      <Footer />
    </div>
  );
};

export default Success;
