import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Button, Modal, ModalBody } from "reactstrap";
import Header from "../components/LoggedHeader";
import Footer from "../components/Footer";
import Categories from "../components/Categories";

import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

//============redux import============
import { useSelector } from "react-redux";

const TermsAndConditions = () => {
  const { user } = useSelector((state) => {
    return state.auth;
  });

  const [allTermsAndConditions, setallTermsAndConditions] = useState([]);
  useEffect(() => {
    getTermsAndConditions();
  }, []);

  const getTermsAndConditions = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/termAndCondition/get"
      );
      if (res.data) setallTermsAndConditions(res?.data);
    } catch (err) {
      console.log(err.response.data.error);
    }
  };

  //---------------------Form Data with change Handler start------------------------
  const [title, setTitle] = useState();
  const [content, setContent] = useState("");

  //---------------------Form Data with change Handler end------------------------

  //-------------------EditorToolbar start----------------
  const editor = useRef(null);
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    // autofocus: true,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    saveModeInStorage: false,
    disablePlugins:
      "drag-and-drop,file,image,image-processor,image-properties,media,mobile,link,preview,powered-by-jodit,speech-recognize,sticky,video",
    buttons:
      "bold,italic,underline,strikethrough,ul,ol,spellcheck,cut,copy,paste,selectall,hr,symbol,indent,outdent,left,undo,redo,find",
  };

  //-------------------EditorToolbar end----------------

  //-------------------Handle Add RefundPolicy start-----------------------
  const [add_TermsAndConditions, setadd_TermsAndConditions] = useState(false);
  function tog_add_TermsAndConditions() {
    setTitle("");
    setContent("");
    setadd_TermsAndConditions(!add_TermsAndConditions);
  }

  const handleTermsAndConditions = async (e) => {
    e.preventDefault();

    try {
      const url = process.env.REACT_APP_API_URL + "/termAndCondition/add";
      const response = await axios({
        method: "post",
        url: url,
        data: {
          title: title,
          content: content,
        },
        headers: {
          "x-auth-token": localStorage.token,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data?.msg);
        setadd_TermsAndConditions(!add_TermsAndConditions);
        getTermsAndConditions();
        setTitle("");
      } else {
        if (response.data.success === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //-------------------Handle Add RefundPolicy end-----------------------

  //=================Edit RefundPolicy Modal start==============
  const [editId, setEditId] = useState();
  const [editTermsAndConditions, seteditTermsAndConditions] = useState(false);

  function tog_edit(t) {
    setEditId(t);
    for (let i = 0; i < allTermsAndConditions.length; i++) {
      if (allTermsAndConditions[i]?._id === t) {
        setTitle(allTermsAndConditions[i].title);
        setContent(allTermsAndConditions[i].content);
      }
      seteditTermsAndConditions(!editTermsAndConditions);
    }
  }

  const handlEditTermsAndConditions = async (e) => {
    e.preventDefault();

    try {
      const url = process.env.REACT_APP_API_URL + "/termAndCondition/update";
      const response = await axios({
        method: "post",
        url: url,
        data: {
          id: editId,
          title: title,
          content: content,
        },
        headers: {
          "x-auth-token": localStorage.token,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.msg);
        seteditTermsAndConditions(!editTermsAndConditions);
        getTermsAndConditions();
      } else {
        if (response.data.success === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //=================Edit RefundPolicy Modal end==============
  return (
    <div>
      <Header />
      <Categories />
      <section className="profile-infos d-block">
        <div className="container">
          <div className="col-12 col-md-10 mx-auto">
            <div className="container  rounded">
              <div className="row my-3 rounded">
                {/* {user?.user?.role === "Admin" && (
                  <div className="infos-btns">
                    <button
                      className=" green-btn hover-shadow "
                      onClick={() => tog_add_TermsAndConditions()}
                    >
                      Add Term And Condition
                    </button>
                  </div>
                )} */}
              </div>
              <div className="row">
                {allTermsAndConditions.length > 0 &&
                  allTermsAndConditions.map((CPolicy, idx) => (
                    <>
                      <h2
                        className="text-success border-bottom w-25 pb-2 text-nowrap"
                        key={idx}
                      >
                        {CPolicy.title}
                      </h2>

                      <div className="col-md-12 mx-auto">
                        <div
                          dangerouslySetInnerHTML={{ __html: CPolicy?.content }}
                        />
                      </div>
                      {user?.user?.role === "Admin" && (
                        <div className="infos-btns my-4">
                          <button
                            className=" green-btn hover-shadow mt-2"
                            onClick={() => tog_edit(CPolicy._id)}
                          >
                            Update Term And Condition
                          </button>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      {/* Add RefundPolicy Modal start */}

      <Modal
        isOpen={add_TermsAndConditions}
        toggle={() => {
          tog_add_TermsAndConditions();
        }}
        size="md"
        centered
      >
        <div className="bg-light p-3 d-flex justify-content-between">
          <div>
            <h2 className="pt-2">Add Term And Condition</h2>
          </div>
          <div className="">
            <Button
              type="button"
              onClick={() => {
                setadd_TermsAndConditions(false);
              }}
              className="btn-close "
              aria-label="Close"
            ></Button>
          </div>
        </div>

        <ModalBody>
          <form className="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div className="row">
              <div className="d-flex flex-column col-lg-12 mb-8 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                  <span>Title</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="Enter your Term And Condition title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>

            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label className="fs-6 fw-semibold my-2">Content</label>
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                // tabIndex={1}  tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)}
                // style={{minHeight : "600px"}}
              />

              <div className="fv-plugins-message-container invalid-feedback" />
            </div>
            <div className="text-center">
              <div className="infos-btns mx-auto">
                <button
                  className=" my-2 green-btn hover-shadow"
                  onClick={handleTermsAndConditions}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* EDIT Cookie policy start */}

      <Modal
        isOpen={editTermsAndConditions}
        toggle={() => {
          tog_edit(editId);
        }}
        size="lg"
        centered
      >
        <div className="bg-light p-3 d-flex justify-content-between">
          <div>
            <h2 className="pt-2">Edit Terms And Conditions</h2>
          </div>
          <div className="">
            <Button
              type="button"
              onClick={() => {
                seteditTermsAndConditions(false);
              }}
              className="btn-close "
              aria-label="Close"
            ></Button>
          </div>
        </div>

        <ModalBody>
          <form className="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div className="row">
              <div className="d-flex flex-column col-lg-12 mb-8 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                  <span>Title</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="Enter your Terms And Conditions title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>

            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label className="fs-6 fw-semibold my-2">Content</label>
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                // tabIndex={1}  tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)}
                // style={{minHeight : "600px"}}
              />

              <div className="fv-plugins-message-container invalid-feedback" />
            </div>
            <div className="text-center">
              <div className="infos-btns mx-auto">
                <button
                  className=" my-2 green-btn hover-shadow"
                  onClick={handlEditTermsAndConditions}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TermsAndConditions;
