import React, { useState, useEffect } from "react";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import mail from "../assets/icons/mail.png";
import passwordIcon from "../assets/icons/password.png";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { getAuthCategories } from "../features/ad/adSlice";

import { toast } from "react-toastify";

//===================Redux===================
import { useSelector, useDispatch } from "react-redux";

//=================API METHODS================
import { login, reset } from "../features/auth/authSlice";

//---------trnslate
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const { authCategories } = useSelector((state) => state.ads);

  //==============Use Effect================================
  useEffect(() => {
    dispatch(getAuthCategories());
  }, [dispatch]);
  //============== USE EFFECTS END=============================

  //============== USE EFFECTS START=============================

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (message?.success === false) {
      toast.error(message);
    }

    if (user?.user?.role === "Admin") {
      window.location.href = "/dashboard";
    }

    if (user?.user?.role === "Buyer" || user?.user?.role === "Seller") {
      window.location.href = "/profileSettings/profile";

      // navigate("");
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);
  //============== USE EFFECTS END=============================
  //===============Initialization Plus Handler ONCHANGE Start

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //===============Initialization Plus Handler ONCHANGE End

  //==============Login Handler Submit=============
  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    dispatch(login(userData));
  };

  // const onKeyPress = async (e) => {
  //   console.log("event", e);
  //   e.preventDefault();

  //   if (e.key === "Enter") {
  //     alert("true");
  //     const userData = {
  //       email,
  //       password,
  //     };
  //     dispatch(login(userData));
  //   }
  // };
  //==============Login Handler Submit end=============

  // if (isLoading) {
  //   return <Spinner />;
  // }

  return (
    <div>
      <Header />
      <Categories />

      <div className="register">
        <div className="container mb-5">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="register-heading">
                {t("Sell, Buy and connect with people in your life.")}
              </h4>
              <div className="register-cards">
                {authCategories &&
                  authCategories?.map((cate, i) => (
                    <div className="register-card shadow">
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          cate?.sportCategory.images[0]
                        }
                        alt="Category"
                        onError={(e) => (e.target.src = "/cate.jpg")}
                      />
                      <h5>{cate?.sportCategory.name}</h5>
                      <span>
                        {cate?.count} {t("items")}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="register-form shadow">
                <h3 className="register-form-heading font-sora">
                  {t("Login to your Account")}
                </h3>
                <h5>
                  {t("Don't have account?")}{" "}
                  <Link to={"/register"}>{t("Register")}</Link>
                </h5>
                <div className="row">
                  <div className="col-12 register-col">
                    <label>{t("Email")}</label>
                    <div className="register-field">
                      <img src={mail} alt="" />
                      <input
                        type="text"
                        placeholder="e.g  jhn22@gmail.com"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 register-col">
                    <label>{t("Password")}</label>
                    <div className="register-field">
                      <img src={passwordIcon} alt="" />
                      <input
                        type="password"
                        placeholder={t("Enter Password")}
                        name="password"
                        value={password}
                        onChange={handleChange}
                      />
                    </div>
                    <span className="d-flex justify-content-end mt-2">
                      <Link className="text-secondary" to={"/forgetPassword"}>
                        {t("Forget Password ?")}
                      </Link>
                    </span>
                  </div>
                  <div className="col-12">
                    <Link
                      type="button"
                      onClick={handleSubmit}
                      // onKeyPress={onKeyPress}
                      className="register-submit text-light green-btn hover-shadow"
                    >
                      {t("Login")}
                    </Link>
                  </div>

                  {/* <div className="col-12">
                    <Link
                      type="button"
                      to={"/forgetPassword"}
                      className="register-submit text-light green-btn hover-shadow"
                    >
                      {t("Forget Password")}
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
