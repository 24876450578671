import React, { useEffect, useState } from "react";
import axios from "axios";
import Moment from "moment";
import Pagination from "../pages/Pagination";
import { useSelector } from "react-redux";

import { Button, Modal, ModalBody } from "reactstrap";

//====================React translate================================
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Commission = () => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    getCommission();
    getAdminCommission();
  }, []);

  const [commission, setCommission] = useState([]);

  let totalSum = 0;
  for (let i = 0; i < commission.length; i++) {
    totalSum = totalSum + commission[i].amount;
  }

  const getCommission = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `/user/get/fetchCommissionHistory/${user?.user?._id}`,
      config
    );
    // console.log("response", response);
    if (response) {
      setCommission(response?.data);
    }
  };

  //===========Get commission rate for admin=========
  const [commissionPercent, setCommissionPercent] = useState(0);
  const [adminCommission, setAdminCommission] = useState([]);
  let commissionId;
  let commissionPrice;
  if (commission) {
    for (let i = 0; i < adminCommission.length; i++) {
      commissionId = adminCommission[i]._id;
      commissionPrice = adminCommission[i].commissionRate;
    }
  }
  // console.log("commissionId", commissionId);
  useEffect(() => {
    setCommissionPercent(commissionPrice);
  }, [commissionPrice]);

  //Get
  const getAdminCommission = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `/commission/get/`,
      config
    );
    // console.log("response", response);
    if (response) {
      setAdminCommission(response?.data);
    }
  };
  //Update
  const UpdateAdminCommission = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + `/commission/update/${commissionId}`,
      {
        commissionRate: commissionPercent,
        commissionType: "Order commission",
      },
      config
    );
    if (response.data.success === true) {
      toast.success(response?.data.msg);
      getAdminCommission();
    }
    if (response.data.success === false) {
      toast.error(response?.data.msg);
    }
  };

  //==============Update commission from admin==============

  //===========Commission Toggle and API Start ====================
  // const [add_commission, setadd_commission] = useState(false);
  // function tog_add_commission() {
  //   setCommissionPercent(0);
  //   setadd_commission(!add_commission);
  // }

  // //==============Pagination  START=============================

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(8);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = commission?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(commission?.length / recordsPerPage);
  // //==============Pagination  END=============================
  return (
    <div>
      {" "}
      <div className="profile-infos d-block">
        <div className="d-flex col-lg-12 ">
          <label className="d-flex  fs-6 fw-semibold">
            <span className="text-nowrap mt-1">
              {t("Commission (In Percentage)")}
            </span>
          </label>
          <input
            type="number"
            className="form-control form-control-solid w-50 mx-2"
            placeholder={t("Enter your Commission")}
            name="commission"
            value={commissionPercent}
            onChange={(e) => setCommissionPercent(e.target.value)}
          />
          <div className="infos-btns">
            <button
              className=" green-btn hover-shadow text-nowrap"
              onClick={UpdateAdminCommission}
            >
              {t("Update Commission")}
            </button>
          </div>
        </div>

        <div className="my-3">
          <h4 className="withdraw-title my-auto">{t("Commissions")}</h4>
        </div>
        <div className="table-responsive">
          <table className="table shop-table table-bordered">
            <thead>
              <tr>
                <th className="text-center">Sr. no</th>
                <th className="text-center">{t("Amount")}</th>
                <th className="text-center">{t("Description")}</th>
                <th className="text-center">{t("Created At")}</th>
                <th className="text-center">{t("Credit/Debit")}</th>
                {/* <th className="text-center">Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {commission &&
                currentRecords.map((trns, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td className="text-center">$ {trns?.amount.toFixed(2)}</td>
                    <td className="text-center">{trns?.description}</td>
                    <td className="text-center">
                      {Moment(trns?.createdAt).format("LLL")}
                    </td>
                    <td className="text-center text-success">{trns?.type}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-end">
            <h5 className="withdraw-title text-nowrap">
              Total Commission :{" "}
              <span className="text-success border-bottom border-dark w-25 pb-2">
                {totalSum ? totalSum.toFixed(2) : 0}
              </span>
            </h5>
          </div>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      {/* Toggle commission Modal */}
      {/* <Modal
        isOpen={add_commission}
        toggle={() => {
          tog_add_commission();
        }}
        size="md"
        centered
      >
        <div className="bg-light p-3 d-flex justify-content-between">
          <div>
            <h4 className="pt-2">{t("Add Commission")}</h4>
          </div>
          <div className="">
            <Button
              type="button"
              onClick={() => {
                setadd_commission(false);
              }}
              className="btn-close "
              aria-label="Close"
            ></Button>
          </div>
        </div>

        <ModalBody>
          <form className="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div className="row">
              <div className="d-flex flex-column col-lg-12 mb-8 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                  <span className="required">
                    {t("Commission (In Percentage)")}
                  </span>
                </label>
                <input
                  type="number"
                  className="form-control form-control-solid mb-3"
                  placeholder={t("Enter your Commission")}
                  name="commission"
                  value={commissionPercent}
                  onChange={(e) => setCommissionPercent(e.target.value)}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>
            <div className="text-center">
              <div className="infos-btns mx-auto">
                <button
                  className=" my-2 green-btn hover-shadow"
                  // onClick={handleAddTicket}
                >
                  {t("Submit")}
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal> */}
    </div>
  );
};

export default Commission;
