import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryService from "./categoryService";

const initialState = {
  categories: [],
  allCategories: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Add Category
export const addCategories = createAsyncThunk(
  "category/add",
  async (data, thunkAPI) => {
    try {
      await categoryService.addCategory(data);
      return await categoryService.getAllCategories();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All Sub And Main  Category

export const getAllCateAndSub = createAsyncThunk(
  "category/getAllCateAndSub",
  async (thunkAPI) => {
    try {
      return await categoryService.getAllCateAndSub();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Sub Category
export const addSubCategories = createAsyncThunk(
  "category/subcategory/add",
  async (body, thunkAPI) => {
    try {
      await categoryService.addSubCategory(body);
      const response = await categoryService.getAllCateAndSub();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Category
export const deleteCategory = createAsyncThunk(
  "category/delete",
  async (id, thunkAPI) => {
    try {
      await categoryService.deleteCategory(id);
      return await categoryService.getAllCategories();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All Categories
export const getAllCategoriesMain = createAsyncThunk(
  "category/all",
  async (thunkAPI) => {
    try {
      return await categoryService.getAllCategories();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All Categories
export const getHomeCategories = createAsyncThunk(
  "category/getHomeCategories",
  async (thunkAPI) => {
    try {
      return await categoryService.getHomeCategories();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All Categories and Sub Categories
// export const getAllMainSubCategories = createAsyncThunk(
//   "category/allMainSubCategories",
//   async (id, thunkAPI) => {
//     try {
//       return await categoryService.getAllCateAndSub(id);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCategories.pending, (state, action) => {
        state.isLoading = true;
        state.message = action.payload;
      })
      .addCase(addCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.categories = action.payload;

        // state.user = action.payload;
      })
      .addCase(addCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.user = null;
      })
      .addCase(addSubCategories.pending, (state, action) => {
        state.isLoading = true;
        state.message = action.payload;
      })
      .addCase(addSubCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allCategories = action.payload;
      })
      .addCase(addSubCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllCategoriesMain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCategoriesMain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload;
      })

      .addCase(getAllCateAndSub.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCateAndSub.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allCategories = action.payload;
      })
      .addCase(deleteCategory.pending, (state, action) => {
        state.isLoading = true;
        state.message = action.payload;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.categories = action.payload;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.user = null;
      });
    // .addCase(getAllMainSubCategories.pending, (state) => {
    //   state.isLoading = true;
    // })
    // .addCase(getAllMainSubCategories.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.allCategories = action.payload;
    // });
  },
});

export const { reset } = categorySlice.actions;
export default categorySlice.reducer;
