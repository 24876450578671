import React, { useState, useEffect } from "react";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import heartRed from "../assets/icons/heartRed.svg";
import p1 from "../assets/images/p1.jpg";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import { AiFillEye } from "react-icons/ai";
// import Spinner from "../components/Spinner";
import axios from "axios";

//============Import Pagination Page==============
import Pagination from "./Pagination";

const AllProducts = () => {
  //==============redux start=============================
  const { id } = useParams();

  //==============redux  end===============================

  //==============Use Effect================================

  const [filteredAds, setFilteredAds] = useState([]);
  const [optionType, setAdOptionType] = useState();

  useEffect(() => {
    getFilteredAds();
    // getFilterdAds();
  }, []);
  const getFilteredAds = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/product/getAdfilters/${id}`
      );
      setFilteredAds(response?.data);
    } catch (error) {
      console.log(error.response.error);
    }
  };

  const handleAdTypeFilter = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/product/getAdfilters/${optionType}`
      );
      setFilteredAds(response?.data);
    } catch (error) {
      console.log(error.response.error);
    }
  };

  //==============Use Effect end================================

  //==============Pagination  START=============================
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(8);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredAds?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(filteredAds.length / recordsPerPage);

  //==============Pagination  END===============================

  return (
    <div>
      <Header />
      <Categories />

      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="items-heading my-4 font-poppins d-flex align-items-end border-bottom pb-2 border-dark">
            {optionType ? optionType : id}
            <span className="fs-6 mb-1 ms-2">
              ({filteredAds?.length} result show)
            </span>
          </h2>
          <div className="dropdown items-filter">
            <select
              className="form-cancel-2 dropdown-togglej items-filter-btn"
              value={optionType}
              onChange={(e) => setAdOptionType(e.target.value)}
              onClick={handleAdTypeFilter}
            >
              <option value="Popular">Popular</option>
              <option value="Featured">Featured</option>
              <option value="Latest">Latest</option>
              {/* <option>Price Lowest to Highest</option> */}
            </select>
          </div>
        </div>

        <div className="row">
          {filteredAds.length > 0 &&
            currentRecords?.map((ad, i) => (
              <div className="col-lg-3 col-md-6" key={i}>
                <div className="item-card shadow">
                  <Link
                    to={`/details/${ad._id}`}
                    className="item-card-img w-100"
                  >
                    {ad?.type === "Urgent" && (
                      <div className="product-ribbon urgent">{ad?.type}</div>
                    )}
                    {ad?.type === "Standard" && (
                      <div className="product-ribbon standard">{ad?.type}</div>
                    )}
                    {ad?.type === "Featured" && (
                      <div className="product-ribbon featured">{ad?.type}</div>
                    )}
                    {ad?.images ? (
                      <img
                        className="product-img"
                        src={
                          process.env.REACT_APP_IMAGES_URL + "/" + ad?.images[0]
                        }
                        onError={(e) => (e.target.src = "/product.jpg")}
                        alt=""
                      />
                    ) : (
                      <img className="product-img" src={p1} alt="" />
                    )}
                    <div className="item-card-like">
                      <img src={heartRed} alt="" />
                    </div>
                  </Link>
                  <div className="item-card-text">
                    <div className="product-text-top">
                      <h6>
                        <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                      </h6>
                      <div>
                        {/* <i onClick={() => setP1Like(!p1Like)}>
                            {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                          </i> */}
                        <AiFillEye className="me-2" />
                        {ad?.views?.length}
                      </div>
                    </div>
                    <div className="product-text-bottom">
                      <div className="desc">{ad?.description}</div>
                      <h5>$ {ad?.price}</h5>
                    </div>
                    <div className="item-card-brand">
                      <span>Brand:</span> {ad?.brand}
                    </div>
                    <div className="item-card-tags">
                      {ad?.option?.map((opt) => (
                        <>
                          {opt.split(",").map((op) => (
                            <div className="item-card-tag both">{op}</div>
                          ))}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <Footer />
    </div>
  );
};

export default AllProducts;
