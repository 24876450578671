import axios from "axios";
// Search by keyword
const searchByKeyword = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/search",
    body
  );
  return response;
};

// Search by category
const searchByCategory = async (category) => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `/product/searchByCategory/${category}`
  );
  return response;
};

// Search by Ad type
const conditionFilter = async (type) => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `/product/adCondition/${type}`
  );
  return response;
};

// // Search by category
// const searchBySubCategory = async (category) => {
//   const response = await api.get(`/ad/search/searchBySubCategory/${category}`)
//   return response;
// }

// // Search by all categories
// const searchByAllCategories = async (body) => {
//   const response = await api.post('/ad/search/allCategories', body)
//   return response;
// }

// Search by price filter
const priceFilter = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/product/priceFilter",
    body
  );
  return response;
};

// Search by price filter
const homeSeached = async (id) => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `/product/getAdfilters?${id}`
  );
  return response;
};

// // Search by condition filter
// const conditionFilter = async (body) => {
//   const response = await api.post('/ad/search/conditionFilter', body)
//   return response;
// }

// // Search suggestions
// const getSuggetions = async (body) => {
//   const response = await api.post('/ad/search/getSuggetions', body)
//   return response;
// }

// // Search by selecting from the dropdown
// const fetchAds = async (type) => {
//   const response = await api.post('/ad/search/fetchAds', { type })
//   return response;
// }

const searchService = {
  searchByKeyword,
  searchByCategory,
  // searchByAllCategories,
  priceFilter,
  // conditionFilter,
  // getSuggetions,
  // searchBySubCategory,
  // fetchAds,
  homeSeached,
  conditionFilter,
};

export default searchService;
