import React from "react";
import left from "../assets/icons/left.svg";
import right from "../assets/icons/right.svg";

import { Link } from "react-router-dom";

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <>
      <div className="paginations">
        <Link to={"#"} onClick={prevPage}>
          <img src={left} alt="" />
        </Link>
        {pageNumbers.map((pgNumber) => (
          <Link
            key={pgNumber}
            className={`${
              currentPage === pgNumber ? "active rounded-circle" : ""
            } `}
            onClick={() => setCurrentPage(pgNumber)}
          >
            <a className="page-link" href="#!">
              {pgNumber}
            </a>
          </Link>
        ))}
        {/* <Link to={"#"}>1</Link> */}

        <Link to={"#"} onClick={nextPage}>
          <img src={right} alt="" />
        </Link>
      </div>
      {/* <nav>
      <ul className="paginations justify-content-center">
        <li className="page-item">
          <a className="page-link" onClick={prevPage} href="#!">
            Previous
          </a>
        </li>
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`page-item ${currentPage == pgNumber ? "active" : ""} `}
          >
            <a
              onClick={() => setCurrentPage(pgNumber)}
              className="page-link"
              href="#!"
            >
              {pgNumber}
            </a>
          </li>
        ))}
        <li className="page-item">
          <a className="page-link" onClick={nextPage} href="#!">
            Next
          </a>
        </li>
      </ul>
    </nav> */}
    </>
  );
};

export default Pagination;
