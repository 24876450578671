import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getAllMainSports } from "../features/sport/sportSlice";
// import { homeSeached } from "../features/search/searchSlice";

const Categories = () => {
  //==================Use Selector Start=================
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { sports } = useSelector((state) => state.sport);

  useEffect(() => {
    dispatch(getAllMainSports());
  }, [dispatch]);

  //================Handle Onclick Sports Category start ===============
  // const handleSportCategory = (e, id) => {
  //   console.log("--------------------e Clicked", e);

  //   e.preventDefault();
  //   navigate(`/products/Sport=${id}`);
  //   // dispatch(homeSeached(`/products/Sport=${id}`));
  // };

  //================Handle Onclick Sports Category end =================

  return (
    <div
      className="container-fluid categories-container"
      // className={`container-fluid categories-container ${
      //   window.location.pathname === "/details" ? "border-bottom-0" : ""
      // }`}
    >
      <div className="container">
        <div className="categories">
          {sports &&
            sports?.map((sport, i) => (
              <Link
                className="category"
                to={`/products?Sport=${sport._id}`}
                // onClick={(e) => handleSportCategory(e, sport._id)}
                key={i}
              >
                {sport?.name}
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
