import React, { useState } from "react";
import { NavLink, Routes, Route } from "react-router-dom";
//=========Pages =================================
import Header from "../components/LoggedHeader";
import Dashboard from "./Dashboard";
import AddCategory from "./AddCategory";
import AddSport from "./AddSport";
// import Shops from "./Shops";
import User from "./Users";
import Products from "./Products";
import Transactions from "./Transactions";
import Commission from "./Commission";
import Tickets from "./Tickets";
import Messages from "./Messages";
import PaymentRequests from "./PaymentRequests";
import TicketDetails from "../components/TicketDetails";
// import Cashback from "./Cashback";
import Notifications from "./Notifications";
import EditProfile from "../components/EditProfile";
import Footer from "../components/Footer";
// import FAQs from "./FAQs";
//=========Pages end=================================

//=========Assets =================================

// import left from "../assets/icons/left.svg";

// import messageGreen from "../assets/icons/messageGreen.png";
// import { IoCashOutline } from "react-icons/io5";
// import { BsShop } from "react-icons/bs";
import { FaTicketAlt, FaSitemap, FaQuora, FaCookie } from "react-icons/fa";
import {
  MdOutlineSportsCricket,
  MdPrivacyTip,
  MdDataUsage,
} from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { TbMessage } from "react-icons/tb";
import { AiOutlineStar } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";

import { MdPayments, MdNotifications, MdOutlineCategory } from "react-icons/md";

import {
  AiFillDashboard,
  AiOutlineTransaction,
  AiFillEdit,
} from "react-icons/ai";

//=========Assets end===============================
//----------react-translate------------
import { useTranslation } from "react-i18next";
import Reviews from "./Reviews";

const AdminDashboard = () => {
  const { t } = useTranslation();

  const [activeLink, setActiveLink] = useState("");

  const handleClick = (link) => {
    setActiveLink(link);
  };

  const sidebar = [
    {
      link: "/dashboard",
      icon: <AiFillDashboard />,
      text: "Dashboard",
    },
    {
      link: "addcategory",
      icon: <MdOutlineCategory />,
      text: "Add Category",
    },
    {
      link: "addsport",
      icon: <MdOutlineSportsCricket />,
      text: "Add Sport",
    },
    {
      link: "users",
      icon: <FiUsers />,
      text: "Users",
    },
    {
      link: "products",
      icon: <FaSitemap />,
      text: "All Products",
    },

    {
      link: "transactions",
      icon: <AiOutlineTransaction />,
      text: "Transactions",
    },
    {
      link: "tickets",
      icon: <FaTicketAlt />,
      text: "Tickets",
    },
    {
      link: "reviews",
      icon: <AiOutlineStar />,
      text: "Reviews",
    },
    {
      link: "messages",
      icon: <TbMessage />,
      text: "Messages",
    },
    {
      link: "paymentrequests",
      icon: <MdPayments />,
      text: "Payment Requests",
    },
    {
      link: "commission",
      icon: <GiMoneyStack />,
      text: "Commission",
    },

    {
      link: "notifications",
      icon: <MdNotifications />,
      text: "Notifications",
    },
    {
      link: "editprofile",
      icon: <AiFillEdit />,
      text: "Edit Profile",
    },
    {
      link: "/faq",
      icon: <FaQuora />,
      text: "FAQs",
    },
    {
      link: "/privacypolicy",
      icon: <MdPrivacyTip />,
      text: "Privacy Policy",
    },
    {
      link: "/cookiepolicy",
      icon: <FaCookie />,
      text: "Cookie Policy",
    },
    {
      link: "/refundpolicy",
      icon: <HiOutlineReceiptRefund />,
      text: "Refund Policy",
    },
    {
      link: "/termAndCondition",
      icon: <MdDataUsage />,
      text: "Term And Condition",
    },
  ];

  return (
    <div>
      <Header />

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-3 mt-2">
            <div className="profile-sidebar">
              <div className="sidebar-links">
                {sidebar &&
                  sidebar.map((item, index) => (
                    <div key={index}>
                      <NavLink
                        to={`${item.link}`}
                        onClick={() => handleClick(item.link)}
                        // activeClassName="activeSideBar"

                        className={
                          activeLink === `${item.link}` ? "activeSideBar" : ""
                        }
                      >
                        <span className="fs-5 me-2 ">{item.icon}</span>
                        {t(`${item.text}`)}
                      </NavLink>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-lg-9 mt-2">
            <Routes>
              <Route path="" element={<Dashboard />} />
              <Route path="addcategory" element={<AddCategory />} />
              <Route path="addsport" element={<AddSport />} />
              <Route path="users" element={<User />} />
              <Route path="products" element={<Products />} />
              {/* <Route path="shops" element={<Shops />} /> */}
              <Route path="transactions" element={<Transactions />} />
              <Route path="commission" element={<Commission />} />
              <Route path="tickets" element={<Tickets />} />
              <Route path="reviews" element={<Reviews />} />
              <Route path="messages" element={<Messages />} />
              <Route path="paymentrequests" element={<PaymentRequests />} />
              {/* <Route path="cashback" element={<Cashback />} /> */}
              <Route path="notifications" element={<Notifications />} />
              <Route path="editprofile" element={<EditProfile />} />
              <Route path="details/:id" element={<TicketDetails />} />

              {/* <Route path="faqs" element={<FAQs />} /> */}
            </Routes>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AdminDashboard;
