import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Header from "../components/LoggedHeader";
// import sneaker from "../assets/images/sneaker1.jpg";
//---------trnslate
import { useTranslation } from "react-i18next";

const Invoice = () => {
  const { t } = useTranslation();

  //------Get Single Invoice on Id base----------------
  const { id } = useParams();

  //------Get Single Invoice on Id base----------------
  const [invoiceData, setInvoiceData] = useState([]);
  useEffect(() => {
    getOrderById();
  }, []);

  const getOrderById = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `/user/fetchInvoice/${id}`,
      config
    );
    setInvoiceData(response.data);
  };

  return (
    <div>
      <Header />
      <Categories />

      <div className="container my-5">
        <div className="row">
          {/* <div className="col-12">
                    <div className="invoice-product">
                        <img src={sneaker} alt="" />
                        <div className="invoice-product-text">
                            <h5>Product Title</h5>
                            <h6><strong>Quantity: </strong>2</h6>
                            <h6 className='mb-0'><strong>Total Amount: </strong>$350.00</h6>
                        </div>
                    </div>
                </div> */}

          <div className="col-lg-6 mb-4">
            <div className="invoice-information seller-infos">
              <h5>{t("Seller Information")}</h5>
              {invoiceData &&
                invoiceData.map((data) =>
                  data?.orderItems.map((item, idx) => (
                    <div className="border-bottom">
                      <p key={idx}>{idx + 1}</p>
                      <h6>
                        <strong>{t("Full Name")}:</strong> {item?.user?.name}
                      </h6>
                      {item?.user?.email && (
                        <h6>
                          <strong>{t("Email")}:</strong> {item?.user?.email}
                        </h6>
                      )}

                      <h6>
                        <strong>{t("Address")}:</strong> {item?.user?.address} ,{" "}
                        {item?.user?.state}
                      </h6>
                      {item?.user?.phone && (
                        <h6>
                          <strong>{t("Phone")}:</strong> {item?.user?.phone}
                        </h6>
                      )}
                      {item?.name && (
                        <h6>
                          <strong>{t("Product Name")}:</strong> {item?.name}
                        </h6>
                      )}
                    </div>
                  ))
                )}
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="invoice-information seller-infos text-end">
              <h5>{t("Buyer Information")}</h5>
              <h6>
                <strong>{t("Full Name")}:</strong>{" "}
                {invoiceData[0]?.contactInfo.fullName}
              </h6>
              <h6>
                <strong>{t("Email")}:</strong>{" "}
                {invoiceData[0]?.contactInfo.email}
              </h6>
              <h6>
                <strong>{t("Address")}:</strong>{" "}
                {invoiceData[0]?.shippingInfo.address},{" "}
                {invoiceData[0]?.shippingInfo.state}
                {/* ,{invoiceData[0]?.shippingInfo.city} ,{" "} */}
              </h6>
              <h6>
                <strong>{t("Phone")}:</strong>{" "}
                {invoiceData[0]?.contactInfo.phone}
              </h6>
            </div>
          </div>

          <div className="col-12">
            <div className="profile-infos d-block mt-3">
              <h5 className="withdraw-title">{t("Products")}</h5>
              <div className="table-responsive">
                <table className="table withdraw-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="text-center">{t("Item")}</th>
                      <th className="text-center">{t("Price")}</th>
                      <th className="text-center">{t("Quantity")}</th>
                      <th className="text-center">{t("Total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData &&
                      invoiceData.map((data) =>
                        data?.orderItems.map((item, idx) => (
                          <tr>
                            <td>00{idx + 1}</td>
                            <td className="text-center">{item?.name}</td>
                            <td className="text-center">
                              $
                              {item?.price
                                ? item?.price
                                : invoiceData[0]?.totalPrice}
                            </td>
                            <td className="text-center">
                              {item?.cartQuantity ? item?.cartQuantity : 1}
                            </td>
                            <td className="text-center">
                              $
                              {item?.cartQuantity
                                ? item?.price * item?.cartQuantity
                                : invoiceData[0]?.totalPrice}
                            </td>
                          </tr>
                        ))
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-lg-6"></div>
          <div className="col-lg-6">
            <div className="invoice-infos">
              {/* <div className="invoice-info">
                <span>Subtotal: </span> $480.00
              </div>
              <div className="invoice-info">
                <span>Tax: </span> $20.00
              </div> */}
              <div className="invoice-info">
                <span>{t("Total")}: </span>
                <span className="me-5 pe-5">
                  {" "}
                  ${invoiceData[0]?.totalPrice}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Invoice;
