import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllAds } from "../features/ad/adSlice";
import p1 from "../assets/images/p1.jpg";
import { AiFillEye } from "react-icons/ai";

import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import Footer from "../components/Footer";

const Collection = () => {
  const dispatch = useDispatch();
  const { ads } = useSelector((state) => state.ads);

  //==============Use Effect================================
  useEffect(() => {
    dispatch(getAllAds());
  }, [dispatch]);

  //==============Use Effect end================================

  return (
    <>
      <Header />

      <Categories />
      <div className="container my-5">
        <h3 className="text-center mt-2 border-bottom pb-2 w-25">
          Collections
        </h3>
        <div className="row">
          {ads.length > 0 &&
            ads?.map((ad, i) => (
              <div className="col-lg-3 col-md-6" key={i}>
                <div className="products-item shadow">
                  <Link
                    to={`/details/${ad._id}`}
                    className="product-img-container w-100"
                  >
                    {ad?.type === "Urgent" && (
                      <div className="product-ribbon urgent">{ad?.type}</div>
                    )}
                    {ad?.type === "Standard" && (
                      <div className="product-ribbon standard">{ad?.type}</div>
                    )}
                    {ad?.type === "Featured" && (
                      <div className="product-ribbon featured">{ad?.type}</div>
                    )}
                    {ad?.images ? (
                      <img
                        className="product-img"
                        src={
                          process.env.REACT_APP_IMAGES_URL + "/" + ad?.images[0]
                        }
                        alt=""
                        onError={(e) => (e.target.src = "/product.jpg")}
                      />
                    ) : (
                      <img className="product-img" src={p1} alt="" />
                    )}
                  </Link>
                  <div className="product-text">
                    <div className="product-text-top">
                      <h6>
                        <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                      </h6>
                      <div>
                        {/* <i onClick={() => setP1Like(!p1Like)}>
                            {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                          </i> */}
                        <AiFillEye className="me-2" />
                        {ad?.views?.length}
                      </div>
                    </div>
                    <div className="product-text-bottom">
                      <div className="desc">{ad?.description}</div>
                      <h6>$ {ad?.price}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Collection;
