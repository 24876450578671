import React from "react";
// import footerBg from '../assets/images/footerBg.svg'
import logoWhite from "../assets/images/sportsrepeatwhite.png";
import facebook from "../assets/icons/facebook.svg";
import twitter from "../assets/icons/twitter.svg";
import instagram from "../assets/icons/instagram.svg";
import { Link } from "react-router-dom";
//----------react-translate------------
import { useTranslation } from "react-i18next";

const Footer = () => {
  //----------react-translate------------
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-5">
            <div className="footer-left">
              <Link to={"/"}>
                <img src={logoWhite} width="220" alt="" />
              </Link>
              {/* <h5 className="font-sora">{t("About us")}</h5> */}
              <p className="mt-4">
                Sports Repeat is a marketplace application for sports equipment,
                You can login now and sell or buy used or new equipment in easy
                steps.
              </p>
            </div>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-2 col-md-6">
            <div className="footer-links">
              <h5>Useful Links</h5>
              <Link to={"/aboutus"}>{t("About")}</Link>
              <Link to={"/collection"}>{t("Collection")}</Link>
              <Link to="/allCategories">{t("Categories")}</Link>
              <Link to="/faq">{t("FAQs")}</Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-social">
              <h5 className="font-sora">{t("SOCIAL MEDIA")}</h5>
              <div className="footer-social-img">
                <Link to={"#"}>
                  <img src={facebook} alt="" />
                </Link>
                <Link to={"#"}>
                  <img src={twitter} alt="" />
                </Link>
                <Link to={"#"}>
                  <img src={instagram} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container footer-copy">
        <p>
          © 2023{" "}
          <Link to={"/"} className="text-light">
            Sports Repeat
          </Link>{" "}
          {t("All Rights Reserved")}
        </p>
        <div className="legal-links">
          <Link to={"/privacypolicy"}>{t("Privacy Policy")}</Link>
          <Link to={"/cookiepolicy"}>{t("Cookie Policy")}</Link>
          <Link to={"/refundpolicy"}>{t("Refund Policy")}</Link>
          <Link to={"/termAndCondition"}>{t("Terms and Conditions")}</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
