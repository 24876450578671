import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePaperClip } from "react-icons/ai";
import { RxEnvelopeOpen } from "react-icons/rx";
import searchBlue from "../assets/icons/searchBlue.svg";
import $ from "jquery";
import send from "../assets/icons/send.svg";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../features/auth/authSlice";
import axios from "axios";

//====================React translate================================
import { useTranslation } from "react-i18next";

//============socket Import============
import { io } from "socket.io-client";
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

var connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity",
  timeout: 10000,
};

export var socket;
socket = io.connect(SOCKET_URL, connectionOptions);

const Messages = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { AllUsers, user } = useSelector((state) => state.auth);
  useEffect(() => {
    socket.emit("addUser", user?.user._id);
  }, [user]);
  // socket.emit("msg-recieve");

  //==================Use Effect start (Get All Users)=================
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  //==================Use Effect end (Get All Users)=================

  //=============OnClick User to get User data and Message start=============================
  const [currentUser, setCurrentUser] = useState();

  const handleConversation = async (e, userData) => {
    setCurrentUser(userData);
    e.preventDefault();
  };

  //=============OnClick User to get User data and Message end=============================

  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    const getConversations = async () => {
      try {
        const rest = await axios.get(
          process.env.REACT_APP_API_URL +
            `/adminuser/getSingleConversation/${currentUser?._id}`
        );
        setConversations(rest.data);
        const messagesid = document.getElementById("scrollhere");
        $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, [currentUser]);
  //=========================== Send Message  end =============================

  const [newMessage, setNewMessage] = useState("");
  const [attachment, setAttachment] = useState([]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("senderId", user.user?._id);
      formData.append(
        "receiverId",
        currentUser?._id === user?.user?._id
          ? user?.user?._id
          : currentUser?._id
      );
      formData.append("text", newMessage);
      if (attachment !== null) {
        for (let i = 0; i < attachment.length; i++) {
          formData.append("attachments", attachment[i]);
        }
      }

      await axios.post(
        process.env.REACT_APP_API_URL + "/adminuser/addNewMessage",
        formData
      );

      socket.emit("send_Msg", {
        senderId: user?.user?._id,
        text: newMessage,
        receiverId:
          currentUser._id === user?.user?._id
            ? user?.user?._id
            : currentUser._id,
      });

      // const rest = await axios.post(
      //   process.env.REACT_APP_API_URL + "/adminuser/getConv/",
      //   {
      //     senderId: user?.user._id,
      //   }
      // );
      const rest = await axios.get(
        process.env.REACT_APP_API_URL +
          `/adminuser/getSingleConversation/${currentUser?._id}`
      );

      setConversations(rest.data);
      // const rest = await axios.get(
      //   process.env.REACT_APP_API_URL +
      //     `/adminuser/getSingleConversation/${currentUser?._id}`
      // );
      // setConversations(rest.data);
      // setConversations(rest.data);

      setNewMessage("");
      setAttachment([]);

      const messagesid = document.getElementById("scrollhere");
      $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
    } catch (err) {
      console.log(err);
    }
  };

  const onKeyPress = async (e) => {
    if (e.key === "Enter" && newMessage !== "") {
      handleSendMessage(e);
    }
  };

  //=========================== Send Message  end =============================
  //========================Get messages throught socket start=================
  const [mapid, setMapid] = useState();
  useEffect(() => {
    if (mapid !== undefined) {
      let newCall = async () => {
        const rest = await axios.get(
          process.env.REACT_APP_API_URL +
            `/adminuser/getSingleConversation/${currentUser?._id}`
        );

        setConversations(rest.data);
        const messagesid = document.getElementById("scrollhere");
        $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
        // console.log("adConversation", adConversation);
      };
      newCall();
    }
  }, [user, currentUser?._id, mapid]);

  socket.on("msgRecieve", (data) => {
    setMapid(data);
  });

  return (
    <div className="row messages">
      <div className="col-lg-4 messages-list">
        <h5 className="messages-heading">{t("Messages")}</h5>
        <div className="messages-input">
          <img src={searchBlue} alt="" />
          <input type="text" placeholder="Search" />
        </div>

        {AllUsers.length > 0 &&
          AllUsers.map((user, idx) => (
            <div className="users-list" key={idx}>
              <Link
                to={"#"}
                className="chat-user"
                onClick={(e) => handleConversation(e, user)}
              >
                <img
                  src={process.env.REACT_APP_IMAGES_URL + "/" + user?.image}
                  alt=""
                  onError={(e) => (e.target.src = "/user.png")}
                />
                <div className="chat-user-text">
                  <h5>{user?.name}</h5>
                </div>
                <span className="chat-time">
                  {Moment(user?.createdAt).format("DD-MM-YY")}
                </span>
              </Link>
            </div>
          ))}
      </div>
      <div className="col-lg-8 chat-container">
        {currentUser && conversations && (
          <>
            <div className="chat-top">
              <img
                src={
                  process.env.REACT_APP_IMAGES_URL + "/" + currentUser?.image
                }
                alt=""
                onError={(e) => (e.target.src = "/user.png")}
              />
              <div className="chat-top-text">
                <h5>{currentUser?.name}</h5>
                <p>{currentUser?.email}</p>
                <p>{currentUser?.contact}</p>
              </div>
            </div>

            <div className="chat mt-3" id="chatbody">
              {conversations &&
                conversations?.map((singleCon, i) => (
                  <div key={i}>
                    {singleCon.senderId._id === user?.user._id && (
                      <div className="chat-message user1">
                        <span>
                          {Moment(singleCon?.createdAt).format(
                            "DD-MM-YYYY h:mm a"
                          )}
                        </span>
                        {singleCon.text !== "" && <p>{singleCon.text}</p>}

                        {singleCon.images.map((url, i) => {
                          return (
                            <div
                              className="col-1 mx-1"
                              style={{ padding: "5px" }}
                              key={i}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGES_CHAT_URL +
                                    "/" +
                                    url
                                  }
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "7px",
                                    objectFit: "cover",
                                  }}
                                  alt=""
                                  // onClick={() => openLightboxOnSlide(1)}
                                />
                              </div>
                            </div>
                          );
                        })}
                        <img
                          src={
                            process.env.REACT_APP_IMAGES_URL +
                            "/" +
                            singleCon?.senderId?.image
                          }
                          onError={(e) => (e.target.src = "/user.png")}
                          alt=""
                        />
                      </div>
                    )}
                    {singleCon.senderId._id !== user?.user._id && (
                      <div className="chat-message user2">
                        <img
                          src={
                            process.env.REACT_APP_IMAGES_URL +
                            "/" +
                            singleCon?.senderId?.image
                          }
                          onError={(e) => (e.target.src = "/user.png")}
                          alt=""
                        />
                        {singleCon.text !== "" && <p>{singleCon.text}</p>}
                        <span>
                          {Moment(singleCon?.createdAt).format(
                            "DD-MM-YYYY h:mm a"
                          )}
                        </span>
                        {singleCon.images.map((url, i) => {
                          return (
                            <div
                              className="col-1 mx-1"
                              style={{ padding: "4px" }}
                              key={i}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "4px",
                                }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGES_CHAT_URL +
                                    "/" +
                                    url
                                  }
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "7px",
                                    objectFit: "cover",
                                  }}
                                  alt=""
                                  // onClick={() => openLightboxOnSlide(1)}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ))}

              <div id="scrollhere"></div>
            </div>

            <div className="chat-field">
              <label htmlFor="fileInput">
                <div
                  className="input-group-prepend"
                  id="fileBtn"
                  style={{ cursor: "pointer" }}
                >
                  {attachment?.length === 0 ? (
                    <span className="input-group-text">
                      <AiOutlinePaperClip style={{ fontSize: "27px" }} />
                    </span>
                  ) : (
                    <span
                      className="input-group-text"
                      style={{ fontSize: "11px" }}
                    >
                      {attachment?.length}
                    </span>
                  )}
                </div>
              </label>
              <input
                type="text"
                placeholder={t("Enter your message...")}
                className="ms-3"
                value={newMessage}
                onKeyPress={onKeyPress}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <div
                className="input-group-prepend"
                style={{ cursor: "pointer" }}
                onClick={handleSendMessage}
              >
                <span className="input-group-text">
                  <img src={send} alt="" />
                </span>
              </div>
              <input
                type="file"
                id="fileInput"
                multiple
                hidden
                accept="image/*, application/pdf"
                onChange={(e) => setAttachment(e.target.files)}
              />
            </div>
          </>
        )}

        {!currentUser && (
          <div
            style={{
              height: "50vh",
              textAlign: "center",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <h3>
              <RxEnvelopeOpen className="me-2" />
              {t("Inbox is Empty")}
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
