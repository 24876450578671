import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import user2 from "../assets/images/user.png";
import p1 from "../assets/images/p5.jpg";
import axios from "axios";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import {
  AiFillEye,
  AiFillDelete,
  AiFillEdit,
  AiOutlineSave,
  AiOutlinePlus,
} from "react-icons/ai";
import { FaBan, FaUserEdit } from "react-icons/fa";
import { VscVmActive } from "react-icons/vsc";
import { SiSellfy } from "react-icons/si";
import { GiArmorUpgrade } from "react-icons/gi";
import { TbMessage } from "react-icons/tb";

//==============redux actions============
// import {
//   getPersonalAds,
//   deleteAd,
//   markAsInactive,
//   markAsActive,
//   markAsSold,
// } from "../features/ad/adSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getWalletBalanace } from "../features/auth/authSlice";
//==============redux actions end============
//---------trnslate
import { useTranslation } from "react-i18next";
//---------Loader skeleton-------------------
import { Code } from "react-content-loader";
const MyCodeLoader = () => <Code />;

//---------Loader skeleton end-------------------

const Profile = () => {
  const { t } = useTranslation();
  //-----------------------LOADING ON DATA LOAD START---------------
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // simulate data loading
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  //-----------------------LOADING ON DATA LOAD END---------------
  //==============redux Hooks start==================================
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //==============redux Hooks start==================================

  //==============User selector start==================================
  const { profile, user, walletBalance } = useSelector((state) => {
    return state.auth;
  });
  let userId = user?.user?._id;

  //==============User selector end==================================

  useEffect(() => {
    getSoldAds();
    getPersonalAds();
    getSellerStates();
  }, []);

  useEffect(() => {
    dispatch(getWalletBalanace());
  }, [dispatch]);

  //---------------Get sold Ads start================================
  const [soldAds, setSoldAds] = useState([]);

  const getSoldAds = async () => {
    const body = {
      user: userId,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/product/get/getSoldAds",
      body,
      config
    );
    // console.log("Sold Ads Response Service", response);
    setSoldAds(response?.data?.soldAds);
  };

  //---------------Get sold Ads end================================

  //---------------Get Personal Ads Ads start================================
  const [personalAds, setPersonalAds] = useState([]);

  const getPersonalAds = async () => {
    const body = {
      user: userId,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/product/get/personalAds",
      body,
      config
    );
    // console.log("Sold Ads Response Service", response);
    setPersonalAds(response?.data?.personalAd);

    // return response?.data;
  };

  //---------------Get sold Ads end================================

  //============== Deactive Personal Ads start--------------------
  const handleDeActiveAd = async (id) => {
    const body = {
      adId: id,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/product/markAsInactive",
      body,
      config
    );
    // console.log("handleDeActiveAd Response Service", response);
    if (response?.data?.success === true) {
      toast.success(response.data?.message);
      getPersonalAds();
    }
    // setPersonalAds(response?.data);
  };

  //============== Deactive Personal Ads end--------------------

  //============== Active Personal Ads start--------------------
  const handleActiveAd = async (id) => {
    const body = {
      adId: id,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/product/markAsActive",
      body,
      config
    );
    // console.log("handleDeActiveAd Response Service", response);
    if (response?.data?.success === true) {
      toast.success(response.data?.message);
      getPersonalAds();
    }
  };

  //============== Active Personal Ads end--------------------

  //============== Delete Personal Ads start--------------------
  const handleDeleteAd = async (id) => {
    const body = {
      adId: id,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/product/delete",
      body,
      config
    );
    // console.log("handleDeActiveAd Response Service", response);
    if (response?.data?.success === true) {
      toast.success(response.data?.message);
      getPersonalAds();
    }
  };

  //============== Delete Personal Ads end--------------------

  const handleSoldAd = async (id) => {
    const body = {
      adId: id,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/product/markAsSold",
      body,
      config
    );
    // console.log("handleDeActiveAd Response Service", response);
    if (response?.data?.success === true) {
      toast.success(response.data?.message);
      getSoldAds();
      getPersonalAds();
    }
  };

  //===============Upgrade Ad Start================
  const [upgradeType, setUpgradeType] = useState("");
  const [adId, setAdId] = useState();

  const [upgradeAdModal, setUpgradeAdModal] = useState(false);
  function tog_UpgradeModal(id) {
    setAdId(id);
    setUpgradeType("");
    setUpgradeAdModal(!upgradeAdModal);
  }

  const handleUpgradeAd = async (id) => {
    const body = {
      adId: adId,
      type: upgradeType,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/product/upgradeAd",
      body,
      config
    );
    if (response?.data?.success === true) {
      toast.success(response.data?.message);
      getPersonalAds();
      getSoldAds();
      setUpgradeType("");
      setUpgradeAdModal(false);
    }
  };

  //==============Get Seller States Api Start====================
  const [sellerStates, setSellerStates] = useState([]);
  // console.log("sellerStates", sellerStates);

  const getSellerStates = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/user/getStates/${userId}`
      );
      setSellerStates(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  //==============Get Seller States Api END====================

  //-----------Redirect user to wallet page
  // ======== Handle Previews Back Button Start=========
  const handleBack = () => {
    navigate("/profileSettings/wallet", { replace: true });
  };
  // ======== Handle Previews Back Button End=========

  return (
    <div>
      {loading && <MyCodeLoader />}

      {!loading && (
        <>
          <div className="row">
            <div className="col-lg-4 mb-2">
              <div className="profile-img">
                {profile?.user.image ? (
                  <img
                    src={
                      process.env.REACT_APP_IMAGES_URL +
                      "/" +
                      profile?.user.image
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={user2}
                    alt=""
                    onError={(e) => (e.target.src = "/user.png")}
                  />
                )}

                <h5 className="font-poppins">{user?.user.name}</h5>
                <p className="mb-0">
                  {parseInt(user?.user?.soldCount) > 10 ? "Top Seller" : ""}
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="profile-infos mb-2">
                <div className="infos-left">
                  <h5>{t("Performance")} </h5>
                  <p>
                    {parseInt(sellerStates?.soldCount) > 0
                      ? parseInt(sellerStates?.soldCount)
                      : 0}{" "}
                    {t("Item sold out")}{" "}
                  </p>
                  <p>
                    {parseInt(sellerStates?.activeCount) > 0
                      ? parseInt(sellerStates?.activeCount)
                      : 0}{" "}
                    {t("available")}{" "}
                  </p>
                </div>
                <div className="infos-pourcentage">
                  <div className="pourcentage-item">
                    <h5>Sales Amount</h5>
                    <p className="font-poppins">
                      $
                      {sellerStates?.trans[0]
                        ? sellerStates.trans[0]?.allamount.toFixed(2)
                        : 0}
                    </p>
                  </div>

                  {/* <div className="pourcentage-item">
                    <h1>79%</h1>
                    <p className="font-poppins">{t("Income")}</p>
                  </div>
                  <div className="pourcentage-item">
                    <h1>80%</h1>
                    <p className="font-poppins">{t("Sale")}</p>
                  </div> */}
                </div>
              </div>
              <div className="profile-infos mb-2">
                <div className="infos-left infos-2 w-100">
                  <h5>{t("Wallet")}</h5>
                  <p className="font-poppins">
                    {t("Available Balance")}:{" "}
                    <span className="ms-3 text-success">
                      ${" "}
                      {walletBalance[0]?.allamount
                        ? walletBalance[0]?.allamount.toFixed(2)
                        : 0}
                    </span>
                  </p>
                  {/* <p className="font-poppins">
                Pending: <span>$20</span>
              </p> */}
                </div>
                <div className="infos-btns">
                  <button
                    className="me-2 green-btn hover-shadow ms-5"
                    onClick={handleBack}
                  >
                    {t("Withdraw")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row  my-3">
            <div className="col-md-6 col-lg-3">
              <div className="infos-btns-profile">
                <Link to="/profileSettings/supportChat">
                  <button className="green-btn hover-shadow me-5 w-100">
                    <span>
                      <TbMessage />
                    </span>{" "}
                    {t("Support Chat")}
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="infos-btns-profile">
                <Link to="/profileSettings/edit">
                  <button className="green-btn hover-shadow me-5 w-100">
                    <span>
                      <FaUserEdit />
                    </span>{" "}
                    {t("Edit Profile")}
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="infos-btns-profile">
                <Link to="/profileSettings/savedAds">
                  <button className="green-btn hover-shadow me-5 w-100">
                    <span>
                      <AiOutlineSave />
                    </span>{" "}
                    {t("Saved Ads")}
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="infos-btns-profile">
                <Link to="/createItem">
                  <button className="green-btn hover-shadow me-5 w-100">
                    <span>
                      <AiOutlinePlus />
                    </span>{" "}
                    {t("Post a new ad")}
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {/* Personal Ads start*/}
          <div className="orders-container">
            <div className="orders-container-top profile-products">
              <h5 className="m-0">{t("Personal Products")} </h5>
              <Link to={"#"}>{t("See All")}</Link>
            </div>
            <div className="row">
              {personalAds?.length > 0 ? (
                personalAds?.map((ad, i) => (
                  <div className="col-lg-4 col-md-6" key={i}>
                    <div className="products-item shadow">
                      <Link
                        to={`/details/${ad._id}`}
                        className="product-img-container w-100"
                      >
                        {ad?.type === "Urgent" && (
                          <div className="product-ribbon urgent">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.type === "Standard" && (
                          <div className="product-ribbon standard">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.type === "Featured" && (
                          <div className="product-ribbon featured">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.images ? (
                          <img
                            className="product-img"
                            src={
                              process.env.REACT_APP_IMAGES_URL +
                              "/" +
                              ad?.images[0]
                            }
                            onError={(e) => (e.target.src = "/product.jpg")}
                            alt=""
                          />
                        ) : (
                          <img className="product-img" src={p1} alt="" />
                        )}
                      </Link>
                      <div className="product-text">
                        <div className="product-text-top">
                          <h6>
                            <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                          </h6>

                          <div>
                            <AiFillDelete
                              className="me-2 text-danger cursor-pointer"
                              onClick={() => handleDeleteAd(ad?._id)}
                            />
                          </div>
                        </div>
                        <div className="product-text-bottom">
                          <div className="desc">{ad?.description}</div>
                          <h6>$ {ad?.price}</h6>
                        </div>
                      </div>
                      <div className="">
                        <div className="product-text-top">
                          <div>
                            <GiArmorUpgrade
                              className="mx-2 cursor-pointer"
                              onClick={() => tog_UpgradeModal(ad._id)}
                            />
                            {/* {ad?.views?.length} */}
                          </div>
                          <div>
                            <Link to={`/editItem/${ad?._id}`}>
                              {" "}
                              <AiFillEdit className="me-2" />
                            </Link>
                          </div>
                          <button className="btn ">
                            <SiSellfy
                              className="text-danger"
                              title="Sold Ad"
                              onClick={() => handleSoldAd(ad._id)}
                            />
                          </button>

                          {ad?.adStatus === "active" ? (
                            <button className="btn ">
                              <FaBan
                                className="text-danger"
                                title="Deactive"
                                onClick={() => handleDeActiveAd(ad._id)}
                              />
                            </button>
                          ) : (
                            <button className="btn">
                              <VscVmActive
                                className="text-primary"
                                title="Active"
                                onClick={() => handleActiveAd(ad._id)}
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="card shadow-sm p-2 cate-card">
                  <h3 className="mx-auto fs-6 my-2">
                    {t("No Personal Ad/Product Added Yet !")}
                  </h3>
                  <div className="infos-btns ">
                    <Link to="/createItem">
                      <button className="mx-auto green-btn hover-shadow">
                        {" "}
                        {t("Add Personal Ad")}
                      </button>
                    </Link>
                  </div>
                </div>
              )}
              {/* <div className="col-lg-4 col-md-6">
            <div className="products-item shadow">
              <Link to={"/details"} className="product-img-container w-100">
                <div className="product-ribbon standard">Standard</div>
                <img src={p1} className="product-img" alt="" />
              </Link>
              <div className="product-text">
                <div className="product-text-top">
                  <h6>
                    <Link to={"/details"}>Omega</Link>
                  </h6>
                  <div>
                    <i onClick={() => setP1Like(!p1Like)}>
                      {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                    </i>
                    9
                  </div>
                </div>
                <div className="product-text-bottom">
                  <div>Watch Sea Master</div>
                  <h6>$65.00</h6>
                </div>
              </div>
            </div>
          </div> */}
            </div>
          </div>
          {/* Personal Ads End */}

          <div className="orders-container">
            <div className="orders-container-top profile-products">
              <h5 className="m-0">{t("Products Sold Out")}</h5>
              {/* <Link to={"#"}>See All</Link> */}
            </div>
            <div className="row">
              {soldAds?.length > 0 ? (
                soldAds?.map((ad, i) => (
                  <div className="col-lg-4 col-md-6" key={i}>
                    <div className="products-item shadow">
                      <Link
                        to={`/details/${ad._id}`}
                        className="product-img-container w-100"
                      >
                        {ad?.type === "Urgent" && (
                          <div className="product-ribbon urgent">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.type === "Standard" && (
                          <div className="product-ribbon standard">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.type === "Featured" && (
                          <div className="product-ribbon featured">
                            {ad?.type}
                          </div>
                        )}

                        {ad?.adStatus === "sold" && (
                          <div className="product-ribbon sold mt-5 ">
                            {t("Sold")}
                          </div>
                        )}

                        {ad?.images ? (
                          <img
                            className="product-img"
                            src={
                              process.env.REACT_APP_IMAGES_URL +
                              "/" +
                              ad?.images[0]
                            }
                            onError={(e) => (e.target.src = "/product.jpg")}
                            alt=""
                          />
                        ) : (
                          <img className="product-img" src={p1} alt="" />
                        )}
                      </Link>
                      <div className="product-text">
                        <div className="product-text-top">
                          <h6>
                            <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                          </h6>

                          {/* <div>
                        <AiFillDelete
                          className="me-2 text-danger cursor-pointer"
                          onClick={() => handleDeleteAd(ad?._id)}
                        />
                      </div> */}
                        </div>
                        <div className="product-text-bottom">
                          <div className="desc">{ad?.description}</div>
                          <h6>$ {ad?.price}</h6>
                        </div>
                      </div>
                      <div className="">
                        <div className="product-text-top">
                          <div>
                            <AiFillEye className="mx-2" />
                            {ad?.views?.length}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="card shadow-sm p-2 cate-card">
                    <h3 className="mx-auto fs-6 my-2">{t("No Sold Ads !")}</h3>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {/* Ban Ad Product Modal Start */}
      <Modal
        className="fade"
        show={upgradeAdModal}
        onHide={() => {
          tog_UpgradeModal(adId);
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Upgrade Product")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setUpgradeAdModal(false);
            }}
          />
        </div>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="form-field">
                <label>{t("Upgrade Ad Type")}</label>
                <select
                  value={upgradeType}
                  onChange={(e) => setUpgradeType(e.target.value)}
                >
                  <option selected>{t("Select Upgrade Type")}</option>

                  <option value="Standard">{t("Standard")}</option>
                  <option value="Featured">{t("Featured")}</option>

                  <option value="Urgent">{t("Urgent")}</option>
                </select>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button
              className="form-save green-btn hover-shadow mx-2"
              onClick={() => {
                setUpgradeAdModal(false);
              }}
            >
              {t("Cancel")}
            </button>
            <button
              className="form-save green-btn hover-shadow"
              onClick={(e) => handleUpgradeAd(e)}
            >
              {t("Upgrade")}
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* Ban Ad Product Modal End */}
    </div>
  );
};

export default Profile;
