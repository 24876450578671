import React, { useState, useEffect } from "react";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import p13 from "../assets/images/p13.jpg";
import star from "../assets/icons/star.svg";
import user from "../assets/images/user.jpg";
import Footer from "../components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAdById } from "../features/ad/adSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Moment from "moment";

const Reviews = () => {
  //==============redux Hooks start==================================
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //==============redux Hooks start==================================

  //==============AD selector start==================================

  const { Ad, Offers, sellerAd, categoryAd, isSuccess } = useSelector(
    (state) => state.ads
  );

  //==============AD selector end==================================

  //==============User selector start==================================

  const { profile } = useSelector((state) => {
    return state.auth;
  });

  let userId = profile?.user?._id;
  let adUserId = Ad?.user?._id;

  //==============User selector end==================================

  //==============redux end==================================

  //==============Get single Ad start==================================

  useEffect(() => {
    dispatch(getAdById(id));
  }, [dispatch, id]);

  // useEffect(() => {
  //   tog_feedbacks();
  // }, []);

  //==============Get single Ad end==================================

  //==============Review get Api Start====================
  const [rating, setRating] = useState("");
  const [allReview, setAllReview] = useState([]);

  const getallReview = async () => {
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/product/getAdReview",
        {
          productid: Ad?._id,
        },
        config
      );
      setAllReview(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getallReview();
  }, []);
  //==============Review get Api END====================

  return (
    <div className="reviews-page">
      <Header />
      <Categories />

      <div className="container my-5">
        <h4 className="reviews-heading">
          Customer Reviews: <span> Spalding Basketball 32410</span>
        </h4>

        <div className="reviews-product">
          <img src={p13} className="reviews-product-img" alt="" />
          <div className="reviews-modal-top d-flex align-items-center">
            <div className="review-modal-top-left">
              <h1>4.9</h1>
              <p>
                Average based on <br /> 41 reviews
              </p>
            </div>
            <div className="review-modal-top-right">
              <div className="star-item">
                <img src={star} alt="" />4
                <div className="star-pourcentage">32</div>
              </div>
              <div className="star-item">
                <img src={star} alt="" />4
                <div className="star-pourcentage">32</div>
              </div>
              <div className="star-item">
                <img src={star} alt="" />4
                <div className="star-pourcentage">32</div>
              </div>
              <div className="star-item">
                <img src={star} alt="" />4
                <div className="star-pourcentage">32</div>
              </div>
              <div className="star-item">
                <img src={star} alt="" />4
                <div className="star-pourcentage">32</div>
              </div>
            </div>
          </div>
          <div className="review-modal-center">
            <div className="review-modal-center-item">
              <div
                className="circle"
                style={{
                  backgroundImage:
                    "conic-gradient(#059669 0deg, #059669 300deg, #D9D9D9 300deg, #D9D9D9 360deg)",
                }}
              >
                <div className="circle-center">
                  97% <br />
                  Agree
                </div>
              </div>
              <p>Eazy to use</p>
            </div>
            <div className="review-modal-center-item">
              <div
                className="circle"
                style={{
                  backgroundImage:
                    "conic-gradient(#059669 0deg, #059669 250deg, #D9D9D9 250deg, #D9D9D9 360deg)",
                }}
              >
                <div className="circle-center">
                  88% <br />
                  Agree
                </div>
              </div>
              <p>Eazy to use</p>
            </div>
            <div className="review-modal-center-item">
              <div
                className="circle"
                style={{
                  backgroundImage:
                    "conic-gradient(#059669 0deg, #059669 330deg, #D9D9D9 330deg, #D9D9D9 360deg)",
                }}
              >
                <div className="circle-center">
                  98% <br />
                  Agree
                </div>
              </div>
              <p>Eazy to use</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <h4 className="reviews-heading">Customer Reviews</h4>

        <div className="row">
          <div className="col-lg-4">
            <div className="review-card">
              <div className="review-card-top">
                <img src={user} alt="" />
                <div className="review-card-top-text">
                  <p>John</p>
                  <span>John Jailandos</span>
                </div>
              </div>
              <p className="review-card-text">
                “Lorem ipsum dolor sit amet consectetur. Semper ipsum sed purus
                molestie aliquam nunc.”
              </p>
              <div className="review-card-bottom">
                <p className="review-card-date">8:22 pm - Jan 6,2022</p>
                <p className="review-card-stars">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="review-card">
              <div className="review-card-top">
                <img src={user} alt="" />
                <div className="review-card-top-text">
                  <p>John</p>
                  <span>John Jailandos</span>
                </div>
              </div>
              <p className="review-card-text">
                “Lorem ipsum dolor sit amet consectetur. Semper ipsum sed purus
                molestie aliquam nunc.”
              </p>
              <div className="review-card-bottom">
                <p className="review-card-date">8:22 pm - Jan 6,2022</p>
                <p className="review-card-stars">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="review-card">
              <div className="review-card-top">
                <img src={user} alt="" />
                <div className="review-card-top-text">
                  <p>John</p>
                  <span>John Jailandos</span>
                </div>
              </div>
              <p className="review-card-text">
                “Lorem ipsum dolor sit amet consectetur. Semper ipsum sed purus
                molestie aliquam nunc.”
              </p>
              <div className="review-card-bottom">
                <p className="review-card-date">8:22 pm - Jan 6,2022</p>
                <p className="review-card-stars">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="review-card">
              <div className="review-card-top">
                <img src={user} alt="" />
                <div className="review-card-top-text">
                  <p>John</p>
                  <span>John Jailandos</span>
                </div>
              </div>
              <p className="review-card-text">
                “Lorem ipsum dolor sit amet consectetur. Semper ipsum sed purus
                molestie aliquam nunc.”
              </p>
              <div className="review-card-bottom">
                <p className="review-card-date">8:22 pm - Jan 6,2022</p>
                <p className="review-card-stars">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="review-card">
              <div className="review-card-top">
                <img src={user} alt="" />
                <div className="review-card-top-text">
                  <p>John</p>
                  <span>John Jailandos</span>
                </div>
              </div>
              <p className="review-card-text">
                “Lorem ipsum dolor sit amet consectetur. Semper ipsum sed purus
                molestie aliquam nunc.”
              </p>
              <div className="review-card-bottom">
                <p className="review-card-date">8:22 pm - Jan 6,2022</p>
                <p className="review-card-stars">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="review-card">
              <div className="review-card-top">
                <img src={user} alt="" />
                <div className="review-card-top-text">
                  <p>John</p>
                  <span>John Jailandos</span>
                </div>
              </div>
              <p className="review-card-text">
                “Lorem ipsum dolor sit amet consectetur. Semper ipsum sed purus
                molestie aliquam nunc.”
              </p>
              <div className="review-card-bottom">
                <p className="review-card-date">8:22 pm - Jan 6,2022</p>
                <p className="review-card-stars">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Reviews;
