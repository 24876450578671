import React, { useState } from "react";
import Categories from "../components/Categories";
// import left from "../assets/icons/left.svg";
import {
  FaHouseUser,
  FaUserEdit,
  FaTicketAlt,
  FaFirstOrder,
} from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
// import { MdProductionQuantityLimits } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { SlBadge } from "react-icons/sl";
import { AiOutlinePlus, AiOutlineStar, AiOutlineSave } from "react-icons/ai";
import { TbMessage } from "react-icons/tb";

// import homeGreen from "../assets/icons/homeGreen.png";
// import cartGreen from "../assets/icons/cartGreen.png";
// import adsGreen from "../assets/icons/adsGreen.png";
// import messageGreen from "../assets/icons/messageGreen.png";
// import editGreen from "../assets/icons/editGreen.png";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import EditProfile from "../components/EditProfile";
import Footer from "../components/Footer";
import Orders from "../components/Orders";
// import Ads from "../components/Ads";
import BuyerOrders from "../components/BuyerOrders";
import Profile from "../components/Profile";
import Messages from "../components/Messages";
import Header from "../components/LoggedHeader";
import Wallet from "../components/Wallet";
import Reviews from "../components/Reviews";
import Points from "../components/Points";
import SavedAds from "./SavedAds";
import UserTickets from "../components/UserTickets";
import TicketDetails from "../components/TicketDetails";
import SupportChat from "../components/SupportChat";
// import FAQs from "../admin/FAQs";
//----------react-translate------------
import { useTranslation } from "react-i18next";

const ProfileSettings = () => {
  const { t } = useTranslation();

  // const [activeLink, setActiveLink] = useState("profile");

  // const handleClick = (link) => {
  //   setActiveLink(link);
  // };
  const location = useLocation();

  const sidebar = [
    {
      link: "profileSettings/profile",
      icon: <FaHouseUser />,
      text: "Dashboard",
    },
    {
      link: "createItem",
      icon: <AiOutlinePlus />,
      text: "Add Product",
    },
    {
      link: "profileSettings/orders",
      icon: <FiShoppingCart />,
      text: "My Orders",
    },
    {
      link: "profileSettings/buyerorders",
      icon: <FaFirstOrder />,
      text: "Buyer Orders",
    },
    // {
    //   link: "ads",
    //   icon: <MdProductionQuantityLimits />,
    //   text: "All Ads",
    // },
    {
      link: "profileSettings/messages",
      icon: <TbMessage />,
      text: "Messages",
    },
    {
      link: "profileSettings/supportChat",
      icon: <TbMessage />,
      text: "Support Chat",
    },
    {
      link: "profileSettings/edit",
      icon: <FaUserEdit />,
      text: "Edit Profile",
    },

    {
      link: "profileSettings/savedAds",
      icon: <AiOutlineSave />,
      text: "Saved Ads",
    },
    {
      link: "profileSettings/wallet",
      icon: <IoWalletOutline />,
      text: "My Wallet",
    },
    {
      link: "profileSettings/reviews",
      icon: <AiOutlineStar />,
      text: "My Reviews",
    },
    {
      link: "profileSettings/points",
      icon: <SlBadge />,
      text: "My Points",
    },
    {
      link: "profileSettings/tickets",
      icon: <FaTicketAlt />,
      text: "Tickets",
    },
  ];
  return (
    <div>
      <Header />
      <Categories />

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-3 mt-2">
            <div className="profile-sidebar">
              {/* <h5 className="sidebar-heading">
                <img src={left} alt="" />
                {t("Settings")}
              </h5> */}

              <div className="sidebar-links">
                {sidebar.map((item) => (
                  <div key={item.link}>
                    <Link
                      to={`/${item.link}`}
                      className={`${
                        location.pathname.includes(item.link)
                          ? "activeSideBar"
                          : ""
                      }`}
                    >
                      <span className="fs-5 me-2 ">{item.icon}</span>
                      {t(`${item.text}`)}
                    </Link>
                  </div>
                ))}
                {/* {sidebar &&
                  sidebar.map((item, index) => (
                    <div key={index}>
                      <Link
                        to={`${item.link}`}
                        onClick={() => handleClick(item.link)}
                        className={
                          activeLink === `${item.link}` ? "activeSideBar" : ""
                        }
                      >
                        <span className="fs-5 me-2 ">{item.icon}</span>
                        {t(`${item.text}`)}
                      </Link>
                    </div>
                  ))} */}
              </div>
            </div>
          </div>
          <div className="col-lg-9 mt-2">
            <Routes>
              <Route path="edit" element={<EditProfile />} />
              <Route path="orders" element={<Orders />} />
              <Route path="buyerorders" element={<BuyerOrders />} />
              {/* <Route path="ads" element={<Ads />} /> */}
              <Route path="profile" element={<Profile />} />
              <Route path="messages" element={<Messages />} />
              <Route path="supportChat" element={<SupportChat />} />
              <Route path="wallet" element={<Wallet />} />
              <Route path="reviews" element={<Reviews />} />
              <Route path="points" element={<Points />} />
              <Route path="savedAds" element={<SavedAds />} />
              <Route path="tickets" element={<UserTickets />} />
              <Route path="details/:id" element={<TicketDetails />} />

              {/* <Route path="faqs" element={<FAQs />} /> */}
            </Routes>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProfileSettings;
