import React, { useState, useEffect } from "react";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import profile from "../assets/icons/profile.png";
import mail from "../assets/icons/mail.png";
import passwordIcon from "../assets/icons/password.png";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";

import { toast } from "react-toastify";
//===================Redux===================
import { useSelector, useDispatch } from "react-redux";

//=================API METHODS================
import { register, reset } from "../features/auth/authSlice";

//---------trnslate
import { useTranslation } from "react-i18next";
import { getAuthCategories } from "../features/ad/adSlice";

const Register = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const { authCategories } = useSelector((state) => state.ads);
  //============== USE EFFECTS START=============================

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (message?.success === false) {
      toast.error(message);
    }
    if (message?.success === true) {
      toast.success(message);
      navigate("/verify");
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  //==============Use Effect================================
  useEffect(() => {
    getAuthCategories();
  }, [dispatch]);
  //============== USE EFFECTS END=============================

  //===============Initialization Plus Handler ONCHANGE Start
  const [termsChecked, setTermsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const { name, email, password } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //===============Initialization Plus Handler ONCHANGE End

  //==============Register Handler Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!termsChecked) {
      toast.error("Please accept the terms and conditions to proceed");
      return;
    }

    const userData = {
      name,
      email,
      password,
      termAndCondition: termsChecked,
    };

    dispatch(register(userData));
  };

  //==============Register Handler Submit End

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Header />
      <Categories />

      <div className="register">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="register-heading">
                {t("Sell, Buy and connect with people in your life.")}
              </h4>
              <div className="register-cards">
                {authCategories &&
                  authCategories?.map((cate, i) => (
                    <div className="register-card shadow">
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          cate?.sportCategory.images[0]
                        }
                        alt="Category"
                        onError={(e) => (e.target.src = "/cate.jpg")}
                      />
                      <h5>{cate?.sportCategory.name}</h5>
                      <span>
                        {cate?.count} {t("items")}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="register-form shadow">
                <h3 className="register-form-heading font-sora">
                  {t("Create Account")}
                </h3>
                <h5>
                  {t("Already A Member?")}{" "}
                  <Link to={"/login"}>{t("Login")}</Link>
                </h5>
                <div className="row">
                  <div className="col-lg-12 register-col">
                    <label>{t("Full Name")}</label>
                    <div className="register-field">
                      <img src={profile} alt="" />
                      <input
                        type="text"
                        placeholder={t("Enter Full Name")}
                        name="name"
                        value={name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-6 register-col">
                                    <label>Last Name</label>
                                    <div className="register-field">
                                        <img src={profile} alt="" />
                                        <input type="text" placeholder='Enter Name' />
                                    </div>
                                </div> */}
                  <div className="col-12 register-col">
                    <label>{t("Email")}</label>
                    <div className="register-field">
                      <img src={mail} alt="" />
                      <input
                        type="text"
                        placeholder="e.g  jhn22@gmail.com"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 register-col">
                    <label>{t("Password")}</label>
                    <div className="register-field">
                      <img src={passwordIcon} alt="" />
                      <input
                        type="password"
                        placeholder={t("Enter Password")}
                        name="password"
                        value={password}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-4 d-flex align-items-center px-4 register-check">
                    <input
                      id="registerCheck"
                      className="form-check-input mt-0 shadow-none"
                      type="checkbox"
                      checked={termsChecked}
                      onChange={handleCheckboxChange}
                    />

                    <label htmlFor="registerCheck">
                      {t("By sigining up I accept")}{" "}
                      <Link to={"/terms"}>{t("terms of use")}</Link>
                      {t("and")}{" "}
                      <Link to={"/privacyPolicy"}>{t("privacy policy")}</Link>
                    </label>
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="register-submit text-light green-btn hover-shadow"
                    >
                      {t("Register")}
                    </button>
                    {/* <Link to={'/password'} className='register-submit text-light green-btn hover-shadow'>Register</Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Register;
