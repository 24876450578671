import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import searchBlue from "../assets/icons/searchBlue.svg";
// import file from "../assets/icons/file.svg";
import { AiOutlinePaperClip } from "react-icons/ai";
import send from "../assets/icons/send.svg";
import $ from "jquery";
import Moment from "moment";
import { useSelector } from "react-redux";
import FsLightbox from "fslightbox-react";

//---------trnslate
import { useTranslation } from "react-i18next";

//============socket Import============
import { io } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

var connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity",
  timeout: 10000,
};

export var socket;
socket = io.connect(SOCKET_URL, connectionOptions);

const Messages = () => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    socket.emit("addUser", user?.user._id);
  }, [user]);

  //============== GET All conversations sidebar List start =============
  let userID = localStorage.getItem("userId");
  const [conversations, setConversations] = useState([]);
  const [adConversation, setAdConversations] = useState([]);
  const [mapid, setMapid] = useState();
  const [conId, setConId] = useState();
  // const [typingStatus, setTypingStatus] = useState("");
  const getConversations = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/userProConv/getConv",
        { senderId: userID }
      );
      setConversations(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getConversations();
  }, []);

  useEffect(() => {
    if (mapid !== undefined) {
      let newCall = async () => {
        const res = await axios.get(
          process.env.REACT_APP_API_URL +
            `/userProConv/getSingleConversation/${conId}`
        );
        setAdConversations(res.data);
        const messagesid = document.getElementById("scrollhere");
        $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
        // console.log("adConversation", adConversation);
      };
      newCall();
    }
  }, [conId, mapid]);

  socket.on("msg-recieve", (data) => {
    setMapid(data);
  });
  //============== GET All conversations sidebar List end =============

  //============== GET All conversations on Single Ad Clicked start =============

  const [adData, setAdData] = useState();

  const handleConversation = async (e, id, ad) => {
    setAdData(ad);
    setConId(id);
    e.preventDefault();
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          `/userProConv/getSingleConversation/${id}`
      );
      setAdConversations(res.data);
      const messagesid = document.getElementById("scrollhere");
      $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
    } catch (err) {
      console.log(err);
    }
  };

  //============== GET All conversations on Single Ad Clicked end =============

  //=========================== Send Message  start ===========================
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState([]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("adId", adData?._id);
      formData.append("senderId", userID);
      formData.append(
        "receiverId",
        conversations[0]?.productBuyer?._id === userID
          ? conversations[0]?.productOwner?._id
          : conversations[0]?.productBuyer?._id
      );
      formData.append("text", message);
      formData.append("mapId", conId);
      if (attachment !== null) {
        for (let i = 0; i < attachment.length; i++) {
          formData.append("attachments", attachment[i]);
        }
      }

      await axios.post(
        process.env.REACT_APP_API_URL + "/userProConv/addNewMessage",
        formData
      );

      socket.emit("sendMsg", {
        adId: adData?._id,
        senderId: userID,
        receiverId:
          conversations[0]?.productBuyer?._id === userID
            ? conversations[0]?.productOwner?._id
            : conversations[0]?.productBuyer?._id,
        text: message,
        mapId: conId,
      });

      const rest = await axios.get(
        process.env.REACT_APP_API_URL +
          `/userProConv/getSingleConversation/${conId}`
      );
      setAdConversations(rest.data);
      setMessage("");
      setAttachment([]);
      const messagesid = document.getElementById("scrollhere");
      $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
    } catch (err) {
      console.log(err);
    }
  };
  //=========================== Send Message  end =============================

  //============typing status============
  // useEffect(() => {
  //   socket.on("typingResponse", (data) => setTypingStatus(data));
  // }, [mapid]);

  // const handleTyping = () => {
  //   socket.emit("typing", `${userID} is typing...`);
  // };

  //============typing status============

  useEffect(() => {
    //===== Scroll To Latest Message ======
    if (adConversation !== null) {
      const messagesid = document.getElementById("scrollhere");
      $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
    }
  }, [adConversation]);

  const onKeyPress = async (e) => {
    if (e.key === "Enter" && message !== "") {
      handleSendMessage(e);
    }
  };

  //==============lightboxController start================================

  //============== Images array of Slider LightBox Start===================
  const images = adConversation?.map((adConver) => {
    return adConver?.images;
  });

  const imgUrls = [];
  images &&
    images.length !== 0 &&
    images.map((item) => {
      return imgUrls.push(process.env.REACT_APP_IMAGES_CHAT_URL + "/" + item);
    });

  //============== Image array of Slider LightBox end===================

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const openLightboxOnSlide = (number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };
  //==============lightboxController end================================

  //==================Search filter start ==============================
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = conversations.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(conversations);
    }
  };

  //==================Search filter end ==============================

  return (
    <div className="row messages">
      <div className="col-lg-4 messages-list">
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={imgUrls}
          slide={lightboxController.slide}
        />
        <h5 className="messages-heading">{t("Messages")} </h5>
        <div className="messages-input">
          <img src={searchBlue} alt="" />
          <input
            type="text"
            placeholder={t("Search")}
            onChange={(e) => searchItems(e.target.value)}
          />
        </div>
        <div className="users-list">
          {searchInput.length > 1
            ? filteredResults.map((item, i) => {
                return (
                  <Link
                    to={"#"}
                    className="chat-user"
                    onClick={(e) =>
                      handleConversation(e, item?._id, item?.adId)
                    }
                    key={i}
                  >
                    <img
                      src={
                        process.env.REACT_APP_IMAGES_URL +
                        "/" +
                        item?.adId?.images[0]
                      }
                      onError={(e) => (e.target.src = "/product.jpg")}
                      alt=""
                    />
                    <div className="chat-user-text">
                      <h5>{item?.adId?.name}</h5>(
                      {item?.adId?.user === userID
                        ? item?.productBuyer?.name
                        : item?.productOwner?.name}
                      )
                    </div>
                    <span className="chat-time">
                      {Moment(item?.createdAt).format("DD-MM-YY")}
                    </span>
                  </Link>
                );
              })
            : conversations?.map((con, i) => (
                <Link
                  to={"#"}
                  className="chat-user"
                  onClick={(e) => handleConversation(e, con?._id, con?.adId)}
                  key={i}
                >
                  <img
                    src={
                      process.env.REACT_APP_IMAGES_URL +
                      "/" +
                      con?.adId?.images[0]
                    }
                    onError={(e) => (e.target.src = "/product.jpg")}
                    alt=""
                  />
                  <div className="chat-user-text">
                    <h5>{con?.adId?.name}</h5>(
                    {con?.adId?.user === userID
                      ? con?.productBuyer?.name
                      : con?.productOwner?.name}
                    )
                  </div>
                  <span className="chat-time">
                    {Moment(con?.createdAt).format("DD-MM-YY")}
                  </span>
                </Link>
              ))}
        </div>
      </div>
      {adConversation ? (
        <div className="col-lg-8 chat-container">
          {adData && (
            <Link to={`/details/${adData?._id}`} className="chat-top">
              <img
                src={process.env.REACT_APP_IMAGES_URL + "/" + adData?.images[0]}
                alt=""
                onError={(e) => (e.target.src = "/product.jpg")}
              />
              <div className="chat-top-text">
                <h5>{adData?.name}</h5>
                <p>
                  {t("Color")} : {adData?.color}, {t("Size")}:{adData?.size}
                </p>
              </div>
            </Link>
          )}

          {/* <h5 className="chat-heading">John</h5> */}
          <div className="chat mt-3" id="chatbody">
            {adConversation &&
              adConversation?.map((singleCon, i) => (
                <div key={i}>
                  {singleCon.sender._id === userID && (
                    <div className="chat-message user1">
                      <span>
                        {Moment(singleCon?.createdAt).format(
                          "DD-MM-YYYY h:mm a"
                        )}
                      </span>
                      {singleCon.text !== "" && <p>{singleCon.text}</p>}

                      {singleCon.images.map((url, i) => {
                        return (
                          <div
                            className="col-1 mx-1"
                            style={{ padding: "5px" }}
                            key={i}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_IMAGES_CHAT_URL +
                                  "/" +
                                  url
                                }
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "7px",
                                  objectFit: "cover",
                                }}
                                alt=""
                                onClick={() => openLightboxOnSlide(1)}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          singleCon?.sender?.image
                        }
                        alt=""
                      />
                    </div>
                  )}
                  {singleCon.sender._id !== userID && (
                    <div className="chat-message user2">
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          singleCon?.sender?.image
                        }
                        alt=""
                      />
                      {singleCon.text !== "" && <p>{singleCon.text}</p>}
                      <span>
                        {Moment(singleCon?.createdAt).format(
                          "DD-MM-YYYY h:mm a"
                        )}
                      </span>
                      {singleCon.images.map((url, i) => {
                        return (
                          <div
                            className="col-1 mx-1"
                            style={{ padding: "4px" }}
                            key={i}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "4px",
                              }}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_IMAGES_CHAT_URL +
                                  "/" +
                                  url
                                }
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "7px",
                                  objectFit: "cover",
                                }}
                                alt=""
                                onClick={() => openLightboxOnSlide(1)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}
            {/* <p>{typingStatus}</p> */}

            <div id="scrollhere"></div>
          </div>

          <div className="chat-field">
            <label htmlFor="fileInput">
              <div
                className="input-group-prepend"
                id="fileBtn"
                style={{ cursor: "pointer" }}
              >
                {attachment?.length === 0 ? (
                  <span className="input-group-text">
                    <AiOutlinePaperClip style={{ fontSize: "27px" }} />
                  </span>
                ) : (
                  <span
                    className="input-group-text"
                    style={{ fontSize: "11px" }}
                  >
                    {attachment?.length}
                  </span>
                )}
              </div>
            </label>
            <input
              type="text"
              placeholder={t("Enter your message...")}
              className="ms-3"
              value={message}
              onKeyPress={onKeyPress}
              onChange={(e) => setMessage(e.target.value)}
              // onKeyDown={handleTyping}
            />
            <div
              className="input-group-prepend"
              style={{ cursor: "pointer" }}
              onClick={handleSendMessage}
            >
              <span className="input-group-text">
                <img src={send} alt="" />
              </span>
            </div>
            <input
              type="file"
              id="fileInput"
              multiple
              hidden
              accept="image/*, application/pdf"
              onChange={(e) => setAttachment(e.target.files)}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <h3>Inbox is Empty</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default Messages;
