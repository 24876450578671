// import { configureStore } from "@reduxjs/toolkit";
// import authReducer from "../features/auth/authSlice";

// export const store = configureStore({
//   reducer: {
//     auth: authReducer,
//   },
// });

import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import authReducer from "../features/auth/authSlice";
import categoryReducer from "../features/category/categorySlice";
import sportReducer from "../features/sport/sportSlice";

import adReducer from "../features/ad/adSlice";
import cartReducer from "../features/cart/cartSlice";
import searchReducer from "../features/search/searchSlice";

import brandReducer from "../features/brands/brandSlice";
import colorReducer from "../features/color/colorSlice";

import ticketReducer from "../features/tickets/ticketSlice";

const reducers = combineReducers({
  auth: authReducer,
  category: categoryReducer,
  sport: sportReducer,
  ads: adReducer,
  cart: cartReducer,
  search: searchReducer,
  brand: brandReducer,
  color: colorReducer,
  ticket: ticketReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
