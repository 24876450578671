import React, { useEffect, useState } from "react";
import Moment from "moment";
import $ from "jquery";
import { AiOutlinePaperClip } from "react-icons/ai";
import send from "../assets/icons/send.svg";

import { useSelector } from "react-redux";

import axios from "axios";
//---------trnslate
import { useTranslation } from "react-i18next";
//============socket Import============
import { io } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

var connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity",
  timeout: 10000,
};

export var socket;
socket = io.connect(SOCKET_URL, connectionOptions);

const SupportChat = () => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    socket.emit("addUser", user?.user._id);
  }, [user]);

  const [conversations, setConversations] = useState([]);

  //========Init admin name email and image for user

  let finalObj = {};

  // loop elements of the array
  for (let i = 0; i < conversations.length; i++) {
    Object.assign(finalObj, conversations[i]);
  }

  useEffect(() => {
    const getConversations = async () => {
      try {
        const rest = await axios.get(
          process.env.REACT_APP_API_URL +
            `/adminuser/getSingleConversation/${user?.user._id}`
        );

        setConversations(rest.data);
        const messagesid = document.getElementById("scrollhere");
        $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, [user?.user._id]);

  //=========================== Send Message  start ===========================
  const [newMessage, setNewMessage] = useState("");
  const [attachment, setAttachment] = useState([]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("senderId", user?.user?._id);
      formData.append(
        "receiverId",
        finalObj?.senderId?._id === user?.user?._id
          ? user?.user?._id
          : finalObj?.senderId?._id
      );
      formData.append("text", newMessage);
      if (attachment !== null) {
        for (let i = 0; i < attachment.length; i++) {
          formData.append("attachments", attachment[i]);
        }
      }

      await axios.post(
        process.env.REACT_APP_API_URL + "/adminuser/addNewMessage",
        formData
      );

      socket.emit("send_Msg", {
        senderId: user?.user?._id,
        text: newMessage,
        receiverId:
          finalObj?.senderId?._id === user?.user?._id
            ? user?.user?._id
            : finalObj?.senderId?._id,
      });

      const rest = await axios.post(
        process.env.REACT_APP_API_URL + "/adminuser/getConv/",
        {
          senderId: user?.user._id,
        }
      );
      setConversations(rest.data);
      setNewMessage("");
      setAttachment([]);
      const messagesid = document.getElementById("scrollhere");
      $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
    } catch (err) {
      console.log(err);
    }
  };

  const onKeyPress = async (e) => {
    if (e.key === "Enter" && newMessage !== "") {
      // handleSubmit(e);
      handleSendMessage(e);
    }
  };
  //=========================== Send Message  end =============================

  // ======================Get messages through socket start=================
  const [mapid, setMapid] = useState();
  useEffect(() => {
    if (mapid !== undefined) {
      let newCall = async () => {
        const rest = await axios.get(
          process.env.REACT_APP_API_URL +
            `/adminuser/getSingleConversation/${user?.user._id}`
        );

        setConversations(rest.data);
        const messagesid = document.getElementById("scrollhere");
        $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
        // console.log("adConversation", adConversation);
      };
      newCall();
    }
  }, [user, mapid]);

  socket.on("msgRecieve", (data) => {
    setMapid(data);
  });

  return (
    <div className="row messages">
      {/* <div className="col-lg-4 messages-list">
        <h5 className="messages-heading">Messages</h5>
        
      
      </div> */}
      <div className="col-lg-12 chat-container">
        <h5 className="messages-heading">{t("Messages")} </h5>

        {/* {AllUsers &&
        AllUsers.map((user, idx) => ( 
          
        ))} */}
        {finalObj && (
          <div className="chat-top">
            <img
              src={
                process.env.REACT_APP_IMAGES_URL +
                "/" +
                finalObj?.senderId?.image
              }
              onError={(e) => (e.target.src = "/user.png")}
              alt=""
            />
            <div className="chat-top-text">
              <h5>{finalObj?.senderId?.name}</h5>
              <p>{finalObj?.senderId?.email}</p>
            </div>
          </div>
        )}

        {!finalObj?.senderId && (
          <div className="chat-top">{t("No Chat found Against Admin")}</div>
        )}

        <div className="chat mt-3" id="chatbody">
          {conversations &&
            conversations?.map((singleCon, i) => (
              <div key={i}>
                {singleCon.senderId._id === user?.user._id && (
                  <div className="chat-message user1">
                    <span>
                      {Moment(singleCon?.createdAt).format("DD-MM-YYYY h:mm a")}
                    </span>
                    {singleCon.text !== "" && <p>{singleCon.text}</p>}

                    {singleCon.images.map((url, i) => {
                      return (
                        <div
                          className="col-1 mx-1"
                          style={{ padding: "5px" }}
                          key={i}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src={
                                process.env.REACT_APP_IMAGES_CHAT_URL +
                                "/" +
                                url
                              }
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "7px",
                                objectFit: "cover",
                              }}
                              onError={(e) => (e.target.src = "/user.png")}
                              alt=""
                              // onClick={() => openLightboxOnSlide(1)}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <img
                      src={
                        process.env.REACT_APP_IMAGES_URL +
                        "/" +
                        singleCon?.senderId?.image
                      }
                      onError={(e) => (e.target.src = "/user.png")}
                      alt=""
                    />
                  </div>
                )}
                {singleCon.senderId._id !== user?.user._id && (
                  <div className="chat-message user2">
                    <img
                      src={
                        process.env.REACT_APP_IMAGES_URL +
                        "/" +
                        singleCon?.senderId?.image
                      }
                      onError={(e) => (e.target.src = "/user.png")}
                      alt=""
                    />
                    {singleCon.text !== "" && <p>{singleCon.text}</p>}
                    <span>
                      {" "}
                      {Moment(singleCon?.createdAt).format("DD-MM-YYYY h:mm a")}
                    </span>
                    {singleCon.images.map((url, i) => {
                      return (
                        <div
                          className="col-1 mx-1"
                          style={{ padding: "4px" }}
                          key={i}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "4px",
                            }}
                          >
                            <img
                              src={
                                process.env.REACT_APP_IMAGES_CHAT_URL +
                                "/" +
                                url
                              }
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "7px",
                                objectFit: "cover",
                              }}
                              onError={(e) => (e.target.src = "/user.png")}
                              alt=""
                              // onClick={() => openLightboxOnSlide(1)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          {/* <p>{typingStatus}</p> */}

          <div id="scrollhere"></div>
        </div>

        {!finalObj?.senderId === false && (
          <div className="chat-field">
            <label htmlFor="fileInput">
              <div
                className="input-group-prepend"
                id="fileBtn"
                style={{ cursor: "pointer" }}
              >
                {attachment?.length === 0 ? (
                  <span className="input-group-text">
                    <AiOutlinePaperClip style={{ fontSize: "27px" }} />
                  </span>
                ) : (
                  <span
                    className="input-group-text"
                    style={{ fontSize: "11px" }}
                  >
                    {attachment?.length}
                  </span>
                )}
              </div>
            </label>
            <input
              type="text"
              placeholder={t("Enter your message...")}
              className="ms-3"
              value={newMessage}
              onKeyPress={onKeyPress}
              onChange={(e) => setNewMessage(e.target.value)}
              // onKeyDown={handleTyping}
            />
            <div
              className="input-group-prepend"
              style={{ cursor: "pointer" }}
              onClick={handleSendMessage}
              // onClick={handleSubmit}
            >
              <span className="input-group-text">
                <img src={send} alt="" />
              </span>
            </div>
            <input
              type="file"
              id="fileInput"
              multiple
              hidden
              accept="image/*, application/pdf"
              onChange={(e) => setAttachment(e.target.files)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportChat;
