import React, { useEffect, useState } from "react";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import { Link, useNavigate } from "react-router-dom";
import p1 from "../assets/images/p1.jpg";

import bannerImg1 from "../assets/images/bannerImg1.svg";
import bannerImg2 from "../assets/images/bannerImg2.svg";
import bannerImg3 from "../assets/images/bannerImg3.svg";
import Footer from "../components/Footer";
import { AiFillEye } from "react-icons/ai";
//===================Redux===================
import {
  getAllAds,
  getMostSoldAds,
  getFeaturedAd,
  getHomeCategories,
} from "../features/ad/adSlice";
import { getAllBrands } from "../features/brands/brandSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { slice } from "lodash";
//----------react-translate------------
import { useTranslation } from "react-i18next";

const Home = () => {
  //==============Other states and useEffect modal popup Home Page start================================
  const { t } = useTranslation();

  //==============Other states and useEffect modal popup Home Page end================================

  //==============redux start=============================
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ads, mostSoldAd, featuredAds, homeCategories } = useSelector(
    (state) => state.ads
  );
  const { allBrands } = useSelector((state) => state.brand);
  const { user } = useSelector((state) => state?.auth);

  //==============redux  end===============================

  //==============Use Effect================================
  useEffect(() => {
    dispatch(getAllAds());
    dispatch(getFeaturedAd());
    dispatch(getMostSoldAds());
    dispatch(getHomeCategories());
    dispatch(getAllBrands());
  }, [dispatch]);

  //==============Use Effect end================================

  //==============Categories filter on Click Category start=====
  // const handleClickedSearch = async (Id) => {
  //   if (Id) {
  //     await navigate("/products");
  //   }
  // };
  //==============Categories filter on Click Category start=====

  //===============Load More data Popular Ads Start===============================
  const [isPopularAd, setIsPopularAd] = useState(false);
  const [indexPopularAd, setIndexPopularAd] = useState(8);
  const initialPopularAds = slice(ads, 0, indexPopularAd);
  const loadMorePopularAds = () => {
    setIndexPopularAd(indexPopularAd + 5);
    // console.log(indexPopularAd);
    if (indexPopularAd >= ads?.length) {
      setIsPopularAd(true);
    } else {
      setIsPopularAd(false);
    }
  };
  //===============Load More data Popular Ads end===============================

  //===============Get Latest Ads start===============================
  const [latestAds, setLatestAds] = useState([]);

  useEffect(() => {
    const getLatestAds = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/product/get/latestAd"
        );
        setLatestAds(response?.data?.latestAds);
      } catch (error) {
        console.log(error.response.error);
      }
    };
    getLatestAds();
  }, []);

  //===============Get Latest Ads start===============================

  //===============Load More data Latest Ads Start===============================
  const [isLatestAd, setIsLatestAd] = useState(false);
  const [indexLatestAd, setIndexLatestAd] = useState(4);
  const initialLatestAds = slice(latestAds, 0, indexLatestAd);
  const loadMore = () => {
    setIndexLatestAd(indexLatestAd + 5);
    if (indexLatestAd >= latestAds?.length) {
      setIsLatestAd(true);
    } else {
      setIsLatestAd(false);
    }
  };
  //===============Load More data Latest Ads end===============================

  // //===============Handle Popular Ads Clicked start============================
  const handleClickSell = (e) => {
    e.preventDefault();
    navigate("/login");
  };
  // //===============Handle Popular Ads Clicked  end  ===========================

  return (
    <div>
      <Header />
      <Categories />
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="banner-heading">
                {t("Ready to Sell Your Products")}
              </h1>
              {user ? (
                <Link
                  to="/createItem"
                  className="banner-link text-light hover-shadow"
                >
                  {t("Sell Now")}
                </Link>
              ) : (
                <Link
                  to={"#"}
                  className="banner-link text-light hover-shadow"
                  onClick={handleClickSell}
                >
                  {t("Sell Now")}
                </Link>
              )}
            </div>
            <div className="col-lg-6 banner-img">
              <img src={bannerImg1} alt="" />
              <img src={bannerImg2} alt="" />
              <img src={bannerImg3} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="products container">
        <div className="products-heading">
          <h5>{t("Popular Items")}</h5>
          <Link to={`/products?Popular=Popular`}>{t("See All")}</Link>
        </div>

        <div className="row">
          {ads.length > 0 &&
            initialPopularAds?.map((ad, i) => (
              <div className="col-lg-3 col-md-6" key={i}>
                <div className="products-item shadow">
                  <Link
                    to={`/details/${ad._id}`}
                    className="product-img-container w-100"
                  >
                    {ad?.type === "Urgent" && (
                      <div className="product-ribbon urgent">{ad?.type}</div>
                    )}
                    {ad?.type === "Standard" && (
                      <div className="product-ribbon standard">{ad?.type}</div>
                    )}
                    {ad?.type === "Featured" && (
                      <div className="product-ribbon featured">{ad?.type}</div>
                    )}
                    {ad?.images ? (
                      <img
                        className="product-img"
                        src={
                          process.env.REACT_APP_IMAGES_URL + "/" + ad?.images[0]
                        }
                        alt=""
                        onError={(e) => (e.target.src = "/product.jpg")}
                      />
                    ) : (
                      <img className="product-img" src={p1} alt="" />
                    )}
                  </Link>
                  <div className="product-text">
                    <div className="product-text-top">
                      <h6>
                        <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                      </h6>
                      <div>
                        {/* <i onClick={() => setP1Like(!p1Like)}>
                            {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                          </i> */}
                        <AiFillEye className="me-2" />
                        {ad?.views?.length}
                      </div>
                    </div>
                    <div className="product-text-bottom">
                      <div className="desc">{ad?.description}</div>
                      <h6>$ {ad?.price}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {isPopularAd ? (
          <div className="load-more">
            <Link
              to={"#"}
              onClick={loadMorePopularAds}
              className="btn btn-danger disabled"
            >
              {t("NO MORE DATA")}
            </Link>
          </div>
        ) : (
          <div className="load-more">
            <Link to={"#"} onClick={loadMorePopularAds}>
              {t("LOAD MORE")}
            </Link>
          </div>
        )}
      </div>
      {featuredAds.length > 0 ? (
        <div className="products container">
          <div className="products-heading">
            <h5>{t("Featured Items")} </h5>
            <Link to={`/products?Featured=Featured`}>{t("See All")}</Link>

            {/* <Link to={`/products?${"Featured"}`}>See All</Link> */}
            {/* <Link to={"/allProducts"}>See All</Link> */}
          </div>

          <div className="row">
            {featuredAds?.map((ad, i) => (
              <div className="col-lg-3 col-md-6" key={i}>
                <div className="products-item shadow">
                  <Link
                    to={`/details/${ad._id}`}
                    className="product-img-container w-100"
                  >
                    {ad?.type === "Featured" && (
                      <div className="product-ribbon featured">{ad?.type}</div>
                    )}
                    {ad?.images ? (
                      <img
                        className="product-img"
                        src={
                          process.env.REACT_APP_IMAGES_URL + "/" + ad?.images[0]
                        }
                        alt=""
                        onError={(e) => (e.target.src = "/product.jpg")}
                      />
                    ) : (
                      <img className="product-img" src={p1} alt="" />
                    )}
                  </Link>
                  <div className="product-text">
                    <div className="product-text-top">
                      <h6>
                        <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                      </h6>
                      <div>
                        {/* <i onClick={() => setP1Like(!p1Like)}>
         {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
       </i> */}
                        <AiFillEye className="me-2" />
                        {ad?.views?.length}
                      </div>
                    </div>
                    <div className="product-text-bottom">
                      <div className="desc">{ad?.description}</div>
                      <h6>$ {ad?.price}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      <div className="products cat-cards">
        <div className="container">
          <h5 className="cat-heading">{t("Categories")} </h5>
          <div className="row">
            {homeCategories ? (
              homeCategories.map((cate, i) => (
                <div className="col-lg-4 col-md-6" key={i}>
                  <div className="cat-card">
                    <Link
                      to={`/products?Category=${cate.category._id}`}
                      className="cat-img"
                    >
                      <img
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          cate.category?.images[0]
                        }
                        alt=""
                        onError={(e) => (e.target.src = "/img03.png")}
                      />
                    </Link>
                    <div className="cat-text ms-3 my-auto">
                      <Link
                        to={`/products?Category=${cate?.category._id}`}
                        className="cat-name"
                      >
                        {cate?.category?.name}
                      </Link>
                      <p>{cate?.count} items</p>
                      {/* <p>
                        Football is the best game Football is the best game
                        Football is the best game
                      </p>
                       */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="card shadow-sm p-2 cate-card">
                <h3 className="mx-auto fs-6 my-2">
                  {t("Not Added Any Category Yet !")}
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="products brands">
        <div className="container">
          <h5 className="brands-heading">{t("Shop by brand")}</h5>

          <div className="brands-container">
            <Link to={"#"} className="brand">
              <div className="brand-top">
                {t("All Brands")}
                <span>{allBrands.length}</span>
              </div>
            </Link>

            {allBrands &&
              allBrands.map((brand, idx) => (
                <Link
                  to={`/products?Brand=${brand?.name}`}
                  className="brand"
                  key={idx}
                >
                  {brand?.totalProduct > 0 && (
                    <div className="brand-top">
                      {brand?.name}

                      <span>
                        {brand?.totalProduct} {t("Items")}
                      </span>
                    </div>
                  )}
                </Link>
              ))}
          </div>
        </div>
      </div>

      <div className="products container">
        <div className="products-heading">
          <h5>{t("Top Sellers")} </h5>
          {/* <Link to={"/allProducts"}>See All</Link> */}
        </div>

        <div className="row">
          {mostSoldAd &&
            mostSoldAd?.map((ad, i) => (
              <div className="col-lg-3 col-md-6" key={i}>
                <div className="products-item shadow">
                  <Link
                    to={`/details/${ad._id}`}
                    className="product-img-container w-100"
                  >
                    {ad?.type === "Urgent" && (
                      <div className="product-ribbon urgent">{ad?.type}</div>
                    )}
                    {ad?.type === "Standard" && (
                      <div className="product-ribbon standard">{ad?.type}</div>
                    )}
                    {ad?.type === "Featured" && (
                      <div className="product-ribbon featured">{ad?.type}</div>
                    )}
                    {ad?.images ? (
                      <img
                        className="product-img"
                        src={
                          process.env.REACT_APP_IMAGES_URL + "/" + ad?.images[0]
                        }
                        alt=""
                        onError={(e) => (e.target.src = "/product.jpg")}
                      />
                    ) : (
                      <img className="product-img" src={p1} alt="" />
                    )}
                  </Link>
                  <div className="product-text">
                    <div className="product-text-top">
                      <h6>
                        <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                      </h6>
                      <div>
                        {/* <i onClick={() => setP1Like(!p1Like)}>
                            {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                          </i> */}
                        <AiFillEye className="me-2" />
                        {ad?.views?.length}
                      </div>
                    </div>
                    <div className="product-text-bottom">
                      <div className="desc">{ad?.description}</div>
                      <h6>$ {ad?.price}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="products container">
        <div className="products-heading">
          <h5>{t("Latest Items")}</h5>
          <Link to={`/products?Latest=Latest`}>{t("See All")}</Link>

          {/* <Link to={`/products/${"Latest"}`}>See All</Link> */}

          {/* <Link to={"/allProducts"}>See All</Link> */}
        </div>

        <div className="row">
          {latestAds &&
            initialLatestAds?.map((ad, i) => (
              <div className="col-lg-3 col-md-6" key={i}>
                <div className="products-item shadow">
                  <Link
                    to={`/details/${ad._id}`}
                    className="product-img-container w-100"
                  >
                    {ad?.type === "Urgent" && (
                      <div className="product-ribbon urgent">{ad?.type}</div>
                    )}
                    {ad?.type === "Standard" && (
                      <div className="product-ribbon standard">{ad?.type}</div>
                    )}
                    {ad?.type === "Featured" && (
                      <div className="product-ribbon featured">{ad?.type}</div>
                    )}
                    {ad?.images ? (
                      <img
                        className="product-img"
                        src={
                          process.env.REACT_APP_IMAGES_URL + "/" + ad?.images[0]
                        }
                        alt=""
                        onError={(e) => (e.target.src = "/product.jpg")}
                      />
                    ) : (
                      <img className="product-img" src={p1} alt="" />
                    )}
                  </Link>
                  <div className="product-text">
                    <div className="product-text-top">
                      <h6>
                        <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                      </h6>
                      <div>
                        {/* <i onClick={() => setP1Like(!p1Like)}>
                            {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                          </i> */}
                        <AiFillEye className="me-2" />
                        {ad?.views?.length}
                      </div>
                    </div>
                    <div className="product-text-bottom">
                      <div className="desc">{ad?.description}</div>
                      <h6>$ {ad?.price}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {isLatestAd ? (
          <div className="load-more">
            <Link
              to={"#"}
              onClick={loadMore}
              className="btn btn-danger disabled"
            >
              {t("NO MORE DATA")}
            </Link>
          </div>
        ) : (
          <div className="load-more">
            <Link to={"#"} onClick={loadMore}>
              {t("LOAD MORE")}
            </Link>
          </div>
        )}
        {/* {next < latestAds?.length && (
          <div className="load-more">
            <Link to={"#"} onClick={handleMoreImage}>
              LOAD MORE
            </Link>
          </div>
        )} */}
      </div>

      <Footer />
    </div>
  );
};

export default Home;
