import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import searchService from "./searchService";

const initialState = {
  loading: false,
  data: [],
  //   searchSuggetions : [ ]
};

// Search by keyword
export const searchByKeyword = createAsyncThunk(
  "search/searchByKeyword",
  async (body) => {
    try {
      const res = await searchService.searchByKeyword(body);
      return res.data;
    } catch (err) {
      return err.response.data.error;
    }
  }
);

// Search by keyword
export const searchByCategory = createAsyncThunk(
  "search/searchByCategory",
  async (body) => {
    try {
      const res = await searchService.searchByCategory(body);
      return res.data;
    } catch (err) {
      return err.response.data.error;
    }
  }
);

// Search by Price
export const priceFilter = createAsyncThunk(
  "search/priceFilter",
  async (body) => {
    try {
      const res = await searchService.priceFilter(body);
      return res.data;
    } catch (err) {
      return err.response.data.error;
    }
  }
);

// Search by Ad Condition
export const conditionFilter = createAsyncThunk(
  "search/conditionFilter",
  async (body) => {
    try {
      const res = await searchService.conditionFilter(body);
      return res.data;
    } catch (err) {
      return err.response.data.error;
    }
  }
);

// Search From through Home page
export const homeSeached = createAsyncThunk(
  "search/homeSeached",
  async (body) => {
    try {
      const res = await searchService.homeSeached(body);
      return res.data;
    } catch (err) {
      return err.response.data.error;
    }
  }
);

export const search = createSlice({
  name: "search",
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = true;
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchByKeyword.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchByKeyword.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(searchByKeyword.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(searchByCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(searchByCategory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(conditionFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(conditionFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(conditionFilter.rejected, (state) => {
        state.loading = false;
      })
      .addCase(homeSeached.fulfilled, (state, action) => {
        if (action.payload.success === false) {
          state.data = [];
        }
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(homeSeached.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(homeSeached.rejected, (state, action) => {
        state.loading = false;
      })
      // .addCase(searchBySubCategory.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(searchBySubCategory.rejected, (state) => {
      //   state.loading = false;
      // })
      // .addCase(searchBySubCategory.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.data = action.payload;
      // })
      // .addCase(searchByAllCategories.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.data = action.payload;
      // })
      // .addCase(searchByAllCategories.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(searchByAllCategories.rejected, (state) => {
      //   state.loading = false;
      // })
      .addCase(priceFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(priceFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(priceFilter.rejected, (state) => {
        state.loading = false;
      });
    // .addCase(conditionFilter.pending, (state) => {
    //   state.loading = true;
    // })
    // .addCase(conditionFilter.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.data = action.payload;
    // })
    // .addCase(conditionFilter.rejected, (state) => {
    //   state.loading = false;
    // })
    // .addCase(fetchAds.pending, (state) => {
    //   state.loading = true;
    // })
    // .addCase(fetchAds.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.data = action.payload;
    // })
    // .addCase(fetchAds.rejected, (state) => {
    //   state.loading = false;
    // })
    // .addCase(getSuggetions.pending, (state) => {
    //   state.loading = true;
    // })
    // .addCase(getSuggetions.rejected, (state) => {
    //   state.loading = false;
    // })
    // .addCase(getSuggetions.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.searchSuggetions = action.payload;
    // })
  },
});

export const { reset } = search.actions;
export default search.reducer;
