import { useEffect, useState } from "react";
import axios from "axios";
import Moment from "moment";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
//====================React translate================================
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const { t } = useTranslation();

  //-----------------------LOADING ON DATA LOAD START---------------
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // simulate data loading
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  //-----------------------LOADING ON DATA LOAD END---------------
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    getLogsData();
  }, []);

  const [logsData, setLogsData] = useState([]);

  const getLogsData = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + "/logs/getAdminLogs";
      const response = await axios({
        method: "post",
        url: url,
        data: {
          role: user?.user?.role,
        },
        headers: {
          "x-auth-token": localStorage.token,
        },
      });
      setLogsData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card border-0 p-2">
      {loading && (
        <div className="loadingSpinnerContainer">
          <div className="loadingSpinner"></div>
        </div>
      )}
      <div className="points-heading">
        <h3 className="border-bottom w-25 border-dark py-2">
          {t("Notifications")}
        </h3>
        <h5 className="my-3">
          {t("Total Notifications")} :{" "}
          <span className="text-primary"> ({logsData.length})</span>
        </h5>
      </div>
      {!loading && (
        <>
          <div
            style={{
              height: "500px",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            {logsData &&
              logsData?.map((log, idx) => (
                <>
                  {log?.type === "Product" && (
                    <Link to={`/details/${log?.targetId}`} key={idx}>
                      <div className="point-card shadow">
                        <h6>
                          {log?.user?.name}
                          <span> {log?.activity} </span>.
                        </h6>
                        <span>
                          {" "}
                          {Moment(log?.createdAt).format(
                            "DD-MM-YYYY h:mm:ss a"
                          )}
                        </span>
                      </div>
                    </Link>
                  )}
                  {log?.type === "Ticket" && (
                    <Link to={`/dashboard/details/${log?.targetId}`} key={idx}>
                      <div className="point-card shadow">
                        <h6>
                          {log?.user?.name}
                          <span> {log?.activity} </span>.
                        </h6>
                        <span>
                          {" "}
                          {Moment(log?.createdAt).format(
                            "DD-MM-YYYY h:mm:ss a"
                          )}
                        </span>
                      </div>
                    </Link>
                  )}
                  {log?.type === "Category" && (
                    <Link to={"/dashboard/addcategory"} key={idx}>
                      <div className="point-card shadow">
                        <h6>
                          {log?.user?.name}
                          <span> {log?.activity} </span>.
                        </h6>
                        <span>
                          {" "}
                          {Moment(log?.createdAt).format(
                            "DD-MM-YYYY h:mm:ss a"
                          )}
                        </span>
                      </div>
                    </Link>
                  )}
                  {log?.type === "Sport" && (
                    <Link to={"/dashboard/addsport"} key={idx}>
                      <div className="point-card shadow">
                        <h6>
                          {log?.user?.name}
                          <span> {log?.activity} </span>.
                        </h6>
                        <span>
                          {" "}
                          {Moment(log?.createdAt).format(
                            "DD-MM-YYYY h:mm:ss a"
                          )}
                        </span>
                      </div>
                    </Link>
                  )}
                  {log?.type === "User" && (
                    <Link to={`/profile/${log?.targetId}`} key={idx}>
                      <div className="point-card shadow">
                        <h6>
                          {log?.user?.name}
                          <span> {log?.activity} </span>.
                        </h6>
                        <span>
                          {" "}
                          {Moment(log?.createdAt).format(
                            "DD-MM-YYYY h:mm:ss a"
                          )}
                        </span>
                      </div>
                    </Link>
                  )}
                  {log?.type === "Faq" && (
                    <Link to={"/faq"} key={idx}>
                      <div className="point-card shadow">
                        <h6>
                          {log?.user?.name}
                          <span> {log?.activity} </span>.
                        </h6>
                        <span>
                          {" "}
                          {Moment(log?.createdAt).format(
                            "DD-MM-YYYY h:mm:ss a"
                          )}
                        </span>
                      </div>
                    </Link>
                  )}
                </>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Notifications;
