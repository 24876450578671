import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import brandService from "./brandService";

const initialState = {
  allBrands: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All Brands
export const getAllBrands = createAsyncThunk("brand/all", async (thunkAPI) => {
  try {
    const res = await brandService.getAllBrands();
    return res?.AllBrands;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBrands.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getAllBrands.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBrands.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allBrands = action.payload;
      });
  },
});

export const { reset } = brandSlice.actions;
export default brandSlice.reducer;
