import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  cartItems: [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // addToCart(state, action) {
    //   const existingIndex = state.cartItems.findIndex(
    //     (cartItem) => cartItem._id === action.payload._id
    //   );

    //   if (existingIndex > -1) {
    //     state.cartItems[existingIndex] = {
    //       ...state.cartItems[existingIndex],
    //       cartQuantity: state.cartItems[existingIndex].cartQuantity + 1,
    //     };
    //     toast.info("Increased Item Quantity");
    //   } else {
    //     let tempProductItem = { ...action.payload, cartQuantity: 1 };
    //     state.cartItems.push(tempProductItem);
    //     toast.success("Item added to cart");
    //   }
    // },
    addToCart(state, action) {
      const existingIndex = state.cartItems.findIndex(
        (cartItem) => cartItem._id === action.payload._id
      );

      if (existingIndex > -1) {
        toast.error("Item already in cart");
      } else {
        let tempProductItem = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProductItem);
        toast.success("Item added to cart");
      }
    },

    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (cartItem) => cartItem._id === action.payload._id
      );

      if (state.cartItems[itemIndex].cartQuantity === 1) {
        state.cartItems.splice(itemIndex, 1);
        toast.error("Item removed from cart");
      } else if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;
        toast.info("Decreased item quantity");
      }
    },

    removeFromCart(state, action) {
      state.cartItems.map((cartItem) => {
        if (cartItem.id === action.payload.id) {
          const nextCartItems = state.cartItems.filter(
            (cartItem) => cartItem.id !== action.payload.id
          );
          state.cartItems = nextCartItems;
          toast.error("Item removed from cart");
        }
        return state;
      });
    },

    getTotals(state, action) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { price, cartQuantity } = cartItem;
          const itemTotal = price * cartQuantity;
          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;
          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );

      total = parseInt(total.toFixed(2));
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },

    clearCart(state, action) {
      state.cartItems = [];
      toast.error("Cart cleared");
    },
  },
});

export const { addToCart, decreaseCart, removeFromCart, getTotals, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;
