import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import user from "../assets/images/user1.jpg";
import p1 from "../assets/images/p5.jpg";
import axios from "axios";
import Moment from "moment";
//============Import Pagination Page==============
import Pagination from "../pages/Pagination";
import MyLoader from "../pages/MyLoader";

import { getWalletBalanace } from "../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
//----------react-translate------------
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();

  //-----------------------LOADING ON DATA LOAD START---------------
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // simulate data loading
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  //-----------------------LOADING ON DATA LOAD END---------------
  //==================Utilities Start================================
  const dispatch = useDispatch();
  const { walletBalance } = useSelector((state) => {
    return state.auth;
  });

  let walletAmount = walletBalance.length ? walletBalance[0].allamount : 0;

  useEffect(() => {
    dispatch(getWalletBalanace());
  }, [dispatch]);
  //==================Utilities end==================================

  //==================Get Admin Dashboard states start===============
  const [adminStates, setAdminStates] = useState();
  const [visitosData, setVisitorsData] = useState([]);
  const [topSearches, setTopSearches] = useState([]);
  const [topSeller, setTopSeller] = useState([]);
  const [allSearches, setAllSearches] = useState([]);
  const [usersByCity, setAllUsersByCity] = useState([]);
  const [usersByCountry, setAllUsersByCountry] = useState([]);

  useEffect(() => {
    getAdminStates();
  }, []);
  const getAdminStates = async () => {
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/user/get/OtherStats",
        config
      );
      if (res.data) {
        setAdminStates(res.data);
        setVisitorsData(res.data?.visitors);
        setTopSearches(res.data?.topSearches);
        setAllSearches(res.data?.searches);
        setTopSeller(res.data?.topSeller);
        setAllUsersByCountry(res.data?.userProfile[0]?.usersByCountry);
        setAllUsersByCity(res.data?.userProfile[0]?.usersByCity);
      }
    } catch (err) {
      console.log(err.response.data.error);
    }
  };
  //==================Get Admin Dashboard states end===============

  //==================Get Admin Dashboard Products states start===============
  const [activeAds, setActiveAds] = useState();
  const [soldAds, setSoldAds] = useState([]);
  const [inactiveAds, setInavtiveAds] = useState([]);

  useEffect(() => {
    const getProductStates = async () => {
      try {
        const config = {
          headers: {
            "x-auth-token": localStorage.token,
          },
        };
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/product/getAdsStats",
          config
        );
        // console.log("Response", res);
        if (res.data) {
          setActiveAds(res.data?.activeAds);
          setSoldAds(res.data?.soldAds);
          setInavtiveAds(res.data?.withdrawnAds);
        }
      } catch (err) {
        console.log(err.response.data.error);
      }
    };
    getProductStates();
  }, []);
  //==================Get Admin Dashboard Products states end===============

  //==================Get Admin Dashboard Tickets states start===============
  const [pendingTickets, setPendingTickets] = useState([]);
  const [resolvedTickets, setResolvedTickets] = useState([]);
  const [cancelledTickets, setCancelledTickets] = useState([]);

  useEffect(() => {
    const getTicketsStates = async () => {
      try {
        const config = {
          headers: {
            "x-auth-token": localStorage.token,
          },
        };
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/ticket/ticketStates",
          config
        );
        // console.log("Response", res);
        if (res.data) {
          setPendingTickets(res.data?.pendingTickets);
          setResolvedTickets(res.data?.resolvedTickets);
          setCancelledTickets(res.data?.cancelledTickets);
        }
      } catch (err) {
        console.log(err.response.data.error);
      }
    };
    getTicketsStates();
  }, []);
  //==================Get Admin Dashboard Tickets states end===============

  // //==============Pagination  START=============================

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = topSearches?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(topSearches?.length / recordsPerPage);
  // //==============Pagination  END=============================

  return (
    <div>
      {loading && <MyLoader />}
      {!loading && (
        <>
          <div className="row">
            <div className="col-6 col-lg-2 mb-2">
              <Link to={"/dashboard/users"}>
                <div className="container-card">
                  <div className="content">
                    <p className="text-center">{t("Overall Users")} </p>
                    <h1 className="text-center">{adminStates?.users}</h1>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-lg-2 mb-2">
              <Link to={"/dashboard/products"}>
                <div className="container-card">
                  <div className="content">
                    <p className="text-center">{t("Total Products")}</p>
                    <h1 className="text-center">{adminStates?.totalAds}</h1>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-6 col-lg-2 mb-2">
              <div className="container-card">
                <div className="content">
                  <p className="text-center">{t("Shared Products")}</p>
                  <h1 className="text-center">{adminStates?.shares}</h1>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-2 mb-2">
              <div className="container-card">
                <div className="content">
                  <p className="text-center">{t("Total Visitors")}</p>
                  <h1 className="text-center">
                    {adminStates?.visitors?.length}
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-2 mb-2">
              <Link to={"/dashboard/tickets"}>
                <div className="container-card">
                  <div className="content">
                    <p className="text-center">{t("Total Tickets")}</p>
                    <h1 className="text-center">{adminStates?.totalTickets}</h1>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-lg-2 mb-2">
              <Link to={"/dashboard/notifications"}>
                <div className="container-card">
                  <div className="content">
                    <p className="text-center">{t("Total Activities")}</p>
                    <h1 className="text-center">
                      {adminStates?.totalActivities}
                    </h1>
                  </div>
                </div>
              </Link>
            </div>

            {/* Product States start */}
            <div className="col-6 col-lg-4 mb-2">
              <div className="container-card card-height">
                <div className="content">
                  <p className="text-center">{t("Active Products")}</p>
                  {activeAds &&
                    activeAds.map((ActiveAd) => (
                      <>
                        {ActiveAd?._id?.type === "Standard" && (
                          <div className="d-flex justify-content-between pb-1">
                            <div className="font-weight-bold">
                              {ActiveAd?._id?.type}
                            </div>
                            <div className="text-center font-weight-bold">
                              {ActiveAd?.count}
                            </div>
                          </div>
                        )}
                        {ActiveAd?._id?.type === "Featured" && (
                          <div className="d-flex justify-content-between pb-1">
                            <div className="font-weight-bold">
                              {ActiveAd?._id?.type}
                            </div>
                            <div className="text-center font-weight-bold">
                              {ActiveAd?.count}
                            </div>
                          </div>
                        )}
                        {ActiveAd?._id?.type === "Urgent" && (
                          <div className="d-flex justify-content-between pb-1">
                            <div className="font-weight-bold">
                              {ActiveAd?._id?.type}
                            </div>
                            <div className="text-center font-weight-bold">
                              {ActiveAd?.count}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>

            <div className="col-6 col-lg-4 mb-2">
              <div className="container-card card-height">
                <div className="content">
                  <p className="text-center">{t("Sold Products")}</p>
                  {soldAds &&
                    soldAds.map((soldAd) => (
                      <>
                        {soldAd?._id?.type === "Standard" && (
                          <div className="d-flex justify-content-between pb-1">
                            <div className="font-weight-bold">
                              {soldAd?._id?.type}
                            </div>
                            <div className="text-center font-weight-bold">
                              {soldAd?.count}
                            </div>
                          </div>
                        )}
                        {soldAd?._id?.type === "Featured" && (
                          <div className="d-flex justify-content-between pb-1">
                            <div className="font-weight-bold">
                              {soldAd?._id?.type}
                            </div>
                            <div className="text-center font-weight-bold">
                              {soldAd?.count}
                            </div>
                          </div>
                        )}
                        {soldAd?._id?.type === "Urgent" && (
                          <div className="d-flex justify-content-between pb-1">
                            <div className="font-weight-bold">
                              {soldAd?._id?.type}
                            </div>
                            <div className="text-center font-weight-bold">
                              {soldAd?.count}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>

            <div className="col-6 col-lg-4 mb-2">
              <div className="container-card card-height">
                <div className="content">
                  <p className="text-center">{t("InActive Products")}</p>
                  {inactiveAds &&
                    inactiveAds.map((inactiveAd) => (
                      <>
                        {inactiveAd?._id?.type === "Standard" && (
                          <div className="d-flex justify-content-between pb-1">
                            <div className="font-weight-bold">
                              {inactiveAd?._id?.type}
                            </div>
                            <div className="text-center font-weight-bold">
                              {inactiveAd?.count}
                            </div>
                          </div>
                        )}
                        {inactiveAd?._id?.type === "Featured" && (
                          <div className="d-flex justify-content-between pb-1">
                            <div className="font-weight-bold">
                              {inactiveAd?._id?.type}
                            </div>
                            <div className="text-center font-weight-bold">
                              {inactiveAd?.count}
                            </div>
                          </div>
                        )}
                        {inactiveAd?._id?.type === "Urgent" && (
                          <div className="d-flex justify-content-between pb-1">
                            <div className="font-weight-bold">
                              {inactiveAd?._id?.type}
                            </div>
                            <div className="text-center font-weight-bold">
                              {inactiveAd?.count}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>

            {/* Product States end */}

            {/* Tickets States start */}
            <div className="col-6 col-lg-4 mb-2">
              <Link to={"/dashboard/tickets"}>
                <div className="container-card card-height">
                  <div className="content">
                    <p className="text-center">{t("Pending Tickets")}</p>
                    {pendingTickets[0]?._id?.status === "Pending" && (
                      <div className="d-flex justify-content-between pb-1">
                        <div className="font-weight-bold">
                          {pendingTickets[0]?._id?.status}
                        </div>
                        <div className="text-center font-weight-bold">
                          {pendingTickets[0]?.count}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-lg-4 mb-2">
              <Link to={"/dashboard/tickets"}>
                <div className="container-card card-height">
                  <div className="content">
                    <p className="text-center">{t("Resolved Tickets")}</p>

                    {resolvedTickets[0]?._id?.status === "Resolved" && (
                      <div className="d-flex justify-content-between pb-1">
                        <div className="font-weight-bold">
                          {resolvedTickets[0]?._id?.status}
                        </div>
                        <div className="text-center font-weight-bold">
                          {resolvedTickets[0]?.count}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-lg-4 mb-2">
              <Link to={"/dashboard/tickets"}>
                <div className="container-card card-height">
                  <div className="content">
                    <p className="text-center">{t("Cancelled Tickets")}</p>

                    {cancelledTickets[0]?._id?.status === "Cancelled" && (
                      <div className="d-flex justify-content-between pb-1">
                        <div className="font-weight-bold">
                          {cancelledTickets[0]?._id?.status}
                        </div>
                        <div className="text-center font-weight-bold">
                          {cancelledTickets[0]?.count}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </div>

            {/* Tickets States end */}

            <div className="col-6 col-lg-4 mb-2">
              <div className="container-card">
                <div className="content">
                  <p className="text-center">{t("Total Earned")}</p>
                  <h1 className="text-center">$ 2206</h1>
                  <h6 className="text-center">{t("last 15 days")}</h6>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 mb-2">
              <div className="container-card">
                <div className="content">
                  <p className="text-center">{t("Total Order tax")}</p>
                  <h1 className="text-center">$ 2206</h1>
                  <h6 className="text-center">{t("last 15 days")}</h6>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 mb-2">
              <div className="container-card">
                <div className="content">
                  <p className="text-center">{t("Total Commision")}</p>
                  <h1 className="text-center">$ {walletAmount.toFixed(2)}</h1>
                  <h6 className="text-center">{t("last 15 days")}</h6>
                </div>
              </div>
            </div>

            {/* Top Searches (Keywords) start */}

            <div className="col-12 col-lg-12 mb-2 ">
              <div className="table-responsive card border-0">
                <h4 className="p-2">{t("Top Searches (Keywords)")}</h4>

                <table className="table shop-table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">{t("Keyword")}</th>
                      <th className="text-center">{t("Frequency (count)")}</th>
                      <th className="text-center">{t("Created At")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topSearches &&
                      currentRecords.map((top, i) => (
                        <tr key={i}>
                          <td className="text-center">{top?.keyword}</td>
                          <td className="text-center">{top?.frequency}</td>
                          <td className="text-center">
                            {Moment(top?.createdAt).format(
                              "DD-MM-YYYY h:mm:ss a"
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
            {/* Top Searches (Keywords) end */}

            {/* Users By City start */}

            <div className="col-12 col-lg-6 mb-2 ">
              <div className="table-responsive card border-0">
                <h4 className="p-2">{t("Users By City")}</h4>

                <table className="table shop-table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">{t("City Name")}</th>
                      <th className="text-center">{t("Frequency (count)")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersByCity &&
                      usersByCity.map((userCity, i) => (
                        <tr key={i}>
                          <td className="text-center">{userCity?._id?.city}</td>
                          <td className="text-center">{userCity?.count}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Users By City end */}

            {/* Users By Country start */}

            <div className="col-12 col-lg-6 mb-2 ">
              <div className="table-responsive card border-0">
                <h4 className="p-2">{t("Users By Country")}</h4>

                <table className="table shop-table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">{t("Country Name")}</th>
                      <th className="text-center">{t("Frequency (count)")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersByCountry &&
                      usersByCountry.map((userCountry, i) => (
                        <tr key={i}>
                          <td className="text-center">
                            {userCountry?._id?.state}
                          </td>
                          <td className="text-center">{userCountry?.count}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Users By Country end */}

            {/* Searches (SUCCESS/FAILURE) start */}

            <div className="col-12 col-lg-12 mb-2 ">
              <div className="container-fluid categories-container card">
                <h4 className="p-2">{t("All Searches (Success/Failed)")}</h4>

                <div className="container border-bottom pb-2">
                  <h6 className="mb-2">{t("Failed")}</h6>

                  <div className="categories">
                    {allSearches &&
                      allSearches.map((search, i) => (
                        <div key={i}>
                          {search?.type === "failed" &&
                            search?.keywords.map((keyw) => (
                              <>
                                {keyw.split(",").map((op) => (
                                  <>
                                    <Link className="category">{op}</Link>
                                  </>
                                ))}
                              </>
                            ))}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="container mt-2">
                  <h6 className="">{t("Success")}</h6>

                  <div className="categories">
                    {allSearches &&
                      allSearches.map((search, i) => (
                        <div key={i}>
                          {search?.type === "success" &&
                            search?.keywords.map((keyw) => (
                              <>
                                {keyw.split(",").map((op) => (
                                  <>
                                    <Link className="category">{op}</Link>
                                  </>
                                ))}
                              </>
                            ))}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {/* Searches (SUCCESS/FAILURE)end */}

            <div className="col-12 col-lg-6 mb-2">
              <div className="card border-0 p-3">
                <div className="content">
                  <h4 className="text-start mb-3 border-bottom pb-2">
                    {t("Top Products")}
                  </h4>
                  <div className="d-flex justify-content-between border-bottom my-2 pb-1">
                    <div className="">
                      <img
                        src={p1}
                        className="cust-img rounded-circle me-2"
                        alt=""
                      />
                      <span>{t("Tennis Ball")}</span>
                    </div>
                    <div className="">
                      <h6>{t("Sales")}</h6>
                      {/* <br /> */}
                      <small>2</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between border-bottom my-2 pb-1">
                    <div className="">
                      <img
                        src={p1}
                        className="cust-img rounded-circle me-2"
                        alt=""
                      />
                      <span>{t("Basket Ball")}</span>
                    </div>
                    <div className="">
                      <h6>{t("Sales")}</h6>
                      {/* <br /> */}
                      <small>9</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between border-bottom my-2 pb-1">
                    <div className="">
                      <img
                        src={p1}
                        className="cust-img rounded-circle me-2"
                        alt=""
                      />
                      <span>{t("Cricket Ball")}</span>
                    </div>
                    <div className="">
                      <h6>{t("Sales")}</h6>
                      {/* <br /> */}
                      <small>4</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-2">
              <div className="card border-0 p-3">
                <div className="content">
                  <div className="d-flex jutify-content-between mb-3 border-bottom pb-2">
                    <div>
                      <h4>{t("Top Seller")}</h4>
                    </div>
                    <div className="ms-auto">
                      <h4>{t("Product Sold")}</h4>
                    </div>
                  </div>

                  {topSeller &&
                    topSeller?.map((top, idx) => (
                      <div
                        className="d-flex justify-content-between border-bottom my-2 pb-1"
                        key={idx}
                      >
                        <div className="">
                          <img
                            src={
                              process.env.REACT_APP_IMAGES_URL +
                              "/" +
                              top?.sellerInfo?.image
                            }
                            onError={(e) => (e.target.src = "/user.png")}
                            className="cust-img rounded-circle me-2"
                            alt=""
                          />
                          <span>{top?.sellerInfo?.name}</span>
                        </div>
                        <div className="">
                          {/* <br /> */}
                          <small className="text-center">
                            {top?.totalSales}
                          </small>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {/* Visitors Ip (Start End Date) start */}

            <div className="col-12 col-lg-6 mb-2">
              <div
                className="card border-0 p-3"
                style={{ height: "300px", overflow: "auto" }}
              >
                <div className="content">
                  <h4 className="text-start mb-3 border-bottom pb-2">
                    {t("Visitors Ip")}
                  </h4>

                  <div className="d-flex justify-content-between border-bottom my-2 pb-1">
                    <div className="h5">{t("Ip")}</div>
                    <div className="h5">{t("Count")}</div>
                  </div>
                  {visitosData &&
                    visitosData.map((visitos, index) => (
                      <div
                        className="d-flex justify-content-between border-bottom my-2 pb-1"
                        key={index}
                      >
                        <div className="">
                          <h6 className="mb-0">{visitos?.ip}</h6>

                          <small className="text-primary fs-8rem">
                            {t("Session Start")} (
                            {Moment(visitos?.sessionStart).format(
                              "DD-MM-YYYY hh:mm"
                            )}
                            )
                          </small>
                        </div>
                        <div className="">
                          <h6 className="text-end mb-0">{visitos?.count}</h6>
                          <small className="text-info fs-8rem">
                            {t("Session End")} (
                            {Moment(visitos?.sessionEnd).format(
                              "DD-MM-YYYY hh:mm:ss"
                            )}
                            )
                          </small>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {/* Visitors Ip (Start End Date) end */}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
