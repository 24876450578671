import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal, ModalBody } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";

import p1 from "../assets/images/p1.jpg";
import { AiFillEye } from "react-icons/ai";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
//---------trnslate
import { useTranslation } from "react-i18next";

const Orders = () => {
  const { t } = useTranslation();

  const [ads, setAds] = useState(1);

  const { user } = useSelector((state) => {
    return state.auth;
  });

  //---------------Get Personal Ads Ads start================================
  useEffect(() => {
    getOrderAds();
  }, []);

  const [orders, setOrders] = useState([]);
  const [sellerIds, setsellerIds] = useState([]);
  const [markedDeleivered, setMarkedDeleivered] = useState([]);
  const [isIncludeId, setIsIncludeId] = useState(false);

  useEffect(() => {
    if (orders.length) {
      for (let i = 0; i < orders.length; i++) {
        setsellerIds(orders[i].sellerId);
        setMarkedDeleivered(orders[i].sellersmarkedcomplete);
      }
    }

    for (var i = 0; i < sellerIds.length; i++) {
      if (markedDeleivered.includes(sellerIds[i])) {
        setIsIncludeId(true);
      } else {
        setIsIncludeId(false);
      }
    }
  }, [orders, markedDeleivered, sellerIds]);

  const getOrderAds = async () => {
    const body = {
      userId: user?.user?._id,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/user/fetchOrder",
      body,
      config
    );
    setOrders(response?.data);
  };

  let sum = 0;
  orders.forEach((order) => {
    order.orderItems.forEach((item) => {
      sum += parseInt(item.cartQuantity ? item.cartQuantity : 1);
    });
  });

  //---------------Get sold Ads end================================

  //--------------Handle Clear orders start========================
  const handleClearOrders = async () => {
    const body = {
      user: user?.user?._id,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/user/deleteOrder",
      body,
      config
    );

    if (response?.data?.success === true) {
      toast.success(response.data?.message);
      getOrderAds();
    }

    // return response?.data;
  };
  //--------------Handle Clear orders end================================

  //=============Review system===============================
  //==============Review An Ad Modal and Api Start====================
  const [reviewAdModal, setReviewAdModal] = useState(false);
  const [textReview, setTextReview] = useState("");
  const [sellerid, setSellerid] = useState("");
  const [productid, setProductid] = useState("");
  const [orderid, setOrderid] = useState("");
  const [rating, setRating] = useState(0);
  // console.log("rating: ", rating);
  // const ratings = allReview.map((review) => review.rating);
  // const sumRating = ratings.reduce((acc, cur) => acc + cur, 0);
  // const averageRating = sum / ratings.length;

  function tog_review_ad(orderId, productId, sellerid) {
    setSellerid(sellerid);
    setOrderid(orderId);
    setProductid(productId);
    setTextReview("");
    setRating(0);
    setReviewAdModal(!reviewAdModal);
  }

  const reviewAd = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    try {
      if (textReview === "") {
        toast.error("Text is required");
      } else {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/product/reviewAd",
          {
            orderId: orderid,
            buyerId: user?.user._id, // Who review this Ad
            sellerId: sellerid,
            productId: productid,
            rating: rating,
            comment: textReview,
          },
          config
        );

        if (res.data.success === true) {
          toast.success("Review Added Successfully !");
          setTextReview("");
          setRating(0);
          setReviewAdModal(!reviewAdModal);
        }
        if (res.data.success === false) {
          toast.error(res.data.msg);
          setTextReview("");
          setRating(0);
        }
      }
    } catch (err) {
      console.log(err.response.date.error);
    }
  };
  //==============Review An Ad Modal and Api END====================

  return (
    <div>
      <div className="orders-top">
        <h3>{t("My Orders")}</h3>
        <p>
          {t("View all your pending, delivered, and returned orders here.")}{" "}
        </p>
        <div className="orders-container-top my-2">
          <h5 className="m-0  text-nowrap">
            {" "}
            {orders.length} {orders.length > 1 ? " Orders " : " Order "}
            {t("with")} ({sum}) {t("Products")}
          </h5>

          <Link className="text-dark" onClick={handleClearOrders}>
            {t("Clear all")}
          </Link>
        </div>
      </div>

      {/* <div className="orders-container"></div> */}
      {/* Tabs Start */}
      <div className="ads-tabs">
        <button className={ads === 1 ? "active" : ""} onClick={() => setAds(1)}>
          {t("Pending Orders")}
        </button>
        <button className={ads === 2 ? "active" : ""} onClick={() => setAds(2)}>
          {t("Delivered Orders")}
        </button>
      </div>

      {/* Tab 1 ANd 2 */}

      {ads === 1 && (
        <div>
          <div className="row">
            {orders &&
              orders.map((order) => (
                <div className="row">
                  {/* {!isIncludeId && ( */}
                  <>
                    {order?.orderItems.map(
                      (item, idx) =>
                        !order?.sellersmarkedcomplete.includes(
                          item.user._id
                        ) && (
                          <>
                            <div
                              className="col-lg-4 col-md-6 mx-auto "
                              key={idx}
                            >
                              <div className="products-item shadow">
                                <Link
                                  to={`/details/${item._id}`}
                                  className="product-img-container w-100"
                                >
                                  {item?.type === "Urgent" && (
                                    <div className="product-ribbon urgent">
                                      {item?.type}
                                    </div>
                                  )}
                                  {item?.type === "Standard" && (
                                    <div className="product-ribbon standard">
                                      {item?.type}
                                    </div>
                                  )}
                                  {item?.type === "Featured" && (
                                    <div className="product-ribbon featured">
                                      {item?.type}
                                    </div>
                                  )}
                                  {item?.cartQuantity ? (
                                    <div className="product-quantity text-center">
                                      {item?.cartQuantity} {t("Quantity")}
                                    </div>
                                  ) : (
                                    <div className="product-quantity text-center">
                                      {1} {t("Quantity")}
                                    </div>
                                  )}
                                  {item?.images ? (
                                    <img
                                      className="product-img"
                                      src={
                                        process.env.REACT_APP_IMAGES_URL +
                                        "/" +
                                        item?.images[0]
                                      }
                                      onError={(e) =>
                                        (e.target.src = "/product.jpg")
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="product-img"
                                      src={p1}
                                      alt=""
                                    />
                                  )}
                                </Link>
                                <div className="product-text">
                                  <div className="product-text">
                                    <div className="product-text-top">
                                      <h6>
                                        <Link to={`/details/${item._id}`}>
                                          {item?.name}
                                        </Link>
                                      </h6>
                                      <div>
                                        <AiFillEye className="me-2" />
                                        {item?.views?.length}
                                      </div>
                                    </div>
                                    <div className="product-text-bottom">
                                      <div className="desc">
                                        {item?.description}
                                      </div>
                                      <h6>
                                        ${" "}
                                        {item?.cartQuantity
                                          ? item?.price
                                          : order?.totalPrice}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center my-2 border-bottom border-dark pb-2">
                              <Link
                                to={`/invoice/${order?._id}`}
                                className="view-invoice-btn hover-shadow"
                              >
                                {t("View Invoice")}
                              </Link>
                            </div>
                          </>
                        )
                    )}
                  </>
                  {/* // )} */}

                  {/* {!isIncludeId && (
                 
                )} */}
                </div>
              ))}
          </div>
        </div>
      )}
      {ads === 2 && (
        <div>
          {orders &&
            orders.map((order) => (
              <div className="row">
                {/* {!isIncludeId && ( */}
                <>
                  {order?.orderItems.map(
                    (item, idx) =>
                      order?.sellersmarkedcomplete.includes(item.user._id) && (
                        <>
                          <div className="col-lg-4 col-md-6 mx-auto " key={idx}>
                            <div className="products-item shadow">
                              <Link
                                to={`/details/${item._id}`}
                                className="product-img-container w-100"
                              >
                                {item?.type === "Urgent" && (
                                  <div className="product-ribbon urgent">
                                    {item?.type}
                                  </div>
                                )}
                                {item?.type === "Standard" && (
                                  <div className="product-ribbon standard">
                                    {item?.type}
                                  </div>
                                )}
                                {item?.type === "Featured" && (
                                  <div className="product-ribbon featured">
                                    {item?.type}
                                  </div>
                                )}
                                {item?.cartQuantity ? (
                                  <div className="product-quantity text-center">
                                    {item?.cartQuantity} {t("Quantity")}
                                  </div>
                                ) : (
                                  <div className="product-quantity text-center">
                                    {1} {t("Quantity")}
                                  </div>
                                )}
                                {item?.images ? (
                                  <img
                                    className="product-img"
                                    src={
                                      process.env.REACT_APP_IMAGES_URL +
                                      "/" +
                                      item?.images[0]
                                    }
                                    onError={(e) =>
                                      (e.target.src = "/product.jpg")
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="product-img"
                                    src={p1}
                                    alt=""
                                  />
                                )}
                              </Link>
                              <div className="product-text">
                                <div className="product-text">
                                  <div className="product-text-top">
                                    <h6>
                                      <Link to={`/details/${item._id}`}>
                                        {item?.name}
                                      </Link>
                                    </h6>
                                    <div>
                                      <AiFillEye className="me-2" />
                                      {item?.views?.length}
                                    </div>
                                  </div>
                                  <div className="product-text-bottom">
                                    <div className="desc">
                                      {item?.description}
                                    </div>
                                    <h6>
                                      ${" "}
                                      {item?.cartQuantity
                                        ? item?.price
                                        : order?.totalPrice}
                                    </h6>
                                  </div>
                                  <div className="d-flex justify-content-between mt-1">
                                    <button
                                      className="add-feedback-btn green-btn hover-shadow"
                                      onClick={() =>
                                        tog_review_ad(
                                          order._id,
                                          item._id,
                                          item?.user?._id
                                        )
                                      }
                                    >
                                      {t("Add Feedback/Review")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                  )}
                  <div className="d-flex justify-content-center my-2 border-bottom border-dark pb-2">
                    <Link
                      to={`/invoice/${order?._id}`}
                      className="view-invoice-btn hover-shadow"
                    >
                      {t("View Invoice")}
                    </Link>
                  </div>
                </>
                {/* // )} */}

                {/* {!isIncludeId && (
                 
                )} */}
              </div>
            ))}
        </div>
      )}

      {/* <div className="orders-container">
        <div className="orders-container-top">
          <h5 className="m-0 border-bottom pb-2 w-25 border-dark">
            {t("Pending")}
          </h5>
        </div>

      
      </div>
      <div className="orders-container">
        <div className="orders-container-top">
          <h5 className="m-0 border-bottom pb-2 w-25 border-dark">
            {t("Delivered")}
          </h5>
        </div>
    
      </div> */}

      {/* REVIEW MODAL START*/}
      <Modal
        className="fade"
        show={reviewAdModal}
        onHide={() => {
          tog_review_ad();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            {t("Add Feedback")}
          </h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setReviewAdModal(false);
            }}
          />
        </div>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="form-field">
                <label>{t("Rating")}</label>
                <div className="rating-box">
                  <div className="rating-container">
                    <ReactStars
                      count={5}
                      // value={review.rating}
                      onChange={setRating}
                      size={20}
                      isHalf={true}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ffd700"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <textarea
            className="message-modal-input"
            placeholder={t("Send Review")}
            value={textReview}
            onChange={(e) => setTextReview(e.target.value)}
          ></textarea>
          <button
            className="message-modal-btn hover-shadow green-btn"
            onClick={reviewAd}
          >
            {t("Send")}
          </button>
        </ModalBody>
      </Modal>

      {/* Review MODAL END*/}

      {/* <div
        className="modal fade"
        id="feedbackModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add Feedback
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-field">
                    <label>Rating</label>
                    <div className="rating-box">
                      <div className="rating-container">
                        <input
                          type="radio"
                          name="rating"
                          value="5"
                          id="star-5"
                        />{" "}
                        <label htmlFor="star-5">&#9733;</label>
                        <input
                          type="radio"
                          name="rating"
                          value="4"
                          id="star-4"
                        />{" "}
                        <label htmlFor="star-4">&#9733;</label>
                        <input
                          type="radio"
                          name="rating"
                          value="3"
                          id="star-3"
                        />{" "}
                        <label htmlFor="star-3">&#9733;</label>
                        <input
                          type="radio"
                          name="rating"
                          value="2"
                          id="star-2"
                        />{" "}
                        <label htmlFor="star-2">&#9733;</label>
                        <input
                          type="radio"
                          name="rating"
                          value="1"
                          id="star-1"
                        />{" "}
                        <label htmlFor="star-1">&#9733;</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-field">
                    <label>Description</label>
                    <div className="form-input form-textarea">
                      <textarea placeholder="Description"></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-end mt-3">
                  <button
                    className="form-save green-btn hover-shadow"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Orders;
