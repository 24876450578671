import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import p1 from "../assets/images/p5.jpg";
import { AiFillEye, AiFillDelete, AiFillEdit } from "react-icons/ai";
import { FaBan } from "react-icons/fa";
import { GoReport } from "react-icons/go";
import { VscVmActive } from "react-icons/vsc";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import axios from "axios";
import Moment from "moment";

import {
  getAdminProducts,
  markAsBanned,
  markAsDelete,
  markAsUnBanned,
  getMostSoldAds,
  getAdById,
} from "../features/ad/adSlice";

import Pagination from "../pages/Pagination";
//============redux import============
import { useDispatch, useSelector } from "react-redux";
//====================React translate================================
import { useTranslation } from "react-i18next";

const Products = () => {
  const { t } = useTranslation();
  //==================redux hooks start=================
  const dispatch = useDispatch();
  const [adId, setAdId] = useState();
  const [ads, setAds] = useState(1);

  const { user } = useSelector((state) => {
    return state.auth;
  });
  //   console.log("User", user?.user?.role);
  const { adminProducts, mostSoldAd, Ad } = useSelector((state) => {
    return state.ads;
  });

  //==============Get single Ad start==================================

  useEffect(() => {
    dispatch(getAdById(adId));
  }, [dispatch, adId]);

  //==============Get single Ad end==================================

  //==================redux hooks end=================================

  //==================Use Effect start (Get All Products)=================
  useEffect(() => {
    let body = {
      role: user?.user?.role,
    };
    dispatch(getAdminProducts(body));
    dispatch(getMostSoldAds());
  }, [dispatch, user]);

  //==================Use Effect end (Get All Products)=================

  //==============handleBanAd Item/Products Api Start====================

  const [banAdModal, setBanAdModal] = useState(false);
  function tog_BanModal(id) {
    setAdId(id);
    setBanAdModal(!banAdModal);
  }
  const handleBanAd = (e) => {
    e.preventDefault();
    let body = {
      role: user?.user?.role,
      user: user?.user?._id,
      adId: adId,
    };
    dispatch(markAsBanned(body));
    setBanAdModal(!banAdModal);
  };
  //==============handleDeActiveAd Item/Products Api end====================

  //==============handleUnBanAd Item/Products Api Start====================
  const handleUnBanAd = (e, id) => {
    e.preventDefault();
    let body = {
      role: user?.user?.role,
      user: user?.user?._id,
      adId: id,
    };
    dispatch(markAsUnBanned(body));
  };
  //==============handleDeActiveAd Item/Products Api end====================
  //==============Delete Item/Products Api Start====================
  const [deleteAdModal, setDeleteAdModal] = useState(false);
  function tog_DeleteModal(id) {
    setAdId(id);
    setDeleteAdModal(!deleteAdModal);
  }

  const handleDeleteAd = (e) => {
    e.preventDefault();
    let body = {
      role: user?.user?.role,
      user: user?.user?._id,
      adId: adId,
    };
    dispatch(markAsDelete(body));
    setDeleteAdModal(!deleteAdModal);
  };
  //==============Delete Item/Products Api Start====================

  //=============Users feedback Start =================
  const [showFeedback, setShowFeedback] = useState(false);
  const [allReports, setAllReports] = useState([]);

  async function tog_feedbacks(id) {
    setAdId(id);
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/product/getAdReports",
        {
          adId: id,
        },
        config
      );
      setAllReports(res.data);
    } catch (err) {
      console.log(err.response.date.error);
    }
    setShowFeedback(!showFeedback);
  }
  //--------------------Get Reports on Ad End ------------------

  // //==============Pagination  START=============================

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = adminProducts?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(adminProducts?.length / recordsPerPage);
  // //==============Pagination  END=============================

  return (
    <div>
      <div className="profile-infos d-block">
        {/* <h5 className="withdraw-title">{t("Products Without Sold Items")}</h5> */}
        <div className="ads-tabs">
          <button
            className={ads === 1 ? "active" : ""}
            onClick={() => setAds(1)}
          >
            {t("Active Products")}
          </button>
          <button
            className={ads === 2 ? "active" : ""}
            onClick={() => setAds(2)}
          >
            {t("Sold Products")}
          </button>
        </div>
        {ads === 1 && (
          <>
            <div className="row">
              {adminProducts?.length > 0 ? (
                currentRecords?.map((ad, i) => (
                  <div className="col-lg-4 col-md-6" key={i}>
                    <div className="products-item shadow">
                      <Link
                        to={`/details/${ad._id}`}
                        className="product-img-container w-100"
                      >
                        {ad?.type === "Urgent" && (
                          <div className="product-ribbon urgent">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.type === "Standard" && (
                          <div className="product-ribbon standard">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.type === "Featured" && (
                          <div className="product-ribbon featured">
                            {ad?.type}
                          </div>
                        )}
                        {ad?.images ? (
                          <img
                            className="product-img"
                            src={
                              process.env.REACT_APP_IMAGES_URL +
                              "/" +
                              ad?.images[0]
                            }
                            onError={(e) => (e.target.src = "/product.jpg")}
                            alt=""
                          />
                        ) : (
                          <img className="product-img" src={p1} alt="" />
                        )}
                      </Link>
                      <div className="product-text">
                        <div className="product-text-top">
                          <h6>
                            <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                          </h6>

                          <div>
                            <AiFillDelete
                              className="me-2 text-danger cursor-pointer"
                              onClick={() => tog_DeleteModal(ad?._id)}
                            />
                          </div>
                        </div>
                        <div className="product-text-bottom">
                          <div className="desc">{ad?.description}</div>
                          <h6>$ {ad?.price}</h6>
                        </div>
                      </div>
                      <div className="">
                        <div className="product-text-top">
                          <div>
                            <AiFillEye className="mx-2" />
                            {ad?.views?.length}
                          </div>
                          <div>
                            <Link to={`/editItem/${ad?._id}`}>
                              {" "}
                              <AiFillEdit className="me-2" />
                            </Link>
                          </div>

                          {ad?.adStatus === "active" && ad?.status !== "Ban" ? (
                            <button className="btn ">
                              <FaBan
                                className="text-danger"
                                title="Deactive"
                                onClick={() => tog_BanModal(ad._id)}
                              />
                            </button>
                          ) : (
                            <button className="btn">
                              <VscVmActive
                                className="text-primary"
                                title="Active"
                                onClick={(e) => handleUnBanAd(e, ad._id)}
                              />
                            </button>
                          )}
                          <button className="btn">
                            <GoReport
                              className="text-secondary"
                              title="Report"
                              onClick={() => tog_feedbacks(ad._id)}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="card shadow-sm p-2 cate-card">
                    <h3 className="mx-auto fs-6 my-2">
                      {t("No Product Found !")}{" "}
                    </h3>
                  </div>
                </>
              )}
            </div>
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        )}

        {ads === 2 && (
          <div className="row">
            {mostSoldAd &&
              mostSoldAd?.map((ad, i) => (
                <div className="col-lg-4 col-md-6" key={i}>
                  <div className="products-item shadow">
                    <Link
                      to={`/details/${ad._id}`}
                      className="product-img-container w-100"
                    >
                      {ad?.type === "Urgent" && (
                        <div className="product-ribbon urgent">{ad?.type}</div>
                      )}
                      {ad?.type === "Standard" && (
                        <div className="product-ribbon standard">
                          {ad?.type}
                        </div>
                      )}
                      {ad?.type === "Featured" && (
                        <div className="product-ribbon featured">
                          {ad?.type}
                        </div>
                      )}
                      {ad?.images ? (
                        <img
                          className="product-img"
                          src={
                            process.env.REACT_APP_IMAGES_URL +
                            "/" +
                            ad?.images[0]
                          }
                          alt=""
                          onError={(e) => (e.target.src = "/product.jpg")}
                        />
                      ) : (
                        <img className="product-img" src={p1} alt="" />
                      )}
                    </Link>
                    <div className="product-text">
                      <div className="product-text-top">
                        <h6>
                          <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                        </h6>
                        <div>
                          <button className="btn">
                            <GoReport
                              className="text-secondary"
                              title="Report"
                              onClick={() => tog_feedbacks(ad._id)}
                            />
                          </button>
                          <AiFillEye className="me-2" />
                          {ad?.views?.length}
                        </div>
                      </div>
                      <div className="product-text-bottom">
                        <div className="desc">{ad?.description}</div>
                        <h6>$ {ad?.price}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      {/* Delete Ad Product Modal Start */}
      <Modal
        className="fade"
        show={deleteAdModal}
        onHide={() => {
          tog_DeleteModal(adId);
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Delete Product")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setDeleteAdModal(false);
            }}
          />
        </div>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <h4 className="text-danger">
                Are You Sure to Delete Product/Ad ?
              </h4>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button
              className="form-save green-btn hover-shadow mx-2"
              onClick={() => {
                setDeleteAdModal(false);
              }}
            >
              {t("Cancel")}
            </button>
            <button
              className="form-save green-btn hover-shadow"
              onClick={(e) => handleDeleteAd(e)}
            >
              {t("Yes")}
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* Delete Ad Product Modal End */}

      {/* Ban Ad Product Modal Start */}
      <Modal
        className="fade"
        show={banAdModal}
        onHide={() => {
          tog_BanModal(adId);
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Ban Product")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setBanAdModal(false);
            }}
          />
        </div>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <h4 className="text-danger">Are You Sure to Ban Product/Ad ?</h4>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button
              className="form-save green-btn hover-shadow mx-2"
              onClick={() => {
                setBanAdModal(false);
              }}
            >
              {t("Cancel")}
            </button>
            <button
              className="form-save green-btn hover-shadow"
              onClick={(e) => handleBanAd(e)}
            >
              {t("Yes")}
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* Ban Ad Product Modal End */}

      {/*GET ALL REPORT MODAL START*/}

      <Modal
        className="fade"
        show={showFeedback}
        onHide={() => {
          tog_feedbacks();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Reports On Product")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShowFeedback(false);
            }}
          />
        </div>
        <div className="message-modal-top mx-auto">
          <div className="offer-modal-item">
            <img
              src={process.env.REACT_APP_IMAGES_URL + "/" + Ad?.images?.[0]}
              onError={(e) => (e.target.src = "/product.jpg")}
              alt=""
            />
            <div className="offer-modal-item-text">
              <h5>{Ad?.name}</h5>
              <p>
                {t("Color")} : {Ad?.color},{t("Size")} :{Ad?.size}
              </p>
            </div>
          </div>
        </div>
        <div className="modal-dialog-scrollable">
          <ModalBody style={{ maxHeight: "350px ", overflowY: "scroll" }}>
            {allReports.length > 0 ? (
              allReports.map((report, idx) => (
                <div className="reviews-modal-item" key={idx}>
                  <div className="reviews-modal-item-top">
                    <div className="reviews-modal-item-left">
                      <img
                        className="reviews-modal-user"
                        src={
                          process.env.REACT_APP_IMAGES_URL +
                          "/" +
                          report?.user?.image
                        }
                        onError={(e) => (e.target.src = "/user.png")}
                        alt=""
                      />
                      <div className="reviews-modal-item-left-text">
                        <h6>{report?.user?.name}</h6>
                      </div>
                    </div>
                    <div className="reviews-modal-item-right">
                      {Moment(report?.createdAt).format("LLL")}
                    </div>
                  </div>
                  <p className="reviews-modal-item-bottom">"{report?.text}"</p>
                </div>
              ))
            ) : (
              <div className="card shadow bg-light border-0 mx-auto p-3 w-75 mb-4">
                <h5 className="text-center my-auto">
                  {t("No Report Found !")}
                </h5>
              </div>
            )}
          </ModalBody>
          <button className="message-modal-btn hover-shadow green-btn">
            {allReports.length}
            {t("Reports")}
          </button>
        </div>
      </Modal>

      {/*GET ALL REPORT MODAL End*/}
    </div>
  );
};

export default Products;
