import axios from "axios";
// import { toast } from "react-toastify";
const config = {
  headers: {
    "x-auth-token": localStorage.token,
  },
};

// Add Ticket
const addTicket = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/ticket/addTicket",
    body,
    config
  );
  return response?.data;
};

// GET All Tickets
const getAllTickets = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/ticket/getAllTickets",
    body,
    config
  );
  // console.log("respons from services", response);
  return response?.data;
};

// Post a Question on Ticket
const addQuestion = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/ticket/addQuestion",
    body,
    config
  );
  return response?.data;
};

const categoryServcie = {
  addTicket,
  getAllTickets,
  addQuestion,
};

export default categoryServcie;
