import React, { useState, useEffect } from "react";
import userImage from "../assets/images/user1.jpg";
import camera from "../assets/icons/camera.svg";
import axios from "axios";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//===================Redux===================
import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";
//---------trnslate
import { useTranslation } from "react-i18next";

const EditProfile = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  //============== Redux State plus local storage start=============================

  const { user, isLoading, profile } = useSelector((state) => state.auth);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    if (userId) {
      dispatch(getUserProfile(userId));
    }
  }, [user, navigate, dispatch, userId]);

  //============== Redux State plus local storage end=============================

  //============Initialization form state with Handler======================

  //============password show hide start===========================
  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleShowOld = () => setShowOld(!showOld);
  const handleShowNew = () => setShowNew(!showNew);
  const handleShowConfirm = () => setShowConfirm(!showConfirm);

  //============password show hide end===========================

  const [name, setName] = useState();
  const [contact, setContact] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setName(profile?.user?.name);
    setContact(profile?.user?.contact);
    setAddress(profile?.user?.address);
    setCity(profile?.user?.city);
    setState(profile?.user?.state);
    setDescription(profile?.user?.description);
  }, [profile]);

  //================= Handle file And Preview start==========================
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  //================= Handle file And Preview end==========================

  //============Initialization form state with Handler end======================

  //===============Handle Profile Edit================
  const handleEditSetting = async (e) => {
    e.preventDefault();
    try {
      const url = process.env.REACT_APP_API_URL + `/user/update/${userId}`;
      var profileData = new FormData();
      profileData.append("name", name);
      profileData.append("contact", contact);
      profileData.append("address", address);
      profileData.append("city", city);
      profileData.append("state", state);
      profileData.append("description", description);
      profileData.append("file", selectedFile);

      const response = await axios({
        method: "patch",
        url: url,
        data: profileData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response?.data.success === true) {
        toast.success(response.data.message);
      } else {
        if (response?.data.success === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //===============Handle Profile Edit================

  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //===============Handle Update Password ================
  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      const url =
        process.env.REACT_APP_API_URL + `/user/updatePassword/${userId}`;

      const response = await axios({
        method: "patch",
        url: url,
        data: {
          oldPassword: oldPassword,
          newPassword: password,
          confirmPassword: confirmPassword,
        },
      });
      if (response?.data.success === true) {
        toast.success(response.data.message);
      } else {
        if (response?.data.success === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //===============Handle Update Password ================

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="edit-profile">
      {user && (
        <div className="row">
          <div className="col-lg-6">
            <h3 className="edit-heading">{t("Profile Setting")}</h3>
            <div className="form-field">
              <label>{t("Name")} </label>
              <div className="form-input">
                <input
                  type="text"
                  placeholder="Leman"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="form-field">
              <label>{t("Contact Number")}</label>
              <div className="form-input">
                <input
                  type="text"
                  name="contact"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="edit-img-container">
              {selectedFile && (
                <img className="edit-img" src={preview} alt="" />
              )}

              {profile?.user?.image ? (
                <img
                  className="edit-img"
                  src={
                    process.env.REACT_APP_IMAGES_URL +
                    "/" +
                    profile?.user?.image
                  }
                  onError={(e) => (e.target.src = "/user.png")}
                  alt=""
                />
              ) : (
                <img
                  src={
                    process.env.REACT_APP_IMAGES_URL +
                    "/" +
                    profile?.user?.image
                  }
                  alt=""
                  onError={(e) => (e.target.src = "/user.png")}
                />
              )}
              <img className="edit-img-icon" src={camera} alt="" />
              <input
                type="file"
                onChange={onSelectFile}
                accept="image/png, image/gif, image/jpeg"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-field">
              <label>{t("Email")}</label>
              <div className="form-input">
                <input type="text" value={user?.user?.email} readOnly />
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-field">
              <label>{t("Address")}</label>
              <div className="form-input">
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-field">
              <label>{t("City")}</label>
              <select value={city} onChange={(e) => setCity(e.target.value)}>
                <option>Select City</option>
                <option value="Zürich (ZH)">Zürich (ZH)</option>
                <option value="Berne (BE)">Berne (BE)</option>

                <option value="Luzern (LU)">Luzern (LU)</option>

                <option value="Uri (UR)">Uri (UR)</option>
                <option value="Schwyz (SZ)">Schwyz (SZ)</option>
                <option value="Unterwalden (Obwalden (OW) / Nidwalden (NW))">
                  Unterwalden (Obwalden (OW) / Nidwalden (NW))
                </option>
                <option value="Glarus (GL)">Glarus (GL)</option>
                <option value="Zug (ZG)">Zug (ZG)</option>
                <option value="Freiburg / Fribourg (FR)">
                  Freiburg / Fribourg (FR)
                </option>
                <option value="Solothurn (SO)">Solothurn (SO)</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-field">
              <label>{t("State")}</label>
              <div className="form-input">
                <input
                  type="text"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-field-profile form-field">
              <label>{t("Description")}</label>
              <div className="form-input form-textarea">
                <textarea
                  className="form-control form-control-solid"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-12 mt-4 d-flex justify-content-end form-btns">
            <button
              className="form-save font-poppins green-btn hover-shadow"
              onClick={handleEditSetting}
            >
              {t("Save")}
            </button>
          </div>

          <div className="col-12 mt-4 ">
            <h5 className="text-success border-bottom border-success w-25 pb-2">
              Change Password
            </h5>
          </div>

          <div className="col-lg-4">
            <div className="form-field">
              <label>{t("Old Password")}</label>
              <div className="form-input">
                <input
                  type={showOld ? "text" : "password"}
                  autoComplete="off"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                {/* <input type="password" placeholder="sbdfbnd65sfdvb s" /> */}
                <button
                  type="button"
                  className="btn btn-sm"
                  onClick={handleShowOld}
                >
                  {showOld ? <AiFillEyeInvisible /> : <AiFillEye />}
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-field">
              <label>{t("New Password")}</label>
              <div className="form-input">
                <input
                  type={showNew ? "text" : "password"}
                  autoComplete="off"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* <input type="password" placeholder="sbdfbnd65sfdvb s" /> */}
                <button
                  type="button"
                  className="btn btn-sm"
                  onClick={handleShowNew}
                >
                  {showNew ? <AiFillEyeInvisible /> : <AiFillEye />}
                </button>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-field">
              <label>{t("Confirm Password")}</label>
              <div className="form-input">
                <input
                  type={showConfirm ? "text" : "password"}
                  autoComplete="off"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {/* <input type="password" placeholder="sbdfbnd65sfdvb s" /> */}
                <button
                  type="button"
                  className="btn btn-sm"
                  onClick={handleShowConfirm}
                >
                  {showConfirm ? <AiFillEyeInvisible /> : <AiFillEye />}
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4 d-flex justify-content-end form-btns">
            <button
              className="form-save font-poppins green-btn hover-shadow"
              onClick={handleUpdatePassword}
            >
              {t("Update Password")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
