import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Header from "../components/LoggedHeader";
import upload from "../assets/icons/upload.svg";
import CreatableSelect from "react-select/creatable";

// import Spinner from "../components/Spinner";
//===================Redux===================
//===================Redux===================
import {
  getAllCategoriesMain,
  getAllCateAndSub,
} from "../features/category/categorySlice";
import { getAllBrands } from "../features/brands/brandSlice";
import { getAllColors } from "../features/color/colorSlice";
import { getAllMainSports } from "../features/sport/sportSlice";
import { useSelector, useDispatch } from "react-redux";

import { AiOutlineStepBackward } from "react-icons/ai";
import { toast } from "react-toastify";
//---------trnslate
import { useTranslation } from "react-i18next";

import { Code } from "react-content-loader";

const MyCodeLoader = () => <Code />;

const EditItem = () => {
  const { t } = useTranslation();

  //-----------------------LOADING ON DATA LOAD START---------------
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // simulate data loading
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  //-----------------------LOADING ON DATA LOAD END---------------

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const { categories, allCategories } = useSelector((state) => state.category);
  const { sports } = useSelector((state) => state.sport);
  const { allBrands } = useSelector((state) => state.brand);
  const { allColors } = useSelector((state) => state.color);

  const [step, setStep] = useState(1);

  function scrollToTopNext() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setStep(2);
  }

  //==============Constants starts================================
  //==============Use Effect================================
  useEffect(() => {
    dispatch(getAllCategoriesMain());
    dispatch(getAllCateAndSub());
    dispatch(getAllMainSports());
    dispatch(getAllBrands());
    dispatch(getAllColors());
  }, [dispatch]);
  //==============Use Effect================================

  //==============Constants end================================
  //=================Utilities start================
  const { user } = useSelector((state) => {
    return state.auth;
  });
  //=================Utilities end  ============

  //==============Use Effect================================
  const [adData, setAdData] = useState();

  useEffect(() => {
    const getAdById = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + `/product/getById/${id}`
        );
        if (response) {
          setAdData(response?.data?.product[0]);
        }
        // return response?.data;
      } catch (error) {
        console.log(error);
      }
    };
    getAdById();
  }, [id]);

  //==============Use Effect================================

  //============ Form Data Start==============

  //=================Edit State start =================
  const [title, setTitle] = useState();
  const [price, setPrice] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [length, setLength] = useState();
  const [stock, setStock] = useState();
  const [description, setDescription] = useState();
  const [size, setSize] = useState();
  const [AdCondition, setAdCondition] = useState();
  const [adType, setAdType] = useState();
  const [shipping_cost, setshipping_cost] = useState();
  const [paymentOption, setPaymentOption] = useState();

  const [location, setLocation] = useState();
  const [address, setAddress] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();

  const [deliveryNotes, setDeliveryNotes] = useState();

  // const [oldImage, setOldImages] = useState(adData?.images);
  //=================Handler category=================
  const [mainCategory, setMainCategoryData] = useState();

  const handleMainCategoryChange = (e) => {
    setMainCategoryData(e.target.value);
  };

  const [subCategory, setSubCategoryData] = useState("");

  const handleSubCategoryChange = (e) => {
    setSubCategoryData(e.target.value);
  };

  //=================Handler Sport category start=================
  const [sportCategory, setSportCategoryData] = useState();

  const handleSportCategoryChange = (e) => {
    setSportCategoryData(e.target.value);
  };
  //=================Handler Sport category end=================

  //=================Handler Brand start=================
  const [brand, setBrand] = useState("");

  const handleBrandChange = (e) => {
    setBrand(e);
  };
  //=================Handler Brand end=================

  //=================Handler Color start=================
  const [color, setColor] = useState("");
  const handleColorChange = (e) => {
    setColor(e);
  };
  //=================Handler Color end=================

  //=================Ad type =================
  const checkList = ["Exchange", "Sell"];

  // Add/Remove checked item from list
  const [checkedVal, setChecked] = useState([]);

  const handleCheck = (event) => {
    var updatedList = [...checkedVal];
    if (event.target.checked) {
      updatedList = [...checkedVal, event.target.value];
    } else {
      updatedList.splice(checkedVal.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };
  const checkedItems =
    checkedVal !== undefined
      ? checkedVal.reduce((total, item) => {
          if (total === "") {
            return item;
          } else {
            return total + "," + item;
          }
        }, "")
      : "";

  var isChecked = (item) =>
    checkedVal?.includes(item) ? "checked-item" : "not-checked-item";

  // const [adOption, setAdOption] = useState([]);
  // const handleChange = (e) => {
  //   const { value, checked } = e.target;
  //   // Case 1 : The user checks the box
  //   if (checked) {
  //     setAdOption([...adOption, value]);
  //   }
  //   // Case 2  : The user unchecks the box
  //   else {
  //     setAdOption(adOption.filter((e) => e !== value));
  //   }
  // };

  useEffect(() => {
    setTitle(adData?.name);
    setPrice(adData?.price);
    setWidth(adData?.width);
    setHeight(adData?.height);
    setLength(adData?.length);
    setStock(adData?.stock);
    setshipping_cost(adData?.shipping_cost);
    setDescription(adData?.description);
    setMainCategoryData(adData?.category?._id);
    setSubCategoryData(adData?.subCategory[0]?._id);
    setSportCategoryData(adData?.sportCategory?._id);
    setPaymentOption(adData?.payment_option);
    setLocation(adData?.location);
    setAddress(adData?.address);
    setCity(adData?.city);
    setState(adData?.state);
    setDeliveryNotes(adData?.delivery_notes);
    setChecked(adData?.option[0].split(","));
  }, [adData]);

  //=================Edit State end =================

  //============ Form Data end ===============

  //============ POST AN PRODUCT/AD Start ================
  const handleUpdateAd = async (e) => {
    e.preventDefault();
    let body = {
      user: user?.user._id,
      name: title ? title : adData?.name,
      price: price ? price : adData?.price,
      category: mainCategory ? mainCategory : adData?.category?._id,
      sportCategory: sportCategory ? sportCategory : adData?.sportCategory?._id,
      shipping_cost: shipping_cost ? shipping_cost : adData?.shipping_cost,
      option: checkedItems ? checkedItems : checkedVal,
      width: width ? width : adData?.width,
      length: length ? length : adData?.length,
      height: height ? height : adData?.height,
      description: description ? description : adData?.description,
      stock: stock ? stock : adData?.stock,
      brand: brand?.value ? brand?.value : adData?.brand,
      color: color?.value ? color?.value : adData?.color,
      condition: AdCondition ? AdCondition : adData?.condition,
      size: size ? size : adData?.size,
      type: adType ? adType : adData?.type,
      address: address ? address : adData?.address,
      city: city ? city : adData?.city,
      state: state ? state : adData?.state,
    };
    try {
      const response = await axios.patch(
        process.env.REACT_APP_API_URL + `/product/update/${id}`,
        body,
        {
          headers: {
            "x-auth-token": localStorage.token,
          },
        }
      );

      if (response.data.success === true) {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //============ POST AN PRODUCT/AD end ================

  // ======== Handle Images and Previews Start =========
  // const [images, setImages] = useState([]);

  // const handleMultipleImages = (evnt) => {
  //   const selectedFIles = [];
  //   const targetFiles = evnt.target.files;
  //   setOldImages({ ...oldImage, image: targetFiles });
  //   const targetFilesObject = [...targetFiles];
  //   targetFilesObject.map((file) => {
  //     return selectedFIles.push(URL.createObjectURL(file));
  //   });
  //   setImages(selectedFIles);
  // };

  // const uploadMore = (evnt) => {
  //   const selectedFIles = [];
  //   const targetFiles = evnt.target.files;
  //   const targetFilesObject = [...targetFiles];
  //   targetFilesObject.map((file) => {
  //     return selectedFIles.push(URL.createObjectURL(file));
  //   });
  //   const more = images.concat(selectedFIles);
  //   setImages(more);
  // };

  // const removeImg = (i) => {
  //   var x = [];
  //   var targetFiles = [];
  //   for (var j = 0; j < images.length; j++) {
  //     if (j !== i) {
  //       x.push(images[j]);
  //       targetFiles.push(oldImage[j]);
  //     }
  //   }
  //   setOldImages({ ...oldImage, image: targetFiles });
  //   setOldImages(x);
  // };

  //============Handler for Images End=============

  // ======== Handle Previews Back Button Start=========

  //------------Handle Back button Click start--------------------
  const handleBack = () => {
    // 👇️ replace set to true
    navigate("/profileSettings/profile", { replace: true });
  };

  //------------Handle Back button Click start--------------------

  //------------Handle Back button for Admin Click start--------------------
  const handleBackAdmin = () => {
    // 👇️ replace set to true
    navigate("/dashboard/products", { replace: true });
  };

  //------------Handle Back button for Admin Click start--------------------

  return (
    <div>
      <Header />
      <Categories />

      <div className="container ">
        <div className="my-3">
          {user?.user.role === "Admin" ? (
            <button
              className="form-save green-btn hover-shadow btn-sm"
              onClick={handleBackAdmin}
            >
              <AiOutlineStepBackward />
            </button>
          ) : (
            <button
              className="form-save green-btn hover-shadow btn-sm"
              onClick={handleBack}
            >
              <AiOutlineStepBackward />
            </button>
          )}
        </div>
        <h4 className="create-item-heading">{t("Edit Product")}</h4>

        <div className="row">
          <div className="col-lg-8 mt-2">
            {loading && <MyCodeLoader />}
            {!loading && (
              <>
                {step === 1 && (
                  <div className="create-item-form">
                    {adData?.images.length === 0 ? (
                      <div className="row mb-2 ">
                        <div className="col-12">
                          <div className="drop-file">
                            <img src={upload} alt="" />
                            <h5 className="drop-heading">
                              {t("Drag and Drop or")} <span>{t("Browse")}</span>
                            </h5>
                            <h6>
                              {t(
                                "File Type Supported:JPG,PNG, Max size: 100 MB"
                              )}
                            </h6>
                            <input
                              type="file"
                              multiple
                              // onChange={handleMultipleImages}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        {adData?.images.map((url, i) => {
                          return (
                            <div
                              className="col-2"
                              style={{ padding: "5px" }}
                              key={i}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGES_URL + "/" + url
                                  }
                                  // src={url}
                                  style={{
                                    width: "90px",
                                    height: "90px",
                                    borderRadius: "7px",
                                    objectFit: "cover",
                                  }}
                                  alt=""
                                />

                                {/* <AiOutlineDelete
                        className="text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeImg(i)}
                      /> */}
                              </div>
                            </div>
                          );
                        })}
                        {/* <div
                style={{
                  border: "1px solid grey",
                  width: "90px",
                  height: "90px",
                  fontSize: "52px",
                  textAlign: "center",
                  borderRadius: "7px",
                  padding: "5px",
                  marginLeft: "6px",
                  marginTop: "6px",
                }}
              >
                <label htmlFor="formId">
                  <input
                    name="image"
                    onChange={uploadMore}
                    type="file"
                    id="formId"
                    hidden
                    accept="image/png, image/jpeg"
                    multiple
                  />
                  <AiOutlinePlus />
                </label>
              </div> */}
                      </div>
                    )}

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Name of Product")}</label>
                          <div className="form-input">
                            <input
                              type="text"
                              name="title"
                              value={title}
                              defaultValue={adData?.name}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Price of Product")}</label>
                          <div className="form-input">
                            <input
                              type="number"
                              name="price"
                              value={price}
                              defaultValue={adData?.price}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-field">
                          <label>{t("Product Category")}</label>
                          <select
                            value={mainCategory}
                            defaultValue={adData?.category?._id}
                            onChange={(e) => handleMainCategoryChange(e)}
                          >
                            <option>{t("Select Category")}</option>
                            {categories
                              ? categories.map((category) => (
                                  <option
                                    value={category?._id}
                                    key={category?._id}
                                  >
                                    {category?.name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-field">
                          <label>{t("Product Sub Category")}</label>
                          <select
                            value={subCategory}
                            onChange={(e) => handleSubCategoryChange(e)}
                          >
                            <option>{t("Select Sub Category")}</option>
                            {allCategories
                              ? allCategories.map(
                                  (cate) =>
                                    mainCategory === cate?.category._id && (
                                      <option value={cate?._id} key={cate?._id}>
                                        {cate?.name}
                                      </option>
                                    )
                                )
                              : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-field">
                          <label>{t("Sport Category")}</label>
                          <select
                            value={sportCategory}
                            defaultValue={adData?.sportCategory?._id}
                            onChange={(e) => handleSportCategoryChange(e)}
                          >
                            <option>{t("Select Sport Category")}</option>
                            {sports
                              ? sports.map((sport) => (
                                  <option value={sport?._id} key={sport?._id}>
                                    {sport?.name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("In Stock")}</label>
                          <div className="form-input">
                            <input
                              type="number"
                              name="stock"
                              defaultValue={adData?.stock}
                              value={stock}
                              onChange={(e) => setStock(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Shipping Cost")}</label>

                          <div className="form-input">
                            <input
                              type="number"
                              name="shipping_cost"
                              defaultValue={adData?.shipping_cost}
                              value={shipping_cost}
                              onChange={(e) => setshipping_cost(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Ad Type")}</label>

                          <div className="form-group d-flex align-items-center">
                            <div className=" d-flex">
                              {checkList.map((item, index) => (
                                <>
                                  <input
                                    value={item}
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={handleCheck}
                                    checked={
                                      checkedVal?.includes(item) ? true : false
                                    }
                                  />
                                  {/* <label htmlFor="sell">{item}</label> */}
                                  <label
                                    className={isChecked(item)}
                                    htmlFor="sell"
                                  >
                                    {item}
                                  </label>
                                </>
                              ))}
                              {/* <input
                      className="form-check-input"
                      type="checkbox"
                      value="Exchange"
                      id="exchange"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exchange"
                    >
                      {t("Exchange")}
                    </label> */}
                            </div>
                            {/* <div className="form-check d-flex">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Sell"
                      id="sell"
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="sell">
                      {t("Sell")}
                    </label>
                  </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Dimensions")}</label>
                          <div className="row">
                            <div className="col-4">
                              <label>{t("Width")}</label>

                              <div className="form-input">
                                <input
                                  type="number"
                                  name="width"
                                  defaultValue={adData?.width}
                                  value={width}
                                  onChange={(e) => setWidth(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <label>{t("Length")}</label>

                              <div className="form-input">
                                <input
                                  type="number"
                                  name="length"
                                  defaultValue={adData?.length}
                                  value={length}
                                  onChange={(e) => setLength(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <label>{t("Height")}</label>

                              <div className="form-input">
                                <input
                                  type="number"
                                  placeholder="Height"
                                  defaultValue={adData?.height}
                                  value={height}
                                  name="height"
                                  onChange={(e) => setHeight(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-field">
                          <label>{t("Description")}</label>
                          <div className="form-input form-textarea">
                            <textarea
                              placeholder="Description"
                              value={description}
                              defaultValue={adData?.description}
                              name="description"
                              onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-end mt-3">
                        <button
                          className="form-save green-btn hover-shadow"
                          onClick={scrollToTopNext}
                        >
                          {t("Next")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="create-item-form">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Brand")}</label>
                          <CreatableSelect
                            isClearable={true}
                            closeMenuOnSelect={true}
                            isSearchable={true}
                            onChange={handleBrandChange}
                            defaultInputValue={adData?.brand}
                            value={brand}
                            options={allBrands?.map((singleBrand) => ({
                              value: singleBrand.name,
                              label: singleBrand.name,
                            }))}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Color")}</label>
                          <CreatableSelect
                            isClearable={true}
                            closeMenuOnSelect={true}
                            isSearchable={true}
                            onChange={handleColorChange}
                            defaultInputValue={adData?.color}
                            value={color}
                            options={allColors?.map((singleColor) => ({
                              value: singleColor.name,
                              label: singleColor.name,
                            }))}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Condition")}</label>
                          <select
                            value={AdCondition}
                            defaultValue={adData?.condition}
                            onChange={(e) => setAdCondition(e.target.value)}
                          >
                            <option>{t("Select Condition")}</option>

                            <option value="New">{t("New")}</option>
                            <option value="Good">{t("Good")}</option>
                            <option value="Fair">{t("Fair")}</option>
                            <option value="Repaired">{t("Repaired")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Size")}</label>
                          <div className="form-input">
                            <input
                              type="text"
                              placeholder="6 / S"
                              name="size"
                              value={size}
                              defaultValue={adData?.size}
                              onChange={(e) => setSize(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Ad Type")}</label>
                          <select
                            value={adType}
                            defaultValue={adData?.type}
                            onChange={(e) => setAdType(e.target.value)}
                          >
                            <option>{t("Select Ad Type")}</option>

                            <option value="Standard">{t("Standard")}</option>
                            <option value="Featured">{t("Featured")}</option>

                            <option value="Urgent">{t("Urgent")}</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Payment Options")}</label>
                          <select
                            name="paymentOption"
                            value={paymentOption}
                            onChange={(e) => setPaymentOption(e.target.value)}
                          >
                            <option selected>
                              {t("Select Payment Methods")}
                            </option>
                            <option value="Offline">{t("Offline")}</option>
                            <option value="VISA">{t("VISA")}</option>
                            <option value="Paypal">{t("Paypal")}</option>
                            <option value="Payoneer">{t("Payoneer")}</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("City")}*</label>
                          <select
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                          >
                            <option selected>{t("Select Ad Location")}</option>

                            <option value="Zürich (ZH)">Zürich (ZH)</option>
                            <option value="Berne (BE)">Berne (BE)</option>

                            <option value="Luzern (LU)">Luzern (LU)</option>

                            <option value="Uri (UR)">Uri (UR)</option>
                            <option value="Schwyz (SZ)">Schwyz (SZ)</option>
                            <option value="Unterwalden (Obwalden (OW) / Nidwalden (NW))">
                              Unterwalden (Obwalden (OW) / Nidwalden (NW))
                            </option>
                            <option value="Glarus (GL)">Glarus (GL)</option>
                            <option value="Zug (ZG)">Zug (ZG)</option>
                            <option value="Freiburg / Fribourg (FR)">
                              Freiburg / Fribourg (FR)
                            </option>
                            <option value="Solothurn (SO)">
                              Solothurn (SO)
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-field">
                          <label>{t("Address")}*</label>
                          <div className="form-input">
                            <input
                              type="text"
                              placeholder="Ganclik m/s, Ziya Bunyadzade."
                              name="address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("Location")}*</label>
                          <div className="form-input">
                            <input
                              type="text"
                              placeholder="Ganclik m/s."
                              name="city"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>
                          {/* <select
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                >
                  <option selected>Select City</option>

                  <option>London</option>
                  <option>Manchister</option>
                </select> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-field">
                          <label>{t("State")}*</label>
                          <div className="form-input">
                            <input
                              type="text"
                              placeholder="Azerbaijan"
                              name="state"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-field form-field-profile">
                          <label>{t("Delivery Notes")}</label>
                          <div className="form-input form-textarea">
                            <textarea
                              placeholder={t("Enter Delivery Notes...")}
                              value={deliveryNotes}
                              name="deliveryNotes"
                              onChange={(e) => setDeliveryNotes(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-end mt-3">
                        <button
                          className="form-cancel hover-shadow"
                          onClick={() => setStep(1)}
                        >
                          {t("Previous")}
                        </button>
                        <button
                          className="form-save green-btn hover-shadow"
                          onClick={handleUpdateAd}
                        >
                          {t("Submit")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="col-lg-4 mt-2">
            <div className="terms-card">
              <h5>Heading</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Neque in in faucibus
                facilisis fermentum et ultrices malesuada. Sed lacus sed
                scelerisque in enim sed nisi leo. Elementum sed egestas interdum
                consectetur pulvinar eget.
              </p>
              <h5>Heading</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Neque in in faucibus
                facilisis fermentum et ultrices malesuada. Sed lacus sed
                scelerisque in enim sed nisi leo. Elementum sed egestas interdum
                consectetur pulvinar eget. lacus sed scelerisque in enim sed
                nisi leo. Elementum sed egestas interdum consectetur pulvinar
                eget. lacus sed scelerisque in enim sed nisi leo. Elementum sed
                egestas interdum consectetur pulvinar eget.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditItem;

// const formData = new FormData();
// formData.append("user", user?.user._id);
// formData.append("name", title ? title : adData?.name);
// formData.append("price", price ? price : adData?.price);
// formData.append(
//   "category",
//   mainCategory ? mainCategory : adData?.category?._id
// );
// formData.append(
//   "sportCategory",
//   sportCategory ? sportCategory : adData?.sportCategory?._id
// );
// formData.append("option", adOption ? adOption : adData?.option);
// formData.append("width", width ? width : adData?.width);
// formData.append("length", length ? length : adData?.length);
// formData.append("height", height ? height : adData?.height);
// formData.append(
//   "description",
//   description ? description : adData?.description
// );
// formData.append("stock", stock ? stock : adData?.stock);
// formData.append("brand", brand?.value ? brand?.value : adData?.brand);
// formData.append("color", color?.value ? color?.value : adData?.color);
// formData.append("condition", AdCondition ? AdCondition : adData?.condition);
// formData.append("size", size ? size : adData?.size);
// formData.append("type", adType ? adType : adData?.type);
// formData.append("address", address ? address : adData?.address);
// formData.append("city", city ? city : adData?.city);
// formData.append("state", state ? state : adData?.state);

// if (oldImage !== null) {
//   for (let i = 0; i < oldImage; i++) {
//     formData.append("file", oldImage[i] ? oldImage[i] : adData?.images);
//   }
// }
