import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Moment from "moment";
import ReactStars from "react-rating-stars-component";
//---------trnslate
import { useTranslation } from "react-i18next";

const Reviews = () => {
  const { t } = useTranslation();

  //==============User selector start==================================

  const { user } = useSelector((state) => {
    return state.auth;
  });

  const [ads, setAds] = useState(1);

  //==============User selector end==================================
  useEffect(() => {
    getallReview();
  }, []);

  //==============Review get Api Start====================
  const [allReview, setAllReview] = useState([]);

  const getallReview = async () => {
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          `/product/getBuyerReview/${user?.user?._id}`,
        config
      );
      setAllReview(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  //==============Review get Api END====================
  return (
    <div>
      <div className="ads-top">
        <div className="ads-top-left">
          <h5>{t("Reviews")} </h5>
        </div>
      </div>
      <div className="ads-tabs">
        <button className={ads === 1 ? "active" : ""} onClick={() => setAds(1)}>
          {t("My Reviews")}
        </button>
        <button className={ads === 2 ? "active" : ""} onClick={() => setAds(2)}>
          {t("Others Reviews")}
        </button>
      </div>
      {ads === 1 && (
        <div className="row px-2">
          {allReview.length > 0 ? (
            allReview.map((review, idx) => (
              <>
                {review?.buyerId._id === user.user._id &&
                  review.status === true && (
                    <div className="col-lg-4 col-md-6 " key={idx}>
                      <div className="review-card my-review">
                        <div className="review-card-top">
                          <img
                            className="reviews-modal-user"
                            src={
                              process.env.REACT_APP_IMAGES_URL +
                              "/" +
                              review?.productId?.images[0]
                            }
                            onError={(e) => (e.target.src = "/product.jpg")}
                            alt=""
                          />
                          <div className="review-card-top-text">
                            <p>{review?.productId?.name}</p>
                            <span>
                              {t("Color")} :{" "}
                              <span className="text-primary">
                                {review?.productId?.color}
                              </span>{" "}
                              , {t("size")} :{" "}
                              <span className="text-info">
                                {" "}
                                {review?.productId?.size}
                              </span>
                            </span>
                          </div>
                        </div>
                        <p className="review-card-text text-trancate">
                          “{review?.comment}”
                        </p>
                        <div className="review-card-bottom">
                          <p className="review-card-date">
                            {Moment(review.createdAt).format(
                              "h:mm a - MMM D, YYYY"
                            )}
                          </p>
                          <p className="review-card-stars">
                            <ReactStars
                              count={5}
                              value={review.rating}
                              size={20}
                              isHalf={true}
                              edit={false}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
              </>
            ))
          ) : (
            <div className=" shadow bg-light border-0 mx-auto p-3 w-75 my-4">
              <h5 className="text-center my-auto">{t("No Review Found !")} </h5>
            </div>
          )}
        </div>
      )}
      {ads === 2 && (
        <div className="row px-2">
          {allReview.length > 0 ? (
            allReview.map((review, idx) => (
              <>
                {review?.buyerId._id !== user.user._id &&
                  review.status === true && (
                    <div className="col-lg-4 col-md-6 " key={idx}>
                      <div className="review-card my-review">
                        <div className="review-card-top">
                          <img
                            className="reviews-modal-user"
                            src={
                              process.env.REACT_APP_IMAGES_URL +
                              "/" +
                              review?.productId?.images[0]
                            }
                            onError={(e) => (e.target.src = "/product.jpg")}
                            alt=""
                          />
                          <div className="review-card-top-text">
                            <p>{review?.productId?.name}</p>
                            <span>
                              {t("Color")} :{" "}
                              <span className="text-primary">
                                {review?.productId?.color}
                              </span>{" "}
                              , {t("size")} :{" "}
                              <span className="text-info">
                                {" "}
                                {review?.productId?.size}
                              </span>
                            </span>
                          </div>
                        </div>
                        <p className="review-card-text text-trancate">
                          “{review?.comment}”
                        </p>
                        <div className="review-card-bottom">
                          <p className="review-card-date">
                            {Moment(review.createdAt).format(
                              "h:mm a - MMM D, YYYY"
                            )}
                          </p>
                          <p className="review-card-stars">
                            <ReactStars
                              count={5}
                              value={review.rating}
                              size={20}
                              isHalf={true}
                              edit={false}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
              </>
            ))
          ) : (
            <div className=" shadow bg-light border-0 mx-auto p-3 w-75 my-4">
              <h5 className="text-center my-auto">{t("No Review Found !")} </h5>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Reviews;
