import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";

import axios from "axios";
import Moment from "moment";
import { toast } from "react-toastify";
import user1 from "../assets/images/user1.jpg";

import { AiOutlineStepBackward } from "react-icons/ai";
//===================Redux===================
import { useSelector } from "react-redux";
//---------trnslate
import { useTranslation } from "react-i18next";

const TicketDetails = () => {
  const { t } = useTranslation();

  //   const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  //   console.log(`TicketDetails`, id);
  //========auth user information
  const { user } = useSelector((state) => state.auth);
  // console.log("tickets", tickets);

  //=====Details Ticket START=====
  const [detailsData, setDetailsData] = useState([]);

  useEffect(() => {
    getSingleTicket();
  }, []);

  const getSingleTicket = async () => {
    try {
      const config = {
        headers: {
          "x-auth-token": localStorage.token,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/ticket/getTicketById/${id}`,
        config
      );
      if (res.data) setDetailsData(res?.data);
    } catch (err) {
      console.log(err.response.data.error);
    }
  };

  //=====Details Ticket end=====

  //Add Question on Ticket start =====
  const [addQuestion, setAddQuestion] = useState("");
  const handleAddQuestion = async (e) => {
    e.preventDefault();

    try {
      const url = process.env.REACT_APP_API_URL + "/ticket/addQuestion";
      const response = await axios({
        method: "post",
        url: url,
        data: {
          ticketId: id,
          question: addQuestion,
          userId: user?.user?._id,
        },
        headers: {
          "x-auth-token": localStorage.token,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data?.message);
        setAddQuestion("");
        getSingleTicket();
      } else {
        if (response.data.success === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Add Question on Ticket end =====

  //Add Answer on Ticket By Question start
  const [showA, setShowA] = useState(false);
  const [answer, setAnswer] = useState("");

  const [quesId, setQuestionId] = useState();

  function tog_add_answer(id) {
    setQuestionId(id);
    setAnswer("");
    setShowA(!showA);
  }

  const handleAddAnswer = async (e) => {
    e.preventDefault();

    try {
      const url = process.env.REACT_APP_API_URL + "/ticket/addAnswer";
      const response = await axios({
        method: "post",
        url: url,
        data: {
          ticketId: id,
          answer: answer,
          questionId: quesId,
          userId: user?.user?._id,
          role: user?.user?.role,
        },
        headers: {
          "x-auth-token": localStorage.token,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data?.message);
        setShowA(!showA);
        getSingleTicket();
        setAnswer("");
      } else {
        if (response.data.success === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Add Answer on Ticket By Question end

  //Update Status on Ticket By Admin START

  const [statusTicket, setStatusTicket] = useState("");

  const handleUpdateStatusTicket = async (e) => {
    e.preventDefault();

    try {
      const url = process.env.REACT_APP_API_URL + "/ticket/updateStatus";
      const response = await axios({
        method: "post",
        url: url,
        data: {
          role: user?.user?.role,
          ticketId: id,
          status: statusTicket,
        },
        headers: {
          "x-auth-token": localStorage.token,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data?.message);
        getSingleTicket();
      } else {
        if (response.data.success === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Update Status on Ticket By Admin end

  //------------Handle Back button Click start--------------------
  const handleBack = () => {
    // 👇️ replace set to true
    navigate("/profileSettings/tickets", { replace: true });
  };

  //------------Handle Back button Click start--------------------

  //------------Handle Back button for Admin Click start--------------------
  const handleBackAdmin = () => {
    // 👇️ replace set to true
    navigate("/dashboard/tickets", { replace: true });
  };

  //------------Handle Back button for Admin Click start--------------------

  return (
    <div>
      <div className="profile-infos d-block">
        <div className="infos-btns d-flex justify-content-start">
          {user?.user.role === "Admin" ? (
            <button
              className="btn-sm green-btn hover-shadow  my-auto"
              onClick={handleBackAdmin}
            >
              <AiOutlineStepBackward />
            </button>
          ) : (
            <button
              className="btn-sm green-btn hover-shadow  my-auto"
              onClick={handleBack}
            >
              <AiOutlineStepBackward />
            </button>
          )}
        </div>
        {user?.user.role === "Admin" && (
          <div className="row  my-4">
            <div className="col-12 col-lg-8"></div>
            <div className="col-12 col-lg-4">
              <div className="d-flex">
                <select
                  className="form-select"
                  style={{ width: "fit-content" }}
                  value={statusTicket || detailsData[0]?.status}
                  name="statusTicket"
                  onChange={(e) => setStatusTicket(e.target.value)}
                >
                  <option value="Resolved">{t("Resolved")} </option>
                  <option value="Pending">{t("Pending")}</option>
                  <option value="Cancelled">{t("Cancelled")}</option>
                </select>
                <button
                  type="button"
                  className="btn btn-success btn-sm ms-1"
                  onClick={handleUpdateStatusTicket}
                >
                  {t("Update Status")}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="row  my-4">
          <div className="col-5 col-md-3 col-lg-6">
            <h5>{t("Ticket Title")}</h5>
          </div>
          <div className="col-5 col-md-9 col-lg-6">
            <h5>{detailsData[0]?.title} </h5>
          </div>
        </div>
        <div className="row border-bottom pb-3">
          {/* <div className="col-12 my-2">
            <div className="row">
              <div className="col-6">
                <h6>{t("Ticket Title")}</h6>
              </div>
              <div className="col-6">
                <h6>{detailsData[0]?.title} </h6>
              </div>
            </div>
          </div> */}
          <div className="col-12 my-2">
            <div className="row">
              <div className="col-6">
                <h6>{t("Created By")}</h6>
              </div>
              <div className="col-6">
                <h6>
                  <img
                    src={
                      process.env.REACT_APP_IMAGES_URL +
                      "/" +
                      detailsData[0]?.user?.image
                    }
                    onError={(e) => (e.target.src = "/user.png")}
                    style={{
                      width: "45px",
                      height: "45px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    alt=""
                  />
                  {detailsData[0]?.user?.name}{" "}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-12 my-2">
            <div className="row">
              <div className="col-6">
                <h6>{t("Category")}</h6>
              </div>
              <div className="col-6">
                <h6>{detailsData[0]?.related} </h6>
              </div>
            </div>
          </div>
          <div className="col-12 my-2">
            <div className="row">
              <div className="col-6">
                <h6>{t("Description")}</h6>
              </div>
              <div className="col-6">
                <h6>{detailsData[0]?.description} </h6>
              </div>
            </div>
          </div>
          <div className="col-12 my-2">
            <div className="row">
              <div className="col-6">
                <h6>{t("Status")}</h6>
              </div>
              <div className="col-6">
                {detailsData[0]?.status === "Pending" && (
                  <h6 className=" text-warning">{detailsData[0]?.status}</h6>
                )}
                {detailsData[0]?.status === "Cancelled" && (
                  <h6 className=" text-danger">{detailsData[0]?.status}</h6>
                )}
                {detailsData[0]?.status === "Resolved" && (
                  <h6 className=" text-success">{detailsData[0]?.status}</h6>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 my-2">
            <div className="row">
              <div className="col-6">
                <h6>{t("Created At")}</h6>
              </div>
              <div className="col-6">
                <h6>
                  {Moment(detailsData[0]?.createdAt).format(
                    "DD-MM-YYYY h:mm a"
                  )}
                </h6>
              </div>
            </div>
          </div>
        </div>
        {detailsData[0]?.status !== "Resolved" && (
          <>
            <div className="form-group my-2">
              <label>{t("Comment")}</label>
              <div className="form-input form-textarea">
                <textarea
                  className="form-control form-control-solid"
                  rows={3}
                  value={addQuestion}
                  onChange={(e) => setAddQuestion(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end mb-3">
              <button
                type="button"
                className="form-save green-btn hover-shadow"
                onClick={handleAddQuestion}
              >
                {t("Submit")}
              </button>
            </div>

            <div style={{ height: "265px ", overflowX: "hidden" }}>
              {detailsData[0]?.comments?.map((Q, i) => (
                <div className="qa-item" key={i}>
                  <div className="qa-item-left">
                    <div className="qa-user">
                      {Q?.user?.image ? (
                        <img
                          src={
                            process.env.REACT_APP_IMAGES_URL +
                            "/" +
                            Q?.user?.image
                          }
                          onError={(e) => (e.target.src = "/user.png")}
                          alt=""
                        />
                      ) : (
                        <img src={user1} alt="" />
                      )}
                      <div className="qa-user-text">
                        <h5 className="mb-0">{Q?.user?.name}</h5>
                        <h6>{Q?.question}?</h6>
                      </div>
                    </div>
                    <h6>{Q?.answer}</h6>
                  </div>
                  <div className="qa-item-right text-end">
                    <p>{Moment(Q?.createdAt).format("MMM D, YYYY")}</p>
                    {user?.user?.role === "Admin" ? (
                      <button
                        className="form-save green-btn hover-shadow mt-2"
                        onClick={() => tog_add_answer(Q?._id)}
                      >
                        {t("Reply")}
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {/* REPLY MODAL */}
      <Modal
        className="fade"
        show={showA}
        onHide={() => {
          tog_add_answer();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Answer")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShowA(false);
            }}
          />
        </div>

        <ModalBody>
          <div className="form-field">
            <label>{t("Answer")}</label>
            <div className="form-input">
              <input
                type="text"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="form-save green-btn hover-shadow"
            onClick={handleAddAnswer}
          >
            {t("Submit")}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TicketDetails;
