import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import { toast } from "react-toastify";

// Get user from localStorage
const user = localStorage.getItem("userId");
const token = localStorage.getItem("token");

const initialState = {
  user: user ? user : null,
  profile: user ? user : null,
  token: token ? token : null,
  isAuthenticated: token ? true : false,
  AllUsers: [],
  orderedProducts: [],
  walletBalance: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Register user
export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      return await authService.register(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Login user
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Login user Profile
export const getUserProfile = createAsyncThunk(
  "auth/profile",
  async (id, thunkAPI) => {
    try {
      return await authService.getProfile(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All Users
export const getAllUsers = createAsyncThunk(
  "auth/allUsers",
  async (thunkAPI) => {
    try {
      const response = await authService.getAllUsers();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Unblock User
export const blockUser = createAsyncThunk(
  "auth/blockUser",
  async (id, thunkAPI) => {
    try {
      await authService.blockUser(id);
      const response = await authService.getAllUsers();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Unblock User
export const unblockUser = createAsyncThunk(
  "auth/unblockUser",
  async (id, thunkAPI) => {
    try {
      await authService.unblockUser(id);
      const response = await authService.getAllUsers();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Fetch User wallet Amount and active Orders
export const fetchBuyerOrders = createAsyncThunk(
  "auth/fetchBuyerOrders",
  async (id, thunkAPI) => {
    try {
      const response = await authService.fetchBuyerOrders(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Fetch User wallet Amount
export const getWalletBalanace = createAsyncThunk(
  "auth/getWalletBalanace",
  async (thunkAPI) => {
    try {
      const response = await authService.getWalletBalanace();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
        state.message = action.payload;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;

        // state.user = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.user = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isAuthenticated = true;
        state.user = action.payload;
        state.message = action.payload;
        toast.success(action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.token = null;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profile = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.AllUsers = action.payload;
      })

      .addCase(blockUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(blockUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.AllUsers = action.payload;

        toast.success("User Blocked Successfull");
      })
      .addCase(unblockUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(unblockUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.AllUsers = action.payload;

        toast.success("User Unblocked Successfully!");
      })
      .addCase(fetchBuyerOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBuyerOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orderedProducts = action.payload.orderedProducts;
      })
      .addCase(fetchBuyerOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getWalletBalanace.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWalletBalanace.fulfilled, (state, action) => {
        state.isLoading = false;
        state.walletBalance = action.payload.trans;
      })
      .addCase(getWalletBalanace.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        state.profile = null;
        localStorage.clear();
        localStorage.setItem("persist:root", "");
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
