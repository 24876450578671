import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Categories from "../components/Categories";
import Header from "../components/LoggedHeader";
import heartRed from "../assets/icons/heartRed.svg";
// import heart from "../assets/icons/heart.svg";
// import left from "../assets/icons/left.svg";
// import right from "../assets/icons/right.svg";
import p1 from "../assets/images/p1.jpg";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { AiFillEye } from "react-icons/ai";

//============Import Pagination Page==============
import Pagination from "./Pagination";

//==============redux actions start=============================
import { getAllAds } from "../features/ad/adSlice";
import { getAllCategoriesMain } from "../features/category/categorySlice";
import { searchByCategory } from "../features/search/searchSlice";
//==============redux actions end=============================

//==============redux Hooks start=============================
import { useSelector, useDispatch } from "react-redux";

//==============redux Hooks end=============================

const CategoryFilter = () => {
  // const [p1Like, setP1Like] = useState(false);

  //==============redux start=============================
  const { id } = useParams();
  const dispatch = useDispatch();
  // const { ads } = useSelector((state) => state.ads);
  const { data, loading } = useSelector((state) => {
    return state.search;
  });
  const { categories } = useSelector((state) => state.category);
  //==============redux  end===============================
  //==============Use Effect================================
  useEffect(() => {
    dispatch(getAllCategoriesMain());
    dispatch(getAllAds());
    dispatch(searchByCategory(id));
  }, [dispatch, data, id]);

  //==============Use Effect end================================

  //==============Pagination  START=============================
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(8);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data?.length / recordsPerPage);

  //==============Pagination  END===============================

  return (
    <div>
      <Header />
      <Categories />

      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="items-heading my-4 font-poppins d-flex align-items-end border-bottom pb-2 border-dark">
            {categories
              ? categories.map((category, i) => (
                  <div key={i}>
                    {id === category?._id ? category?.name : null}
                  </div>
                ))
              : null}
            <span className="fs-6 mb-1 ms-2">({data?.length} result show)</span>
          </h2>
          {/* <div className="dropdown items-filter">
            <div
              className="dropdown-togglej items-filter-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="sort">Sort by</span>
              <img src={down} className="d-down-img" alt="" />
            </div>
            <ul className="dropdown-menu">
              <li>Women</li>
              <li>Men</li>
              <li>Kids</li>
            </ul>
          </div> */}
        </div>

        <div className="row">
          {/* {loading && (
            <div style={{ marginTop: "100px" }}>
              <Spinner />
            </div>
          )} */}
          {!loading && data?.length === 0 && (
            <div style={{ marginTop: "50px" }}>
              <h5 className="text-center">No Ads Found !</h5>
            </div>
          )}
          {data?.length > 0 ? (
            currentRecords?.map((ad, i) => (
              <div className="col-lg-3 col-md-6" key={i}>
                <div className="item-card shadow">
                  <Link
                    to={`/details/${ad._id}`}
                    className="item-card-img w-100"
                  >
                    {ad?.type === "Urgent" && (
                      <div className="product-ribbon urgent">{ad?.type}</div>
                    )}
                    {ad?.type === "Standard" && (
                      <div className="product-ribbon standard">{ad?.type}</div>
                    )}
                    {ad?.type === "Featured" && (
                      <div className="product-ribbon featured">{ad?.type}</div>
                    )}
                    {ad?.images ? (
                      <img
                        className="product-img"
                        src={
                          process.env.REACT_APP_IMAGES_URL + "/" + ad?.images[0]
                        }
                        onError={(e) => (e.target.src = "/product.jpg")}
                        alt=""
                      />
                    ) : (
                      <img className="product-img" src={p1} alt="" />
                    )}
                    <div className="item-card-like">
                      <img src={heartRed} alt="" />
                    </div>
                  </Link>
                  <div className="item-card-text">
                    <div className="product-text-top">
                      <h6>
                        <Link to={`/details/${ad._id}`}>{ad?.name}</Link>
                      </h6>
                      <div>
                        {/* <i onClick={() => setP1Like(!p1Like)}>
                            {p1Like ? <AiFillHeart /> : <AiOutlineHeart />}
                          </i> */}
                        <AiFillEye className="me-2" />
                        {ad?.views?.length}
                      </div>
                    </div>
                    <div className="product-text-bottom">
                      <div className="desc">{ad?.description}</div>
                      <h5>$ {ad?.price}</h5>
                    </div>
                    <div className="item-card-brand">
                      <span>Brand:</span> {ad?.brand}
                    </div>
                    <div className="item-card-tags">
                      {ad?.option?.map((opt) => (
                        <>
                          {opt.split(",").map((op) => (
                            <div className="item-card-tag both">{op}</div>
                          ))}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <h5 className="text-center mt-3">No Ad Found !</h5>
              <div className="d-flex justify-content-center">
                <Link className="text-white" to="/">
                  <button className="banner-link text-light hover-shadow border-0 text-center text-white">
                    Go to Home
                  </button>
                </Link>
              </div>
            </>
          )}
        </div>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <Footer />
    </div>
  );
};

export default CategoryFilter;
