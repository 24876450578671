import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { toast } from "react-toastify";
import Moment from "moment";

import { getWalletBalanace } from "../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";

//---------trnslate
import { useTranslation } from "react-i18next";

const Wallet = () => {
  const { t } = useTranslation();

  //-----------Redux-----------
  const dispatch = useDispatch();
  const { walletBalance } = useSelector((state) => {
    return state.auth;
  });

  let walletAmount = walletBalance.length ? walletBalance[0].allamount : 0;

  useEffect(() => {
    dispatch(getWalletBalanace());
  }, [dispatch]);

  useEffect(() => {
    getAllWithDraws();
  }, []);

  //================Wallet withdraw Modal and API================
  const [walletModal, setWalletModal] = useState(false);
  function tog_WalletModal() {
    setFormData({
      amount: 0,
      description: "",
    });

    setWalletModal(!walletModal);
  }

  const [formData, setFormData] = useState({
    amount: 0,
    description: "",
  });

  const { amount, description } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleWithDrawAmount = async () => {
    let body = {
      withDrawAmount: amount,
      description: description,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/user/requestWithDraw",
      body,
      config
    );
    if (response.data.success === true) {
      toast.success(response?.data.msg);
      setWalletModal(!walletModal);
      getAllWithDraws();
    }
    if (response.data.success === false) {
      toast.error(response?.data.msg);
    }
  };
  //================Wallet withdraw Modal and end================

  //================Donate modal and API Start================
  const [donateModal, setDonateModal] = useState(false);
  function tog_DonateModal() {
    setDonateData({
      donateAmount: 0,
      donateDescription: "",
    });

    setDonateModal(!donateModal);
  }

  const [donateData, setDonateData] = useState({
    donateAmount: 0,
    donateDescription: "",
  });

  const { donateAmount, donateDescription } = donateData;

  const handleDonateChange = (e) => {
    setDonateData({ ...donateData, [e.target.name]: e.target.value });
  };

  const handleDonateAmount = async () => {
    let body = {
      donateAmount: donateAmount,
      donateDescription: donateDescription,
    };
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/user/donateToCharity",
      body,
      config
    );
    if (response.data.success === true) {
      toast.success(response?.data.msg);
      setDonateModal(!donateModal);
      getAllWithDraws();
    }
    if (response.data.success === false) {
      toast.error(response?.data.msg);
    }
  };
  //================Donate  Modal and API end================

  //================Get All Wallet Data for user Start=============
  const [allWithdraws, setAllWithdraws] = useState([]);
  const [isPending, setIsPending] = useState(false);
  // console.log(isPending);

  useEffect(() => {
    for (let i = 0; i < allWithdraws.length; i++) {
      setIsPending(allWithdraws[i].status === "Pending");
    }
  }, [allWithdraws]);

  const getAllWithDraws = async () => {
    const config = {
      headers: {
        "x-auth-token": localStorage.token,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/user/get/fetchWithDrawRequest",
      config
    );
    if (response) {
      setAllWithdraws(response?.data?.withdraws);
    }
  };
  //================Get All Wallet Data for user end===============

  return (
    <div>
      <div className="profile-infos">
        <div className="infos-left infos-2 w-100">
          <h5>{t("My Wallet")} </h5>
          <p className="font-poppins">
            {t("Total Credit")} :{" "}
            <span className="ms-3 text-success">
              ${walletAmount !== undefined ? walletAmount.toFixed(2) : 0}
            </span>
          </p>
        </div>
        <div className="infos-btns">
          <button
            className="green-btn hover-shadow mx-2"
            onClick={() => tog_WalletModal()}
            disabled={isPending ? true : false}
          >
            {t("Withdraw")}
          </button>
          <button
            className="green-btn hover-shadow"
            onClick={() => tog_DonateModal()}
            disabled={isPending ? true : false}
          >
            {t("Donate To Charity")}
          </button>
        </div>
      </div>

      <div className="profile-infos d-block mt-3">
        <h5 className="withdraw-title">{t("Withdraw")}</h5>
        <div className="table-responsive">
          <table className="table withdraw-table">
            <thead>
              <tr>
                <th>Sr. no</th>
                <th className="text-center">{t("Amount")}</th>
                <th className="text-center">{t("Description")}</th>
                <th className="text-center">{t("Date")}</th>
                <th className="text-center">{t("Status")}</th>
              </tr>
            </thead>
            <tbody>
              {allWithdraws &&
                allWithdraws.map((withdraw, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td className="text-center">${withdraw?.amount}</td>
                    <td className="text-center">{withdraw?.description}</td>

                    <td className="text-center">
                      {Moment(withdraw?.createdAt).format("LLL")}
                    </td>
                    {withdraw?.status === "Pending" && (
                      <td className="text-center text-warning">
                        {withdraw?.status}
                      </td>
                    )}
                    {withdraw?.status === "Approved" && (
                      <td className="text-center text-success">
                        {withdraw?.status}
                      </td>
                    )}
                    {withdraw?.status === "Cancelled" && (
                      <td className="text-center text-danger">
                        {withdraw?.status}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          {allWithdraws.length === 0 && (
            <div className="d-flex justify-content-center">
              <h6 className="text-center text-success">
                {t("No Withdraw request Found")}
              </h6>
            </div>
          )}
        </div>
      </div>

      <Modal
        className="fade"
        show={walletModal}
        onHide={() => {
          tog_WalletModal();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Withdraw Amount")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setWalletModal(false);
            }}
          />
        </div>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="form-field mb-2">
                <label>{t("Amount")}</label>
                <div className="form-input">
                  <input
                    type="number"
                    placeholder={t("Amount")}
                    name="amount"
                    value={amount}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <label>{t("Description")}</label>
              <div className="form-input form-textarea">
                <textarea
                  className="form-control form-control-solid"
                  rows={3}
                  name="description"
                  value={description}
                  onChange={handleChange}
                  // value={addQuestion}
                  // onChange={(e) => setAddQuestion(e.target.value)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button
              className="form-save green-btn hover-shadow"
              onClick={handleWithDrawAmount}
            >
              {t("Submit")}
            </button>
          </div>
        </ModalFooter>
      </Modal>

      {/* Donate amount request modal start*/}

      <Modal
        className="fade"
        show={donateModal}
        onHide={() => {
          tog_DonateModal();
        }}
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">{t("Donate Amount")}</h1>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setDonateModal(false);
            }}
          />
        </div>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="form-field mb-2">
                <label>{t("Amount")}</label>
                <div className="form-input">
                  <input
                    type="number"
                    placeholder={t("Amount")}
                    name="donateAmount"
                    value={donateAmount}
                    onChange={handleDonateChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <label>{t("Reason To donate")}</label>
              <div className="form-input form-textarea">
                <textarea
                  className="form-control form-control-solid"
                  rows={3}
                  name="donateDescription"
                  value={donateDescription}
                  onChange={handleDonateChange}
                  // value={addQuestion}
                  // onChange={(e) => setAddQuestion(e.target.value)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button
              className="form-save green-btn hover-shadow"
              onClick={handleDonateAmount}
            >
              {t("Submit")}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Wallet;
