import React, { useState, useEffect } from "react";
// import CreatableSelect from "react-select/creatable";
import { useSelector, useDispatch } from "react-redux";
import {
  addCategories,
  addSubCategories,
  deleteCategory,
  getAllCategoriesMain,
  getAllCateAndSub,
} from "../features/category/categorySlice";
//----------react-translate------------
import { useTranslation } from "react-i18next";
import { AiFillDelete } from "react-icons/ai";
// const { t } = useTranslation();

const AddCategory = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { categories, allCategories } = useSelector((state) => state.category);
  //=============Form Add Category start================
  const [name, setName] = useState("");

  //================= Handle file And Preview start==========================
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  //================= Handle file And Preview end==========================
  const handleAddCategory = () => {
    var cateData = new FormData();
    cateData.append("name", name);
    cateData.append("file", selectedFile);
    dispatch(addCategories(cateData));
    setName("");
    setSelectedFile(undefined);
  };
  //=============Form Add Category end================

  const [mainCategory, setMainCategoryData] = useState("");
  const [SubCategory, setSubCategoryData] = useState("");

  // const [SubCategory, setSubCategoryData] = useState([]);

  // let Options = SubCategory?.map((sub) => {
  //   return {
  //     label: sub.label,
  //     value: sub.value,
  //   };
  // });

  // let sendSub = Options?.map((opt) => opt.value);

  // handle Main change
  const handleMainCategoryChange = (e) => {
    setMainCategoryData(e.target.value);
  };
  // handle Sub change

  // const handleSubCategory = (e) => {
  //   setSubCategoryData(e.target.value);

  //   // setSubCategoryData(e);
  // };

  //===============Add Sub category start========================

  const handleAddSubCategory = (e) => {
    e.preventDefault();
    let body = {
      subCategories: SubCategory,
      category: mainCategory,
    };
    dispatch(addSubCategories(body));
    setMainCategoryData("");
    setSubCategoryData("");
  };

  //===============Add Sub category end========================

  //===============Delete main category =================
  const handleDeleteCategory = (e, id) => {
    e.preventDefault();
    let body = {
      id: id,
    };
    dispatch(deleteCategory(body));
  };
  //==============UseEffect start========================

  useEffect(() => {
    dispatch(getAllCategoriesMain());
    // console.log("mainCategory", mainCategory);

    dispatch(getAllCateAndSub());
  }, [dispatch]);
  //==============UseEffect end========================

  return (
    <div>
      <div className="profile-infos d-block">
        <h5 className="withdraw-title">
          {t("Add Category")} & {t("Sub Category")}{" "}
        </h5>
        <div className="row">
          <div className="col-lg-4">
            <div className="form-field">
              <label>{t("Category")}</label>
              <div className="form-input">
                <input
                  type="text"
                  placeholder={t("Please enter main category")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-field">
              <label>{t("Add Image")}</label>
              <div className="form-input">
                <input
                  type="file"
                  className="form-control"
                  onChange={onSelectFile}
                  accept="image/png, image/gif, image/jpeg"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2 my-auto">
            {selectedFile && (
              <img className="rounded-circle cat-img " src={preview} alt="" />
            )}
          </div>
          <div className="col-lg-3  my-auto">
            <label></label>

            <div className="infos-btns">
              <button
                className="mt-3 green-btn hover-shadow"
                onClick={handleAddCategory}
              >
                {t("Add Category")}
              </button>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-field">
              <label>{t("Select Main Category")}</label>
              <select
                value={mainCategory}
                onChange={(e) => handleMainCategoryChange(e)}
              >
                <option selected>{t("Select Main Category")}</option>
                {categories
                  ? categories.map((category, i) => (
                      <option value={category?._id} key={i}>
                        {category?.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="form-field">
              <label>{t("Sub Category")}</label>
              <div className="form-input">
                <input
                  type="text"
                  placeholder={t("Please enter sub category")}
                  value={SubCategory}
                  onChange={(e) => setSubCategoryData(e.target.value)}
                />
              </div>
              {/* <CreatableSelect
                isClearable={true}
                value={SubCategory}
                isMulti={true}
                onChange={(e) => handleSubCategory(e)}
              /> */}
            </div>
          </div>

          <div className="col-lg-3  my-auto">
            <label></label>

            <div className="infos-btns">
              <button
                className="mt-3 green-btn hover-shadow"
                onClick={handleAddSubCategory}
              >
                {t("Add Sub Category")}
              </button>
            </div>
          </div>
        </div>
        <h4 className="mt-4">{t("Main Categories")}</h4>

        <div className="row">
          {categories
            ? categories.map((category, i) => (
                <div className="col-4 col-lg-3 mb-2 card p-2" key={i}>
                  <span
                    className=" text-danger cursor-pointer"
                    onClick={(e) => handleDeleteCategory(e, category._id)}
                  >
                    <AiFillDelete />
                  </span>
                  <div className="mx-auto">
                    <img
                      className="rounded-circle cat-img mx-auto"
                      src={
                        process.env.REACT_APP_IMAGES_URL +
                        "/" +
                        category?.images
                      }
                      onError={(e) => (e.target.src = "/cate.jpg")}
                      alt=""
                    />
                    <h3 className="text-center fs-6 my-2">{category?.name}</h3>
                    <h5 className=" my-2 text-success border-bottom pb-2 ">
                      Sub Categories
                    </h5>
                    <div className="row px-2 ">
                      {allCategories &&
                        allCategories.map((cate) => (
                          <>
                            {category?._id === cate.category?._id && (
                              <small
                                className="col-5 badge badge-primary bg-primary p-1 my-1 w-50"
                                style={{ fontSize: "9px" }}
                              >
                                {cate?.name}
                              </small>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
