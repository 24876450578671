import axios from "axios";
import { toast } from "react-toastify";
const config = {
  headers: {
    "x-auth-token": localStorage.token,
  },
};

// Add Sport
const addSport = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/sport/add",
    body,
    config
  );

  if (response.data.success === true) {
    toast.success(response?.data.message);
    return response.data;
  }
  if (response.data.success === false) {
    toast.error(response.data.message);
  }
};

// Add Sport
const addSubSport = async (body) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/subSport/add",
    body,
    config
  );
  if (response.data.success === true) {
    toast.success(response?.data.message);
    return response.data;
  }
  if (response.data.success === false) {
    toast.error(response.data.message);
  }
};

// GET AllSport
const getAllSports = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/sport/get"
  );
  return response?.data;
};

const categoryServcie = {
  addSport,
  addSubSport,
  getAllSports,
  // getAllCateAndSub,
};

export default categoryServcie;
