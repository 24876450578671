import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import sportService from "./sportService";

const initialState = {
  sports: [],
  allSports: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Add Sport
export const addSport = createAsyncThunk(
  "sport/add",
  async (data, thunkAPI) => {
    try {
      await sportService.addSport(data);
      return await sportService.getAllSports();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Sub Sport
export const addSubSport = createAsyncThunk(
  "sport/subsport/add",
  async (body, thunkAPI) => {
    try {
      return await sportService.addSubSport(body);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All Sports
export const getAllMainSports = createAsyncThunk(
  "sport/all",
  async (thunkAPI) => {
    try {
      return await sportService.getAllSports();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All Categories and Sub Categories
// export const getAllMainSubCategories = createAsyncThunk(
//   "category/allMainSubCategories",
//   async (id, thunkAPI) => {
//     try {
//       return await categoryService.getAllCateAndSub(id);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const sportSlice = createSlice({
  name: "sport",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSport.pending, (state, action) => {
        state.isLoading = true;
        state.message = action.payload;
      })
      .addCase(addSport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.sports = action.payload;

        // state.user = action.payload;
      })
      .addCase(addSport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.user = null;
      })
      .addCase(addSubSport.pending, (state, action) => {
        state.isLoading = true;
        state.message = action.payload;
      })
      .addCase(addSubSport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allSports = action.payload;
      })
      .addCase(addSubSport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllMainSports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMainSports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sports = action.payload;
      });
    // .addCase(getAllMainSubCategories.pending, (state) => {
    //   state.isLoading = true;
    // })
    // .addCase(getAllMainSubCategories.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.allCategories = action.payload;
    // });
  },
});

export const { reset } = sportSlice.actions;
export default sportSlice.reducer;
